import { useBackendQueryFunction } from "./common/useBackendQueryFunction";
import { useQuery } from "react-query";

interface AmateurAssociationsData {
  data: string[];
}

export function useGetAmateurAssociations() {
  const queryFn = useBackendQueryFunction<AmateurAssociationsData>(
    "/contests/amateur/associations"
  );

  return useQuery<AmateurAssociationsData>(["GetAmateurAssociations"], queryFn);
}
