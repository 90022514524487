import { useMutation, useQueryClient } from "react-query";
import { useBackendMutationFunction } from "./common/useBackendMutationFunction";
import { UrlQuery } from "./common/useBackendUrl";
import { GetOpenFixturesKey } from "./useGetOpenFixtures";
import { GetTipgroupsKey } from "./useGetTipgroups";
import { GetTodayKey } from "./useGetToday";

interface PostLeaveTipGroupData {
  data: void; // TODO: what to do with null responses?
}

interface UsePostLeaveTipGroupProps extends UrlQuery {
  groupId: string;
}

export function usePostLeaveTipGroup(props: UsePostLeaveTipGroupProps) {
  const mutationFn = useBackendMutationFunction<PostLeaveTipGroupData, void>(
    "/groups/:groupId/leave",
    "POST",
    props
  );
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onSuccess: async () => {
      await new Promise<void>((resolve) => {
        setTimeout(() => {
          resolve();
        }, 500);
      });
      return await Promise.all([
        queryClient.invalidateQueries(GetTipgroupsKey),
        queryClient.invalidateQueries(GetTodayKey),
        queryClient.invalidateQueries(GetOpenFixturesKey),
      ]);
    },
  });
}
