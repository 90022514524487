import { Match } from "../../models/Match";

interface MatchDetailLastThreeProps {
  match: Match;
}

export function MatchDetailLastThree({ match }: MatchDetailLastThreeProps) {
  const lastResultsHome = match.homeTeam?.last_results;
  const lastResultsAway = match.awayTeam?.last_results;
  if (lastResultsHome || lastResultsAway) {
    // show some placeholder (e.g."-") if data is only present in one team
    return (
      <>
        <MatchDetailLastThreeElement
          value={
            lastResultsHome && lastResultsHome.length >= 1
              ? lastResultsHome[0]
              : "-"
          }
        />
        <MatchDetailLastThreeElement
          value={
            lastResultsHome && lastResultsHome.length >= 2
              ? lastResultsHome[1]
              : "-"
          }
        />
        <MatchDetailLastThreeElement
          value={
            lastResultsHome && lastResultsHome.length >= 3
              ? lastResultsHome[2]
              : "-"
          }
        />

        <span className="text-center inline-block w-1/3 text-sm uppercase">
          Letzte 3 Spiele
        </span>
        <MatchDetailLastThreeElement
          value={
            lastResultsAway && lastResultsAway.length >= 3
              ? lastResultsAway[2]
              : "-"
          }
        />
        <MatchDetailLastThreeElement
          value={
            lastResultsAway && lastResultsAway.length >= 2
              ? lastResultsAway[1]
              : "-"
          }
        />
        <MatchDetailLastThreeElement
          value={
            lastResultsAway && lastResultsAway.length >= 1
              ? lastResultsAway[0]
              : "-"
          }
        />
      </>
    );
  }
  return null;
}

interface MatchDetailLastThreeElementProps {
  value: string;
}

function MatchDetailLastThreeElement({
  value,
}: MatchDetailLastThreeElementProps) {
  const color =
    value === "W"
      ? "bg-k-green-100"
      : value === "L"
      ? "bg-k-red-100"
      : "bg-gray-200";
  const text =
    value === "W" ? "S" : value === "L" ? "N" : value === "D" ? "U" : "-";
  return <span className={`last-three-circle ${color}`}>{text}</span>;
}
