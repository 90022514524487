import { Label } from "../common/Label";
import { HighlightState } from "./MatchRow";

interface TippableMatchResultProps {
  homeValue?: string;
  awayValue?: string;
  highlightState: HighlightState;
}

export function TippableMatchResult({
  homeValue,
  awayValue,
  highlightState,
}: TippableMatchResultProps) {
  return (
    <div className="match-result-tippable">
      <Label
        type={highlightState === "selected" ? "no-tip" : "input"}
        size="medium"
        className="match-result-tippable-label"
      >
        <span className="match-result-number">
          {homeValue === "-1" || awayValue === "-1" ? "-" : homeValue ?? "-"}
        </span>
        <span className="match-result-divider">:</span>
        <span className="match-result-number">
          {awayValue === "-1" || awayValue === "-1" ? "-" : awayValue ?? "-"}
        </span>
      </Label>
    </div>
  );
}
