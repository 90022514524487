import { Link } from "react-router-dom";
import { useGetOpenFixtures } from "../../api/useGetOpenFixtures";
import { League } from "../../models/League";
import { LeagueWithMatchlist } from "../../models/LeagueWithMatchList";
import { getSportTypePriority } from "../../models/SportType";
import { Tipgroup } from "../../models/Tipgroup";
import { MatchPageType } from "../../pages/MatchPage";
import {
  linkUrlLeague,
  linkUrlMatch,
  linkUrlTipgroupLeague,
} from "../../shared/linkUrlHelper";
import { leagueLogoPath } from "../../shared/urlHelper";
import { useSportsTypeHeaderAndSort } from "../../shared/useSportsTypeHeaderAndSort";
import { ButtonWrapper, LinkButton } from "../common/Button";
import Card from "../common/Card";
import LeagueImage from "../common/LeagueImage";
import { SportTypeHeader } from "../home/SportTypeHeader";
import List, { ListItem, ListRow } from "../list/List";

interface OpenTippsProps {
  group?: Tipgroup;
  openFixtures: LeagueWithMatchlist[];
}

export function OpenTipps({ group, openFixtures }: OpenTippsProps) {
  const missingTips = openFixtures?.reduce((a, b) => a + b.fixtures.length, 0);
  const type: MatchPageType = "openTipps";
  const firstMatchEl = openFixtures.find((el) => el.fixtures.length > 0);
  const firstMatchLeague = firstMatchEl?.contest;
  const firstMatch = firstMatchEl?.fixtures[0].fixture;
  const tippAllUrl =
    firstMatch && firstMatchLeague
      ? linkUrlMatch(
          firstMatch.contest_id,
          firstMatchLeague.name,
          firstMatch.id,
          firstMatch.round.number,
          type,
          group?.uuid,
          group?.name
        )
      : null;

  const contests = openFixtures
    .filter((leagueWithMatchList) => leagueWithMatchList.fixtures.length > 0)
    .map((leagueWithMatchList) => leagueWithMatchList.contest);

  const addSportsTypeHeaders = useSportsTypeHeaderAndSort(contests);

  return missingTips > 0 && tippAllUrl ? (
    <Card
      labelText={
        missingTips === 1
          ? "Noch 1 offener Tipp!"
          : `Noch ${missingTips} offene Tipps!`
      }
      labelType="card-attention"
      labelLinkTo={tippAllUrl}
    >
      <List>
        {contests?.map((league, index) => {
          return (
            <OpenTippsElement
              key={league.id}
              league={league}
              group={group}
              addSportsTypeHeader={
                addSportsTypeHeaders &&
                league.sportType &&
                (index === 0 ||
                  getSportTypePriority(league.sportType) !==
                    getSportTypePriority(contests.at(index - 1)?.sportType))
              }
            />
          );
        })}
      </List>
      <ButtonWrapper>
        {tippAllUrl ? (
          <LinkButton to={tippAllUrl}>
            {missingTips === 1 ? "Jetzt tippen" : "Alle Spiele tippen"}
          </LinkButton>
        ) : null}
      </ButtonWrapper>
    </Card>
  ) : null;
}

interface OpenTippsElementProps {
  league: League;
  group?: Tipgroup;
  addSportsTypeHeader: boolean;
}

function OpenTippsElement({
  league,
  group,
  addSportsTypeHeader,
}: OpenTippsElementProps) {
  const { data: openFixturesData } = useGetOpenFixtures({
    props: group ? { group_id: group.uuid } : undefined,
  });

  const missingTips =
    openFixturesData?.data.find(
      (openFixture) => openFixture.contest.id === league.id
    )?.fixtures.length ?? 0;

  return missingTips > 0 ? (
    <>
      {addSportsTypeHeader && <SportTypeHeader type={league.sportType} />}
      <Link
        to={
          group
            ? linkUrlTipgroupLeague(
                group.uuid,
                group.name,
                league.id,
                league.name,
                league.currentRound.number
              )
            : linkUrlLeague(league.id, league.name, league.currentRound.number)
        }
        className="text-default-color"
      >
        <ListRow>
          <ListItem>
            <LeagueImage image={leagueLogoPath(league.id)} name={league.name} />
          </ListItem>
          <ListItem>{league.name}</ListItem>
          <ListItem className="text-status-negative-red100">
            {`${missingTips || 0} Spiel${missingTips === 1 ? "" : "e"}`}
          </ListItem>
        </ListRow>
      </Link>
    </>
  ) : null;
}
