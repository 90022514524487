import { GroupApplication } from "../../models/GroupApplication";
import { ApplicationCountBadge } from "./ApplicationCountBadge";

interface ApplicationCountLabelProps {
    groupName: string;
    groupApplication?: GroupApplication;
}

export function ApplicationCountLabel({ groupName, groupApplication }: ApplicationCountLabelProps) {

    if (!groupApplication) {
        return <>{groupName}</>
    }

    if (!groupApplication.group.user_is_admin) {
        return <>{groupName}</>
    }

    const count = groupApplication.applications > 9 ? "9+" : groupApplication.applications.toString()

    return (
        <>
            <span className="inline-flex items-center space-x-2 max-w-full relative pr-6">
                <span className="truncate min-w-0 max-w-full">{groupName}</span>
                <ApplicationCountBadge applications={count} />
            </span>
        </>
    )
}
