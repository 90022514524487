import { UrlQuery } from "./common/useBackendUrl";
import { useBackendQueryFunction } from "./common/useBackendQueryFunction";
import { useQuery } from "react-query";
import { TipUser } from "../models/TipUser";

export const GetTipgroupApplicationsKey = "GetTipgroupApplications";

interface GetTipgroupApplicationsData {
  data: TipUser[];
}

interface UseGetTipgroupApplicationsProps {
  props: UseGetTipgroupApplicationsQueryProps;
  enabled: boolean;
}

interface UseGetTipgroupApplicationsQueryProps extends UrlQuery {
  groupId: string;
}

export function useGetTipgroupApplications(
  props: UseGetTipgroupApplicationsProps
) {
  const queryFn = useBackendQueryFunction<GetTipgroupApplicationsData>(
    "/groups/:groupId/applications",
    props.props,
    true
  );

  return useQuery<GetTipgroupApplicationsData>(
    [GetTipgroupApplicationsKey, props],
    queryFn,
    { enabled: props.enabled }
  );
}
