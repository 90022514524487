import { Gameday } from "../../models/Gameday";
import Dropdown, { DropdownItem } from "../common/Dropdown";

interface GamedayChooserHeaderProps {
  rounds: Gameday[];
  gameday: Gameday;
  navigate: (roundNumber: number) => void;
}

export function GamedayChooserHeader({
  rounds,
  gameday,
  navigate,
}: GamedayChooserHeaderProps) {
  const currentRoundIndex = rounds.findIndex(
    (r) => r.number === gameday.number
  );
  return rounds && gameday ? (
    <div className="gameday-chooser-header">
      {rounds[currentRoundIndex - 1] ? (
        <div
          className="gameday-chooser-header-left"
          onClick={() => navigate(rounds[currentRoundIndex - 1].number)}
        />
      ) : (
        <div className="gameday-chooser-header-empty" />
      )}

      <div className="gameday-chooser-header-center">
        <Dropdown text={gameday.name}>
          {rounds.map((round) => {
            const active = gameday.name === round.name;
            return (
              <DropdownItem
                key={round.name}
                onClick={() => navigate(round.number)}
                active={active}
              >
                {round.name}
              </DropdownItem>
            );
          })}
        </Dropdown>
      </div>

      {rounds[currentRoundIndex + 1] ? (
        <div
          className="gameday-chooser-header-right"
          onClick={() => navigate(rounds[currentRoundIndex + 1].number)}
        />
      ) : (
        <div className="gameday-chooser-header-empty" />
      )}
    </div>
  ) : null;
}
