import { useMutation, useQueryClient } from "react-query";
import { ApplicationResponse } from "../models/ApplicationResponse";
import { useBackendMutationFunction } from "./common/useBackendMutationFunction";
import { GetRecentTipgroupMembersKey } from "./useGetRecentTipgroupMembers";
import { GetTipgroupApplicationsKey } from "./useGetTipgroupApplications";
import { GetTipgroupMembersKey } from "./useGetTipgroupMembers";

interface PostHandleApplicationData {
  data: void; // TODO: what to do with null responses?
}

export function usePostHandleApplication() {
  const mutationFn = useBackendMutationFunction<
    PostHandleApplicationData,
    ApplicationResponse
  >("/groups/applications/handle");
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries(GetTipgroupMembersKey),
        queryClient.invalidateQueries(GetRecentTipgroupMembersKey),
        queryClient.invalidateQueries(GetTipgroupApplicationsKey),
      ]);
    },
  });
}
