import React, { useState, useRef, useEffect } from "react";
import "react-image-crop/dist/ReactCrop.css";
import ReactCrop, {
  Crop,
  PixelCrop,
  centerCrop,
  makeAspectCrop,
} from "react-image-crop";
import { canvasPreview } from "./canvasPreview";
import Button from "../common/Button";

interface CropImageProps {
  image: string;
  handleImageSaveEvent?: () => void;
  handleCloseModal?: () => void;
  setImageFormData?: (image: FormData) => void;
  setBase64Image?: (image: string) => void;
}

export function CropImage({
  setImageFormData,
  setBase64Image,
  handleImageSaveEvent,
  handleCloseModal,
  image,
}: CropImageProps) {
  const [crop, setCrop] = useState<Crop>();
  const imageRef = useRef<HTMLImageElement>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

  function onImageLoad(e: unknown) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    const { naturalWidth: width, naturalHeight: height } = (e as any)
      .currentTarget;

    const tempCrop = centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 100,
        },
        1,
        width as number,
        height as number
      ),
      width as number,
      height as number
    );

    setCrop(tempCrop);
  }

  useEffect(() => {
    if (
      completedCrop?.width &&
      completedCrop?.height &&
      imageRef.current &&
      previewCanvasRef.current != null
    ) {
      canvasPreview(
        imageRef.current,
        previewCanvasRef.current,
        completedCrop,
        1,
        0
      );
    }
  }, [completedCrop]);

  function saveCroppedImage() {
    if (
      previewCanvasRef.current &&
      handleImageSaveEvent &&
      setBase64Image &&
      setImageFormData
    ) {
      previewCanvasRef.current.toBlob(function (blob: Blob | null) {
        if (blob) {
          const formData = new FormData();
          formData.append("file", blob);
          setImageFormData(formData);
        }
      });
      setBase64Image(previewCanvasRef.current.toDataURL("image/jpg"));
      handleImageSaveEvent();
    }
  }

  return (
    <div>
      <ReactCrop
        aspect={1}
        crop={crop}
        onChange={(c: Crop) => setCrop(c)}
        onComplete={(c: PixelCrop) => setCompletedCrop(c)}
      >
        <img ref={imageRef} alt="Crop me" src={image} onLoad={onImageLoad} />
      </ReactCrop>

      <div>
        {!!completedCrop && (
          <canvas
            ref={previewCanvasRef}
            style={{
              display: "none",
              border: "1px solid black",
              objectFit: "contain",
              width: completedCrop.width,
              height: completedCrop.height,
            }}
          />
        )}
      </div>

      <div className={"flex justify-between"}>
        <Button color="primary" onClick={handleCloseModal}>
          Abbrechen
        </Button>
        <Button color="primary" onClick={saveCroppedImage}>
          Ok
        </Button>
      </div>
    </div>
  );
}
