import { Gameday } from "../../models/Gameday";
import { League } from "../../models/League";
import { Tipgroup } from "../../models/Tipgroup";
import { TipgroupRankingData } from "../../models/TipgroupRankingData";
import { ErrorCard } from "../common/ErrorCard";
import { RoundRanking } from "./Ranking";

interface RankingDetailRoundProps {
  roundNumber: number;
  tipGroup: Tipgroup;
  league: League;
  rounds: Gameday[];
  rankingData: TipgroupRankingData;
  gameday: Gameday;
}

export function RankingDetailRound({
  league,
  roundNumber,
  tipGroup,
  rounds,
  gameday,
  rankingData,
}: RankingDetailRoundProps) {
  const gameDayData = rounds.find((gameDay) => gameDay.number === roundNumber);
  return gameDayData ? (
    <>
      <RoundRanking
        group={tipGroup}
        league={league}
        roundNumber={gameday.number}
        size="complete"
        rankingData={rankingData}
      />
    </>
  ) : (
    <ErrorCard type="empty" />
  );
}
