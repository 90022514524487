import React from "react";
import { useParams } from "react-router";
import { Header } from "../components/common/Header";
import { E2BarSticky } from "../components/e2/E2Bar";
import { NewsUrlParamKeys } from "../models/ParamKeys";
import { Error404Page } from "./Error404Page";

export function NewsPage() {
  const params = useParams<NewsUrlParamKeys>();

  if (params.newsUrl) {
    return (
      <React.Fragment>
        <Header label={"News"} />
        <iframe
          scrolling="auto"
          className="newsIframe"
          src={`${decodeURIComponent(params.newsUrl)}`}
          sandbox="allow-scripts allow-same-origin"
        />
        <E2BarSticky reset />
      </React.Fragment>
    );
  } else {
    return <Error404Page />;
  }
}
