export const getInitials = (name: string): string => {
  const names = name.split(" ");
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};
export const getInitial = (name: string): string => {
  const names = name.split(" ");
  const initial = names[0].substring(0, 1).toUpperCase();

  return initial;
};

export const getTipgroupInitial = (name: string): string => {
  const names = name.split(" ");
  const initials =
    names.length > 1
      ? names
          .slice(0, 2)
          .map((n) => n[0])
          .join("")
      : names[0].slice(0, 1);
  return initials;
};
export const getLeagueInitial = (name: string): string => {
  const names = name.split(" ");
  const initials =
    names[0].length > 3
      ? names
          .slice(0, 3)
          .map((n) => (n[0] === "(" ? n[1] : n[0]))
          .join("")
      : names[0];
  return initials;
};
