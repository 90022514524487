import { Dispatch, useCallback, useEffect, useMemo, useState } from "react";
import { useGetTipps } from "../../api/useGetTipps";
import { Match, matchHasSpecialState } from "../../models/Match";
import { MatchTeamName } from "./MatchTeamName";
import { MatchResult } from "./MatchResult";
import { TippResult } from "../../models/TippResult";
import { Label, LabelText, LabelType } from "../common/Label";
import { teamLogoPath } from "../../shared/urlHelper";
import { useIsBreakpointSm } from "../../shared/useBreakpoints";
import {
  LocalTippValues,
  usePostTippWithDebounce,
} from "../../api/usePostTippWithDebounce";
import { Tipgroup } from "../../models/Tipgroup";
import { useGetLeague } from "../../api/useGetLeague";
import BetMatch from "../common/BetMatch";
import { gameTime } from "../../shared/timeHelper";
import { MatchPageType } from "../../pages/MatchPage";
import { linkUrlMatch } from "../../shared/linkUrlHelper";
import { League } from "../../models/League";
import { TippMode } from "../../models/TippMode";
import { phaseEndMinute } from "../../models/Phase";
import {
  MatchLiveState,
  _calculateLiveState,
} from "../../shared/matchLiveState";

interface MatchRowProps {
  match: Match;
  group?: Tipgroup;
  matchType?: MatchPageType;
  isMatchDetail?: boolean;
  tippDeleted?: boolean;
  setTippDeleted?: Dispatch<boolean>;
  otherUserId?: string;
}

export type HighlightState =
  | "correct"
  | "incorrect"
  | "selected"
  | "no-background"
  | "default";
export type TipChange = "home" | "away" | "draw";

// selected = blue outline
// incorrect = red outline
// correct = green outline
// can be 0

interface MatchResultState {
  liveState: MatchLiveState;
  tippResult?: TippResult;
}

function _calculateHighlightState(
  liveState: MatchLiveState,
  homeScore?: number,
  awayScore?: number,
  userScore?: number,
  isOtherUser?: boolean
) {
  const score = userScore ?? 0;
  const selectedHome = (homeScore ?? 0) > (awayScore ?? 0);
  const selectedAway = (awayScore ?? 0) > (homeScore ?? 0);
  const selectedDraw =
    homeScore !== undefined &&
    homeScore !== -1 &&
    awayScore !== undefined &&
    awayScore !== -1 &&
    awayScore === homeScore;
  const homeTeamState: HighlightState =
    liveState === "pre-match" && isOtherUser
      ? "no-background"
      : selectedHome && liveState !== MatchLiveState.PreMatch && score > 0
      ? "correct"
      : selectedHome && liveState !== MatchLiveState.PreMatch && score === 0
      ? "incorrect"
      : selectedHome && liveState === MatchLiveState.PreMatch
      ? "selected"
      : "default";
  const awayTeamState: HighlightState =
    liveState === "pre-match" && isOtherUser
      ? "no-background"
      : selectedAway && liveState !== MatchLiveState.PreMatch && score > 0
      ? "correct"
      : selectedAway && liveState !== MatchLiveState.PreMatch && score === 0
      ? "incorrect"
      : selectedAway && liveState === MatchLiveState.PreMatch
      ? "selected"
      : "default";
  const resultState: HighlightState =
    selectedDraw && liveState !== MatchLiveState.PreMatch && score > 0
      ? "correct"
      : selectedDraw && liveState !== MatchLiveState.PreMatch && score === 0
      ? "incorrect"
      : selectedDraw && liveState === MatchLiveState.PreMatch
      ? "selected"
      : "default";
  return [homeTeamState, awayTeamState, resultState];
}

function _getLabelType(score: number | undefined): LabelType {
  switch (score) {
    case 0:
      return "incorrect";
    case 1:
      return "correct-tendency";
    case 2:
      return "correct-difference";
    case 3:
      return "correct-score";
    default:
      return "incorrect";
  }
}

export function MatchRow({
  match,
  group,
  matchType,
  isMatchDetail = false,
  tippDeleted,
  setTippDeleted,
  otherUserId,
}: MatchRowProps) {
  // no need to wrap query, is already in cache
  const { data: getTippData } = useGetTipps({
    leagueId: match.contest_id,
    round_number: match.round.number,
    user_id: otherUserId,
  });
  const { data: leagueData } = useGetLeague({ leagueId: match.contest_id });
  //tippMode = "simple";
  const league = leagueData?.data;
  const tippData = getTippData?.data[match.id];
  const tippMode = getTippData?.meta?.prediction_mode ?? "full";
  return league ? (
    <MatchRowDisplay
      tippData={tippData}
      tippMode={tippMode}
      league={league}
      match={match}
      group={group}
      isMatchDetail={isMatchDetail}
      matchType={matchType}
      tippDeleted={tippDeleted}
      setTippDeleted={setTippDeleted}
      isOtherUser={otherUserId != null}
    />
  ) : null;
}
interface MatchRowDisplayProps {
  tippData?: TippResult;
  tippMode?: TippMode;
  league: League;
  match: Match;
  group?: Tipgroup;
  matchType?: MatchPageType;
  isMatchDetail?: boolean;
  tippDeleted?: boolean;
  setTippDeleted?: Dispatch<boolean>;
  isOtherUser?: boolean;
}
export function MatchRowDisplay({
  tippData,
  tippMode = "full",
  league,
  match,
  group,
  isMatchDetail = false,
  matchType,
  tippDeleted,
  setTippDeleted,
  isOtherUser,
}: MatchRowDisplayProps) {
  const matchState: MatchResultState = useMemo(() => {
    return {
      liveState: _calculateLiveState(match),
      tippResult: tippData,
    };
  }, [match, tippData]);

  const homeTeamName = match.homeTeam?.shortName ?? match.homeTeamPlaceholder;
  const awayTeamName = match.awayTeam?.shortName ?? match.awayTeamPlaceholder;

  const hasTipp =
    tippData !== null &&
    tippData?.home_score != null &&
    tippData.away_score != null;

  // SHOW (Nicht getippt) LABEL ABOVE RESULT
  const showLabelNoTip =
    !hasTipp &&
    (matchState.liveState !== MatchLiveState.PreMatch ||
      !match.predictable ||
      matchHasSpecialState(match)) &&
    tippMode === "simple";

  // SHOW (-:-) LABEL ABOVE RESULT
  const showLabelNoTipRunning =
    !hasTipp &&
    tippMode === "full" &&
    (matchState.liveState !== MatchLiveState.PreMatch ||
      !match.predictable ||
      matchHasSpecialState(match));

  // SHOW (1:2) USER TIP ABOVE RESULT
  const showLabelTip =
    hasTipp &&
    (matchState.liveState !== MatchLiveState.PreMatch ||
      !match.predictable ||
      matchHasSpecialState(match)) &&
    tippMode === "full";

  // SHOW (+1| USER SCORE ON RIGHT SIDE
  const userScore = tippData?.user_score ?? tippData?.live_score;

  const isTippable =
    !isOtherUser &&
    matchState.liveState === MatchLiveState.PreMatch &&
    match.predictable &&
    !matchHasSpecialState(match);

  const [hasError, setHasError] = useState(false);

  const {
    responseData: {
      homeValue: responseValueHome,
      awayValue: responseValueAway,
    },
    localValues: {
      homeValue: localHomeValue,
      awayValue: localAwayValue,
      id: localValueId,
    },
    setTipValues: setTipValues,
  } = usePostTippWithDebounce({
    matchId: match.id,
    leagueId: match.contest_id,
    roundNumber: match.round.number,
    onError: (error) => {
      if (error) {
        setHasError(true);
      }
    },
  });
  useEffect(() => {
    if (tippDeleted === true) {
      setTipValues({ id: match.id, homeValue: -1, awayValue: -1 });
      if (setTippDeleted) {
        setTippDeleted(false);
      }
    }
  }, [match.id, setTipValues, setTippDeleted, tippDeleted]);
  const [homeTeamHighlightState, awayTeamHighlightState, resultHighlightState] =
    _calculateHighlightState(
      _calculateLiveState(match),
      (match.id === localValueId ? localHomeValue : null) ??
        tippData?.home_score,
      (match.id === localValueId ? localAwayValue : null) ??
        tippData?.away_score,
      tippData?.user_score ?? tippData?.live_score,
      isOtherUser
    );
  const setTipValuesWrapper = useCallback(
    (newValue: LocalTippValues) => {
      setTipValues(newValue);
      setHasError(false);
    },
    [setTipValues]
  );

  const tippedHomeValue = !hasError
    ? responseValueHome ?? tippData?.home_score
    : undefined;
  const tippedAwayValue = !hasError
    ? responseValueAway ?? tippData?.away_score
    : undefined;

  const _changeTip = useCallback(
    (tipChange: TipChange) => {
      if (tippMode === "full") {
        const home =
          tipChange === "home"
            ? (!hasError
                ? (match.id === localValueId ? localHomeValue : null) ??
                  tippedHomeValue ??
                  -1
                : tippedHomeValue ?? -1) + 1
            : !hasError
            ? (match.id === localValueId ? localHomeValue : null) ??
              tippedHomeValue ??
              0
            : tippedHomeValue ?? 0;
        const away =
          tipChange === "away"
            ? (!hasError
                ? (match.id === localValueId ? localAwayValue : null) ??
                  tippedAwayValue ??
                  -1
                : tippedAwayValue ?? -1) + 1
            : !hasError
            ? (match.id === localValueId ? localAwayValue : null) ??
              tippedAwayValue ??
              0
            : tippedAwayValue ?? 0;
        setTipValuesWrapper({ homeValue: home, awayValue: away, id: match.id });
      } else {
        const home = tipChange === "home" || tipChange === "draw" ? 1 : 0;
        const away = tipChange === "away" || tipChange === "draw" ? 1 : 0;
        setTipValuesWrapper({ homeValue: home, awayValue: away, id: match.id });
      }
    },
    [
      match.id,
      localValueId,
      localAwayValue,
      localHomeValue,
      setTipValuesWrapper,
      tippMode,
      tippedAwayValue,
      tippedHomeValue,
      hasError,
    ]
  );

  const isSM = useIsBreakpointSm();

  const label = (
    <MatchRowLabel
      matchTippData={tippData}
      showLabelNoTip={showLabelNoTip}
      showLabelNoTipRunning={showLabelNoTipRunning}
      showLabelTip={showLabelTip}
      userScore={userScore}
      isOtherUser={isOtherUser}
    />
  );
  const homeDisplayValue = !hasError
    ? (match.id === localValueId ? localHomeValue?.toString() : null) ??
      tippedHomeValue?.toString()
    : tippedHomeValue?.toString();
  const awayDisplayValue = !hasError
    ? (match.id === localValueId ? localAwayValue?.toString() : null) ??
      tippedAwayValue?.toString()
    : tippedAwayValue?.toString();

  if (homeTeamName && awayTeamName && league) {
    const linkUrl =
      matchType && !isMatchDetail
        ? linkUrlMatch(
            match.contest_id,
            league.name,
            match.id,
            match.round.number,
            matchType,
            group?.uuid,
            group?.name
          )
        : undefined;
    const endMinute = match.phase?.stage && phaseEndMinute(match.phase?.stage);
    const subtitle =
      matchState.liveState === MatchLiveState.PostMatch &&
      endMinute &&
      endMinute > 90 ? (
        <span style={{ fontSize: "12px" }}>{"nach 90'"}</span>
      ) : undefined;
    const inGameTime = match.phase && gameTime(match.phase);
    return (
      <div
        className={`
        match-row 
        ${showLabelNoTip ? "match-row-label" : ""} 
        match-row-${tippMode} ${
          showLabelNoTip || showLabelNoTipRunning ? "match-row-opacity-low" : ""
        } 
        ${isTippable ? "match-row-tippable" : " "} 
        ${label != null ? "match-row-has-label" : ""}
        `}
      >
        {hasError && <p>Ein Fehler ist aufgetreten</p>}
        <div className="match-row-items">
          {_calculateLiveState(match) === "live" &&
            match.phase &&
            match.phase.stage !== "Unknown" &&
            inGameTime && (
              <div className="match-row-time">{`${inGameTime}'`}</div>
            )}
          <MatchTeamName
            teamName={homeTeamName}
            isTippable={isTippable}
            teamIconUrl={
              match.homeTeam ? teamLogoPath(match.homeTeam.id) : undefined
            }
            highlightState={homeTeamHighlightState}
            position="left"
            tippmode={tippMode}
            changeTip={_changeTip}
            linkUrl={linkUrl}
            isOtherUser={isOtherUser}
          />
          <MatchResult
            tippResult={tippData}
            match={match}
            userScore={userScore}
            highlightState={resultHighlightState}
            liveState={matchState.liveState}
            tippmode={tippMode}
            homeValue={homeDisplayValue}
            awayValue={awayDisplayValue}
            changeTip={_changeTip}
            label={label}
            linkUrl={linkUrl}
            showDate={isOtherUser}
            sportType={league.sportType}
          />
          <MatchTeamName
            teamName={awayTeamName}
            teamIconUrl={
              match.awayTeam ? teamLogoPath(match.awayTeam.id) : undefined
            }
            isTippable={isTippable}
            highlightState={awayTeamHighlightState}
            position="right"
            tippmode={tippMode}
            changeTip={_changeTip}
            linkUrl={linkUrl}
            isOtherUser={isOtherUser}
          />
          {!isTippable && userScore != null && userScore > 0 && (
            <Label
              className="match-row-points-label"
              type="correct-tendency"
              side={!isSM}
              size="medium"
              isOtherUser={isOtherUser}
            >
              <LabelText>{`+${userScore.toString()}${
                isSM ? " Pkt" : ""
              }`}</LabelText>
            </Label>
          )}
          {!isTippable &&
            matchState.liveState === MatchLiveState.PreMatch &&
            isOtherUser &&
            tippData != null && (
              <Label
                className="match-row-points-label"
                type="correct-tendency"
                size="small"
                checkmark
                isOtherUser={isOtherUser}
              >
                <div className="w-2 h-2 relative z-[1] inline-flex items-center justify-center">
                  <svg
                    className="absolute transform translate-x-0.5"
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={15}
                    viewBox="0 0 16 15"
                  >
                    <path
                      fill="#0CB332"
                      fillRule="nonzero"
                      d="M6.52 14.45c.539 0 .95-.213 1.234-.637L15.027 2.68c.102-.154.176-.304.223-.45.047-.145.07-.287.07-.425 0-.365-.123-.666-.369-.905-.246-.238-.555-.357-.928-.357-.257 0-.474.05-.648.152-.174.102-.341.275-.5.52L6.488 11.3 3.242 7.277c-.278-.338-.625-.507-1.039-.507-.377 0-.69.12-.935.363a1.23 1.23 0 0 0-.37.914c0 .164.028.32.084.47.056.15.155.306.295.467l4.036 4.887c.322.386.725.578 1.207.578Z"
                    />
                  </svg>
                </div>
              </Label>
            )}
        </div>
        {matchState.liveState === MatchLiveState.PostMatch && subtitle}
        {!isOtherUser &&
          matchState.liveState != MatchLiveState.PostMatch &&
          league.prediction_mode !== "simple" && (
            <BetMatch
              leagueId={match.contest_id}
              gameday={match.round.number}
              matchId={match.id}
            />
          )}
      </div>
    );
  } else return null;
}

interface MatchRowLabelProps {
  showLabelNoTip: boolean;
  showLabelTip: boolean;
  showLabelNoTipRunning: boolean;
  matchTippData?: TippResult;
  userScore: number | undefined;
  isOtherUser?: boolean;
}

function MatchRowLabel({
  showLabelNoTip,
  showLabelTip,
  showLabelNoTipRunning,
  matchTippData,
  userScore,
  isOtherUser = false,
}: MatchRowLabelProps) {
  if (showLabelNoTip) {
    return (
      <Label
        type="no-tip"
        size="medium"
        className="match-result-label"
        isOtherUser={isOtherUser}
      >
        <LabelText>Nicht getippt</LabelText>
      </Label>
    );
  } else if (showLabelTip && matchTippData) {
    return (
      <Label
        type={_getLabelType(userScore)}
        size="medium"
        className="match-result-label match-result-label-tip"
        isOtherUser={isOtherUser}
      >
        <span className="match-result-label-tip-number">
          {matchTippData?.home_score}
        </span>
        <span className="match-result-label-tip-divider">:</span>
        <span className="match-result-label-tip-number">
          {matchTippData?.away_score}
        </span>
      </Label>
    );
  } else if (showLabelNoTipRunning) {
    return (
      <Label
        type="incorrect"
        size="medium"
        className="match-result-label match-result-label-no-tip"
        isOtherUser={isOtherUser}
      >
        <span className="match-result-label-tip-number">-</span>
        <span className="match-result-label-tip-divider">:</span>
        <span className="match-result-label-tip-number">-</span>
      </Label>
    );
  } else {
    return null;
  }
}
