import { LocalTippValues } from "../../api/usePostTippWithDebounce";
import { Match } from "../../models/Match";
import { teamLogoPath } from "../../shared/urlHelper";
import TeamLogo from "../common/TeamLogo";
import { TippableMatchResult } from "./TippableMatchResult";
//import Plus from "../../assets/team-name/result-controls.svg";

interface MatchDetailTippOverlayProps {
  match: Match;
  homeResult?: number;
  awayResult?: number;
  setTipValues: (newValue: LocalTippValues) => void;
}

export function MatchDetailTippOverlay({
  match,
  homeResult,
  awayResult,
  setTipValues,
}: MatchDetailTippOverlayProps) {
  return (
    <div className="match-detail-tipp-overlay">
      <div
        className="match-row match-row-full match-row-tippable 
        match-row-has-label"
      >
        <div className="match-row-items">
          <div
            className={`match-team-name 
            match-team-name-left
            match-team-name-${
              homeResult !== undefined &&
              awayResult !== undefined &&
              homeResult !== -1 &&
              awayResult !== -1 &&
              homeResult > awayResult
                ? "selected"
                : "default"
            }
            match-team-name-tippable`}
          >
            <div
              className="match-team-name-btn match-team-name-plus"
              onClick={() => {
                setTipValues(
                  homeResult === -1
                    ? { homeValue: 0, awayValue: 0, id: match.id }
                    : {
                        homeValue: (homeResult ?? -1) + 1,
                        awayValue: awayResult ?? 0,
                        id: match.id,
                      }
                );
              }}
            >
              <span>+</span>
            </div>
            <div
              className="match-team-name-content"
              onClick={() => {
                setTipValues(
                  homeResult === -1
                    ? { homeValue: 0, awayValue: 0, id: match.id }
                    : {
                        homeValue: (homeResult ?? -1) + 1,
                        awayValue: awayResult ?? 0,
                        id: match.id,
                      }
                );
              }}
            >
              <div className="match-team-name-bg" />

              <div className="match-team-name-icon">
                <TeamLogo
                  image={match.homeTeam ? teamLogoPath(match.homeTeam?.id) : ""}
                  name={match.homeTeam?.id || ""}
                />
              </div>
            </div>
            <button
              className="match-team-name-btn match-team-name-minus"
              onClick={() => {
                setTipValues({
                  homeValue: (homeResult ?? 1) - 1,
                  awayValue: awayResult ?? 0,
                  id: match.id,
                });
              }}
              disabled={homeResult === -1 || homeResult == undefined}
            >
              <span>&minus;</span>
            </button>
          </div>
          <div>
            <TippableMatchResult
              highlightState={
                homeResult !== undefined &&
                awayResult !== undefined &&
                homeResult !== -1 &&
                awayResult !== -1 &&
                homeResult === awayResult
                  ? "selected"
                  : "default"
              }
              homeValue={homeResult?.toString()}
              awayValue={awayResult?.toString()}
            />
          </div>
          <div
            className={`match-team-name 
              match-team-name-right
              match-team-name-${
                homeResult !== undefined &&
                awayResult !== undefined &&
                homeResult !== -1 &&
                awayResult !== -1 &&
                homeResult < awayResult
                  ? "selected"
                  : "default"
              }
              match-team-name-tippable`}
          >
            <div
              className="match-team-name-btn match-team-name-plus"
              onClick={() => {
                setTipValues(
                  awayResult === -1
                    ? {
                        homeValue: 0,
                        awayValue: 0,
                        id: match.id,
                      }
                    : {
                        homeValue: homeResult ?? 0,
                        awayValue: (awayResult ?? -1) + 1,
                        id: match.id,
                      }
                );
              }}
            >
              <span>+</span>
            </div>
            <div
              className="match-team-name-content"
              onClick={() => {
                setTipValues(
                  awayResult === -1
                    ? {
                        homeValue: 0,
                        awayValue: 0,
                        id: match.id,
                      }
                    : {
                        homeValue: homeResult ?? 0,
                        awayValue: (awayResult ?? -1) + 1,
                        id: match.id,
                      }
                );
              }}
            >
              <div className="match-team-name-bg" />
              <div className="match-team-name-icon">
                <TeamLogo
                  image={match.awayTeam ? teamLogoPath(match.awayTeam?.id) : ""}
                  name={match.awayTeam?.name || ""}
                />
              </div>
            </div>
            <button
              className="match-team-name-btn match-team-name-minus"
              onClick={() => {
                setTipValues({
                  homeValue: homeResult ?? 0,
                  awayValue: (awayResult ?? 1) - 1,
                  id: match.id,
                });
              }}
              disabled={homeResult === -1 || homeResult == undefined}
            >
              <span>&minus;</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
