import jwt_decode from "jwt-decode";

interface JwtProperties {
  exp: number;
}

interface JwtExpiresSoonProps {
  token: string;
}

export function jwtExpiresSoon({ token }: JwtExpiresSoonProps) {
  return (
    // true if valid for less than 2 hours
    token.length === 0 ||
    jwt_decode<JwtProperties>(token).exp * 1000 - Date.now() <
      2 * 60 * 60 * 1000
  );
}
