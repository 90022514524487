import { UrlQuery } from "./common/useBackendUrl";
import { useBackendQueryFunction } from "./common/useBackendQueryFunction";
import { useQuery } from "react-query";
import { TippResult } from "../models/TippResult";
import { MetaData } from "../models/MetaData";

export const GetTippsKey = "GetTipp";

interface GetTippsData {
  data: Record<string, TippResult>;
  meta?: MetaData;
}

export interface UseGetTippsProps extends UrlQuery {
  leagueId: string;
  round_number: number;
  user_id?: string;
}

export function useGetTipps(props: UseGetTippsProps) {
  const queryFn = useBackendQueryFunction<GetTippsData>(
    "/predictions/round/:leagueId",
    props,
    true
  );

  return useQuery<GetTippsData>([GetTippsKey, props], queryFn);
}
