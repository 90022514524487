import { linkUrlNewTipgroup } from "../../shared/linkUrlHelper";
import { ButtonWrapper, LinkButton } from "../common/Button";
import Card from "../common/Card";

export function NoTipgroup() {
  return (
    <Card labelText="Du bist in keiner Tippgruppe">
      <p className="mb-4">
        Mit einem Zugangs-Link kannst du einer Tippgruppe beitreten.
      </p>
      <p className="mb-4">
        Frage bei Freunden oder Kollegen nach ob sie bereits eine Tippgruppe
        gegründet haben.
      </p>
      <p className="mb-4">
        Alternativ kannst du eine eigene Tippgruppe gründen und selbst
        Mitglieder einladen.
      </p>
      <ButtonWrapper>
        <LinkButton to={linkUrlNewTipgroup()}>Tippgruppe erstellen</LinkButton>
      </ButtonWrapper>
    </Card>
  );
}
