import React, { useContext } from "react";
import { useMediaQuery } from "react-responsive";

interface BreakpointContextProps {
  isXs: boolean;
  isSm: boolean;
  isMd: boolean;
  isLg: boolean;
  isXl: boolean;
}

const BreakpointContext = React.createContext<BreakpointContextProps>({
  isXs: false,
  isSm: false,
  isMd: false,
  isLg: false,
  isXl: false,
});

interface BreakpointProviderProps {
  children: React.ReactNode;
}

export function BreakpointProvider({ children }: BreakpointProviderProps) {
  const isXs = useMediaQuery({ query: "(min-width: 375px)" });
  const isSm = useMediaQuery({ query: "(min-width: 640px)" });
  const isMd = useMediaQuery({ query: "(min-width: 768px)" });
  const isLg = useMediaQuery({ query: "(min-width: 1024px)" });
  const isXl = useMediaQuery({ query: "(min-width: 1280px)" });

  return (
    <BreakpointContext.Provider
      value={{ isXs: isXs, isSm: isSm, isMd: isMd, isLg: isLg, isXl: isXl }}
    >
      {children}
    </BreakpointContext.Provider>
  );
}

export function useIsBreakpointXl() {
  return useContext(BreakpointContext).isXl;
}

export function useIsBreakpointMd() {
  return useContext(BreakpointContext).isMd;
}

export function useIsBreakpointSm() {
  return useContext(BreakpointContext).isSm;
}

export function useIsBreakpointLg() {
  return useContext(BreakpointContext).isLg;
}

export function useIsBreakpointXs() {
  return useContext(BreakpointContext).isXs;
}
