import React from "react";
import { useIsBreakpointLg } from "../../shared/useBreakpoints";

// HOWTO: add {...{ isRight: true, order: 2 }} to ColumnsWrapper children

interface ChildrenProps {
  isRight?: boolean;
  order?: number;
}
interface ColumnsWrapperProps {
  children: (React.ReactElement<ChildrenProps> | null)[];
}

export function ColumnsWrapper({ children }: ColumnsWrapperProps) {
  const multiColumn = useIsBreakpointLg();
  const childrenLeft = children.filter((c) => c && !c.props.isRight);
  const childrenRight = children
    .filter((c) => c && c.props.isRight)
    .sort((a, b) =>
      a?.props.order && b?.props.order && a.props.order < b.props.order ? -1 : 1
    );
  return multiColumn && childrenRight.length > 0 ? (
    <div className="flex w-full max-w-[1100px] mx-auto">
      <div className="w-2/3">{childrenLeft}</div>
      <div className="w-1/3">{childrenRight}</div>
    </div>
  ) : (
    <>{children}</>
  );
}
