import { Phase, phaseEndMinute, phaseStartMinute } from "../models/Phase";

const locale = "de-DE";

export const formattedDateFull = (timestamp: string): string => {
  if (!timestamp) {
    return "Unbekannt";
  }
  const correctDate = _correctDate(new Date(timestamp));

  return new Intl.DateTimeFormat(locale, {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
  }).format(correctDate);
};

export const formattedDateShort = (timestamp: string): string => {
  if (!timestamp) {
    return "Unbekannt";
  }
  const correctDate = _correctDate(new Date(timestamp));

  return new Intl.DateTimeFormat(locale, {
    day: "2-digit",
    month: "2-digit",
  }).format(correctDate);
};

export const formattedDateTimeFull = (timestamp: string): string => {
  if (!timestamp) {
    return "Unbekannt";
  }
  const correctDate = _correctDate(new Date(timestamp));

  return new Intl.DateTimeFormat(locale, {
    weekday: "long",
    day: "numeric",
    month: "numeric",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  }).format(correctDate);
};

export const formattedTime = (timestamp: string): string => {
  if (!timestamp) {
    return "Unbekannt";
  }
  const correctDate = _correctDate(new Date(timestamp));

  return new Intl.DateTimeFormat(locale, {
    hour: "2-digit",
    minute: "2-digit",
  }).format(correctDate);
};

function _correctDate(date: Date): Date {
  // this is needed because mobivention sends utc timestamps without correct timezone indication
  return new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    )
  );
}

export const gameTime = (phase: Phase): number => {
  const startMinute = phaseStartMinute(phase.stage);
  const endMinute = phaseEndMinute(phase.stage);
  if (startMinute === undefined || endMinute === undefined) {
    return 0;
  }
  const correctPhaseTime = _correctDate(new Date(phase.time));
  const calculatedMinute =
    (Date.now() - correctPhaseTime.getTime()) / 60000 + startMinute;
  if (calculatedMinute <= startMinute) {
    return Math.ceil(startMinute);
  }
  if (calculatedMinute >= endMinute) {
    return Math.ceil(endMinute);
  }
  return Math.ceil(calculatedMinute);
};

export const isToday = (timestamp: string): boolean => {
  const today = new Date().setHours(0, 0, 0, 0);
  const date = new Date(timestamp).setHours(0, 0, 0, 0);
  if (today === date) return true;
  return false;
};

export const isSameDay = (firstDate: Date, secondDate: Date): boolean => {
  return (
    firstDate.getFullYear() == secondDate.getFullYear() &&
    firstDate.getMonth() == secondDate.getMonth() &&
    firstDate.getDate() == secondDate.getDate()
  );
};
