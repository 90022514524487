import { UrlQuery } from "./common/useBackendUrl";
import { useBackendQueryFunction } from "./common/useBackendQueryFunction";
import { useQuery } from "react-query";
import { TipUser } from "../models/TipUser";

export const GetRecentTipgroupMembersKey = "GetRecentTipgroupMembers";

interface GetRecentTipgroupMembersData {
  data: TipUser[];
}

interface UseGetRecentTipgroupMembersProps extends UrlQuery {
  groupId: string;
}

export function useGetRecentTipgroupMembers(
  props: UseGetRecentTipgroupMembersProps
) {
  const queryFn = useBackendQueryFunction<GetRecentTipgroupMembersData>(
    "/groups/:groupId/members",
    {
      ...props,
      recent_only: true,
    },
    true
  );

  return useQuery<GetRecentTipgroupMembersData>(
    [GetRecentTipgroupMembersKey, props],
    queryFn
  );
}
