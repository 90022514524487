import { Gameday } from "./Gameday";
import { Phase } from "./Phase";
import { ResultWrapper } from "./Result";
import { Status } from "./Status";
import { Team } from "./Team";
import { TippTendency } from "./TippTendency";

export interface Match {
  id: string;
  round: Gameday;
  season_id: string;
  contest_id: string;
  kickoff: string;
  status: Status;
  started: boolean;
  predictable: boolean;
  finished: boolean;
  homeTeam?: Team;
  homeTeamPlaceholder?: string;
  awayTeam?: Team;
  awayTeamPlaceholder?: string;
  liveScores?: ResultWrapper;
  results?: ResultWrapper;
  prediction_tendency?: TippTendency;
  phase?: Phase;
}

export const matchHasSpecialState = (match: Match) =>
  match.status === "CanceledBefore" ||
  match.status === "CanceledRunning" ||
  match.status === "WithoutRating" ||
  match.status === "Annulled";
