import { useCallback } from "react";
import {
  AddLeagueState,
  AddLeagueUiState,
} from "../components/tipgroup/AddLeague";
import { SportTypeType } from "../models/SportType";

export function useLeagueListSetUiState(
  setState: React.Dispatch<React.SetStateAction<AddLeagueState>>
) {
  return useCallback(
    (uiState: AddLeagueUiState, sportType?: SportTypeType) =>
      setState((state) => {
        return {
          ...state,
          uiState: uiState,
          sportType: sportType ?? state.sportType,
        };
      }),
    [setState]
  );
}
