import { useMutation, useQueryClient } from "react-query";
import { useBackendMutationFunction } from "./common/useBackendMutationFunction";
import { UrlQuery } from "./common/useBackendUrl";
import { GetRecentTipgroupMembersKey } from "./useGetRecentTipgroupMembers";
import { GetTipgroupMembersKey } from "./useGetTipgroupMembers";

export enum UserManageAction {
  MakeAdmin = "make_admin",
  RemoveAdmin = "remove_admin",
  Kick = "kick",
}
interface PostLeaveTipGroupData {
  data?: void;
}

interface usePostManageGroupUserGroupProps extends UrlQuery {
  groupId: string;
}

interface usePostManageGroupUserProps extends UrlQuery {
  action: UserManageAction;
  user_id: string;
}

export function usePostManageGroupUser(
  props: usePostManageGroupUserGroupProps
) {
  const mutationFn = useBackendMutationFunction<
    PostLeaveTipGroupData,
    usePostManageGroupUserProps
  >("/groups/:groupId/manage_user", "POST", props);
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries(GetTipgroupMembersKey),
        queryClient.invalidateQueries(GetRecentTipgroupMembersKey),
      ]);
    },
  });
}
