import { useBackendQueryFunction } from "./common/useBackendQueryFunction";
import { useQuery } from "react-query";
import { GroupApplication } from "../models/GroupApplication";

interface GroupApplicationsData {
  data: GroupApplication[];
}


export function useGetGroupApplications() {
  const queryFn = useBackendQueryFunction<GroupApplicationsData>(
    "/me/group_applications",
    {},
    true
  );

  return useQuery<GroupApplicationsData>(["GetGroupApplications", {}], queryFn);
}
