import { ReactNode } from "react";
import { useNavigate } from "react-router";
import { Match, matchHasSpecialState } from "../../models/Match";
import { SportTypeType } from "../../models/SportType";
import { specialStatusToString } from "../../models/Status";
import { TippMode } from "../../models/TippMode";
import { TippResult } from "../../models/TippResult";
import { MatchLiveState } from "../../shared/matchLiveState";
import { DateMatchResult } from "./DateMatchResult";
import { HighlightState, TipChange } from "./MatchRow";
import { TippableMatchResult } from "./TippableMatchResult";
import { UntippableMatchResult } from "./UntippableMatchResult";

interface MatchResultProps {
  match: Match;
  liveState: MatchLiveState;
  tippResult?: TippResult;
  userScore?: number;
  tippmode: TippMode;
  highlightState: HighlightState;
  homeValue?: string;
  awayValue?: string;
  changeTip: (tipChange: TipChange) => void;
  label?: ReactNode;
  linkUrl?: string;
  showDate?: boolean;
  sportType?: SportTypeType;
}

export function MatchResult({
  match,
  liveState,
  tippResult,
  userScore,
  tippmode,
  highlightState,
  homeValue,
  awayValue,
  changeTip,
  label,
  linkUrl,
  showDate,
  sportType,
}: MatchResultProps) {
  const navigate = useNavigate();
  // TODO: use states to style the teamname (see comments in interface HighlightState)
  if (matchHasSpecialState(match)) {
    return (
      <div
        className={`match-result match-result-${highlightState} h-[33px] text-sm match-result-number`}
      >
        {label}
        <span className="px-2">{specialStatusToString(match.status)}</span>
      </div>
    );
  }
  if (
    liveState === MatchLiveState.PreMatch &&
    tippmode === "full" &&
    match.predictable
  ) {
    if (showDate) {
      return (
        <div
          onClick={() => {
            if (linkUrl) {
              navigate(linkUrl);
            }
          }}
        >
          <DateMatchResult match={match} forceTimeOnly />
        </div>
      );
    } else {
      return (
        <div
          onClick={() => {
            if (linkUrl) {
              navigate(linkUrl);
            }
          }}
        >
          <TippableMatchResult
            highlightState={highlightState}
            homeValue={homeValue}
            awayValue={awayValue}
          />
        </div>
      );
    }
  } else if (
    liveState === MatchLiveState.PreMatch &&
    tippmode === "simple" &&
    match.predictable
  ) {
    return (
      <div
        className={`match-result-x match-result-x-${highlightState}`}
        onClick={() => {
          if (highlightState === "selected") {
            if (linkUrl) {
              navigate(linkUrl);
            }
          } else {
            changeTip("draw");
          }
        }}
      >
        <span className="match-result-x-img" />
      </div>
    );
  } else {
    return (
      <div
        onClick={() => {
          if (linkUrl) {
            navigate(linkUrl);
          }
        }}
      >
        <UntippableMatchResult
          result={
            liveState === "live"
              ? match.liveScores ?? match.results
              : match.results
          }
          userScore={userScore}
          liveState={liveState}
          tippmode={tippmode}
          tippResult={tippResult}
          highlightState={highlightState}
          label={label}
          sportType={sportType}
        />
      </div>
    );
  }
}
