import { useEffect, useRef, EffectCallback, DependencyList } from "react";

export const useSkipFirstEffect = (
  effect: EffectCallback,
  deps?: DependencyList
) => {
  const firstRenderRef = useRef(true);

  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      return;
    }

    return effect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
