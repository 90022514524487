import IconBack from "../../assets/icons/back.svg";
import IconMenu from "../../assets/icons/menu.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { Dispatch, ReactNode, useCallback, useMemo, useState } from "react";
import IconClose from "../../assets/icons/close.svg";
import { useEnvironment } from "../../Environment";
import { Navigation } from "./Navigation";
import { TrackingAction } from "../../models/TrackedPage";
import LeagueImage from "./LeagueImage";
interface HeaderProps {
  label?: ReactNode | string;
  labelIcon?: ReactNode;
  labelIconUrl?: string;
  sublabel?: string;
  sublabelIcon?: string;
  children?: ReactNode;
  backFunc?: () => void;
}

export function Header({
  label = `Tippspiel${
    SERVER === "stage" ? " - STAGING" : SERVER === "dev" ? " - DEV" : ""
  }`,
  labelIcon,
  labelIconUrl,
  sublabel,
  sublabelIcon,
  children,
  backFunc,
}: HeaderProps) {
  const env = useEnvironment();
  const [mobileMenuIsOpen, setMobileMenuState] = useState(false);
  const closeMobileMenu = useCallback(() => {
    if (!env.isWebApp) {
      setBodyScrollable(true);
    }
    setMobileMenuState(false);
  }, [env.isWebApp]);
  const openMobileMenu = () => {
    if (!env.isWebApp) {
      setBodyScrollable(false);
    }
    setMobileMenuState(true);
  };

  const setBodyScrollable = (scrollable: boolean) => {
    // TODO: NOT WORKING IN IOS SAFARI YET OFC BECAUSE IT'S STUPID
    document.body.style.overflowY = scrollable ? "auto" : "hidden";
  };
  const location = useLocation();
  // hack to prevent back showing after gameday was changed. can't find another way for now...
  const showClose = useMemo(
    () => cantGoBack(location.pathname, env.deeplinkUrl),
    [location.pathname, env.deeplinkUrl]
  );
  const closeFunc = useEnvironment().closeFunc;
  const navigate = useNavigate();

  const goBack = useCallback(() => {
    if (mobileMenuIsOpen) {
      closeMobileMenu();
    } else {
      if (showClose) {
        if (closeFunc) {
          closeFunc();
        }
      } else {
        backFunc ? backFunc() : navigate(-1);
      }
    }
  }, [
    backFunc,
    closeFunc,
    navigate,
    showClose,
    closeMobileMenu,
    mobileMenuIsOpen,
  ]);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
  (window as any).TippNavi = { goBack };

  return (
    <>
      <header className={`header ${sublabel ? "header-with-sublabel" : ""}`}>
        <div className="header-shadow">
          <div className="header-wrapper">
            <div className="header-top">
              <div className="header-top-button">
                {showClose ? (
                  closeFunc ? (
                    <div
                      onClick={() => closeFunc()}
                      className="header-top-button-action"
                    >
                      <IconClose />
                    </div>
                  ) : null
                ) : (
                  <div onClick={goBack} className="header-top-button-action">
                    <IconBack />
                  </div>
                )}
              </div>
              <div className="header-top-content">
                {labelIcon && (
                  <div className="header-top-icon">{labelIcon}</div>
                )}
                {labelIconUrl && (
                  <img className="header-top-icon" src={labelIconUrl} />
                )}
                <span className="header-top-label">{label}</span>
              </div>
              <div className="header-top-button">
                {!env.isVereinsheim ? (
                  <div
                    onClick={openMobileMenu}
                    className="header-top-button-action"
                  >
                    <IconMenu className="header-top-menu-icon" />
                  </div>
                ) : null}
              </div>
            </div>
            {sublabel && <div className="header-divider" />}
            {sublabel && (
              <div className="header-bottom">
                {sublabelIcon && (
                  <LeagueImage image={sublabelIcon} name={sublabel} size="sm" />
                )}
                <span className="header-bottom-sublabel">{sublabel}</span>
              </div>
            )}
          </div>
        </div>
        {children}
      </header>
      {!env.isVereinsheim ? (
        <Navigation open={mobileMenuIsOpen} closeAction={closeMobileMenu} />
      ) : null}
    </>
  );
}

interface HeaderButtonSwitchProps<X extends string, Y extends string> {
  options: [X, Y];
  state: X | Y;
  setState: Dispatch<X | Y>;
}

export function HeaderButtonSwitch<X extends string, Y extends string>({
  options,
  state,
  setState,
}: HeaderButtonSwitchProps<X, Y>) {
  const trackingFunc = useEnvironment().trackingFunc;
  return (
    <div className="header-buttons">
      <HeaderButton
        value={options[0]}
        currentValue={state}
        onChange={(newValue: string) => {
          const newState = newValue === options[0] ? options[0] : undefined;
          if (newState) {
            setState(newState);
          }
          trackingFunc ? trackingFunc(TrackingAction.HeaderToggleChange) : null;
        }}
      />
      <HeaderButton
        value={options[1]}
        currentValue={state}
        onChange={(newValue: string) => {
          const newState = newValue === options[1] ? options[1] : undefined;
          if (newState) {
            setState(newState);
          }
          trackingFunc ? trackingFunc(TrackingAction.HeaderToggleChange) : null;
        }}
      />
    </div>
  );
}

function cantGoBack(pathname: string, deeplinkUrl: string): boolean {
  if (pathname === "/" || pathname === deeplinkUrl) {
    return true;
  } else if (pathname.includes("league") && deeplinkUrl.includes("league")) {
    // special handling for league (preloading + ignoring gamedays)
    const splitPathName = pathname.split("/");
    const splitDeeplinkUrl = deeplinkUrl.split("/");
    const pathIndexOfLeague = splitPathName.indexOf("league");
    const pathIndexOfGameday = splitPathName.includes("gameday")
      ? splitPathName.indexOf("gameday")
      : splitPathName.length;
    const pathLeagueId =
      pathIndexOfGameday - pathIndexOfLeague === 3
        ? splitPathName[pathIndexOfLeague + 2]
        : splitPathName[pathIndexOfLeague + 1];
    const deepLinkIndexOfLeague = splitDeeplinkUrl.indexOf("league");
    const deepLinkIndexOfGameday = splitDeeplinkUrl.includes("gameday")
      ? splitDeeplinkUrl.indexOf("gameday")
      : splitDeeplinkUrl.length;
    const deepLinkLeagueId =
      deepLinkIndexOfGameday - deepLinkIndexOfLeague === 3
        ? splitDeeplinkUrl[deepLinkIndexOfLeague + 2]
        : splitDeeplinkUrl[deepLinkIndexOfLeague + 1];
    if (pathname.includes("tipgroup") && deeplinkUrl.includes("tipgroup")) {
      // only check groupIds when "tipgroup" is in both strings
      const pathTipgroupId = splitPathName[pathIndexOfLeague - 1];
      const deepLinkTipgroupId = splitDeeplinkUrl[deepLinkIndexOfLeague - 1];
      return (
        deepLinkLeagueId === pathLeagueId &&
        pathTipgroupId === deepLinkTipgroupId
      );
    } else {
      return deepLinkLeagueId === pathLeagueId;
    }
  }
  return false;
}

interface HeaderButtonProps {
  value: string;
  currentValue: string;
  onChange: (newValue: string) => void;
}

export function HeaderButton({
  value,
  currentValue,
  onChange,
}: HeaderButtonProps) {
  const isEnabled = currentValue !== value;
  return (
    <div
      className={`header-button ${!isEnabled ? "header-button-active" : ""}`}
      onClick={() => {
        if (isEnabled) {
          onChange(value);
        }
      }}
    >
      {value}
    </div>
  );
}
