import Card from "../common/Card";
import { ButtonWrapper, LinkButton } from "../../components/common/Button";
import List, { ListItem, ListRow } from "../list/List";
import { Link } from "react-router-dom";
import { Tipgroup } from "../../models/Tipgroup";
import { useGetTipgroupMembers } from "../../api/useGetTipgroupMembers";
import { League } from "../../models/League";
import { Match } from "../../models/Match";
import {
  linkUrlMatch,
  linkUrlTipgroupLeague,
  linkUrlTipGroupList,
} from "../../shared/linkUrlHelper";
import { TipGroupImage } from "./TipGroupImage";
interface TipGroupRecommendationProps {
  league: League;
  roundnumber?: number;
  match?: Match;
  size: "short" | "long";
  tipGroups: Tipgroup[];
}
export function TipGroupRecommendation({
  league,
  roundnumber,
  match,
  size,
  tipGroups,
}: TipGroupRecommendationProps) {
  const filteredElements = tipGroups?.filter(
    (tipGroup) => tipGroup.contest_ids?.includes(league.id) === true
  );

  const notVereinsheimGroups = filteredElements.filter(
    (g) => !g.is_vereinsheim
  );
  const vereinsheimGroups = filteredElements.filter((g) => g.is_vereinsheim);
  const notVereinsheimElements =
    size === "short" && notVereinsheimGroups.length > 5
      ? notVereinsheimGroups.slice(0, 5)
      : notVereinsheimGroups;
  const vereinsheimElements =
    size === "short" && vereinsheimGroups.length > 5
      ? vereinsheimGroups.slice(0, 5)
      : vereinsheimGroups;

  return (
    <>
      {notVereinsheimElements.length > 0 ? (
        <TipGroupRecommendationWrapper
          type="notVereinsheim"
          elements={notVereinsheimElements}
          linkUrl={linkUrlTipGroupList(
            league.id,
            league.name,
            match?.id,
            match?.round.number
          )}
          showButton={size === "short" && notVereinsheimGroups.length > 5}
          league={league}
          match={match}
          roundNumber={roundnumber}
        />
      ) : null}
      {vereinsheimElements.length > 0 ? (
        <TipGroupRecommendationWrapper
          type="vereinsheim"
          elements={vereinsheimElements}
          linkUrl={linkUrlTipGroupList(
            league.id,
            league.name,
            match?.id,
            match?.round.number,
            true
          )}
          showButton={size === "short" && vereinsheimGroups.length > 5}
          league={league}
          match={match}
          roundNumber={roundnumber}
        />
      ) : null}
    </>
  );
}

interface TipGroupRecommendationWrapperProps {
  type: "vereinsheim" | "notVereinsheim";
  linkUrl?: string;
  showButton: boolean;
  league: League;
  match?: Match;
  roundNumber?: number;
  elements: Tipgroup[];
}

export function TipGroupRecommendationWrapper({
  type,
  linkUrl,
  showButton,
  league,
  match,
  roundNumber,
  elements,
}: TipGroupRecommendationWrapperProps) {
  const typeStr = match ? "Dieses Spiel" : "Dieser Wettbewerb";
  const title =
    type === "notVereinsheim"
      ? `Tippgruppe${elements.length === 1 ? "" : "n"}`
      : `Vereinsheim App${elements.length === 1 ? "" : "s"}`;
  return (
    <Card labelText={title}>
      <p className="text-lg text-center mx-auto max-w-prose mb-4">
        {`${typeStr} befindet sich in deine${
          elements.length === 1 ? "r" : "n"
        } folgenden ${title}`}
      </p>
      <List>
        {elements.map((tipGroup) => {
          return (
            <TipGroupRecommendationElement
              group={tipGroup}
              league={league}
              key={tipGroup.uuid}
              match={match}
              roundNumber={roundNumber}
            />
          );
        })}
      </List>
      {showButton && linkUrl ? (
        <ButtonWrapper>
          <LinkButton to={linkUrl}>Alle {title}</LinkButton>
        </ButtonWrapper>
      ) : null}
    </Card>
  );
}

interface TipGroupRecommendationElementProps {
  group: Tipgroup;
  league: League;
  match?: Match;
  roundNumber?: number;
}
function TipGroupRecommendationElement({
  group,
  league,
  match,
  roundNumber,
}: TipGroupRecommendationElementProps) {
  const { data: members } = useGetTipgroupMembers({ groupId: group.uuid });
  const url = match
    ? linkUrlMatch(
        league.id,
        league.name,
        match.id,
        match.round.number,
        "league",
        group.uuid,
        group.name
      )
    : linkUrlTipgroupLeague(
        group.uuid,
        group.name,
        league.id,
        league.name,
        roundNumber ?? league.currentRound.number
      );
  return (
    <Link to={url}>
      <ListRow>
        <ListItem>
          <TipGroupImage
            name={group.name}
            image={group.image?.thumbnail_file_url}
            fullImage={group.image?.full_file_url}
          />
        </ListItem>
        <ListItem>{group.name}</ListItem>
        {members?.data ? (
          <ListItem>{`${members.data.length} Mitglied${
            members.data.length === 1 ? "" : "er"
          }`}</ListItem>
        ) : null}
      </ListRow>
    </Link>
  );
}
