import { useEffect, useState } from "react";
import { usePostJoinVereinsheim } from "../../api/usePostJoinVereinsheim";
import { Tipgroup } from "../../models/Tipgroup";
import { Error404Page } from "../../pages/Error404Page";
import { LoadingIndicator } from "../common/LoadingIndicator";
import { VereinsheimGroupHome } from "./VereinsheimGroupHome";
import { VereinsheimLeagueHome } from "./VereinsheimLeagueHome";

interface VereinsheimHomeProps {
  vereinsheimId: string;
  leagueId?: string;
}

export function VereinsheimHome({
  vereinsheimId,
  leagueId,
}: VereinsheimHomeProps) {
  const [error, setError] = useState(false);
  const [group, setGroup] = useState<Tipgroup>();
  const { mutate: joinVereinsheim } = usePostJoinVereinsheim({
    props: { vereinsheimId: vereinsheimId },
    onSuccess: (response) => setGroup(response.data),
    onError: () => setError(true),
  });
  useEffect(() => {
    joinVereinsheim();
  }, [joinVereinsheim]);
  if (group) {
    if (leagueId) {
      return <VereinsheimLeagueHome group={group} leagueId={leagueId} />;
    } else {
      return (
        <VereinsheimGroupHome groupId={group.uuid} groupName={group.name} />
      );
    }
  } else if (error) {
    return <Error404Page />;
  } else {
    return <LoadingIndicator />;
  }
}
