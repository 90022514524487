import React, { useEffect, useState } from "react";
import { useGetRecentTipgroupMembers } from "../../api/useGetRecentTipgroupMembers";
import { useGetTipgroup } from "../../api/useGetTipgroup";
import { useGetTipgroupApplications } from "../../api/useGetTipgroupApplications";
import { useGetTipgroupMembers } from "../../api/useGetTipgroupMembers";
import { useGetTopLeagues } from "../../api/useGetTopLeagues";
import { usePostHandleApplication } from "../../api/usePostHandleApplication";
import { League } from "../../models/League";
import Button, { ButtonWrapper, LinkButton } from "../common/Button";
import Card from "../common/Card";
import { QueryWrapper } from "../common/QueryWrapper";
import SectionHeader from "../common/SectionHeader";
import List from "../list/List";
import { AddLeague, AddLeagueState } from "./AddLeague";
import { TipGroupAdminLeagueList } from "./TipGroupAdminLeagueList";
import { TipGroupAdminName } from "./TipGroupAdminName";
import {
  EditTipGroupHeader,
  NewTipGroupAddLeagueHeader,
} from "./TipGroupHeader";
import { TipGroupMember } from "./TipGroupMember";
import { useNavigate } from "react-router";
import StickyFooter from "../common/StickyFooter";
import { usePutEditTipgroup } from "../../api/usePutEditTipgroup";
import { TipGroupWaiting } from "./TipGroupWaiting";
import { linkUrlTipGroupMembers } from "../../shared/linkUrlHelper";
import { TipGroupAdminDescription } from "./TipGroupAdminDescription";
import { TipGroupAdminImage } from "./TipGroupAdminImage";
import { usePostEditTipgroupImage } from "../../api/usePostEditTipgroupImage";
import { E2BarSticky } from "../e2/E2Bar";
import { useLeagueListSetUiState } from "../../shared/useLeagueListSetUiState";

interface EditTipGroupParams {
  groupId: string;
}

export function EditTipGroup({ groupId }: EditTipGroupParams) {
  const navigate = useNavigate();

  const { data: tipGroupData } = useGetTipgroup({
    groupId: groupId,
  });
  const { data: topLeaguesData, ...topLeaguesQuery } = useGetTopLeagues();

  const { data: recentMembers, ...recentMembersQuery } =
    useGetRecentTipgroupMembers({ groupId: groupId });
  const { data: members } = useGetTipgroupMembers({ groupId: groupId });
  const { mutate: handleApplication } = usePostHandleApplication();
  const { data: applications } = useGetTipgroupApplications({
    props: { groupId: groupId },
    enabled: true,
  });
  const [name, setName] = useState(tipGroupData?.data.name ?? "");
  const [imageFormData, setImageFormData] = useState<FormData>();
  const [base64Image, setBase64Image] = useState<string>();
  const [description, setDescription] = useState(
    tipGroupData?.data.description ?? ""
  );
  useEffect(() => {
    // sets name to state once after feed is loaded
    if (name === "" && tipGroupData) {
      setName(tipGroupData?.data.name);
    }
  }, [name, tipGroupData]);

  const [state, setState] = useState<AddLeagueState>({
    uiState: "start",
    leagues: tipGroupData?.data?.contests?.map((c) => c.contest) ?? [],
  });
  useEffect(() => {
    if (state.leagues?.length == 0) {
      setState({
        ...state,
        leagues: tipGroupData?.data?.contests?.map((c) => c.contest) ?? [],
      });
    }
  }, [state, tipGroupData?.data?.contests]);

  const addLeague = (league: League) =>
    setState({
      ...state,
      leagues: [...state.leagues, league],
    });

  const removeLeague = (id: string) => {
    if (state.leagues.some((el) => el.id === id)) {
      setState({
        ...state,
        leagues: state.leagues.filter((el) => el.id !== id),
      });
    }
  };

  const setUiState = useLeagueListSetUiState(setState);
  const backFunc = () => {
    if (state.uiState === "start") {
      navigate(-1);
    } else {
      setState(
        state.uiState === "amateur-association"
          ? { leagues: state.leagues, uiState: "start" }
          : state.uiState === "amateur-teamType"
          ? { ...state, uiState: "amateur-association", association: undefined }
          : state.uiState === "amateur-leagueType"
          ? { ...state, uiState: "amateur-teamType", teamType: undefined }
          : state.uiState === "amateur-league"
          ? { ...state, uiState: "amateur-leagueType", leagueType: undefined }
          : { leagues: state.leagues, uiState: "start" }
      );
    }
  };
  const { mutate: saveTipgroup } = usePutEditTipgroup({ groupId: groupId });
  const { mutate: saveTipgroupImage } = usePostEditTipgroupImage({
    props: { groupId: groupId },
    onSuccessOrError: () => {
      return;
    },
  });
  // only show the save button as enabled if one part of this condition is false
  const saveBtnDisabled =
    // simplest condition: name has changed
    tipGroupData?.data.name === name &&
    // simplest condition: image has changed
    imageFormData === undefined &&
    // simplest condition: name has changed
    tipGroupData?.data.description === description &&
    // simple condition: leagues changed if we have a different count then before
    tipGroupData.data.contests?.length === state.leagues.length &&
    // complex condition: check if there is any contest in the contests from the feed which is not in the state
    // vice versa check not needed because we only get here if the number of leages is the same
    !tipGroupData?.data.contests?.some(
      (groupLeague) =>
        !state.leagues.some((league) => groupLeague.contest.id === league.id)
    );

  return (
    <>
      {state.uiState === "start" && (
        <React.Fragment>
          <EditTipGroupHeader
            name={name}
            image={base64Image ?? tipGroupData?.data?.image?.thumbnail_file_url}
            fullImage={base64Image ?? tipGroupData?.data?.image?.full_file_url}
          />
          <QueryWrapper useQueryResult={[recentMembersQuery, topLeaguesQuery]}>
            {tipGroupData ? (
              <>
                {applications && (
                  <TipGroupWaiting
                    applications={applications.data}
                    handleApplication={handleApplication}
                    tipGroupId={groupId}
                  />
                )}
                <Card labelText="Mitglieder der Gruppe">
                  {recentMembers?.data && recentMembers?.data.length <= 1 ? (
                    <>
                      <p className="text-center uppercase font-bold">
                        KEINE NEUEN MITGLIEDER
                      </p>
                      <p className="text-center font-bold">
                        Du kannst weitere Personen zur Gruppe einladen indem du
                        den teilbaren Link aus der Übersichtsseite an Freunde
                        oder Kollegen verschickt werden.
                      </p>
                    </>
                  ) : (
                    <>
                      <SectionHeader>ZULETZT HINZUGEFÜGT</SectionHeader>
                      {recentMembers ? (
                        <List>
                          {recentMembers?.data.map((member, index) => {
                            return (
                              <TipGroupMember
                                key={index}
                                user={member}
                                type={"default"}
                                groupId={groupId}
                              />
                            );
                          })}
                        </List>
                      ) : null}
                      {recentMembers?.data &&
                      members?.data &&
                      members.data.length > 1 ? (
                        <ButtonWrapper>
                          <LinkButton
                            to={linkUrlTipGroupMembers(groupId)}
                          >{`ALLE ${members.data.length} MITGLIEDER VERWALTEN`}</LinkButton>
                        </ButtonWrapper>
                      ) : null}
                    </>
                  )}
                </Card>
                <TipGroupAdminLeagueList
                  leagues={state.leagues}
                  topLeaguesData={topLeaguesData}
                  addLeague={addLeague}
                  removeLeague={removeLeague}
                  setUiState={setUiState}
                />
                <TipGroupAdminName name={name} setName={setName} />
                <TipGroupAdminImage
                  setImageFormData={setImageFormData}
                  setBase64Image={setBase64Image}
                />
                <TipGroupAdminDescription
                  description={description}
                  setDescription={setDescription}
                />
              </>
            ) : null}
            <StickyFooter>
              <Button
                color="primary"
                size="lg"
                disabled={saveBtnDisabled}
                onClick={() => {
                  saveTipgroup({
                    name: name,
                    description: description,
                    contest_ids: state.leagues.map((l) => l.id),
                  });
                  if (imageFormData) {
                    saveTipgroupImage(imageFormData);
                  }
                  navigate(`/`, {
                    replace: true,
                  });
                }}
              >
                Tippgruppe speichern
              </Button>
            </StickyFooter>
            <E2BarSticky reset />
          </QueryWrapper>
        </React.Fragment>
      )}
      {state.uiState != "start" && (
        <React.Fragment>
          <NewTipGroupAddLeagueHeader name={name} backFunc={backFunc} />
          <AddLeague state={state} setState={setState} uiMode="create" />
        </React.Fragment>
      )}
    </>
  );
}
