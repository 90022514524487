export type UrlQuery = Record<string, string | number | boolean | undefined>;

function _buildQueryWithAccessToken(query: UrlQuery) {
  const p = new URLSearchParams({});
  Object.keys(query).forEach((key) => {
    const value = query[key];
    if (value) {
      p.set(key, value.toString());
    }
  });
  return p.toString();
}

function _parsePathParameters(name: string, query: UrlQuery) {
  const returnQuery = { ...query };
  const segments = name.split("/");
  const path = segments
    .map((segment) => {
      const offset = segment.indexOf(":") + 1;
      if (!offset) return segment;

      const key = segment.slice(offset);
      const value = returnQuery[key];
      if (value === undefined) {
        throw new Error("Missing PathParam " + key);
      }
      delete returnQuery[key];
      return encodeURIComponent(value.toString());
    })
    .join("/");

  return { path, query: returnQuery };
}

function _apiPath(rawPath: string, params: UrlQuery = {}) {
  const { path, query } = _parsePathParameters(rawPath, params);
  const queryString = _buildQueryWithAccessToken(query);
  if (queryString) {
    return path + "?" + queryString;
  } else {
    return path;
  }
}

export function useAuthUrl() {
  return useBackendUrl("/auth/auth");
}

const liveApi = "https://tipapi.kicker.de";
const stageApi = "https://kicker-tippspiel-api-staging.mobivention.eu";
const devApi = "https://kicker-tippspiel-api-dev.mobivention.eu";

export function useBackendUrl(
  path: string,
  params: UrlQuery = {},
  server: "live" | "stage" | "dev" = SERVER
) {
  switch (server) {
    case "live":
      return liveApi + _apiPath(path, params);
    case "stage":
      return stageApi + _apiPath(path, params);
    case "dev":
      return devApi + _apiPath(path, params);
  }
}
