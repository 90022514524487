import { Tipgroup } from "../../models/Tipgroup";
import { UserScore } from "../../models/UserScore";
import { linkUrlTipGroupHome } from "../../shared/linkUrlHelper";
import { RankedListElement } from "../list/ListElement";
import { TipGroupImage, TipGroupImageSize } from "./TipGroupImage";

interface TipGroupListElementProps {
  tipgroup: Tipgroup;
  isHeadline?: boolean;
  hasBorder?: boolean;
  iconSize?: TipGroupImageSize;
  score?: UserScore;
}
export function TipGroupListElement({
  tipgroup,
  isHeadline,
  hasBorder, 
  iconSize,
  score,
}: TipGroupListElementProps) {
  const icon = (
    <TipGroupImage
      name={tipgroup.name}
      size={iconSize || isHeadline ? "lg" : "md"}
      image={tipgroup.image?.thumbnail_file_url}
      fullImage={tipgroup.image?.full_file_url}
    />
  );
  return (
    <RankedListElement
      linkUrl={linkUrlTipGroupHome(tipgroup.uuid, tipgroup.name)}
      icon={icon}
      name={tipgroup.name}
      rank={score?.rank}
      isHeadline={isHeadline}
      hasBorder={hasBorder}
    />
  );
}
