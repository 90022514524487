import { useMutation, useQueryClient } from "react-query";
import { useBackendMutationFunction } from "./common/useBackendMutationFunction";
import { UrlQuery } from "./common/useBackendUrl";
import { GetTippsKey, UseGetTippsProps } from "./useGetTipps";

interface DeleteTippData {
  data: void;
}
interface DeleteTippProps {
  props: DeleteTippQueryProps;
  leagueId: string;
  roundNumber: number;
}
interface DeleteTippQueryProps extends UrlQuery {
  matchId: string;
}
export function useDeleteTipp(props: DeleteTippProps) {
  const mutationFn = useBackendMutationFunction<DeleteTippData, void>(
    "/predictions/:matchId",
    "DELETE",
    props.props
  );
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onSuccess: () => {
      // ensure prediction feed containing the deleted tipp are invalidated
      const tippQueryProps: UseGetTippsProps = {
        leagueId: props.leagueId,
        round_number: props.roundNumber,
      };
      return queryClient.invalidateQueries([GetTippsKey, tippQueryProps]);
    },
  });
}
