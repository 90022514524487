import { UrlQuery } from "./common/useBackendUrl";
import { useBackendQueryFunction } from "./common/useBackendQueryFunction";
import { useQuery } from "react-query";
import { MetaData } from "../models/MetaData";
import { GlobalUserRanking } from "../models/GlobalUserRanking";

interface GetGlobalRankingRoundData {
  data: GlobalUserRanking;
  meta: MetaData;
}

interface UseGetGlobalRankingRoundProps {
  props: UseGetGlobalRankingRoundQueryProps;
  enabled?: boolean;
}

interface UseGetGlobalRankingRoundQueryProps extends UrlQuery {
  leagueId: string;
  round_number: number;
}

export function useGetGlobalRankingRound({
  props,
  enabled = true,
}: UseGetGlobalRankingRoundProps) {
  const queryFn = useBackendQueryFunction<GetGlobalRankingRoundData>(
    "/rankings/round/:leagueId/global",
    props,
    true
  );

  return useQuery<GetGlobalRankingRoundData>(
    ["GetGlobalRankingRound", props],
    queryFn,
    { enabled: enabled }
  );
}

interface UseGetGlobalRankingRoundPageProps extends UrlQuery {
  leagueId: string;
  round_number: number;
  page: number;
}

export function useGetGlobalRankingRoundPage(
  props: UseGetGlobalRankingRoundPageProps
) {
  const queryFn = useBackendQueryFunction<GetGlobalRankingRoundData>(
    "/rankings/round/:leagueId/global",
    props,
    true
  );

  return useQuery<GetGlobalRankingRoundData>(
    ["GetGlobalRankingRoundPage", props],
    queryFn
  );
}
