import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { LeagueWithMatchlist } from "../../models/LeagueWithMatchList";
import { Match } from "../../models/Match";
import { Tipgroup } from "../../models/Tipgroup";
import { TippMode } from "../../models/TippMode";
import { TippResult } from "../../models/TippResult";
import {
  linkUrlLeague,
  linkUrlTipgroupLeague,
} from "../../shared/linkUrlHelper";
import { leagueLogoPath } from "../../shared/urlHelper";
// import BetPresenter from "../common/BetPresenter";
import Card from "../common/Card";
import LeagueImage from "../common/LeagueImage";
import SectionHeader from "../common/SectionHeader";
import { useE2Tipps } from "../e2/E2TippsContext";
import { MatchRowDisplay } from "../match/MatchRow";
import MatchTime from "../match/MatchTime";

interface CurrentMatchesHomeProps {
  todayData: LeagueWithMatchlist[];
  groups?: Tipgroup[];
}

export function CurrentMatchesHome({
  todayData,
  groups,
}: CurrentMatchesHomeProps) {
  const [leaguesWithMatches, setLeaguesWithMatches] = useState<LeagueWithMatchlist[]>(todayData.filter(
    (matchList) => matchList.fixtures.length > 0
  ))
  const { setE2Data } = useE2Tipps();

  useEffect(() => {
    let e2Fixtures = {};
    let e2Matches: Match[] = [];
    const e2TippModes: Record<string, TippMode> = {};

    const temp_leaguesWithMatches = todayData.filter(
      (matchList) => matchList.fixtures.length > 0
    );

    temp_leaguesWithMatches.map((matchList) => {
      const fixtures: Record<string, TippResult | null> = {};
      const matches = matchList.fixtures.map((item) => {
        const id = item.fixture.id;
        fixtures[id] = item.prediction;
        e2TippModes[id] = matchList.contest.prediction_mode
        return item.fixture;
      });

      e2Fixtures = { ...e2Fixtures, ...fixtures };
      e2Matches = [...e2Matches, ...matches];
    });

    setE2Data({
      gamedays: e2Matches,
      tipps: e2Fixtures,
      tippModes: e2TippModes,
      tippMode: "full",
    });
    setLeaguesWithMatches(temp_leaguesWithMatches)
  }, [setE2Data, todayData]);

  if (todayData.every((element) => element.fixtures.length === 0)) {
    return null;
  }

  // const showTippPresenter = leaguesWithMatches.some(
  //   (l) =>
  //     l.contest.state !== "GermanAmateurContest" &&
  //     l.fixtures.some((m) => !m.fixture.finished)
  // );

  return leaguesWithMatches.length > 0 ? (
    <>
      {/* {showTippPresenter && (
        <Card transparent>
          <BetPresenter leagueId={"1"} gameday={null} />
        </Card>
      )} */}
      <Card labelText="Aktuelle Spiele" contentPadding="match-row">
        {leaguesWithMatches.map((matchList, index) => (
          <CurrentMatchesHomeElement
            matchList={matchList}
            key={index}
            groups={groups}
          />
        ))}
      </Card>
    </>
  ) : null;
}

interface CurrentMatchesHomeElementProps {
  matchList: LeagueWithMatchlist;
  groups?: Tipgroup[];
}
function CurrentMatchesHomeElement({
  matchList,
  groups,
}: CurrentMatchesHomeElementProps) {
  let latestDate = "";
  const filteredElements = groups?.filter((tipGroup) =>
    tipGroup.contest_ids
      ? tipGroup.contest_ids.includes(matchList.contest.id) === true
      : tipGroup.contests?.some(
          (contest) => contest.contest.id === matchList.contest.id
        )
  );
  return (
    <>
      <SectionHeader
        logo={
          <LeagueImage
            image={leagueLogoPath(matchList.contest.id)}
            name={matchList.contest.name}
          />
        }
      >
        <Link
          to={
            filteredElements?.length === 1
              ? linkUrlTipgroupLeague(
                  filteredElements[0].uuid,
                  filteredElements[0].name,
                  matchList.contest.id,
                  matchList.contest.name,
                  matchList.contest.currentRound.number
                )
              : linkUrlLeague(
                  matchList.contest.id,
                  matchList.contest.name,
                  matchList.contest.currentRound.number
                )
          }
        >
          {matchList.contest.name} - {matchList.contest.currentRound.name}
        </Link>
      </SectionHeader>
      {matchList.fixtures.map((matchListElement) => {
        if (
          new Date(matchListElement.fixture.kickoff).getTime() !==
          new Date(latestDate).getTime()
        ) {
          const type =
            new Date(matchListElement.fixture.kickoff).getDate() !==
            new Date(latestDate).getDate()
              ? "date-time"
              : "time";
          latestDate = matchListElement.fixture.kickoff;
          return (
            <React.Fragment key={matchListElement.fixture.id}>
              <MatchTime type={type}>
                {matchListElement.fixture.kickoff}
              </MatchTime>
              <MatchRowDisplay
                tippMode={matchList.contest.prediction_mode}
                league={matchList.contest}
                tippData={matchListElement.prediction}
                match={matchListElement.fixture}
                matchType={"todayHome"}
                group={
                  filteredElements?.length === 1
                    ? filteredElements[0]
                    : undefined
                }
              />
            </React.Fragment>
          );
        }
        return (
          <MatchRowDisplay
            tippMode={matchList.contest.prediction_mode}
            league={matchList.contest}
            tippData={matchListElement.prediction}
            key={matchListElement.fixture.id}
            match={matchListElement.fixture}
            matchType={"todayHome"}
            group={
              filteredElements?.length === 1 ? filteredElements[0] : undefined
            }
          />
        );
      })}
    </>
  );
}
