import { useNavigate } from "react-router";
import { UsePostLeaveLeague } from "../../api/usePostLeaveLeague";
import { useEnvironment } from "../../Environment";
import Button, { ButtonWrapper } from "../common/Button";
import Card from "../common/Card";

interface LeagueLeaveProps {
  leagueName: string;
  leagueId: string;
}

export function LeagueLeave({ leagueName, leagueId }: LeagueLeaveProps) {
  const navigate = useNavigate();
  const env = useEnvironment();
  const { mutate: leaveLeague } = UsePostLeaveLeague({ leagueId: leagueId });
  return (
    <Card labelType="card-attention" labelText="Wettbewerb verlassen">
      <p className="pb-4 text-center font-normal">
        Möchtest du wirklich diesen Wettbewerb nicht mehr tippen?
      </p>
      <ButtonWrapper>
        <Button
          color="danger"
          onClick={() => {
            leaveLeague();
            env.isVereinsheim
              ? navigate(
                  env.deeplinkUrl.slice(env.deeplinkUrl.indexOf("/vereinsheim"))
                )
              : navigate("/");
          }}
        >
          {leagueName} verlassen
        </Button>
      </ButtonWrapper>
    </Card>
  );
}
