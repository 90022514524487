import { useGetTipgroups } from "../api/useGetTipgroups";
import { useGetToday } from "../api/useGetToday";
import { useGetTopLeagues } from "../api/useGetTopLeagues";
import { useGetNews } from "../api/useGetNews";
import { Header } from "../components/common/Header";
import { QueryWrapper } from "../components/common/QueryWrapper";
import { CurrentMatchesHome } from "../components/home/CurrentMatchesHome";
import { MyLeagues, NewLeagues } from "../components/home/MyLeagues";
import { MyTipgroups } from "../components/home/MyTipgroups";
import { NoTipgroup } from "../components/tipgroup/NoTipgroup";
import { NewsList } from "../components/home/NewsList";
import LogoTippspiel from "../assets/logo_tippspiel.svg";
import { ColumnsWrapper } from "../components/common/Columns";
import { useGetOpenFixtures } from "../api/useGetOpenFixtures";
import { OpenTipps } from "../components/tipgroup/OpenTipps";
import { E2BarSticky } from "../components/e2/E2Bar";
import { useGetSportTypes } from "../api/useGetSportTypes";

export function HomePage() {
  const { data: groupsData, ...groupsQuery } = useGetTipgroups();
  const { data: todayData, ...todayQuery } = useGetToday({});
  const { data: leaguesData, ...leaguesQuery } = useGetTopLeagues();
  const { data: newsData, ...newsQuery } = useGetNews();
  const { data: openFixturesData, ...openFixturesQuery } = useGetOpenFixtures(
    {}
  );
  // only preloading here
  const { ...sportsTypesQuery } = useGetSportTypes();
  return (
    <>
      <Header labelIcon={<LogoTippspiel />} sublabel="Aktuelles" />

      <QueryWrapper
        useQueryResult={[
          groupsQuery,
          todayQuery,
          leaguesQuery,
          newsQuery,
          openFixturesQuery,
          sportsTypesQuery,
        ]}
      >
        {groupsData && todayData ? (
          <ColumnsWrapper>
            {openFixturesData ? (
              <OpenTipps openFixtures={openFixturesData.data} />
            ) : null}
            {groupsData?.data.length !== 0 ? (
              <MyTipgroups
                tipgroups={groupsData.data}
                size="short"
                {...{ isRight: true, order: 1 }}
              />
            ) : (
              <div {...{ isRight: true, order: 1 }}>
                <NoTipgroup />
              </div>
            )}
            <CurrentMatchesHome
              todayData={todayData.data}
              groups={groupsData?.data}
            />
            {todayData.data.length !== 0 ? (
              <MyLeagues
                todayData={todayData.data}
                groups={groupsData.data}
                {...{ isRight: true, order: 2 }}
              />
            ) : leaguesData ? (
              <NewLeagues leaguesData={leaguesData.data} />
            ) : null}
            {newsData?.data && newsData?.data?.length > 0 ? (
              <NewsList newsData={newsData?.data} />
            ) : null}
          </ColumnsWrapper>
        ) : null}
        <E2BarSticky />
      </QueryWrapper>
    </>
  );
}
