import { useState } from "react";
import { useNavigate } from "react-router";
import { useGetTopLeagues } from "../api/useGetTopLeagues";
import { Header } from "../components/common/Header";
import { QueryWrapper } from "../components/common/QueryWrapper";
import {
  LeagueList,
  LeagueListLeague,
} from "../components/leaguelist/LeagueList";
import { AddLeague, AddLeagueState } from "../components/tipgroup/AddLeague";
import LogoTippspiel from "../assets/logo_tippspiel.svg";
import { E2BarSticky } from "../components/e2/E2Bar";
import { useGetSportTypes } from "../api/useGetSportTypes";
import { useLeagueListOtherLeagues } from "../shared/useLeagueListOtherLeagues";
import { useLeagueListSetUiState } from "../shared/useLeagueListSetUiState";

export function LeagueListPage() {
  const navigate = useNavigate();
  const { data: topLeaguesData, ...topLeaguesQuery } = useGetTopLeagues();
  const { data: sportTypesData, ...sportTypeQuery } = useGetSportTypes();
  const [state, setState] = useState<AddLeagueState>({
    uiState: "start",
    leagues: [],
  });
  const backFunc = () => {
    if (state.uiState === "start") {
      navigate(-1);
    } else {
      setState(
        state.uiState === "amateur-association"
          ? { leagues: state.leagues, uiState: "start" }
          : state.uiState === "amateur-teamType"
          ? {
              ...state,
              uiState: "amateur-association",
              association: undefined,
            }
          : state.uiState === "amateur-leagueType"
          ? { ...state, uiState: "amateur-teamType", teamType: undefined }
          : state.uiState === "amateur-league"
          ? {
              ...state,
              uiState: "amateur-leagueType",
              leagueType: undefined,
            }
          : { leagues: state.leagues, uiState: "start" }
      );
    }
  };

  const topLeagues =
    topLeaguesData && topLeaguesData.data.length > 0
      ? {
          header: "Beliebte Wettbewerbe",
          elements: topLeaguesData.data
            // filter out the leagues the user already added
            .map((league) => (
              <LeagueListLeague
                key={league.id}
                league={league}
                type={"add"}
                uiMode="leaguelist"
              />
            )),
        }
      : null;
  const setUiState = useLeagueListSetUiState(setState);

  const otherLeagues = useLeagueListOtherLeagues({
    setUiState,
    sportTypesData,
  });

  return state.uiState === "start" ? (
    <>
      <Header labelIcon={<LogoTippspiel />} sublabel="Wettbewerb tippen" />
      <QueryWrapper useQueryResult={[topLeaguesQuery, sportTypeQuery]}>
        {topLeagues ? (
          <LeagueList type="leaguelist" sections={[topLeagues, otherLeagues]} />
        ) : (
          <LeagueList type="leaguelist" sections={[otherLeagues]} />
        )}
        <E2BarSticky reset />
      </QueryWrapper>
    </>
  ) : (
    <>
      <Header
        labelIcon={<LogoTippspiel />}
        sublabel="Wettbewerb tippen"
        backFunc={backFunc}
      />
      <>
        <AddLeague state={state} setState={setState} uiMode="leaguelist" />
        <E2BarSticky reset />
      </>
    </>
  );
}
