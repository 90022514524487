export type Status =
  | "Scheduled"
  | "Live"
  | "Result"
  | "CanceledBefore"
  | "CanceledRunning"
  | "WithoutRating"
  | "Annulled";

export const specialStatusToString = (status: Status) => {
  switch (status) {
    case "CanceledBefore":
      return "Abges.";
    case "CanceledRunning":
      return "Abgebr.";
    case "Annulled":
      return "Annul.";
    case "WithoutRating":
      return "o.W.";
  }
};
