import { Match } from "../models/Match";

export enum MatchLiveState {
    PreMatch = "pre-match",
    Live = "live",
    PostMatch = "post-match",
  }


export function _calculateLiveState(match: Match): MatchLiveState {
    if (match.started && !match.finished) {
      return MatchLiveState.Live;
    } else if (match.finished) {
      return MatchLiveState.PostMatch;
    } else {
      return MatchLiveState.PreMatch;
    }
  }
  