import { userImage } from "../../shared/urlHelper";
import IconContext from "../../assets/icons/context.svg";
import { TipUser } from "../../models/TipUser";
import { ListItem, ListRow } from "../list/List";
import { ApplicationResponse } from "../../models/ApplicationResponse";
import {
  usePostManageGroupUser,
  UserManageAction,
} from "../../api/usePostManageGroupUser";
import { useEnvironment } from "../../Environment";
import Dropdown, { DropdownItem } from "../common/Dropdown";
import UserImage from "../common/UserImage";

import IconAdmin from "../../assets/icons/admin.svg";
import IconRemoveAdmin from "../../assets/icons/remove-admin.svg";
import IconRemoveUser from "../../assets/icons/remove-user.svg";
import { Link } from "react-router-dom";
import { linkUrlProfile } from "../../shared/linkUrlHelper";

interface TipGroupMemberProps {
  user: TipUser;
  type: "default" | "waiting" | "detail";
  groupId: string;
  handleApplication?: (response: ApplicationResponse) => void;
}

export function TipGroupMember({
  user,
  type,
  groupId,
  handleApplication,
}: TipGroupMemberProps) {
  const env = useEnvironment();

  const { mutate: manageUser } = usePostManageGroupUser({ groupId: groupId });

  const makeAdmin = () => {
    manageUser({ action: UserManageAction.MakeAdmin, user_id: user.uuid });
  };

  const removeAdmin = () => {
    manageUser({ action: UserManageAction.RemoveAdmin, user_id: user.uuid });
  };

  const kickUser = () => {
    manageUser({ action: UserManageAction.Kick, user_id: user.uuid });
  };

  return (
    <ListRow>
      <ListItem>
        <Link to={linkUrlProfile(user.uuid)}>
          <UserImage image={userImage(user)} name={user.username} size="md" />
        </Link>
      </ListItem>
      <ListItem
        className={user.is_admin ? "text-link flex items-center space-x-2" : ""}
      >
        <Link to={linkUrlProfile(user.uuid)}>
          <span>{user.username}</span>
        </Link>
        <span>{user.is_admin && <IconAdmin />}</span>
      </ListItem>
      {type === "waiting" ? (
        <>
          <ListItem className="text-status-negative-red200 pr-2 xs:pr-4 text-sm">
            <span
              onClick={() =>
                handleApplication
                  ? handleApplication({
                      action: "deny",
                      group_id: groupId,
                      user_id: user.uuid,
                    })
                  : undefined
              }
            >
              ABLEHNEN
            </span>
          </ListItem>
          <ListItem className="text-link text-sm">
            <span
              onClick={() =>
                handleApplication
                  ? handleApplication({
                      action: "accept",
                      group_id: groupId,
                      user_id: user.uuid,
                    })
                  : undefined
              }
            >
              HINZUFÜGEN
            </span>
          </ListItem>
        </>
      ) : type === "detail" ? (
        env.authState.user?.uuid != user.uuid && (
          <ListItem>
            <Dropdown
              text={<IconContext />}
              chevron={false}
              side="right"
              size="small"
            >
              {!user.is_admin && (
                <DropdownItem onClick={() => makeAdmin()}>
                  <span>
                    <IconAdmin />
                  </span>
                  <span className="text-link">Zum Admin machen</span>
                </DropdownItem>
              )}
              {user.is_admin && (
                <DropdownItem onClick={() => removeAdmin()}>
                  <span>
                    <IconRemoveAdmin />
                  </span>
                  <span className="text-status-negative-red200">
                    Admin-Rechte entfernen
                  </span>
                </DropdownItem>
              )}
              <DropdownItem onClick={() => kickUser()}>
                <span>
                  <IconRemoveUser />
                </span>
                <span className="text-status-negative-red200">
                  Aus Gruppe entfernen
                </span>
              </DropdownItem>
            </Dropdown>
          </ListItem>
        )
      ) : null}
    </ListRow>
  );
}
