import { UrlQuery } from "./common/useBackendUrl";
import { useBackendQueryFunction } from "./common/useBackendQueryFunction";
import { useQuery } from "react-query";
import { Gameday } from "../models/Gameday";

interface GamedaysData {
  data: Gameday[];
}

interface UseGetGamedaysProps extends UrlQuery {
  leagueId: string;
}

export function useGetGamedays(props: UseGetGamedaysProps) {
  const queryFn = useBackendQueryFunction<GamedaysData>(
    "/rounds/:leagueId",
    props
  );

  return useQuery<GamedaysData>(["GetGamedays", props], queryFn);
}
