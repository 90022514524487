import { useMutation, useQueryClient } from "react-query";
import { useBackendMutationFunction } from "./common/useBackendMutationFunction";
import { UrlQuery } from "./common/useBackendUrl";
import { GetTipgroupKey } from "./useGetTipgroup";
import { GetTipgroupsKey } from "./useGetTipgroups";

interface PostEditTipgroupImageData {
  uuid: string;
  full_filename: string;
  full_file_url: string;
  thumbnail_filename: string;
  thumbnail_file_url: string;
}

interface UsePostEditTipgroupImageQueryProps extends UrlQuery {
  groupId: string;
}

interface UsePostEditTipgroupImageProps {
  props: UsePostEditTipgroupImageQueryProps;
  onSuccessOrError: () => void;
}

export function usePostEditTipgroupImage({
  props,
  onSuccessOrError,
}: UsePostEditTipgroupImageProps) {
  const mutationFn = useBackendMutationFunction<
    PostEditTipgroupImageData,
    FormData
  >("/groups/:groupId/upload_image", "POST", props);
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onError: onSuccessOrError,
    onSuccess: async () => {
      onSuccessOrError();
      await new Promise<void>((resolve) => {
        setTimeout(() => {
          resolve();
        }, 5000);
      });
      await queryClient.invalidateQueries(GetTipgroupsKey);
      return await queryClient.invalidateQueries([GetTipgroupKey, props]);
    },
  });
}
