import { useQuery } from "react-query";
import { League } from "../models/League";
import { useBackendQueryFunction } from "./common/useBackendQueryFunction";
import { UrlQuery } from "./common/useBackendUrl";

interface GetLeagueData {
  data: League;
}

interface UseGetLeagueProps extends UrlQuery {
  leagueId: string;
}

export function useGetLeague(props: UseGetLeagueProps) {
  const queryFn = useBackendQueryFunction<GetLeagueData>(
    "/contests/:leagueId",
    props,
    true
  );
  return useQuery<GetLeagueData>(["GetLeague", props], queryFn, {
    // onError: () => {},
  });
}
