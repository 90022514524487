interface ApplicationCountBadgeProps {
  applications: string;
}

export function ApplicationCountBadge({ applications }: ApplicationCountBadgeProps) {
  return (
    <span className={`bg-status-negative-red100 rounded-full inline-flex items-center justify-center w-5 h-5 flex-shrink-0`}>
      <span className="text-white text-xs font-normal absolute">{applications}</span>
    </span>
  )
}