import { Dispatch } from "react";
import Button from "../common/Button";

interface DeleteTippButtonProps {
  setTippDeleted: Dispatch<boolean>;
  className?: string;
}

export function DeleteTippButton({
  setTippDeleted,
  className = "",
}: DeleteTippButtonProps) {
  return (
    <Button
      onClick={() => setTippDeleted(true)}
      color="kicker-outline"
      className={className}
    >
      Tipp löschen
    </Button>
  );
}
