import { MatchPageType } from "../pages/MatchPage";

type LinkRankingType = "season" | "round";

export const linkUrlNews = (url: string) =>
  url.includes("#")
    ? `/news/${encodeURIComponent(
        `${url.substring(0, url.indexOf("#"))}#android`
      )}`
    : `/news/${encodeURIComponent(`${url}#android`)}`;

export const linkUrlMatch = (
  leagueId: string,
  leagueName: string,
  matchId: string,
  matchRoundNumber: number,
  matchType: MatchPageType,
  groupId?: string,
  groupName?: string
) =>
  groupId && groupName
    ? `/tipgroup/${encodeURIComponent(
        groupName
      )}/${groupId}/match/${matchId}/league/${encodeURIComponent(
        leagueName
      )}/${leagueId}/round/${matchRoundNumber}/type/${matchType}`
    : `/match/${matchId}/league/${encodeURIComponent(
        leagueName
      )}/${leagueId}/round/${matchRoundNumber}/type/${matchType}`;

export const linkUrlTipGroupHome = (groupId: string, groupName: string) =>
  `/tipgroup/${encodeURIComponent(groupName)}/${groupId}`;

export const linkUrlTipGroupRanking = (groupId: string, groupName: string) =>
  `/tipgroup/${encodeURIComponent(groupName)}/${groupId}/ranking`;

export const linkUrlTipGroupEdit = (groupId: string) =>
  `/tipgroup/${groupId}/edit`;

export const linkUrlTipGroupMembers = (groupId: string) =>
  `/tipgroup/${groupId}/members`;
export const linkUrlTipGroupMemberList = (groupName: string, groupId: string) =>
  `/tipgroup/${encodeURIComponent(groupName)}/${groupId}/memberlist`;

export const linkUrlProfile = (userId: string) => `/profile/${userId}`;

export const linkUrlTipGroupLeagueRanking = (
  groupId: string,
  groupName: string,
  leagueId: string,
  leagueName: string,
  roundNumber: number,
  rankingType: LinkRankingType
) =>
  rankingType == "round"
    ? `/tipgroup/${encodeURIComponent(
        groupName
      )}/${groupId}/ranking/league/${encodeURIComponent(
        leagueName
      )}/${leagueId}/gameday/${roundNumber}`
    : `/tipgroup/${encodeURIComponent(
        groupName
      )}/${groupId}/ranking/league/${encodeURIComponent(
        leagueName
      )}/${leagueId}/season/${roundNumber}`;

export const linkUrlLeague = (
  leagueId: string,
  leagueName: string,
  roundNumber: number
) =>
  `/league/${encodeURIComponent(
    leagueName
  )}/${leagueId}/gameday/${roundNumber}`;

export const linkUrlProfileLeague = (
  leagueId: string,
  leagueName: string,
  roundNumber: number,
  userId: string,
  tipgroupId?: string
) =>
  `/profile/${userId}/league/${encodeURIComponent(
    leagueName
  )}/${leagueId}/gameday/${roundNumber}${
    tipgroupId ? `/group/${tipgroupId}` : ""
  }`;

export const linkUrlLeagueRanking = (
  leagueId: string,
  leagueName: string,
  roundNumber: number,
  rankingType: LinkRankingType
) =>
  rankingType == "round"
    ? `/league/${encodeURIComponent(
        leagueName
      )}/${leagueId}/ranking/gameday/${roundNumber}`
    : `/league/${encodeURIComponent(
        leagueName
      )}/${leagueId}/ranking/season/${roundNumber}`;

export const linkUrlTipgroupLeague = (
  groupId: string,
  groupName: string,
  leagueId: string,
  leagueName: string,
  leagueRoundNumber: number
) =>
  `/tipgroup/${encodeURIComponent(
    groupName
  )}/${groupId}/league/${encodeURIComponent(
    leagueName
  )}/${leagueId}/gameday/${leagueRoundNumber}`;

export const linkUrlNewTipgroup = () => "/tipgroup/new";

export const linkUrlLeagueList = () => "/leaguelist";

export const linkUrlTipGroupList = (
  leagueId?: string,
  leagueName?: string,
  matchId?: string,
  roundNumber?: number,
  showVereinsHeim?: boolean
) =>
  leagueId && leagueName
    ? matchId && roundNumber
      ? `/${
          showVereinsHeim ? "vereinsheim" : ""
        }groups/league/${leagueId}/${leagueName}/round/${roundNumber}/match/${matchId}`
      : `/${
          showVereinsHeim ? "vereinsheim" : ""
        }groups/league/${leagueId}/${leagueName}`
    : `/${showVereinsHeim ? "vereinsheim" : ""}groups`;
