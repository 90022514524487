import { useGetTipgroups } from "../../api/useGetTipgroups";
import { Fragment, ReactNode, useMemo } from "react";
import { Link } from "react-router-dom";
import IconClose from "../../assets/icons/close.svg";
import IconAdd from "../../assets/icons/add.svg";
import LogoTippspiel from "../../assets/logo_tippspiel.svg";
import { Label } from "./Label";
import { leagueLogoPath } from "../../shared/urlHelper";
import { useGetToday } from "../../api/useGetToday";
import {
  linkUrlLeague,
  linkUrlLeagueList,
  linkUrlNews,
  linkUrlNewTipgroup,
  linkUrlTipGroupHome,
  linkUrlTipgroupLeague,
} from "../../shared/linkUrlHelper";
import { useEnvironment } from "../../Environment";
import { NewsDisplayType } from "../home/NewsList";
import LeagueImage from "./LeagueImage";
import { TipGroupImage } from "../tipgroup/TipGroupImage";
import {
  faq,
  feedback,
  impressum,
  privacy,
  prizes,
  rules,
} from "../../shared/articleHelper";
import { useGetGroupApplications } from "../../api/useGetGroupApplications";
import { getApplicationsByUuid } from "../../shared/aplicationHelper";
import { ApplicationCountLabel } from "../applicationCount/ApplicationCountLabel";
import Toggle from "./Toggle";
import { useSportsTypeHeaderAndSort } from "../../shared/useSportsTypeHeaderAndSort";
import { SportTypeHeader } from "../home/SportTypeHeader";
import { getSportTypePriority } from "../../models/SportType";

interface NavigationProps {
  open: boolean;
  closeAction: () => void;
}

export function Navigation({ open, closeAction }: NavigationProps) {
  const { data: groupsData } = useGetTipgroups();
  const { data: todayData } = useGetToday({});
  const { data: applicationsData } = useGetGroupApplications();

  const applicationsByUuid = useMemo(
    () => getApplicationsByUuid(applicationsData?.data),
    [applicationsData]
  );
  const env = useEnvironment();
  const newsDisplayType = env.isVereinsheim
    ? "internal"
    : env.isWebApp
    ? "external"
    : "native";
  const tipGroups: NavItemProps[] =
    groupsData?.data
      .filter((group) => !group.is_vereinsheim)
      .map((group) => {
        return {
          icon: (
            <TipGroupImage
              name={group.name}
              size="sm"
              image={group.image?.thumbnail_file_url}
              fullImage={group.image?.full_file_url}
            />
          ),
          text: (
            <ApplicationCountLabel
              groupName={group.name}
              groupApplication={applicationsByUuid[group.uuid]}
            />
          ),
          url: linkUrlTipGroupHome(group.uuid, group.name),
        };
      }) ?? [];
  const vereinsheimGroups: NavItemProps[] =
    groupsData?.data
      .filter((group) => group.is_vereinsheim)
      .map((group) => {
        return {
          icon: <TipGroupImage name={group.name} size="sm" />,
          text: (
            <ApplicationCountLabel
              groupName={group.name}
              groupApplication={applicationsByUuid[group.uuid]}
            />
          ),
          url: linkUrlTipGroupHome(group.uuid, group.name),
        };
      }) ?? [];

  const contests = todayData?.data?.map((wrapper) => wrapper.contest);
  const addSportsTypeHeaders = useSportsTypeHeaderAndSort(contests);
  const leagues: NavItemProps[] = [];
  contests?.forEach((league, index) => {
    const filteredElements = groupsData?.data?.filter((tipGroup) =>
      tipGroup.contest_ids
        ? tipGroup.contest_ids.includes(league.id) === true
        : tipGroup.contests?.some((contest) => contest.contest.id === league.id)
    );
    if (
      addSportsTypeHeaders &&
      (index === 0 ||
        (league.sportType &&
          getSportTypePriority(league.sportType) !==
            getSportTypePriority(contests.at(index - 1)?.sportType)))
    ) {
      leagues.push({
        text: <SportTypeHeader type={league.sportType} />,
        isSection: true,
      });
    }
    leagues.push({
      icon: (
        <LeagueImage image={leagueLogoPath(league.id)} name={league.name} />
      ),
      text: league.name,
      url:
        filteredElements?.length === 1
          ? linkUrlTipgroupLeague(
              filteredElements[0].uuid,
              filteredElements[0].name,
              league.id,
              league.name,
              league.currentRound.number
            )
          : linkUrlLeague(league.id, league.name, league.currentRound.number),
    });
  }) ?? [];
  const foundTipgroup: NavItemProps = {
    icon: <IconAdd />,
    text: "Tippgruppe gründen",
    url: linkUrlNewTipgroup(),
  };
  const tipOtherLeagues: NavItemProps = {
    icon: <IconAdd />,
    text: "Weitere Wettbewerbe tippen",
    url: linkUrlLeagueList(),
  };
  const navData: NavDataProps = {
    title: {
      icon: <LogoTippspiel />,
      title: "Tippspiel",
    },
    groups: [
      {
        items: [
          {
            text: "Home",
            url: "/",
          },
          {
            text: "Spielregeln",
            ..._getNewsPropsForLinkUrl(
              newsDisplayType,
              rules().url,
              env.replacerFunc,
              rules().id
            ),
          },
          {
            text: "Preise",
            ..._getNewsPropsForLinkUrl(
              newsDisplayType,
              prizes().url,
              env.replacerFunc,
              prizes().id
            ),
          },
          {
            text: "Mitteilungen",
            toggle: {
              checked: env.pushesToggle,
              onToggle: (status: boolean) => {
                env.setPushesToggle(status);
              },
            },
            hide: env.isWebApp || env.pushesFunc === undefined,
          },
        ],
      },
      {
        title: "Tippgruppen",
        items: [...tipGroups, foundTipgroup],
      },
      {
        title: "Vereinsheim Apps",
        items: vereinsheimGroups,
      },
      {
        title: "Wettbewerbe",
        items: [...leagues, tipOtherLeagues],
      },
      {
        title: "Sonstiges",
        items: [
          {
            text: "FAQ",
            ..._getNewsPropsForLinkUrl(
              newsDisplayType,
              faq().url,
              env.replacerFunc,
              faq().id
            ),
          },
          {
            text: "Nutzungsbedingungen",
            ..._getNewsPropsForLinkUrl(
              newsDisplayType,
              "https://www.kicker.de/644235/artikel",
              env.replacerFunc,
              "644235"
            ),
          },
          {
            text: "Impressum",
            ..._getNewsPropsForLinkUrl(
              newsDisplayType,
              impressum().url,
              env.replacerFunc,
              impressum().id
            ),
          },
          {
            text: "Datenschutz",
            ..._getNewsPropsForLinkUrl(
              newsDisplayType,
              privacy().url,
              env.replacerFunc,
              privacy().id
            ),
          },
          {
            text: "Feedback",
            ..._getNewsPropsForLinkUrl(
              newsDisplayType,
              feedback().url,
              env.replacerFunc,
              feedback().id
            ),
          },
        ],
      },
    ],
  };
  return (
    <NavMobile
      data={navData}
      open={open}
      closeAction={closeAction}
      isWebApp={env.isWebApp}
    />
  );
}

function _getNewsPropsForLinkUrl(
  displayType: NewsDisplayType,
  url: string,
  replacerFunc?: (replacer: string) => void,
  docId?: string
) {
  switch (displayType) {
    case "external":
      return { url: url, isExternalUrl: true, onClick: undefined };
    case "internal":
      return {
        url: linkUrlNews(url),
        isExternalUrl: false,
        onClick: undefined,
      };
    case "native":
      return {
        url: replacerFunc ? undefined : url,
        isExternalUrl: false,
        onClick: () =>
          replacerFunc &&
          replacerFunc(docId ? `@@news:${docId}@@` : `@@webview:${url}@@`),
      };
  }
}

interface NavTitleProps {
  iconUrl?: string;
  icon?: ReactNode;
  title?: string;
}

interface NavGroupProps {
  title?: string;
  items: NavItemProps[];
}

interface NavItemToggleProps {
  onToggle?: (status: boolean) => void;
  checked?: boolean;
}

interface NavItemProps {
  icon?: string | ReactNode;
  text: string | ReactNode;
  isSection?: boolean;
  url?: string;
  isExternalUrl?: boolean;
  onClick?: () => void;
  toggle?: NavItemToggleProps;
  hide?: boolean;
}

interface NavItemWrapperProps {
  url?: string;
  isExternalUrl?: boolean;
  onClick?: () => void;
  children?: ReactNode;
}

interface NavDataProps {
  title: NavTitleProps;
  groups: NavGroupProps[];
}

interface NavProps {
  data: NavDataProps;
  open: boolean;
  closeAction: () => void;
  isWebApp: boolean;
}

function NavMobileItemWrapper({
  url,
  isExternalUrl,
  onClick,
  children,
}: NavItemWrapperProps) {
  if (url) {
    if (isExternalUrl) {
      return (
        <a
          className="nav-group-item"
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {children}
        </a>
      );
    } else {
      return (
        <Link to={url} className="nav-group-item" onClick={onClick}>
          {children}
        </Link>
      );
    }
  } else {
    return (
      <div className="nav-group-item" onClick={onClick}>
        {children}
      </div>
    );
  }
}

function NavMobileItem({
  icon,
  text,
  url,
  isExternalUrl,
  onClick,
}: NavItemProps) {
  const isSVG = icon && typeof icon === "object";
  return (
    <NavMobileItemWrapper
      url={url}
      isExternalUrl={isExternalUrl}
      onClick={onClick}
    >
      {icon && (
        <div className="nav-group-item-icon-wrapper">
          {isSVG ? (
            icon
          ) : (
            <img src={icon as string} className="nav-group-item-icon" />
          )}
        </div>
      )}
      <div className="nav-group-item-text">{text}</div>
    </NavMobileItemWrapper>
  );
}

function NavMobileItemWithToggle({
  icon,
  text,
  url,
  isExternalUrl,
  onClick,
  toggle,
}: NavItemProps) {
  const isSVG = icon && typeof icon === "object";
  return (
    <NavMobileItemWrapper
      url={url}
      isExternalUrl={isExternalUrl}
      onClick={onClick}
    >
      {icon && (
        <div className="nav-group-item-icon-wrapper">
          {isSVG ? (
            icon
          ) : (
            <img src={icon as string} className="nav-group-item-icon" />
          )}
        </div>
      )}
      <div className="nav-group-item-text">{text}</div>
      <Toggle
        defaultChecked={toggle?.checked}
        onToggle={toggle?.onToggle}
      ></Toggle>
    </NavMobileItemWrapper>
  );
}

function NavMobile({ data, open, closeAction, isWebApp }: NavProps) {
  return (
    <>
      <div
        className="nav-mobile-backdrop"
        data-open={open}
        onClick={closeAction}
      />
      <div
        className={`nav-mobile${isWebApp ? " nav-web" : ""}`}
        data-open={open}
      >
        <div className="nav-title">
          {data.title.icon && (
            <div className="nav-title-icon">{data.title.icon}</div>
          )}
          {data.title.iconUrl && (
            <img className="nav-title-icon" src={data.title.iconUrl} />
          )}
          <div className="nav-title-title">{data.title.title}</div>
          <div className="nav-close">
            <div className="nav-close-action" onClick={closeAction}>
              <IconClose className="nav-close-icon" />
            </div>
          </div>
        </div>
        <div className="nav-groups">
          {data.groups
            .filter((group) => group.items.length > 0)
            .map((group, i) => (
              <div key={i} className="nav-group">
                {group.title && (
                  <Label className="nav-group-title" type="card">
                    {group.title}
                  </Label>
                )}
                {group.items.map((item, i) => (
                  <Fragment key={i}>
                    {item.isSection ? (
                      <div className="nav-group-section">{item.text}</div>
                    ) : !item.hide ? (
                      item.toggle ? (
                        <NavMobileItemWithToggle
                          icon={item.icon}
                          text={item.text}
                          url={item.url}
                          isExternalUrl={item.isExternalUrl}
                          toggle={item.toggle}
                        />
                      ) : (
                        <NavMobileItem
                          icon={item.icon}
                          text={item.text}
                          url={item.url}
                          isExternalUrl={item.isExternalUrl}
                          onClick={() => {
                            item.onClick && item.onClick();
                            closeAction();
                          }}
                        />
                      )
                    ) : null}
                  </Fragment>
                ))}
              </div>
            ))}
        </div>
      </div>
    </>
  );
}
