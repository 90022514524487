import { UrlQuery, useAuthUrl, useBackendUrl } from "./useBackendUrl";
import { MutationFunction } from "react-query";
import { useEnvironment } from "../../Environment";
import { fetchWithJwtToken } from "./fetchWithJwtToken";

/* R is the return type, t the type of the query body  */
export function useBackendMutationFunction<R, T>(
  path: string,
  method: "POST" | "DELETE" | "PUT" = "POST",
  params: UrlQuery = {},
  overrideHeaders: Record<string, string> = {}
) {
  const url = useBackendUrl(path, params);
  const env = useEnvironment();
  const authUrl = useAuthUrl();

  const mutationFn: MutationFunction<R, T> = async (body: T) => {
    const useFormData = body instanceof FormData;
    return fetchWithJwtToken<R>(url, authUrl, env, {
      method: method,
      body: body ? (useFormData ? body : JSON.stringify(body)) : undefined,
      headers: useFormData
        ? {
            // do not set content-type for formdata
            ...overrideHeaders,
          }
        : {
            "Content-Type": "application/json",
            ...overrideHeaders,
          },
    });
  };

  return mutationFn;
}
