import { Fragment, ReactNode } from "react";
import { Menu, Transition } from "@headlessui/react";
import IconChevronDown from "../../assets/icons/chevron-down.svg";

interface DropdownProps {
  text: string | ReactNode;
  chevron?: boolean;
  size?: "normal" | "small";
  side?: "left" | "normal" | "right";
  children: React.ReactNode;
}

interface DropdownItemProps {
  onClick: () => void;
  children: ReactNode;
  active?: boolean;
}

export function DropdownItem({
  onClick,
  children,
  active = false,
}: DropdownItemProps) {
  return (
    <Menu.Item>
      <div
        className={`
          dropdown-item 
          ${active ? "dropdown-item-active" : ""}
      `}
        onClick={onClick}
      >
        {children}
      </div>
    </Menu.Item>
  );
}

export default function Dropdown({
  text,
  chevron = true,
  size = "normal",
  side = "normal",
  children,
}: DropdownProps) {
  return (
    <Menu
      as="div"
      className={`dropdown dropdown-size-${size} dropdown-side-${side}`}
    >
      <Menu.Button className="dropdown-button">
        <span>{text}</span>
        {chevron && (
          <IconChevronDown
            className="dropdown-button-chevron"
            aria-hidden="true"
          />
        )}
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="dropdown-items">{children}</Menu.Items>
      </Transition>
    </Menu>
  );
}
