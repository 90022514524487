import { useParams } from "react-router";
import { TipGroupApply } from "../../components/tipgroup/TipGroupApply";
import { TipgroupApplyPageParamKeys } from "../../models/ParamKeys";
import { Error404Page } from "../Error404Page";

export function TipGroupApplyPage() {
  const params = useParams<TipgroupApplyPageParamKeys>();
  return params.code ? (
    <TipGroupApply code={params.code} />
  ) : (
    <Error404Page />
  );
}
