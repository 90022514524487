import Card from "../common/Card";
import { Link } from "react-router-dom";
import List, { ListItem, ListRow } from "../list/List";
import { League } from "../../models/League";
import { ButtonWrapper, LinkButton } from "../common/Button";
import { leagueLogoPath } from "../../shared/urlHelper";
import { LeagueWithMatchlist } from "../../models/LeagueWithMatchList";
import { LeagueList, LeagueListLeague } from "../leaguelist/LeagueList";
import { Tipgroup } from "../../models/Tipgroup";
import {
  linkUrlLeague,
  linkUrlLeagueList,
  linkUrlTipgroupLeague,
} from "../../shared/linkUrlHelper";
import LeagueImage from "../common/LeagueImage";
import { useSportsTypeHeaderAndSort } from "../../shared/useSportsTypeHeaderAndSort";
import { getSportTypePriority } from "../../models/SportType";
import { SportTypeHeader } from "./SportTypeHeader";

interface MyLeaguesProps {
  todayData: LeagueWithMatchlist[];
  vereinsheimGroup?: Tipgroup;
  groups?: Tipgroup[];
}
interface NewLeaguesProps {
  leaguesData: League[];
}

export function MyLeagues({
  todayData,
  vereinsheimGroup,
  groups,
}: MyLeaguesProps) {
  const contests = todayData.map(
    (leagueWithMatchList) => leagueWithMatchList.contest
  );

  const addSportsTypeHeaders = useSportsTypeHeaderAndSort(contests);
  return (
    <Card labelText="Meine Wettbewerbe">
      <List>
        {contests.map((contest, index) => (
          <LeagueElement
            league={contest}
            vereinsheimGroup={vereinsheimGroup}
            key={contest.id}
            groups={groups}
            addSportsTypeHeader={
              addSportsTypeHeaders &&
              contest.sportType &&
              (index === 0 ||
                getSportTypePriority(contest.sportType) !==
                  getSportTypePriority(contests.at(index - 1)?.sportType))
            }
          />
        ))}
      </List>
      {!vereinsheimGroup ? (
        <ButtonWrapper>
          <LinkButton to={linkUrlLeagueList()}>
            Weitere Wettbewerbe tippen
          </LinkButton>
        </ButtonWrapper>
      ) : null}
    </Card>
  );
}

interface LeagueElementProps {
  league: League;
  vereinsheimGroup?: Tipgroup;
  groups?: Tipgroup[];
  addSportsTypeHeader: boolean;
}
function LeagueElement({
  league,
  vereinsheimGroup,
  groups,
  addSportsTypeHeader,
}: LeagueElementProps) {
  const filteredElements = groups?.filter((tipGroup) =>
    tipGroup.contest_ids
      ? tipGroup.contest_ids.includes(league.id) === true
      : tipGroup.contests?.some((contest) => contest.contest.id === league.id)
  );
  return (
    <>
      {addSportsTypeHeader && <SportTypeHeader type={league.sportType} />}
      <Link
        to={
          filteredElements?.length === 1
            ? linkUrlTipgroupLeague(
                filteredElements[0].uuid,
                filteredElements[0].name,
                league.id,
                league.name,
                league.currentRound.number
              )
            : vereinsheimGroup
            ? linkUrlTipgroupLeague(
                vereinsheimGroup.uuid,
                vereinsheimGroup.name,
                league.id,
                league.name,
                league.currentRound.number
              )
            : linkUrlLeague(league.id, league.name, league.currentRound.number)
        }
      >
        <ListRow>
          <ListItem>
            <LeagueImage image={leagueLogoPath(league.id)} name={league.name} />
          </ListItem>
          <ListItem>{league.name}</ListItem>
        </ListRow>
      </Link>
    </>
  );
}

export function NewLeagues({ leaguesData }: NewLeaguesProps) {
  const leagues = leaguesData.map((league) => (
    <LeagueListLeague
      key={league.id}
      addLeague={() => null}
      league={league}
      type="add"
      removeLeague={() => null}
      uiMode="tipnow"
    />
  ));
  return leagues ? (
    <LeagueList
      sections={[
        { header: "Beliebte Wettbewerbe", elements: leagues },
        {
          elements: [
            <LinkButton key="0" to="/leaguelist">
              Weitere Wettbewerbe tippen
            </LinkButton>,
          ],
        },
      ]}
      type="top"
      label="Jetzt Wettbewerbe Tippen"
    />
  ) : null;
}
