import { linkUrlTipGroupEdit } from "../../shared/linkUrlHelper";
import { ButtonWrapper, LinkButton } from "../common/Button";
import Card from "../common/Card";

interface TipGroupEditProps {
  groupId: string;
  groupName: string;
}

export function TipGroupEdit({ groupId, groupName }: TipGroupEditProps) {

  return (
    <Card
      labelText={`Tippgruppe bearbeiten`}
      labelLinkTo={linkUrlTipGroupEdit(groupId)}
    >
      <p className="mb-4 text-center">
        Hier kannst du den Gruppennamen ändern und neue Wettbewerbe oder
        Mitglieder hinzufügen.
      </p>
      <ButtonWrapper>
        <LinkButton to={linkUrlTipGroupEdit(groupId)}>
          {groupName} bearbeiten
        </LinkButton>
      </ButtonWrapper>
    </Card>
  );
}
