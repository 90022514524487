import { ErrorCard } from "../components/common/ErrorCard";
import { Header } from "../components/common/Header";

export function Error404Page() {
  return (
    <>
      <Header />
      <ErrorCard type="404" />
    </>
  );
}
