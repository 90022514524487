import { useQuery } from "react-query";
import { LeagueWithMatchlist } from "../models/LeagueWithMatchList";
import { useBackendQueryFunction } from "./common/useBackendQueryFunction";
import { UrlQuery } from "./common/useBackendUrl";

export const GetOpenFixturesKey = "GetOpenFixtures";

interface GetOpenFixturesData {
  data: LeagueWithMatchlist[];
}

interface UseGetOpenFixturesQueryProps extends UrlQuery {
  group_id?: string;
}

interface UseGetOpenFixturesProps {
  props?: UseGetOpenFixturesQueryProps;
  enabled?: boolean;
}

export function useGetOpenFixtures({
  props,
  enabled = true,
}: UseGetOpenFixturesProps) {
  const queryFn = useBackendQueryFunction<GetOpenFixturesData>(
    "/me/open_fixtures",
    props,
    true
  );

  return useQuery<GetOpenFixturesData>([GetOpenFixturesKey], queryFn, {
    enabled: enabled,
  });
}
