import { useQuery } from "react-query";
import { MetaData } from "../models/MetaData";
import { ProfileRankings } from "../models/ProfileRankings";
import { useBackendQueryFunction } from "./common/useBackendQueryFunction";
import { UrlQuery } from "./common/useBackendUrl";

interface GetProfileRankingsData {
  meta: MetaData;
  data: ProfileRankings;
}

interface UseGetProfileRankingsProps extends UrlQuery {
  uuid?: string;
}

export function useGetProfileRankings(props: UseGetProfileRankingsProps) {
  const queryFn = useBackendQueryFunction<GetProfileRankingsData>(
    "/user/:uuid/rankings",
    props,
    true
  );

  return useQuery<GetProfileRankingsData>(["GetProfileRankings"], queryFn, {
    enabled: props.uuid != null,
  });
}
