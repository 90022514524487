import { Match } from "../../models/Match";
import {
  formattedDateShort,
  formattedTime,
  isToday,
} from "../../shared/timeHelper";

interface DateMatchResultProps {
  match: Match;
  forceTimeOnly?: boolean;
}

export function DateMatchResult({
  match,
  forceTimeOnly,
}: DateMatchResultProps) {
  return (
    <span className="match-time-decent">
      {forceTimeOnly || isToday(match.kickoff)
        ? formattedTime(match.kickoff)
        : formattedDateShort(match.kickoff)}
    </span>
  );
}
