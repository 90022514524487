type PhaseStage =
  | "BeforeKickOff"
  | "Fb_FirstHalfStart"
  | "Fb_FirstHalfEnd"
  | "Fb_SecondHalfStart"
  | "Fb_SecondHalfEnd"
  | "Fb_ExtraTimeFirstHalfStart"
  | "Fb_ExtraTimeFirstHalfEnd"
  | "Fb_ExtraTimeSecondHalfStart"
  | "Fb_ExtraTimeSecondHalfEnd"
  | "Fb_PenaltyStart"
  | "Fb_PenaltyEnd"
  | "Unknown";
export interface Phase {
  stage: PhaseStage;
  time: string;
}

export const phaseStartMinute = (stage: PhaseStage): number | undefined => {
  switch (stage) {
    case "Fb_FirstHalfStart": {
      return 1;
    }
    case "Fb_FirstHalfEnd": {
      return 45;
    }
    case "Fb_SecondHalfStart": {
      return 46;
    }
    case "Fb_SecondHalfEnd": {
      return 90;
    }
    case "Fb_ExtraTimeFirstHalfStart": {
      return 91;
    }
    case "Fb_ExtraTimeFirstHalfEnd": {
      return 105;
    }
    case "Fb_ExtraTimeSecondHalfStart": {
      return 106;
    }
    case "Fb_ExtraTimeSecondHalfEnd": {
      return 120;
    }
    case "Fb_PenaltyStart": {
      return 121;
    }
    case "Fb_PenaltyEnd": {
      return 121;
    }
    default: {
      return undefined;
    }
  }
};

export const phaseEndMinute = (stage: PhaseStage): number | undefined => {
  switch (stage) {
    case "Fb_FirstHalfStart": {
      return 45;
    }
    case "Fb_FirstHalfEnd": {
      return 45;
    }
    case "Fb_SecondHalfStart": {
      return 90;
    }
    case "Fb_SecondHalfEnd": {
      return 90;
    }
    case "Fb_ExtraTimeFirstHalfStart": {
      return 105;
    }
    case "Fb_ExtraTimeFirstHalfEnd": {
      return 105;
    }
    case "Fb_ExtraTimeSecondHalfStart": {
      return 120;
    }
    case "Fb_ExtraTimeSecondHalfEnd": {
      return 120;
    }
    case "Fb_PenaltyStart": {
      return 121;
    }
    case "Fb_PenaltyEnd": {
      return 121;
    }
    default: {
      return undefined;
    }
  }
};
