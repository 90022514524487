import { useMutation, useQueryClient } from "react-query";
import { TippResult } from "../models/TippResult";
import { useBackendMutationFunction } from "./common/useBackendMutationFunction";
import { GetOpenFixturesKey } from "./useGetOpenFixtures";
import { GetTippsKey } from "./useGetTipps";
import { GetTodayKey } from "./useGetToday";

interface PostTippData {
  data: TippResult;
  onError?: (error: unknown) => void;
}

export interface PostTippProps {
  onError?: (error: unknown) => void;
}

export function usePostTipp(props?: PostTippProps) {
  const mutationFn = useBackendMutationFunction<PostTippData, TippResult>(
    "/predictions"
  );
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries(GetTippsKey),
        queryClient.invalidateQueries(GetTodayKey),
        queryClient.invalidateQueries(GetOpenFixturesKey),
      ]),
    onError: props?.onError,
  });
}
