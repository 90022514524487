import { useGetGameday } from "../../api/useGetGameday";
import { useGetLeague } from "../../api/useGetLeague";
import { useGetTipgroups } from "../../api/useGetTipgroups";
import { ErrorCard } from "../common/ErrorCard";
import { Header } from "../common/Header";
import { QueryWrapper } from "../common/QueryWrapper";
import { MyTipgroupsWrapper } from "../home/MyTipgroups";
import { LeagueHeader } from "../league/LeagueHeader";
import { TipGroupRecommendationWrapper } from "./TipGroupRecommendation";
import LogoTippspiel from "../../assets/logo_tippspiel.svg";
import { E2BarSticky } from "../e2/E2Bar";

interface TipGroupListProps {
  leagueId?: string;
  leagueName?: string;
  matchId?: string;
  roundNumber?: number;
  type?: "vereinsheim" | "default";
}

export function TipGroupList({
  leagueId,
  leagueName,
  matchId,
  roundNumber,
  type = "default",
}: TipGroupListProps) {
  if (leagueId && leagueName) {
    if (matchId && roundNumber) {
      return (
        <TipGroupListForMatch
          leagueId={leagueId}
          leagueName={leagueName}
          matchId={matchId}
          roundNumber={roundNumber}
          type={type}
        />
      );
    } else {
      return (
        <TipGroupListForLeague
          leagueId={leagueId}
          leagueName={leagueName}
          type={type}
        />
      );
    }
  } else {
    return <TipGroupListSimple type={type} />;
  }
}

interface TipGroupListSimpleProps {
  type: "vereinsheim" | "default";
}

function TipGroupListSimple({ type }: TipGroupListSimpleProps) {
  const { data: tipGroupsData, ...tipGroupsQuery } = useGetTipgroups();
  const elements = tipGroupsData?.data.filter((g) =>
    type === "vereinsheim" ? g.is_vereinsheim : !g.is_vereinsheim
  );
  return (
    <>
      <Header
        label="Tippspiel"
        labelIcon={<LogoTippspiel />}
        sublabel={`Meine ${
          type === "default" ? "TippGruppen" : "Vereinsheim Apps"
        }`}
      />
      <QueryWrapper useQueryResult={[tipGroupsQuery]}>
        {elements ? (
          <MyTipgroupsWrapper
            type={type === "default" ? "notVereinsheim" : "vereinsheim"}
            elements={elements}
          />
        ) : (
          <ErrorCard type="empty" />
        )}
        <E2BarSticky reset />
      </QueryWrapper>
    </>
  );
}

interface TipGroupListForLeagueProps {
  leagueId: string;
  leagueName: string;
  type: "vereinsheim" | "default";
}

function TipGroupListForLeague({
  leagueId,
  leagueName,
  type,
}: TipGroupListForLeagueProps) {
  const { data: tipGroupsData, ...tipGroupsQuery } = useGetTipgroups();
  const { data: leagueData, ...leagueQuery } = useGetLeague({
    leagueId: leagueId,
  });
  const elements = tipGroupsData?.data.filter(
    (g) =>
      (type === "vereinsheim" ? g.is_vereinsheim : !g.is_vereinsheim) &&
      g.contest_ids?.includes(leagueId) === true
  );
  return (
    <>
      <LeagueHeader leagueId={leagueId} leagueName={leagueName} />
      <QueryWrapper useQueryResult={[tipGroupsQuery, leagueQuery]}>
        {leagueData && elements ? (
          <TipGroupRecommendationWrapper
            league={leagueData.data}
            elements={elements}
            showButton={false}
            type={type === "default" ? "notVereinsheim" : "vereinsheim"}
          />
        ) : (
          <ErrorCard type="empty" />
        )}
        <E2BarSticky reset roundNumber={leagueData?.data.currentRound.number} />
      </QueryWrapper>
    </>
  );
}

interface TipGroupListForMatchProps {
  leagueId: string;
  leagueName: string;
  matchId: string;
  roundNumber: number;
  type: "vereinsheim" | "default";
}

function TipGroupListForMatch({
  leagueId,
  leagueName,
  matchId,
  roundNumber,
  type,
}: TipGroupListForMatchProps) {
  const { data: tipGroupsData, ...tipGroupsQuery } = useGetTipgroups();
  const { data: leagueData, ...leagueQuery } = useGetLeague({
    leagueId: leagueId,
  });
  const { data: matchData, ...matchQuery } = useGetGameday({
    leagueId: leagueId,
    round_number: roundNumber,
  });
  const elements = tipGroupsData?.data.filter(
    (g) =>
      (type === "vereinsheim" ? g.is_vereinsheim : !g.is_vereinsheim) &&
      g.contest_ids?.includes(leagueId) === true
  );
  const match = matchData?.data.find((m) => m.id === matchId);
  return (
    <>
      <LeagueHeader leagueId={leagueId} leagueName={leagueName} />
      <QueryWrapper useQueryResult={[tipGroupsQuery, leagueQuery, matchQuery]}>
        {leagueData && elements && match ? (
          <TipGroupRecommendationWrapper
            league={leagueData.data}
            elements={elements}
            match={match}
            roundNumber={roundNumber}
            showButton={false}
            type={type === "default" ? "notVereinsheim" : "vereinsheim"}
          />
        ) : (
          <ErrorCard type="empty" />
        )}
        <E2BarSticky reset />
      </QueryWrapper>
    </>
  );
}
