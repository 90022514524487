import { Label, LabelText, LabelType } from "../common/Label";
import UserImage from "../common/UserImage";
import {
  _getTrendElement,
  RankingTrend,
  RankingSize,
} from "../ranking/Ranking";
import { Link } from 'react-router-dom'

interface TableProps {
  headerItems: Column[];
  rows: Row[];
  size?: RankingSize;
}

export interface Row {
  columns: Column[];
  isSelected?: boolean;
  linkUrl?: string
}

type CellType = "numeric" | "image" | "icon" | "trend";

export interface Column {
  value: string;
  width?: string;
  minWidth?: string;
  columnWidth?: number; // fraction of the screen between 0 and 1
  label?: LabelType;
  columnSpan?: number;
  align?: "left" | "center" | "right";
  truncate?: boolean;
  type?: CellType;
  username?: string;
}

interface TableCellContentProps {
  value: string;
  type?: CellType;
  label?: LabelType;
  truncate?: boolean;
  username?: string;
}

export function TableCellContent({
  value,
  label,
  truncate,
  type,
  username = "",
}: TableCellContentProps) {
  if (label) {
    return (
      <div className="flex items-center">
        <Label type={label}>
          <LabelText>{value}</LabelText>
        </Label>
      </div>
    );
  } else if (type === "image" && value) {
    return <UserImage image={value} name={username} />;
  } else if (type === "trend" && value) {
    return _getTrendElement(value as RankingTrend);
  } else {
    return truncate ? <div className="truncate">{value}</div> : <>{value}</>;
  }
}

export function Table({ headerItems, rows, size }: TableProps) {
  return (
    <table
      className={`kicker-tippspiel-table ${
        size === "minimal" ? "table-minimal" : ""
      }`}
    >
      <thead>
        <tr>
          {headerItems.map((item, index) => {
            item.align = item.align ? item.align : "left";
            return (
              <th
                key={`th-${index}`}
                style={{
                  width: item.width,
                  minWidth: item.minWidth,
                  textAlign: item.align,
                }}
                colSpan={item.columnSpan}
              >
                {item.label ? (
                  <Label type={item.label} size="small">
                    <LabelText>{item.value}</LabelText>
                  </Label>
                ) : (
                  item.value
                )}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, i) => {
          return (
            <tr
              key={`tr-${i}`}
              className={row.isSelected ? "row-selected" : ""}
            >
              {row.columns.map((item, index) => {
                item.align = item.align ? item.align : "left";
                const tableContent = (
                  <TableCellContent
                      value={item.value}
                      label={item.label}
                      type={item.type}
                      truncate={item.truncate}
                      username={item.username}
                    />
                )

                return (
                  <td
                    key={index}
                    style={{
                      width: item.width,
                      minWidth: item.minWidth,
                      textAlign: item.align,
                    }}
                    colSpan={item.columnSpan}
                    className={`${
                      item.type === "numeric" ? "tabular-nums" : ""
                    } ${item.truncate ? "w-full max-w-0" : ""}`}
                  >
                    {row.linkUrl ? <Link to={row.linkUrl}>{tableContent}</Link> : tableContent}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
