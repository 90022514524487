import Card from "../common/Card";

interface TipGroupAdminDescriptionProps {
  description: string;
  setDescription: (description: string) => void;
}

export function TipGroupAdminDescription({
  description,
  setDescription,
}: TipGroupAdminDescriptionProps) {
  return (
    <Card labelText="Beschreibung der Gruppe festlegen">
      <textarea
        className="input"
        placeholder="Beschreibung (optional)"
        value={description}
        maxLength={1000}
        onChange={(e) => {
          e.preventDefault();
          setDescription(e.target.value);
        }}
      />
    </Card>
  );
}
