import { useMutation, useQueryClient } from "react-query";
import { NewTipgroup } from "../models/NewTipgroup";
import { Tipgroup } from "../models/Tipgroup";
import { useBackendMutationFunction } from "./common/useBackendMutationFunction";
import { UrlQuery } from "./common/useBackendUrl";
import { GetTipgroupKey } from "./useGetTipgroup";
import { GetTipgroupsKey } from "./useGetTipgroups";

interface PostEditTipgroupData {
  data: Tipgroup;
}

interface UsePutEditTipgroupProps extends UrlQuery {
  groupId: string;
}

export function usePutEditTipgroup(props: UsePutEditTipgroupProps) {
  const mutationFn = useBackendMutationFunction<
    PostEditTipgroupData,
    NewTipgroup
  >("/groups/:groupId/update", "PUT", props);
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(GetTipgroupsKey);
      return await queryClient.invalidateQueries([GetTipgroupKey, props]);
    },
  });
}
