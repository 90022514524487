import { UrlQuery } from "./common/useBackendUrl";
import { useBackendQueryFunction } from "./common/useBackendQueryFunction";
import { useQuery } from "react-query";
import { Match } from "../models/Match";
import { MetaData } from "../models/MetaData";

interface GamedayData {
  data: Match[];
  meta?: MetaData;
}

interface UseGetGamedayProps extends UrlQuery {
  leagueId: string;
  round_number: number;
}

export function useGetGameday(props: UseGetGamedayProps) {
  const queryFn = useBackendQueryFunction<GamedayData>(
    "/fixtures/:leagueId",
    props
  );

  return useQuery<GamedayData>(["GetGameday", props], queryFn);
}
