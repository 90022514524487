import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import { useEnvironment } from "../../Environment";
import { News } from "../../models/News";
import { linkUrlNews } from "../../shared/linkUrlHelper";
import Card from "../common/Card";

interface NewsListProps {
  newsData: News[];
}

export type NewsDisplayType = "native" | "external" | "internal";

export function NewsList({ newsData }: NewsListProps) {
  const env = useEnvironment();
  const newsDisplayType = env.isVereinsheim
    ? "internal"
    : env.isWebApp
    ? "external"
    : "native";
  const topNews = newsData[0];
  newsData = newsData.filter((value: News) => value.guid != topNews.guid);
  return (
    <Card header={false} contentPadding="news">
      {topNews && (
        <TopNews
          topNews={topNews}
          newsDisplayType={newsDisplayType}
          replacerFunc={env.replacerFunc}
        />
      )}
      {newsData &&
        newsData.map((entry: News) => (
          <NewsEntry
            key={entry.guid}
            news={entry}
            newsDisplayType={newsDisplayType}
            replacerFunc={env.replacerFunc}
          />
        ))}
    </Card>
  );
}

interface NewsWrapperProps {
  news: News;
  newsDisplayType: NewsDisplayType;
  replacerFunc?: (replacer: string) => void;
  children: ReactNode;
}

function NewsWrapper({
  news,
  newsDisplayType,
  replacerFunc,
  children,
}: NewsWrapperProps) {
  switch (newsDisplayType) {
    case "external":
      return (
        <a href={news.link} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      );
    case "internal":
      return <Link to={linkUrlNews(news.link)}>{children}</Link>;
    case "native":
      return (
        <div
          onClick={() => replacerFunc && replacerFunc(`@@news:${news.guid}@@`)}
        >
          {children}
        </div>
      );
  }
}

interface TopNewsProps {
  topNews: News;
  newsDisplayType: NewsDisplayType;
  replacerFunc?: (replacer: string) => void;
}

function TopNews({ topNews, newsDisplayType, replacerFunc }: TopNewsProps) {
  return (
    <NewsWrapper
      news={topNews}
      newsDisplayType={newsDisplayType}
      replacerFunc={replacerFunc}
    >
      <div className="top-news">
        <div className="top-news-image-wrapper">
          <img src={topNews.image_url} className="top-news-image" />
        </div>
        <div className="top-news-title">{topNews?.title}</div>
      </div>
    </NewsWrapper>
  );
}

interface NewsEntrytProps {
  news: News;
  newsDisplayType: NewsDisplayType;
  replacerFunc?: (replacer: string) => void;
}
function NewsEntry({ news, newsDisplayType, replacerFunc }: NewsEntrytProps) {
  return (
    <NewsWrapper
      news={news}
      newsDisplayType={newsDisplayType}
      replacerFunc={replacerFunc}
    >
      <div className="news-entry">
        <div className="news-entry-image-wrapper">
          <img src={news.image_url} className="news-entry-image" />
        </div>
        <div className="news-entry-text">
          <div className="news-entry-subline">{news.subline}</div>
          <div className="news-entry-title">{news.title}</div>
        </div>
      </div>
    </NewsWrapper>
  );
}
