import { useParams } from "react-router";
import { useGetProfile } from "../api/useGetProfile";
import { useGetProfileRankings } from "../api/useGetProfileRankings";
import { QueryWrapper } from "../components/common/QueryWrapper";
import { E2BarSticky } from "../components/e2/E2Bar";
import { Profile } from "../components/profile/Profile";
import { ProfileHeader } from "../components/profile/ProfileHeader";
import { ProfileParamKeys } from "../models/ParamKeys";
import { Error404Page } from "./Error404Page";

export function ProfilePage() {
  const params = useParams<ProfileParamKeys>();

  return params.uuid ? (
    <ProfileComponent userId={params.uuid} />
  ) : (
    <Error404Page />
  );
}

interface ProfileComponentProps {
  userId: string;
}

function ProfileComponent({ userId }: ProfileComponentProps) {
  const { data: profileData, ...profileQuery } = useGetProfile({
    uuid: userId,
  });
  const { data: profileRankingsData } = useGetProfileRankings({
    uuid: userId,
  });
  return (
    <>
      <ProfileHeader profile={profileData?.data} />
      <QueryWrapper useQueryResult={profileQuery}>
        {profileData ? (
          <Profile
            profile={profileData.data}
            profileRankings={profileRankingsData?.data}
          />
        ) : (
          <Error404Page />
        )}
      </QueryWrapper>
      <E2BarSticky reset roundNumber={0}/>
    </>
  );
}
