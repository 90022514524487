import { ReactNode } from "react";
import { formatNumber } from "../../shared/numberHelper";
import { ListItem, ListRow } from "./List";

interface ListElementProps {
  icon?: ReactNode;
  name: ReactNode | string;
  linkUrl?: string;
  endComponent?: ReactNode;
  isHeadline?: boolean;
  isLightHeadline?: boolean;
  hasBorder?: boolean;
  noPaddingName?: boolean;
}

export function ListElement({
  icon,
  name,
  linkUrl,
  endComponent,
  isHeadline,
  isLightHeadline,
  hasBorder,
  noPaddingName,
}: ListElementProps) {
  return (
    <ListRow
      isHeadline={isHeadline}
      isLightHeadline={isLightHeadline}
      hasBorder={hasBorder}
      to={linkUrl}
    >
      <ListItem>{icon}</ListItem>
      <ListItem noPadding={noPaddingName}>{name}</ListItem>
      {endComponent}
    </ListRow>
  );
}

interface RankedListElementProps {
  icon: ReactNode;
  name: string;
  linkUrl: string;
  rank?: number;
  count?: number;
  isHeadline?: boolean;
  isLightHeadline?: boolean;
  hasBorder?: boolean;
}

export function RankedListElement({
  rank,
  count,
  ...props
}: RankedListElementProps) {
  const endComponent = rank ? (
    <ListItem>
      {count ? (
        <span>
          {formatNumber(rank)}{" "}
          <span className={"font-light text-gray-200"}>{`/ ${formatNumber(
            count
          )}`}</span>
        </span>
      ) : (
        <span>
          {rank}
          <span className={"font-light text-gray-200"}>. PLATZ</span>
        </span>
      )}
    </ListItem>
  ) : null;
  return <ListElement endComponent={endComponent} {...props} />;
}
