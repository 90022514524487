import { useMutation, useQueryClient } from "react-query";
import { Tipgroup } from "../models/Tipgroup";
import { useBackendMutationFunction } from "./common/useBackendMutationFunction";
import { UrlQuery } from "./common/useBackendUrl";
import { GetTipgroupsKey } from "./useGetTipgroups";

interface PostJoinVereinsheimData {
  data: Tipgroup;
}

interface PostJoinVereinsheimQueryProps extends UrlQuery {
  vereinsheimId: string;
}

interface PostJoinVereinsheimProps {
  props: PostJoinVereinsheimQueryProps;
  onSuccess: (data: PostJoinVereinsheimData) => void;
  onError: () => void;
}

export function usePostJoinVereinsheim(props: PostJoinVereinsheimProps) {
  const mutationFn = useBackendMutationFunction<PostJoinVereinsheimData, void>(
    "/groups/join_vereinsheim/:vereinsheimId",
    "POST",
    props.props
  );
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onSuccess: (data) => {
      props.onSuccess(data);
      return queryClient.invalidateQueries(GetTipgroupsKey);
    },
    onError: () => props.onError(),
  });
}
