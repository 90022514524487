import { useState } from "react";
import { stringToColour } from "../../shared/colorHelper";
import { getInitial } from "../../shared/nameHelper";
import { CropImageModal } from "../crop/CropImageModal";

type UserImageSize = "sm" | "md" | "lg";

interface UserImageProps {
  image: string;
  name: string;
  size?: UserImageSize;
  customImage?: boolean;
}

export default function UserImage({
  image,
  name,
  size = "sm",
  customImage = false,
}: UserImageProps) {
  const [imageWorks, setImageWorks] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleModalOpen = (e: any) => {
    (e as Event).preventDefault();
    setIsOpen(true);
  };

  const handlModaleClose = () => {
    setIsOpen(false);
  };

  return (
    <div
      className={`
        user-image-wrapper 
        user-image-wrapper-${size} 
        ${customImage ? "user-image-custom" : ""}
      `}
      style={{
        backgroundColor: stringToColour(name),
      }}
    >
      {!imageWorks && (
        <div className="user-image-initials">{getInitial(name)}</div>
      )}
      {imageWorks && (
        <>
          <img
            src={image}
            onClick={handleModalOpen}
            onError={() => setImageWorks(false)}
            className="user-image pointer-cursor"
          />
          <CropImageModal
            isOpen={isOpen}
            handleCloseModal={handlModaleClose}
            image={image}
          />
        </>
      )}
    </div>
  );
}
