import React, { useState, useEffect } from "react";
import { useDebounce } from "../../shared/useDebounce";

type ToggleProps = {
  defaultChecked?: boolean;
  onToggle?: (checked: boolean) => void;
};

const Toggle = ({ defaultChecked = false, onToggle }: ToggleProps) => {
  const [isChecked, setIsChecked] = useState<boolean>(defaultChecked);

  const debouncedPushesToggle = useDebounce<boolean>(isChecked, 1000);

  useEffect(() => {
    if (isChecked === debouncedPushesToggle) {
      if (onToggle) {
        onToggle(isChecked);
      }
    }
  }, [isChecked, debouncedPushesToggle, onToggle]);

  const handleToggle = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div className="relative flex flex-col items-center justify-center overflow-hidden">
      <div className="flex">
        <label className="inline-flex relative items-center mr-5 cursor-pointer">
          <input type="checkbox" className="sr-only peer" defaultChecked={isChecked} />
          <div
            onClick={handleToggle}
            className="w-11 h-6 bg-gray-200 rounded-full peer  peer-focus:ring-primary  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-primary after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-primary"
          ></div>
        </label>
      </div>
    </div>
  );
};

export default Toggle;
