import { useNavigate } from "react-router";
import { usePostLeaveTipGroup } from "../../api/usePostLeaveTipGroup";
import Button, { ButtonWrapper } from "../common/Button";
import Card from "../common/Card";

interface TipGroupLeaveProps {
  groupName: string;
  groupId: string;
}

export function TipGroupLeave({ groupName, groupId }: TipGroupLeaveProps) {
  const navigate = useNavigate();
  const { mutate: leaveGroup } = usePostLeaveTipGroup({ groupId: groupId });
  return (
    <Card
      labelType="card-attention"
      labelText="Tippgruppe verlassen"
      contentClassName="text-center"
    >
      <p className="mb-4 text-center font-normal">
        Möchtest du diese Tippgruppe wirklich verlassen?
      </p>
      <ButtonWrapper>
        <Button
          color="danger"
          onClick={() => {
            leaveGroup();
            navigate("/");
          }}
        >
          {groupName} verlassen
        </Button>
      </ButtonWrapper>
    </Card>
  );
}
