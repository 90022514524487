import React, { useState } from "react";
import { getTipgroupInitial } from "../../shared/nameHelper";
import { stringToColour } from "../../shared/colorHelper";
import { CropImageModal } from "../crop/CropImageModal";

export type TipGroupImageSize = "sm" | "md" | "lg";

interface TipGroupImageProps {
  name: string;
  image?: string;
  fullImage?: string;
  size?: TipGroupImageSize;
}

export function TipGroupImage({
  image,
  fullImage,
  name,
  size = "md",
}: TipGroupImageProps) {
  const [isOpen, setIsOpen] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleModalOpen = (e: any) => {
    (e as Event).preventDefault();
    setIsOpen(true);
  };

  const handlModaleClose = () => {
    setIsOpen(false);
  };

  return (
    <div
      className={`tip-group-image-wrapper tip-group-image-wrapper-${size}`}
      style={{
        backgroundColor: image ? undefined : stringToColour(name),
      }}
      title={`Logo ${name}`}
    >
      {image ? (
        <>
          <img
            src={image}
            alt={name}
            onClick={handleModalOpen}
            className={"cursor-pointer"}
          />
          <CropImageModal
            isOpen={isOpen}
            handleCloseModal={handlModaleClose}
            image={fullImage ?? image}
          />
        </>
      ) : (
        <span className="tip-group-image-initials">
          {getTipgroupInitial(name)}
        </span>
      )}
    </div>
  );
}
