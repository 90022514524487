import { useQuery } from "react-query";
import { MetaData } from "../models/MetaData";
import { UserRanking } from "../models/UserRanking";
import { useBackendQueryFunction } from "./common/useBackendQueryFunction";
import { UrlQuery } from "./common/useBackendUrl";

interface GetTipgroupRankingData {
  data: UserRanking[];
  meta: MetaData;
}

interface UseGetTipgroupRankinkProps extends UrlQuery {
  groupId: string;
}

export function useGetTipgroupRanking(props: UseGetTipgroupRankinkProps) {
  const queryFn = useBackendQueryFunction<GetTipgroupRankingData>(
    "/rankings/group/:groupId",
    props,
    true
  );

  return useQuery<GetTipgroupRankingData>(
    ["useGetTipgroupRanking", props],
    queryFn
  );
}
