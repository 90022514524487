import React, {
  useState,
  useContext,
  createContext,
  Dispatch,
  SetStateAction,
  useMemo,
} from "react";
import { Match } from "../../models/Match";
import { TippMode } from "../../models/TippMode";
import { TippResult } from "../../models/TippResult";
import {
  MatchLiveState,
  _calculateLiveState,
} from "../../shared/matchLiveState";
import { E2Result, getCorrectScore, getW3Status } from "../../shared/useE2Bar";
import { E2BarDataType } from "../league/types";

type E2Match = {
  competition: string;
  match: string;
};

interface GetMatchFormatBasedOnTippModeProps {
  tippMode: TippMode;
  e2Match: E2Match;
  tipp: TippResult;
}

const getMatchFormatBasedOnTippMode = ({
  tippMode,
  e2Match,
  tipp,
}: GetMatchFormatBasedOnTippModeProps): E2Result => {
  if (tippMode === "full") {
    return {
      ...e2Match,
      correctScore: getCorrectScore(tipp),
    };
  }
  return { ...e2Match, "3W": getW3Status(tipp) };
};

const defaultE2Data: E2BarDataType = {
  gamedays: [],
  tipps: {},
  tippMode: "full",
  tippModes: {},
};

type E2ContextProps = {
  e2Tipps: Record<string, E2Result>;
  setE2Data: Dispatch<SetStateAction<E2BarDataType>>;
  resetE2Data: () => void;
};

const E2Context = createContext<E2ContextProps>({
  e2Tipps: {},
  setE2Data: () => null,
  resetE2Data: () => null,
});

interface E2ProviderProps {
  children?: React.ReactNode;
}

export const E2Provider = ({ children }: E2ProviderProps) => {
  const [e2EData, setE2Data] = useState<E2BarDataType>(defaultE2Data);

  const e2Tipps = useMemo(() => {
    const { gamedays, tipps, tippMode, tippModes } = e2EData;
    const tippedMatches: Record<string, E2Result> = {};
    gamedays.forEach((match: Match) => {
      if (_calculateLiveState(match) !== MatchLiveState.PreMatch) {
        return;
      }

      const tipp = tipps[match.id];

      if (tipp == null) {
        return;
      }

      const e2Match = {
        competition: match.contest_id,
        match: match.id,
      };

      if (tippModes) {
        tippedMatches[match.id] = getMatchFormatBasedOnTippMode({
          tippMode: tippModes[match.id] ?? tippMode,
          e2Match,
          tipp,
        });
      } else {
        tippedMatches[match.id] = getMatchFormatBasedOnTippMode({
          tippMode,
          e2Match,
          tipp,
        });
      }
    });
    return tippedMatches;
  }, [e2EData]);

  const resetE2Data = () => {
    setE2Data(defaultE2Data);
  };

  return (
    <E2Context.Provider
      value={{
        e2Tipps,
        setE2Data,
        resetE2Data,
      }}
    >
      {children}
    </E2Context.Provider>
  );
};

export const useE2Tipps = () => useContext(E2Context);
