import { UrlQuery } from "./common/useBackendUrl";
import { useBackendQueryFunction } from "./common/useBackendQueryFunction";
import { useQuery } from "react-query";
import { Tipgroup } from "../models/Tipgroup";

interface GetTipgroupData {
  data: Tipgroup;
}

interface UseGetPublicTipgroupProps {
  queryProps: UseGetPublicTipgroupQueryProps;
  onError:  ((err: unknown) => void) | undefined
}

interface UseGetPublicTipgroupQueryProps extends UrlQuery {
  code: string;
}

export function useGetPublicTipgroup(props: UseGetPublicTipgroupProps) {
  const queryFn = useBackendQueryFunction<GetTipgroupData>(
    "/groups/public/:code",
    props.queryProps,
    true
  );

  return useQuery<GetTipgroupData>(["GetPublicTipgroup", props], queryFn, { onError: props.onError});
}
