import { useParams } from "react-router";
import { useGetLeague } from "../api/useGetLeague";
import { LeagueHeader } from "../components/league/LeagueHeader";
import { League } from "../components/league/League";
import { LeaguePageParamKeys } from "../models/ParamKeys";
import { Error404Page } from "./Error404Page";
import { useGetTipgroups } from "../api/useGetTipgroups";
import { QueryWrapper } from "../components/common/QueryWrapper";
import { TipGroupLeagueHeader } from "../components/tipgroup/TipGroupHeader";
import { TipGroupLeague } from "../components/tipgroup/TipGroupLeague";
import { Header } from "../components/common/Header";
import { ErrorCard } from "../components/common/ErrorCard";
import { E2BarSticky } from "../components/e2/E2Bar";

export function LeaguePage() {
  const params = useParams<LeaguePageParamKeys>();

  if (params.leagueId && params.roundNumber) {
    if (params.roundNumber === undefined || params.roundNumber == "0") {
      return (
          <PreloadCurrentLeague
            leagueId={params.leagueId}
            leagueName={params.leagueName}
          />
      );
    }

    if (params.leagueName) {
      return (
        <>
            <LeagueHeader
              leagueName={params.leagueName}
              leagueId={params.leagueId}
            />
            <League
              leagueId={params.leagueId}
              roundNumber={parseInt(params.roundNumber)}
            />
            <E2BarSticky roundNumber={parseInt(params.roundNumber)} />
        </>
      );
    }

    return (
        <PreloadLeague
          leagueId={params.leagueId}
          roundNumber={parseInt(params.roundNumber)}
        />
    );
  } else {
    return <Error404Page />;
  }
}

interface PreloadCurrentLeagueProps {
  leagueId: string;
  leagueName?: string;
}

function PreloadCurrentLeague({
  leagueId,
  leagueName,
}: PreloadCurrentLeagueProps) {
  const { data: leagueData, ...leagueQuery } = useGetLeague({
    leagueId: leagueId,
  });
  const name = leagueData ? leagueData.data.name : leagueName;

  return (
    <>
      {name ? (
        <LeagueHeader leagueName={name} leagueId={leagueId} />
      ) : (
        <Header />
      )}
      <QueryWrapper useQueryResult={[leagueQuery]}>
        {leagueData && leagueData?.data?.currentRound.number ? (
          <League
            leagueId={leagueId}
            roundNumber={leagueData.data.currentRound.number}
          />
        ) : (
          <ErrorCard type="404" />
        )}
      </QueryWrapper>
      <E2BarSticky roundNumber={0}/>
    </>
  );
}

interface PreloadLeagueProps {
  leagueId: string;
  roundNumber: number;
}

function PreloadLeague({ leagueId, roundNumber }: PreloadLeagueProps) {
  const { data: leagueData, ...leagueQuery } = useGetLeague({
    leagueId: leagueId,
  });
  const { data: tipgroupsData, ...tipgroupsQuery } = useGetTipgroups();
  const matchGroups = tipgroupsData?.data.filter(
    (g) =>
      g.contest_ids && g.contest_ids?.findIndex((id) => id === leagueId) > -1
  );
  const onlyMatchGroup = matchGroups?.length === 1 ? matchGroups[0] : null;
  const header = leagueData ? (
    onlyMatchGroup ? (
      <TipGroupLeagueHeader
        groupName={onlyMatchGroup.name}
        leagueName={leagueData.data.name}
        leagueId={leagueId}
        image={onlyMatchGroup.image?.thumbnail_file_url}
        fullImage={onlyMatchGroup.image?.full_file_url}
      />
    ) : (
      <LeagueHeader leagueName={leagueData.data.name} leagueId={leagueId} />
    )
  ) : (
    <Header />
  );
  return (
    <>
      {header}
      <QueryWrapper useQueryResult={[leagueQuery, tipgroupsQuery]}>
        {leagueData ? (
          onlyMatchGroup ? (
            <>
              <TipGroupLeague
                groupId={onlyMatchGroup.uuid}
                leagueId={leagueId}
                roundNumber={roundNumber}
              />
            </>
          ) : (
            <League leagueId={leagueId} roundNumber={roundNumber} />
          )
        ) : (
          <ErrorCard type="404" />
        )}
      </QueryWrapper>
      <E2BarSticky roundNumber={roundNumber}/>
    </>
  );
}
