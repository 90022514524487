import { useMemo } from "react";
import { LeagueListLink } from "../components/leaguelist/LeagueList";
import { AddLeagueUiState } from "../components/tipgroup/AddLeague";
import { SportType, SportTypeType } from "../models/SportType";

interface LeagueListOtherLeaguesProps {
  setUiState: (uiState: AddLeagueUiState, sportType?: SportTypeType) => void;
  sportTypesData?: SportType[];
}

export function useLeagueListOtherLeagues({
  setUiState,
  sportTypesData,
}: LeagueListOtherLeaguesProps) {
  return useMemo(() => {
    const elements = [
      <LeagueListLink
        key={0}
        setUiState={setUiState}
        title="Fußball - Profis"
        uiState="international"
        sportType="Soccer"
      />,
      <LeagueListLink
        key={1}
        setUiState={setUiState}
        title="Fußball - Amateure"
        uiState="amateur-association"
        sportType="Soccer"
      />,
    ];
    const otherSports =
      sportTypesData && sportTypesData.length > 0
        ? sportTypesData.reduce<Array<JSX.Element>>((result, type, index) => {
            if (type.sport_type !== "Soccer") {
              result.push(
                <LeagueListLink
                  key={index + 2}
                  setUiState={setUiState}
                  title={type.label}
                  uiState="international"
                  sportType={type.sport_type}
                />
              );
            }
            return result;
          }, [])
        : null;
    return {
      header: "Weitere Wettbewerbe",
      elements: otherSports ? [...elements, ...otherSports] : elements,
    };
  }, [sportTypesData, setUiState]);
}
