import { useState } from "react";
import { useNavigate } from "react-router";
import { useGetTopLeagues } from "../../api/useGetTopLeagues";
import { usePostEditTipgroupImage } from "../../api/usePostEditTipgroupImage";
import { usePostNewTipgroup } from "../../api/usePostNewTipgroup";
import { League } from "../../models/League";
import { useLeagueListSetUiState } from "../../shared/useLeagueListSetUiState";
import Button from "../common/Button";
import Card from "../common/Card";
import { QueryWrapper } from "../common/QueryWrapper";
import StickyFooter from "../common/StickyFooter";
import { AddLeagueState } from "./AddLeague";
import { TipGroupAdminDescription } from "./TipGroupAdminDescription";
import { TipGroupAdminImage } from "./TipGroupAdminImage";
import { TipGroupAdminLeagueList } from "./TipGroupAdminLeagueList";
import { TipGroupAdminName } from "./TipGroupAdminName";

interface CreateTipGroupProps {
  name: string;
  setName: React.Dispatch<string>;
  description: string;
  setDescription: React.Dispatch<string>;
  state: AddLeagueState;
  setState: React.Dispatch<React.SetStateAction<AddLeagueState>>;
  setBase64Image: React.Dispatch<string>;
}
export function CreateTipGroup({
  name,
  setName,
  description,
  setDescription,
  setBase64Image,
  state,
  setState,
}: CreateTipGroupProps) {
  const navigate = useNavigate();

  const [groupId, setGroupId] = useState("");
  const { mutate: postImage } = usePostEditTipgroupImage({
    props: { groupId: groupId },
    onSuccessOrError: () => {
      navigate("/", { replace: true });
    },
  });
  const [imageFormData, setImageFormData] = useState<FormData>();
  const { data: topLeaguesData, ...topLeaguesQuery } = useGetTopLeagues();
  const { mutate: postNewGroup } = usePostNewTipgroup({
    onSuccess: (data) => {
      setGroupId(data.data.uuid);
    },
    tryUploadImage: () => {
      if (imageFormData) {
        postImage(imageFormData);
      } else {
        navigate("/", { replace: true });
      }
    },
  });

  const addLeague = (league: League) =>
    setState({
      ...state,
      leagues: [...state.leagues, league],
    });

  const removeLeague = (id: string) => {
    if (state.leagues.some((el) => el.id === id)) {
      setState({
        ...state,
        leagues: state.leagues.filter((el) => el.id !== id),
      });
    }
  };

  const setUiState = useLeagueListSetUiState(setState);

  const canCreate = !(name.length > 0 && state.leagues.length > 0);

  return (
    <QueryWrapper useQueryResult={[topLeaguesQuery]}>
      {canCreate ? <CannotCreateTipGroup /> : null}
      <TipGroupAdminName name={name} setName={setName} />
      <TipGroupAdminDescription
        description={description}
        setDescription={setDescription}
      />
      <TipGroupAdminImage
        setImageFormData={setImageFormData}
        setBase64Image={setBase64Image}
      />
      <TipGroupAdminLeagueList
        leagues={state.leagues}
        topLeaguesData={topLeaguesData}
        addLeague={addLeague}
        removeLeague={removeLeague}
        setUiState={setUiState}
      />
      {/* TODO: minimum name length? */}
      <StickyFooter>
        <Button
          color="primary"
          size="lg"
          disabled={canCreate}
          onClick={() => {
            postNewGroup({
              name: name,
              description: description,
              contest_ids: state.leagues.map((l) => l.id),
            });
          }}
        >
          Tippgruppe speichern
        </Button>
      </StickyFooter>
    </QueryWrapper>
  );
}

function CannotCreateTipGroup() {
  return (
    <Card labelType="card-attention" labelText="Tippgruppe speichern">
      <p className="pb-4 text-center font-normal">
        Zum Speichern der Gruppe musst Du einen Namen vergeben und mindestens
        einen Wettbewerb hinzufügen!
      </p>
    </Card>
  );
}
