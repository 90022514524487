import Card from "../common/Card";

interface TipGroupAdminNameProps {
  name: string;
  setName: (name: string) => void;
}

export function TipGroupAdminName({ name, setName }: TipGroupAdminNameProps) {
  return (
    <Card labelText="Name der Gruppe festlegen">
      <input
        className="input"
        placeholder="Name"
        type="text"
        value={name}
        onChange={(e) => {
          e.preventDefault();
          setName(e.target.value);
        }}
      />
    </Card>
  );
}
