import { useBackendQueryFunction } from "./common/useBackendQueryFunction";
import { useQuery } from "react-query";
import { UrlQuery } from "./common/useBackendUrl";
import { League } from "../models/League";

interface AmateurLeaguesData {
  data: League[];
}

interface UseGetAmateurLeaguesProps extends UrlQuery {
  association: string;
  team_type: string;
  league_type: string;
}

export function useGetAmateurLeagues(props: UseGetAmateurLeaguesProps) {
  const queryFn = useBackendQueryFunction<AmateurLeaguesData>(
    "/contests/amateur",
    props
  );

  return useQuery<AmateurLeaguesData>(["GetAmateurLeagues"], queryFn);
}
