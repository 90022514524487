import { useCallback } from "react";
import { useEnvironment } from "../Environment";

export function useReplacerCallback() {
  const env = useEnvironment();
  const replacerFunc = env.replacerFunc;
  return useCallback(
    (replacer: string) => {
      if (replacerFunc) {
        replacerFunc(replacer);
      } else {
        console.warn("Replacer Function undefined");
      }
    },
    [replacerFunc]
  );
}
