import { LocalTippValues } from "../../api/usePostTippWithDebounce";
import { TippTendency } from "../../models/TippTendency";

interface MatchDetailPredictionProps {
  matchId: string;
  tendency: TippTendency;
  setTipValues: (newValue: LocalTippValues) => void;
}

export function MatchDetailPrediction({
  matchId,
  tendency,
  setTipValues,
}: MatchDetailPredictionProps) {
  return (
    <div className="mt-6">
      <div className="border-t-2 border-primary mb-10"></div>
      {Object.keys(tendency.full).map((result, index) => {
        if (index < 3) {
          return (
            <div
              key={`tendency-results-${index}`}
              style={{
                textAlign: "center",
                display: "inline-block",
                width: "33%",
              }}
              onClick={() => {
                const resultStr = result.split(":");
                setTipValues({
                  homeValue: Number.parseInt(resultStr[0]),
                  awayValue: Number.parseInt(resultStr[1]),
                  id: matchId,
                });
              }}
            >
              <span className="match-detail-tendency-results">{result}</span>
              <div className="vertical-divider"></div>
            </div>
          );
        }
      })}
      <br />
      {Object.values(tendency.full).map((percentage, index) => {
        if (index < 3) {
          return (
            <div
              key={`tendency-percentages-${index}`}
              style={{
                textAlign: "center",
                display: "inline-block",
                width: "33%",
              }}
              className={`row-detail-tendency row-detail-tendency-${index}`}
            >
              <span>{(percentage.rate * 100).toFixed()}</span>
              <div className="match-detail-tendency-result-label"></div>
            </div>
          );
        }
      })}
    </div>
  );
}
