import { useQuery } from "react-query";
import { ErrorResponse } from "../../models/ErrorResponse";
import { ApiError } from "./ApiError";
import { getLocalMd5Token, getMd5Token } from "../../shared/md5";
import { Dispatch } from "react";
import { AuthState } from "./useAuthState";

const url = "https://www.kicker.de/kickerapi/jsonwebtoken";
const Authorization = "Basic ZXh0ZXJuLmdhc3Q6N1BVYmJ2R2I=";

interface JWTResponse {
  Id: number;
  Body: string;
}

export async function getJwtToken(ssoId: string): Promise<string> {
  const headers = {
    Authorization: Authorization,
    token: getMd5Token(ssoId),
    ssoid: ssoId,
  };
  const response = await fetch(url, {
    method: "POST",
    headers: headers,
  });

  if (!response.ok) {
    const error: ErrorResponse = (await response.json()) as ErrorResponse;
    throw new ApiError(response.status, error.short_code, error.description);
  }
  const json = (await response.json()) as JWTResponse;
  if (json && json.Id == 200 && json.Body) {
    return json.Body;
  }
  throw new Error("no jwt token fetchable");
}

interface UseGetJwtTokenProps {
  ssoId: string;
  enabled: boolean;
  setAuthState: Dispatch<React.SetStateAction<AuthState>>;
}

export function useGetJwtToken({
  ssoId,
  enabled,
  setAuthState,
}: UseGetJwtTokenProps) {
  return useQuery(["GetJWT"], () => getJwtToken(ssoId), {
    enabled: enabled,
    onSuccess: (data) => {
      setAuthState((props) => {
        return {
          ...props,
          state: "fetch-user",
          jwt: data,
          user: undefined,
          cryptedCryptedSsoId: getLocalMd5Token(ssoId),
        };
      });
    },
  });
}
