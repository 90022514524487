import { ProfileUser } from "../../models/Profile";
import { userImage } from "../../shared/urlHelper";
import UserImage from "./UserImage";

const BadgeUser = ({ user }: { user: ProfileUser }) => (
  <div className={`badge-user`}>
    <div className="badge-user-user-image">
      <UserImage size="lg" image={userImage(user)} name={user.username} />
    </div>
  </div>
); 

export default BadgeUser;
