import { useMutation, useQueryClient } from "react-query";
import { useBackendMutationFunction } from "./common/useBackendMutationFunction";
import { UrlQuery } from "./common/useBackendUrl";
import { GetLeaguesKey } from "./useGetLeagues";
import { GetOpenFixturesKey } from "./useGetOpenFixtures";
import { GetTodayKey } from "./useGetToday";

interface PostLeaveLeagueData {
  data: void; // TODO: what to do with null responses?
}

interface UsePostLeaveLeagueProps extends UrlQuery {
  leagueId: string;
}

export function UsePostLeaveLeague(props: UsePostLeaveLeagueProps) {
  const mutationFn = useBackendMutationFunction<PostLeaveLeagueData, void>(
    "/me/mute/:leagueId",
    "POST",
    props
  );
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries(GetLeaguesKey),
        queryClient.invalidateQueries(GetOpenFixturesKey),
        queryClient.invalidateQueries(GetTodayKey),
      ]);
    },
  });
}
