import React from "react";
import { useEnvironment } from "../../Environment";
import Button, { LinkButton } from "./Button";
import Card from "./Card";

export type ErrorType =
  | "contest_not_found"
  | "404"
  | "data"
  | "empty"
  | "default";

interface ErrorCardProps {
  type?: ErrorType;
  error?: Error;
  callback?: () => void;
  children?: React.ReactNode;
}

function _getErrorTitleForType(type: ErrorType) {
  if (type === "empty") {
    return undefined;
  } else {
    return "Fehler";
  }
}

function _getErrorMessageForType(type: ErrorType) {
  if (type === "contest_not_found") {
    return "Für diesen Wettbewerb gibt es aktuell keine tippbaren Spiele.";
  } else if (type === "404") {
    return "Die angeforderte Seite konnte leider nicht gefunden werden.";
  } else if (type === "empty") {
    return "Keine Daten vorhanden";
  } else if (type === "data") {
    return "Fehler beim Laden der Daten.";
  } else {
    return "Ein unerwarteter Fehler ist aufgetreten.";
  }
}

function _getErrorButtonMessageForType(type: ErrorType, callback?: () => void) {
  if (type === "data" && callback) {
    return "Erneut laden";
  } else {
    return "Zurück zur Startseite";
  }
}

function _getErrorButtonForType(
  type: ErrorType,
  callback?: () => void,
  isVereinsheim = false,
  url?: string
) {
  const message = _getErrorButtonMessageForType(type, callback);
  if (type === "data" && callback) {
    return <Button onClick={callback}>{message}</Button>;
  } else if (type === "empty") {
    return null;
  } else {
    return (
      <LinkButton to={isVereinsheim && url ? url : "/"}>{message}</LinkButton>
    );
  }
}

declare const DEBUG_MODE: boolean;

export function ErrorCard({
  type = "default",
  callback,
  error = undefined,
  children,
}: ErrorCardProps) {
  const env = useEnvironment();
  if (DEBUG_MODE && error) {
    return (
      <Card labelText="Debug Infos:">
        <p>Error Name: {error.name}</p>
        <p>Error Message: {error.message}</p>
        <Button onClick={callback}>Erneut laden</Button>
      </Card>
    );
  } else {
    return (
      <Card
        labelText={_getErrorTitleForType(type)}
        labelLinkTo={env.isVereinsheim ? env.deeplinkUrl : "/"}
      >
        <p className="text-lg text-center mx-auto max-w-prose mb-4">
          {_getErrorMessageForType(type)}
        </p>
        <div className="flex justify-center">
          {_getErrorButtonForType(
            type,
            callback,
            env.isVereinsheim,
            env.deeplinkUrl
          )}
        </div>
        {children ? (
          <div className={"flex justify-center"}>{children}</div>
        ) : (
          <></>
        )}
      </Card>
    );
  }
}
