import { useNavigate } from "react-router";
import { useGetGameday } from "../../api/useGetGameday";
import { useGetGamedays } from "../../api/useGetGamedays";
import { useGetLeague } from "../../api/useGetLeague";
import { useGetTipgroup } from "../../api/useGetTipgroup";
import { useGetTipgroupRanking } from "../../api/useGetTipgroupRanking";
import { useGetTipgroupRankingRound } from "../../api/useGetTipgroupRankingRound";
import { useGetTipgroupRankingSeason } from "../../api/useGetTipgroupRankingSeason";
import { Gameday } from "../../models/Gameday";
import { League } from "../../models/League";
import { Tipgroup } from "../../models/Tipgroup";
import { RankingHeaderButtonState } from "../../pages/RankingDetailPage";
import { linkUrlTipGroupLeagueRanking } from "../../shared/linkUrlHelper";
import { ErrorCard } from "../common/ErrorCard";
import { QueryWrapper } from "../common/QueryWrapper";
import { E2BarSticky } from "../e2/E2Bar";
import { GamedayChooserHeader } from "../gameday/GamedayChooserHeader";
import { TipGroupHeader } from "../tipgroup/TipGroupHeader";
import { GroupRanking, SeasonRanking } from "./Ranking";
import { RankingDetailRound } from "./RankingDetailRound";

interface RankingDetailProps {
  roundNumber?: string;
  tipGroupId: string;
  leagueId: string;
  headerButtonState: RankingHeaderButtonState;
}
interface GroupDetailRankingProps {
  tipGroupName?: string;
  tipGroupId: string;
  leagueId?: string;
}
interface RankingSeasonDetailProps {
  group: Tipgroup;
  league: League;
  gameday: Gameday;
  rounds: Gameday[];
  roundNumber: number;
}
interface RankingRoundDetailProps {
  roundNumber: number;
  group: Tipgroup;
  league: League;
  gameday: Gameday;
  rounds: Gameday[];
}

export function RankingDetail({
  leagueId,
  tipGroupId,
  roundNumber,
  headerButtonState,
}: RankingDetailProps) {
  const { data: leagueData, ...leagueQuery } = useGetLeague({
    leagueId: leagueId,
  });
  const league = leagueData?.data;
  const { data: roundsData, ...roundsQuery } = useGetGamedays({
    leagueId,
  });
  const rounds = roundsData?.data;
  const gameday = rounds?.find((gameday) =>
    roundNumber
      ? gameday.number === parseInt(roundNumber)
      : gameday.number === league?.currentRound.number
  );
  const { data: tipgroupData, ...tipgroupQuery } = useGetTipgroup({
    groupId: tipGroupId,
  });
  const group = tipgroupData?.data;
  return (
    <QueryWrapper useQueryResult={[leagueQuery, roundsQuery, tipgroupQuery]}>
      {league && gameday && rounds && group ? (
        headerButtonState === "spieltag" ? (
          <RankingRoundDetail
            league={league}
            group={group}
            roundNumber={
              roundNumber ? parseInt(roundNumber) : league.currentRound.number
            }
            gameday={gameday}
            rounds={rounds}
          />
        ) : (
          <RankingSeasonDetail
            league={league}
            group={group}
            roundNumber={
              roundNumber ? parseInt(roundNumber) : league.currentRound.number
            }
            gameday={gameday}
            rounds={rounds}
          />
        )
      ) : (
        <ErrorCard type="empty" />
      )}
    </QueryWrapper>
  );
}

export function GroupDetailRanking({
  tipGroupName,
  tipGroupId,
}: GroupDetailRankingProps) {
  const { data: tipgroupData, ...tipgroupQuery } = useGetTipgroup({
    groupId: tipGroupId,
  });
  const { data: rankingData, ...rankingQuery } = useGetTipgroupRanking({
    groupId: tipGroupId,
  });
  return (
    <>
      <TipGroupHeader
        name={tipGroupName}
        image={tipgroupData?.data.image?.thumbnail_file_url}
        fullImage={tipgroupData?.data.image?.full_file_url}
      />
      <QueryWrapper useQueryResult={[tipgroupQuery, rankingQuery]}>
        {tipgroupData && rankingData ? (
          <>
            <GroupRanking
              group={tipgroupData.data}
              size="complete"
              rankingData={rankingData}
            />
          </>
        ) : (
          <ErrorCard type="empty" />
        )}
        <E2BarSticky reset />
      </QueryWrapper>
    </>
  );
}

function RankingSeasonDetail({
  league,
  group,
  roundNumber,
  gameday,
  rounds,
}: RankingSeasonDetailProps) {
  const navigate = useNavigate();
  const { data: rankingData, ...rankingQuery } = useGetTipgroupRankingSeason({
    groupId: group.uuid,
    leagueId: league.id,
    upto_round_number: roundNumber,
  });
  return (
    <>
      <GamedayChooserHeader
        gameday={gameday}
        rounds={rounds}
        navigate={(number) =>
          navigate(
            linkUrlTipGroupLeagueRanking(
              group.uuid,
              group.name,
              league.id,
              league.name,
              number,
              "season"
            ),
            {
              replace: true,
            }
          )
        }
      />
      <QueryWrapper useQueryResult={[rankingQuery]}>
        {league && rankingData ? (
          <>
            <SeasonRanking
              group={group}
              league={league}
              size="complete"
              rankingData={rankingData}
              roundNumber={roundNumber}
            />
          </>
        ) : (
          <ErrorCard type="empty" />
        )}
      </QueryWrapper>
    </>
  );
}

function RankingRoundDetail({
  league,
  group,
  roundNumber,
  gameday,
  rounds,
}: RankingRoundDetailProps) {
  const navigate = useNavigate();
  const { data: gamedayData, ...gamedayQuery } = useGetGameday({
    leagueId: league.id,
    round_number: roundNumber,
  });
  const showRanking = gamedayData?.data.some(
    (match) => match.started || match.finished
  );
  const { data: rankingData, ...rankingQuery } = useGetTipgroupRankingRound({
    props: {
      groupId: group.uuid,
      leagueId: league.id,
      round_number: roundNumber,
    },
    enabled: showRanking,
  });
  return (
    <>
      <GamedayChooserHeader
        gameday={gameday}
        rounds={rounds}
        navigate={(number) =>
          navigate(
            linkUrlTipGroupLeagueRanking(
              group.uuid,
              group.name,
              league.id,
              league.name,
              number,
              "round"
            ),
            {
              replace: true,
            }
          )
        }
      />
      <QueryWrapper useQueryResult={[rankingQuery, gamedayQuery]}>
        {showRanking && rankingData ? (
          <>
            <RankingDetailRound
              league={league}
              roundNumber={roundNumber}
              tipGroup={group}
              rankingData={rankingData}
              rounds={rounds}
              gameday={gameday}
            />
          </>
        ) : (
          <ErrorCard type="empty" />
        )}
      </QueryWrapper>
    </>
  );
}
