import { useMutation, useQueryClient } from "react-query";
import { NewTipgroup } from "../models/NewTipgroup";
import { Tipgroup } from "../models/Tipgroup";
import { useBackendMutationFunction } from "./common/useBackendMutationFunction";
import { GetTipgroupsKey } from "./useGetTipgroups";

interface PostNewTipgroupRequestData {
  onSuccess: (data: PostNewTipgroupData) => void;
  tryUploadImage: () => void;
}

interface PostNewTipgroupData {
  data: Tipgroup;
}

export function usePostNewTipgroup({
  onSuccess,
  tryUploadImage,
}: PostNewTipgroupRequestData) {
  const mutationFn = useBackendMutationFunction<
    PostNewTipgroupData,
    NewTipgroup
  >("/groups/create");
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onSuccess: async (data) => {
      onSuccess(data);
      await new Promise<void>((resolve) => {
        setTimeout(() => {
          resolve();
        }, 500);
      });
      await queryClient.invalidateQueries(GetTipgroupsKey);
      tryUploadImage();
    },
  });
}
