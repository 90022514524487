import { RefObject } from "react";
import { E2Result } from "./useE2Bar";

export function addContentE2ToIframe(
  matches: E2Result[],
  slotRef: RefObject<HTMLElement>,
  repeatCount = 0
) {
  //console.log("try to post e2 banner message", matches, repeatCount);
  const contentWindow = slotRef.current
    ?.querySelector("iframe")
    ?.contentDocument?.querySelector("iframe")?.contentWindow;
  if (contentWindow && matches.length > 0) {
    postMessage(matches, contentWindow);
  } else if (matches.length > 0 && repeatCount <= 3) {
    setTimeout(
      () => addContentE2ToIframe(matches, slotRef, repeatCount + 1),
      2000
    );
  }
}

export function addContentBetsToIframe(matches: E2Result[], repeatCount = 0) {
  //console.log("try to post oddsserve banner message", matches, repeatCount);
  const iframeContainer = document.querySelector("#oddsServe_4754069_1 iframe");
  if (iframeContainer && (iframeContainer as HTMLIFrameElement)) {
    const contentWindow = (
      iframeContainer as HTMLIFrameElement
    )?.contentDocument?.querySelector("iframe")?.contentWindow;
    if (contentWindow && matches.length > 0) {
      postMessage(matches, contentWindow);
      return;
    }
  }
  if (matches.length > 0 && repeatCount <= 3) {
    setTimeout(() => addContentBetsToIframe(matches, repeatCount + 1), 2000);
  }
}

function postMessage(matches: E2Result[], contentWindow: Window) {
  //console.log("post oddsserve banner message", matches, contentWindow);
  contentWindow.postMessage(matches, "*");
}
