//import { useMemo } from "react";
import { ReactNode } from "react";
import { ResultWrapper } from "../../models/Result";
import { SportTypeType } from "../../models/SportType";
import { TippMode } from "../../models/TippMode";
import { TippResult } from "../../models/TippResult";
import { MatchLiveState } from "../../shared/matchLiveState";
import { HighlightState } from "./MatchRow";
// import { ResultStyle } from "./ResultBox";

interface UntippableMatchResultProps {
  result?: ResultWrapper;
  liveState: MatchLiveState;
  tippmode: TippMode;
  tippResult?: TippResult;
  userScore?: number;
  highlightState: HighlightState;
  label?: ReactNode;
  sportType?: SportTypeType;
}

export function UntippableMatchResult({
  result,
  liveState,
  tippmode,
  tippResult,
  userScore,
  label = null,
  sportType,
}: //highlightState,
UntippableMatchResultProps) {
  // TODO: calculate if 1x2 tipp was correct. convert full tipp to 1x2 if neccessary
  const hasTipps =
    (tippResult?.home_score ?? -1) >= 0 && (tippResult?.away_score ?? -1) >= 0;

  let resultClasses = "";
  const tipDraw = tippResult
    ? tippResult.home_score === tippResult.away_score
    : false;
  const getsPoints = userScore != null && userScore > 0;

  if (liveState === "live") resultClasses += " match-result-live";
  if (tippmode === "full") {
    if (getsPoints && tipDraw) resultClasses += " match-result-green ";
    if (tipDraw && !getsPoints) resultClasses += " match-result-red ";
  } else if (tippmode === "simple") {
    if (getsPoints && tipDraw) resultClasses += " match-result-green ";
    else if (!getsPoints && tipDraw) resultClasses += " match-result-red ";
  }

  return (
    <div
      className={`
      match-result
      ${!hasTipps ? "" : ""}
      ${resultClasses}
    `}
    >
      {label}
      <span
        className={`match-result-number ${
          sportType ? `match-result-number-${sportType.toLowerCase()}` : ""
        }`}
      >
        {result?.home?.regular ?? "-"}
      </span>
      <span
        className={`match-result-divider ${
          sportType ? `match-result-divider-${sportType.toLowerCase()}` : ""
        }`}
      >
        :
      </span>
      <span
        className={`match-result-number ${
          sportType ? `match-result-number-${sportType.toLowerCase()}` : ""
        }`}
      >
        {result?.away?.regular ?? "-"}
      </span>
    </div>
  );
}
