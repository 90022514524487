import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { TipUser } from "../../models/TipUser";
import { getLocalMd5Token } from "../../shared/md5";
import useLocalStorage from "../../shared/useLocalStorage";
import { useGetAuthUser } from "./useGetAuthUser";
import { useGetJwtToken } from "./useGetJwtToken";

export interface AuthState {
  state: "fetch-token" | "fetch-user" | "authorized";
  jwt: string;
  user: TipUser | undefined;
  cryptedCryptedSsoId: string;
}

export function useAuthState(
  ssoId: string | undefined,
  externalJwtToken: string | undefined
): [AuthState, Dispatch<SetStateAction<AuthState>>] {
  const queryClient = useQueryClient();
  const [localJwt, setLocalJwt] = useLocalStorage<string>("jwt", "");
  const [localSsoId, setLocalSsoId] = useLocalStorage<string>("ssoId", "");
  const [localUser, setLocalUser] = useLocalStorage<TipUser | undefined>(
    "user",
    undefined
  );
  const md5Sso = useMemo(() => {
    return ssoId ? getLocalMd5Token(ssoId) : undefined;
  }, [ssoId]);
  const [authState, setAuthState] = useState<AuthState>({
    state: _needJwtRefetch(
      ssoId,
      externalJwtToken,
      localSsoId,
      localJwt,
      md5Sso
    )
      ? "fetch-token"
      : "authorized",
    jwt: localJwt,
    user: localUser,
    cryptedCryptedSsoId: getLocalMd5Token(localSsoId),
  });
  // ignore return value. result is set directly into state
  useGetJwtToken({
    // this will never happen because state is "authorized" when we have no ssoId (external jwt token)
    ssoId: ssoId ?? "",
    enabled: authState.state === "fetch-token",
    setAuthState,
  });
  // ignore return value. result is set directly into state
  useGetAuthUser({
    jwtToken: authState.jwt,
    enabled: authState.state === "fetch-user",
    setAuthState,
  });
  useEffect(() => {
    // called every time the initial params changed
    if (
      _needJwtRefetch(ssoId, externalJwtToken, localSsoId, localJwt, md5Sso)
    ) {
      queryClient.invalidateQueries().catch(() => null);
      setAuthState((props) => {
        return { ...props, state: "fetch-token" };
      });
    }
  }, [externalJwtToken, localJwt, localSsoId, md5Sso, queryClient, ssoId]);
  useEffect(() => {
    if (authState.state === "fetch-user" && authState.user) {
      // save results to local storage
      setLocalJwt(authState.jwt);
      setLocalSsoId(authState.cryptedCryptedSsoId);
      setLocalUser(authState.user);
      setAuthState((props) => {
        return { ...props, state: "authorized" };
      });
    }
  }, [authState, setLocalJwt, setLocalSsoId, setLocalUser]);
  return [authState, setAuthState];
}

function _needJwtRefetch(
  ssoId: string | undefined,
  externalJwtToken: string | undefined,
  localSsoId: string,
  localJwt: string,
  md5Sso: string | undefined
) {
  return (
    externalJwtToken === undefined &&
    ssoId !== undefined &&
    (localSsoId !== md5Sso || localJwt.length === 0)
  );
}
