import { useEnvironment } from "../../Environment";
import IconKicker from "../../assets/icons/kicker.svg";
import Button, { ExternalLinkButton } from "../common/Button";
import { matchReplacer } from "../../shared/urlHelper";
import { useReplacerCallback } from "../../shared/useReplacerCallback";

interface MatchInfoButtonProps {
  matchId: string;
}

export function MatchInfoButton({ matchId }: MatchInfoButtonProps) {
  const env = useEnvironment();
  const replacerCallback = useReplacerCallback();
  if (!env.isWebApp) {
    return (
      <Button
        onClick={() => {
          replacerCallback(matchReplacer(matchId));
        }}
        color="kicker-outline"
      >
        <IconKicker />
        <span>Mehr zum Spiel</span>
      </Button>
    );
  } else if (!matchId.startsWith("ama")) {
    return (
      <ExternalLinkButton
        to={`https://www.kicker.de/${matchId}/spielinfo`}
        color="kicker-outline"
      >
        <IconKicker />
        <span>Mehr zum Spiel</span>
      </ExternalLinkButton>
    );
  }
  return null;
}
