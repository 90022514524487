import Button, { ButtonWrapper } from "../common/Button";
import Card from "../common/Card";
import IconCheck from "../../assets/icons/check.svg";
import { useCallback, useState } from "react";

interface TipGroupInviteProps {
  inviteCode: string;
}

function _fallbackCopy(url: string, setCopyButtonDone: (v: boolean) => void) {
  // fallback for android webview. have to use DOM because it does not work with jsx and refs
  const txt = document.createElement("textarea");
  txt.innerText = url;
  document.body.appendChild(txt);
  txt.focus();
  txt.select();
  const result = document.execCommand("copy");
  txt.remove();
  if (!result) {
    console.log("Failed to copy:");
    setCopyButtonDone(false);
  }
}

async function _copy(
  url: string,
  setCopyButtonDone: (v: boolean) => void
): Promise<void> {
  try {
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(url);
    } else {
      _fallbackCopy(url, setCopyButtonDone);
    }
  } catch (e) {
    console.log(e);
    console.log("Failed to copy, trying fallback:");
    _fallbackCopy(url, setCopyButtonDone);
  }
}

export function TipGroupInvite({ inviteCode }: TipGroupInviteProps) {
  const url = `https://www.kicker.de/tippspiel/tipgroup/apply/${inviteCode}`;
  const [copyButtonDone, setCopyButtonDone] = useState(false);

  const copyWrapper = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    _copy(url, setCopyButtonDone).finally(() => {});
  }, [url, setCopyButtonDone]);

  const copyLink = useCallback(() => {
    copyWrapper();
  }, [copyWrapper]);

  const copyButton = useCallback(() => {
    copyWrapper();
    setCopyButtonDone(true);
  }, [copyWrapper]);

  return (
    <Card
      labelText="Zur Gruppe einladen"
      contentClassName="flex flex-col space-y-4 text-center"
    >
      <p className="font-normal">
        Teile diesen Link um Personen zu dieser Gruppe hinzuzufügen
      </p>
      <div onClick={copyLink} className="fake-link break-all">
        {url}
      </div>
      <ButtonWrapper>
        <Button onClick={copyButton} className="space-x-2 items-center">
          <span>Link kopieren</span>
          {copyButtonDone && <IconCheck />}
        </Button>
      </ButtonWrapper>
    </Card>
  );
}
