import { useCallback } from "react";
import { useEnvironment } from "../../Environment";
import { TrackingAction, TrackingContextProps } from "../../models/TrackedPage";

export function useTrackPage() {
  const envProps = useEnvironment();
  return useCallback(
    (trackingProps: TrackingContextProps) => {
      if (envProps?.isWebApp) {
        track(
          TrackingAction.RouteChange,
          trackingProps,
          envProps?.trackingFunc
        );
      } else {
        track(
          TrackingAction.RouteChangeNoSticky,
          trackingProps,
          envProps?.trackingFunc
        );
      }
    },
    [envProps?.isWebApp, envProps?.trackingFunc]
  );

  // Put back in if we want the sticky from the kicker app in tippspiel again
  // track(TrackingAction.RouteChange, contextProps, envProps?.trackingFunc);
}

export function trackTipp(
  trackingFunc?: (action: string, leagueId?: string) => void
) {
  track(TrackingAction.TippChange, undefined, trackingFunc);
}

function track(
  action: TrackingAction,
  contextProps?: TrackingContextProps,
  trackingFunc?: (action: string, leagueId?: string) => void
) {
  // only send leagueId for now (no AT tracking in versino 1.0). To send more data in future, JS Interface in iOs and Androdi Apps need to be updated too!
  if (trackingFunc) {
    trackingFunc(action, contextProps?.kickerLeagueId);
  } else {
    console.warn("Tracking Function undefined");
  }
}
