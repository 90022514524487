import { useEffect, useState } from "react";
import { ApiError } from "../../api/common/ApiError";
import { useGetPublicTipgroup } from "../../api/useGetPublicTipgroup";
import {
  TippgroupApplyProps,
  usePostTippgroupApply,
  UseTippgroupApplyProps,
} from "../../api/usePostTippgroupApply";
import { ButtonWrapper, LinkButton } from "../common/Button";
import Card from "../common/Card";
import { LoadingIndicator } from "../common/LoadingIndicator";
import { E2BarSticky } from "../e2/E2Bar";
import { TipGroupHeader } from "./TipGroupHeader";

interface TipGroupMemberListProps {
  code: string;
}

export function TipGroupApply({ code }: TipGroupMemberListProps) {
  const [invalidCode, setInvalidCode] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [errorMessage, setErrorMessage] = useState("");

  const errorFct = (error: unknown) => {
    if (error instanceof ApiError) {
      console.log(error.status);
      if (error.status == 404) {
        setInvalidCode(true);
      } else {
        setErrorMessage("Probiere es später nochmals.");
      }
    } else {
      setErrorMessage("Probiere es später nochmals.");
    }
    setIsLoading(false);
  };

  const { data: tipGroupData } = useGetPublicTipgroup({
    queryProps: {
      code: code,
    },
    onError: errorFct,
  });
  const [name, setName] = useState("");

  const applyProps: UseTippgroupApplyProps = {
    onError: errorFct,
  };

  const mutation = usePostTippgroupApply(applyProps);

  useEffect(() => {
    if (tipGroupData?.data.name && isLoading) {
      setName(tipGroupData?.data.name);
      mutation.mutate({ invite_code: code ?? "" } as TippgroupApplyProps);
      setIsLoading(false);
    }
  }, [isLoading, tipGroupData, mutation, setInvalidCode, code]);

  return (
    <>
      <TipGroupHeader
        name={name}
        image={tipGroupData?.data.image?.thumbnail_file_url}
        fullImage={tipGroupData?.data.image?.full_file_url}
      />
      <Card contentClassName="text-center">
        {isLoading ? (
          <div className="flex justify-center">
            <LoadingIndicator />
          </div>
        ) : (
          <>
            {errorMessage && <p>Error {errorMessage}</p>}
            {!errorMessage && !isLoading && !invalidCode && (
              <p>
                Du hast dich erfolgreich um die Aufnahme in die Tippgruppe
                beworben, nun muss ein Admin deine Anfrage bestätigen. Sobald
                sie angenommen wurde, erscheint die Tippgruppe in deiner
                Übersicht.
              </p>
            )}
            {!errorMessage && !isLoading && invalidCode && (
              <p>
                Dieser Einladungslink ist ungültig. Bitte lass dir vom Admin der
                Tippgruppe einen neuen Link zuschicken.
              </p>
            )}
            {errorMessage && <p>{errorMessage}</p>}
            <ButtonWrapper className="pt-4">
              <LinkButton to="/" replace={true}>
                Zur Startseite
              </LinkButton>
            </ButtonWrapper>
          </>
        )}
      </Card>
      <E2BarSticky reset />
    </>
  );
}
