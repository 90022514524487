import { ReactNode } from "react";
import { Link } from "react-router-dom";
import IconChevronRight from "../../assets/icons/chevron-right.svg";

type ButtonColors =
  | "primary"
  | "transparent"
  | "primary-outline"
  | "kicker-outline"
  | "danger"
  | "white-outline";
type ButtonSizes = "xs" | "sm" | "base" | "lg";

export interface ButtonProps {
  children?: React.ReactNode;
  className?: string;
  onClick?: () => void;
  color?: ButtonColors;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
  size?: ButtonSizes;
}

export interface LinkButtonProps extends ButtonProps {
  buttonClassName?: string;
  to: string;
  replace?: boolean;
}

export default function Button({
  children,
  onClick,
  className = "",
  color = "primary-outline",
  disabled = false,
  type = "button",
  size = "base",
}: ButtonProps) {
  return (
    <button
      onClick={onClick}
      className={`
      btn 
      ${color ? "btn-" + color : ""} 
      btn-${size} 
      ${className} 
      space-x-1
      `}
      disabled={disabled}
      type={type}
    >
      {children}
    </button>
  );
}

export function LinkButton({
  children,
  className = "",
  buttonClassName,
  color,
  disabled,
  size,
  type,
  to,
  replace = false,
  ...linkProps
}: LinkButtonProps) {
  return (
    <Link
      {...linkProps}
      replace={replace}
      className={`link-button ${className}`}
      to={to}
    >
      <Button
        color={color}
        disabled={disabled}
        size={size}
        type={type}
        className={buttonClassName}
      >
        {children}
      </Button>
    </Link>
  );
}

export function ExternalLinkButton({
  children,
  className = "",
  buttonClassName,
  color,
  disabled,
  size,
  type,
  to,
  ...linkProps
}: LinkButtonProps) {
  return (
    <a
      {...linkProps}
      className={`link-button ${className}`}
      href={to}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Button
        color={color}
        disabled={disabled}
        size={size}
        type={type}
        className={buttonClassName}
      >
        {children}
      </Button>
    </a>
  );
}

interface NextButtonProps {
  children: ReactNode;
  onClick: () => void;
}

export function NextButton({ children, onClick }: NextButtonProps) {
  return (
    <div onClick={onClick} className="next-button">
      <div className="next-button-text">{children}</div>
      <div className="next-button-icon-wrapper">
        <IconChevronRight className="next-button-icon" />
      </div>
    </div>
  );
}

interface ButtonWrapperProps {
  children: ReactNode[] | ReactNode;
  className?: string;
  type?: "default" | "list";
}

export function ButtonWrapper({
  children,
  className,
  type = "default",
}: ButtonWrapperProps) {
  const amount = Array.isArray(children) ? children.length : 1;
  return (
    <div
      className={`
        btn-wrapper 
        btn-wrapper-${type} 
        ${className ? className : ""}
      `}
      data-amount={amount}
    >
      {children}
    </div>
  );
}
