import { useBackendQueryFunction } from "./common/useBackendQueryFunction";
import { useQuery } from "react-query";
import { Tipgroup } from "../models/Tipgroup";

export const GetTipgroupsKey = "GetTipgroups";
interface GetTipgroupsData {
  data: Tipgroup[];
}

export function useGetTipgroups() {
  const queryFn = useBackendQueryFunction<GetTipgroupsData>(
    "/groups",
    undefined,
    true
  );

  return useQuery<GetTipgroupsData>([GetTipgroupsKey], queryFn);
}
