import { useState, useMemo, useEffect } from "react";
import { useParams } from "react-router";
import { League } from "../../models/League";
import { LeagueWithMatchlist } from "../../models/LeagueWithMatchList";
import { Match } from "../../models/Match";
import { MatchPageParamKeys } from "../../models/ParamKeys";
import { Tipgroup } from "../../models/Tipgroup";
import { TippResult } from "../../models/TippResult";
import { UserTipp } from "../../models/UserTipp";
import { MatchPageType } from "../../pages/MatchPage";
import { linkUrlMatch } from "../../shared/linkUrlHelper";
import { LinkButton } from "../common/Button";
import { ErrorCard } from "../common/ErrorCard";
import { TipGroupRecommendation } from "../tipgroup/TipGroupRecommendation";
import { PostMatchDetail } from "./PostMatchDetail";
import { PreMatchDetail } from "./PreMatchDetail";

interface MatchDetailProps {
  match?: Match;
  league: League;
  roundNumber: number;
  group?: Tipgroup;
  todaysMatches?: LeagueWithMatchlist[];
  openMatches?: LeagueWithMatchlist[];
  roundMatches?: Match[];
  roundTipps?: Record<string, TippResult>;
  groupTipps?: UserTipp[];
  matchPageType?: MatchPageType;
  userTipGroups: Tipgroup[];
  isGlobalMatch?: boolean;
}
export function MatchDetail(props: MatchDetailProps) {
  const {
    match,
    league,
    roundNumber,
    group,
    groupTipps,
    matchPageType,
    userTipGroups,
    isGlobalMatch = false,
  } = props;
  const params = useParams<MatchPageParamKeys>();
  const matchArr = useMemo(() => getMatchArray(props), [props]);
  const [prevMatchIndex, setPrevMatchIndex] = useState<number>(-1);
  const currentMatchIndex = useMemo<number>(() => {
    const index =
      matchArr?.findIndex((m) => m.id === (match?.id ?? params.matchId)) ?? -1;
    if (index >= 0) {
      return index;
    }
    return prevMatchIndex;
  }, [match, params.matchId, matchArr, prevMatchIndex]);

  useEffect(() => {
    if (currentMatchIndex >= 0) {
      if (currentMatchIndex === 0) {
        setPrevMatchIndex(0);
      } else {
        if (currentMatchIndex !== prevMatchIndex) {
          setPrevMatchIndex(currentMatchIndex - 1);
        }
      }
    }
  }, [currentMatchIndex, prevMatchIndex]);

  const nextMatch = useMemo(
    () => getNextMatch(matchArr, currentMatchIndex ?? -1),
    [matchArr, currentMatchIndex]
  );

  const nextMatchLeague = useMemo(
    () => getNextMatchLeague(matchArr, currentMatchIndex ?? -1, props),
    [matchArr, currentMatchIndex, props]
  );

  const shouldUseGroup =
    group && userTipGroups.findIndex((g) => group.uuid === g.uuid) > -1;
  const nextMatchUrl = useMemo(
    () =>
      nextMatch && nextMatchLeague && matchPageType
        ? linkUrlMatch(
            nextMatchLeague.id,
            nextMatchLeague.name,
            nextMatch.id,
            nextMatch.round.number,
            matchPageType,
            shouldUseGroup ? group.uuid : undefined,
            shouldUseGroup ? group.name : undefined
          )
        : undefined,
    [
      nextMatch,
      nextMatchLeague,
      matchPageType,
      shouldUseGroup,
      group?.uuid,
      group?.name,
    ]
  );
  return match ? (
    <>
      {!match.started && !match.finished && match.predictable ? (
        <PreMatchDetail
          match={match}
          nextMatchUrl={nextMatchUrl}
          league={league}
          groupTipps={groupTipps}
        />
      ) : (
        <PostMatchDetail
          match={match}
          roundNumber={roundNumber}
          leagueId={league.id}
          groupId={group?.uuid}
        />
      )}
      {isGlobalMatch && userTipGroups ? (
        <TipGroupRecommendation
          league={league}
          size="short"
          tipGroups={userTipGroups}
          match={match}
        />
      ) : null}
    </>
  ) : (
    <ErrorCard type="empty">
      {nextMatchUrl && (
        <LinkButton to={nextMatchUrl} replace={true} color="primary">
          Nächstes Spiel tippen
        </LinkButton>
      )}
    </ErrorCard>
  );
}
function getMatchArray({
  todaysMatches,
  openMatches,
  roundMatches,
  roundTipps,
  matchPageType,
}: MatchDetailProps): Match[] | undefined {
  if (
    (matchPageType === "todayGroup" || matchPageType === "todayHome") &&
    todaysMatches
  ) {
    return todaysMatches.flatMap((league) =>
      // TODO: auch alle matches mit tipps rausfiltern?
      league.fixtures
        .filter((el) => el.prediction == null)
        .map((el) => el.fixture)
        .filter((m) => !m.started && !m.finished)
    );
  } else if (matchPageType === "openTipps" && openMatches) {
    return openMatches.flatMap((league) =>
      // TODO: auch alle matches mit tipps rausfiltern?
      league.fixtures
        .filter((el) => el.prediction == null)
        .map((el) => el.fixture)
        .filter((m) => !m.started && !m.finished)
    );
  } else if (matchPageType === "league" && roundMatches) {
    return roundTipps
      ? roundMatches.filter((m) => !roundTipps[m.id])
      : roundMatches;
  }
}

function getNextMatch(
  matchArr: Match[] | undefined,
  currentMatchIndex: number
): Match | undefined {
  if (matchArr && matchArr.length > currentMatchIndex + 1) {
    return matchArr[currentMatchIndex + 1];
  }
}

function getNextMatchLeague(
  matchArr: Match[] | undefined,
  currentMatchIndex: number,
  {
    league,
    todaysMatches,
    openMatches,
    roundMatches,
    matchPageType,
  }: MatchDetailProps
): League | undefined {
  if (
    (matchPageType === "todayGroup" || matchPageType === "todayHome") &&
    todaysMatches
  ) {
    if (matchArr && matchArr.length > currentMatchIndex + 1) {
      return todaysMatches.find((league) =>
        league.fixtures.find(
          (el) => el.fixture.id === matchArr[currentMatchIndex + 1]?.id
        )
      )?.contest;
    }
  } else if (matchPageType === "openTipps" && openMatches) {
    if (matchArr && matchArr.length > currentMatchIndex + 1) {
      return openMatches.find((league) =>
        league.fixtures.find(
          (el) => el.fixture.id === matchArr[currentMatchIndex + 1]?.id
        )
      )?.contest;
    }
  } else if (matchPageType === "league" && roundMatches) {
    if (matchArr && matchArr.length > currentMatchIndex + 1) {
      return league;
    }
  }
}
