import { useGetTipgroupRankingRound } from "../../api/useGetTipgroupRankingRound";
import { TippMode } from "../../models/TippMode";
import { UserRanking } from "../../models/UserRanking";
import Card from "../common/Card";
import { QueryWrapper } from "../common/QueryWrapper";
import { Column, Row, Table } from "../table/Table";
import { RankingTop3 } from "./RankingTop3";
import TrendUnchanged from "../../assets/icons/trend-unchanged.svg";
import TrendPositive from "../../assets/icons/trend-positive.svg";
import TrendNegative from "../../assets/icons/trend-negative.svg";
import { ButtonWrapper, LinkButton } from "../common/Button";
import { useGetGlobalRankingRoundPage } from "../../api/useGetGlobalRankingRound";
import { useGetGlobalRankingSeason } from "../../api/useGetGlobalRankingSeason";
import { Dispatch, useEffect, useMemo, useState } from "react";
import { Pagination } from "../../models/Pagination";
import { useGetGroupTipps } from "../../api/useGetGroupTipps";
import { UserTipp } from "../../models/UserTipp";
import { UserScore } from "../../models/UserScore";
import { useEnvironment } from "../../Environment";
import { userImage } from "../../shared/urlHelper";
import { GlobalUserRankingData } from "../../models/GlobalUserRankingdata";
import { TipgroupRankingData } from "../../models/TipgroupRankingData";
import { Tipgroup } from "../../models/Tipgroup";
import { League } from "../../models/League";
import { ErrorCard } from "../common/ErrorCard";
import {
  linkUrlLeagueRanking,
  linkUrlProfile,
  linkUrlTipGroupLeagueRanking,
  linkUrlTipGroupRanking,
} from "../../shared/linkUrlHelper";
import { TipUser } from "../../models/TipUser";
import { formatNumber } from "../../shared/numberHelper";

export type RankingSize = "complete" | "short" | "minimal";
export type RankingType =
  | "group"
  | "season"
  | "round"
  | "roundresult"
  | "postmatch";
export type RankingTrend = "unchanged" | "negative" | "positive";

interface RankingProps {
  rankingData: UserRanking[];
  tippMode: TippMode;
  buttonPath: string;
  size: RankingSize;
  type: RankingType;
  userTipps?: UserTipp[];
}
interface GlobalRankingProps {
  firstPageData: UserRanking[];
  myPageData?: UserRanking[];
  pagination: Pagination;
  myPageNumber?: number;
  tippMode: TippMode;
  buttonPath: string;
  size: RankingSize;
  type: RankingType;
  roundNum?: number;
  leagueId: string;
}

interface GroupRankingProps {
  group: Tipgroup;
  size: RankingSize;
  rankingData: TipgroupRankingData;
}
interface RoundRankingProps {
  group: Tipgroup;
  league: League;
  roundNumber: number;
  size: RankingSize;
  rankingData: TipgroupRankingData;
}
interface GlobalRoundRankingProps {
  rankingData: GlobalUserRankingData;
  league: League;
  roundNumber: number;
  size: RankingSize;
}

interface RoundResultRankingProps {
  score: UserScore;
}

interface SeasonRankingProps {
  group: Tipgroup;
  league: League;
  size: RankingSize;
  roundNumber: number;
  rankingData: TipgroupRankingData;
}
interface GlobalSeasonRankingProps {
  league: League;
  size: RankingSize;
  roundNumber: number;
  rankingData: GlobalUserRankingData;
}

interface PostMatchRankingProps {
  leagueId: string;
  roundNumber: number;
  groupId: string;
  fixtureId: string;
}

export const _getTrendElement = (trend: RankingTrend) => {
  switch (trend) {
    case "unchanged":
      return <TrendUnchanged />;
    case "negative":
      return <TrendNegative />;
    case "positive":
      return <TrendPositive />;
    default:
      return <TrendUnchanged />;
  }
};

export function GroupRanking({ group, size, rankingData }: GroupRankingProps) {
  return rankingData &&
    rankingData.data.length > 0 &&
    !rankingData.data.every((ranking) => !ranking.score_counts.total) ? (
    <Card
      labelText={"Tippgruppenplatzierung"}
      contentPadding="table"
      labelLinkTo={linkUrlTipGroupRanking(group.uuid, group.name)}
    >
      <Ranking
        rankingData={rankingData.data}
        tippMode={rankingData.meta.prediction_mode}
        buttonPath={linkUrlTipGroupRanking(group.uuid, group.name)}
        type={"group"}
        size={size}
      />
    </Card>
  ) : size === "complete" ? (
    <ErrorCard type="empty" />
  ) : null;
}

export function SeasonRanking({
  group,
  league,
  size,
  rankingData,
  roundNumber,
}: SeasonRankingProps) {
  return rankingData &&
    rankingData.data.length > 0 &&
    !rankingData.data.every((ranking) => !ranking.score_counts.total) ? (
    <Card
      labelText={"Saison-Auswertung"}
      contentPadding="table"
      labelLinkTo={linkUrlTipGroupLeagueRanking(
        group.uuid,
        group.name,
        league.id,
        league.name,
        roundNumber,
        "season"
      )}
    >
      <Ranking
        rankingData={rankingData.data}
        tippMode={rankingData.meta.prediction_mode}
        buttonPath={linkUrlTipGroupLeagueRanking(
          group.uuid,
          group.name,
          league.id,
          league.name,
          roundNumber,
          "season"
        )}
        type={"season"}
        size={size}
      />
    </Card>
  ) : size === "complete" ? (
    <ErrorCard type="empty" />
  ) : null;
}
export function GlobalSeasonRanking({
  league,
  size,
  rankingData,
  roundNumber,
}: GlobalSeasonRankingProps) {
  return rankingData != null &&
    rankingData.meta.base &&
    rankingData.data.base.length > 0 ? (
    <Card
      labelText={"Saison-Auswertung"}
      contentPadding="table"
      labelLinkTo={linkUrlLeagueRanking(
        league.id,
        league.name,
        roundNumber,
        "season"
      )}
    >
      <GlobalRanking
        firstPageData={rankingData.data.base}
        myPageData={rankingData.data.my_page}
        myPageNumber={rankingData.meta.my_page?.page_number}
        tippMode={rankingData.meta.prediction_mode}
        buttonPath={linkUrlLeagueRanking(
          league.id,
          league.name,
          roundNumber,
          "season"
        )}
        type={"season"}
        size={size}
        pagination={rankingData.meta.base}
        leagueId={league.id}
        roundNum={roundNumber}
      />
    </Card>
  ) : size === "complete" ? (
    <ErrorCard type="empty" />
  ) : null;
}

export function RoundRanking({
  group,
  league,
  roundNumber,
  size,
  rankingData,
}: RoundRankingProps) {
  return rankingData &&
    rankingData.data.length > 0 &&
    !rankingData.data.every((ranking) => !ranking.score_counts.total) ? (
    <Card
      labelText={"Spieltags - Auswertung"}
      contentPadding="table"
      labelLinkTo={linkUrlTipGroupLeagueRanking(
        group.uuid,
        group.name,
        league.id,
        league.name,
        roundNumber,
        "round"
      )}
    >
      <Ranking
        rankingData={rankingData.data}
        tippMode={rankingData.meta.prediction_mode}
        buttonPath={linkUrlTipGroupLeagueRanking(
          group.uuid,
          group.name,
          league.id,
          league.name,
          roundNumber,
          "round"
        )}
        type={"round"}
        size={size}
      />
    </Card>
  ) : size === "complete" ? (
    <ErrorCard type="empty" />
  ) : null;
}
export function GlobalRoundRanking({
  rankingData,
  league,
  size,
  roundNumber,
}: GlobalRoundRankingProps) {
  return rankingData != null &&
    rankingData.meta.base &&
    rankingData.data.base.length > 0 ? (
    <Card
      labelText={"Spieltags - Auswertung"}
      contentPadding="table"
      labelLinkTo={linkUrlLeagueRanking(
        league.id,
        league.name,
        roundNumber,
        "round"
      )}
    >
      <GlobalRanking
        firstPageData={rankingData.data.base}
        myPageData={rankingData.data.my_page}
        myPageNumber={rankingData.meta.my_page?.page_number}
        tippMode={rankingData.meta.prediction_mode}
        buttonPath={linkUrlLeagueRanking(
          league.id,
          league.name,
          roundNumber,
          "round"
        )}
        type={"round"}
        size={size}
        pagination={rankingData.meta.base}
        leagueId={league.id}
        roundNum={roundNumber}
      />
    </Card>
  ) : size === "complete" ? (
    <ErrorCard type="empty" />
  ) : null;
}

export function RoundResultRanking({ score }: RoundResultRankingProps) {
  const user = useEnvironment().authState.user;
  return user && score && score.score_counts ? (
    <Ranking
      rankingData={[
        {
          user: user,
          score_counts: score.score_counts,
          current_score: score,
        },
      ]}
      tippMode={"full"} // doesn't matter for round result
      buttonPath={""}
      type={"roundresult"}
      size={"minimal"}
    />
  ) : null;
}

export function PostMatchRanking({
  leagueId,
  roundNumber,
  groupId,
  fixtureId,
}: PostMatchRankingProps) {
  const { data: rankingData, ...rankingQuery } = useGetTipgroupRankingRound({
    props: { round_number: roundNumber, leagueId: leagueId, groupId: groupId },
  });
  const { data: groupTippsData, ...groupTippsQuery } = useGetGroupTipps({
    fixtureId: fixtureId,
    groupId: groupId,
  });
  if (rankingData?.data && groupTippsData?.data) {
    groupTippsData.data = [...new Set(groupTippsData.data)];
    const rankingData1 = Array<UserRanking>();
    const rankingData2 = Array<UserRanking>();
    const rankingData3 = Array<UserRanking>();
    const rankingData4 = Array<UserRanking>();
    const rankingData5 = Array<TipUser>();
    rankingData?.data.forEach((user) => {
      const gfd = groupTippsData.data.find(
        (rd) => rd.user.uuid === user.user.uuid
      );
      if (gfd) {
        if (
          gfd.prediction.user_score === 3 ||
          gfd.prediction.live_score === 3
        ) {
          rankingData3.push(user);
        }
        if (
          gfd.prediction.user_score === 2 ||
          gfd.prediction.live_score === 2
        ) {
          rankingData2.push(user);
        }
        if (
          gfd.prediction.user_score === 1 ||
          gfd.prediction.live_score === 1
        ) {
          rankingData1.push(user);
        }
        if (
          gfd.prediction.user_score === 0 ||
          gfd.prediction.live_score === 0
        ) {
          rankingData4.push(user);
        }
      } else {
        rankingData5.push(user.user);
      }
    });
    return (
      <QueryWrapper useQueryResult={[rankingQuery, groupTippsQuery]}>
        {rankingData3 && rankingData3.length !== 0 ? (
          <Card labelType="correct-tendency" labelText="+3 Punkte">
            <Ranking
              buttonPath={""}
              rankingData={rankingData3}
              size={"complete"}
              tippMode="full"
              type="postmatch"
              userTipps={groupTippsData?.data}
            />
          </Card>
        ) : null}
        {rankingData2 && rankingData2.length !== 0 ? (
          <Card labelType="correct-tendency" labelText="+2 Punkte">
            <Ranking
              buttonPath={""}
              rankingData={rankingData2}
              size={"complete"}
              tippMode="full"
              type="postmatch"
              userTipps={groupTippsData?.data}
            />
          </Card>
        ) : null}
        {rankingData1 && rankingData1.length !== 0 ? (
          <Card labelType="correct-tendency" labelText="+1 Punkt">
            <Ranking
              buttonPath={""}
              rankingData={rankingData1}
              size={"complete"}
              tippMode="full"
              type="postmatch"
              userTipps={groupTippsData?.data}
            />
          </Card>
        ) : null}
        {rankingData4 && rankingData4.length !== 0 ? (
          <Card labelType="incorrect" labelText="0 Punkte">
            <Ranking
              buttonPath={""}
              rankingData={rankingData4}
              size={"complete"}
              tippMode="full"
              type="postmatch"
              userTipps={groupTippsData?.data}
            />
          </Card>
        ) : null}
        {rankingData5 && rankingData5.length !== 0 ? (
          <Card labelType="no-tip" labelText="Nicht Getippt">
            <PostMatchRankingNotTipped users={rankingData5} />
          </Card>
        ) : null}
      </QueryWrapper>
    );
  }
  return null;
}

function _getTrend(ranking: UserRanking): RankingTrend {
  if (ranking.old_score) {
    if (ranking.current_score.rank < ranking.old_score.rank) {
      return "positive";
    } else if (ranking.current_score.rank === ranking.old_score.rank) {
      return "unchanged";
    } else {
      return "negative";
    }
  } else return "positive";
}

function _getTableRows(
  rankings: UserRanking[],
  tippMode: TippMode,
  type: RankingType,
  userTipps?: UserTipp[],
  currentUserId?: string
): Row[] {
  return rankings?.map((ranking) => {
    const userTipp = userTipps?.find(
      (ut) => ut.user.uuid === ranking.user.uuid
    );
    if (tippMode === "full") {
      if (type === "roundresult") {
        return {
          columns: [
            {
              value: _getTrend(ranking),
              align: "center",
              type: "trend",
            },
            {
              value: `${formatNumber(ranking.current_score.rank)}`,
              align: "left",
              width: "4ch",
              type: "numeric",
            },
            {
              value: `${formatNumber(ranking.score_counts.total ?? 0)} Spiele`,
              align: "center",
              type: "numeric",
              width: "70%",
            },
            {
              value: `${formatNumber(ranking.score_counts.three ?? 0)}${
                ranking.score_counts.three > 0 ? "x" : ""
              }`,
              align: "center",
              type: "numeric",
            },
            {
              value: `${formatNumber(ranking.score_counts.two ?? 0)}${
                ranking.score_counts.two > 0 ? "x" : ""
              }`,
              align: "center",
              type: "numeric",
            },
            {
              value: `${formatNumber(ranking.score_counts.one ?? 0)}${
                ranking.score_counts.one > 0 ? "x" : ""
              }`,
              align: "center",
              type: "numeric",
            },
          ],
          isSelected: true,
        };
      } else {
        if (type === "postmatch") {
          return {
            columns: [
              {
                value: userImage(ranking.user),
                username: ranking.user.username,
                type: "image",
              },
              {
                value: ranking.user.username,
                truncate: true,
              },
              {
                value:
                  userTipp?.prediction?.home_score != null &&
                  userTipp.prediction.away_score != null
                    ? `${userTipp.prediction.home_score} : ${userTipp.prediction.away_score}`
                    : "Error",
                align: "center",
                label: userTipp
                  ? userTipp?.prediction.user_score === 3 ||
                    userTipp?.prediction.live_score === 3
                    ? "correct-score"
                    : userTipp?.prediction.user_score === 2 ||
                      userTipp?.prediction.live_score === 2
                    ? "correct-difference"
                    : userTipp?.prediction.user_score === 1 ||
                      userTipp?.prediction.live_score === 1
                    ? "correct-tendency"
                    : "incorrect"
                  : "incorrect",
              },
            ],
            isSelected: currentUserId == ranking.user.uuid,
            linkUrl: linkUrlProfile(ranking.user.uuid),
          };
        } else if (type === "group") {
          return {
            columns: [
              {
                value: `${formatNumber(ranking.current_score.rank)}`,
                align: "center",
                width: "4ch",
                type: "numeric",
              },
              {
                value: userImage(ranking.user),
                username: ranking.user.username,
                type: "image",
              },
              {
                value: ranking.user.username,
                truncate: true,
              },
              {
                value: `${formatNumber(ranking.score_counts.total ?? 0)}`,
                align: "center",
                type: "numeric",
              },
              {
                value: `${formatNumber(ranking.score_counts.three ?? 0)}`,
                align: "center",
                type: "numeric",
              },
              {
                value: `${formatNumber(ranking.score_counts.two ?? 0)}`,
                align: "center",
                type: "numeric",
              },
              {
                value: `${formatNumber(ranking.score_counts.one ?? 0)}`,
                align: "center",
                type: "numeric",
              },
              {
                value: `${formatNumber(ranking.current_score.points ?? 0)}`,
                align: "center",
                type: "numeric",
                label: "no-tip",
              },
            ],
            isSelected: currentUserId == ranking.user.uuid,
            linkUrl: linkUrlProfile(ranking.user.uuid),
          };
        }
        return {
          columns: [
            {
              value: _getTrend(ranking),
              align: "center",
              width: "4ch",
              type: "trend",
            },
            {
              value: `${formatNumber(ranking.current_score.rank)}`,
              align: "center",
              width: "4ch",
              type: "numeric",
            },
            {
              value: userImage(ranking.user),
              username: ranking.user.username,
              type: "image",
            },
            {
              value: ranking.user.username,
              truncate: true,
            },
            {
              value: `${formatNumber(ranking.score_counts.total ?? 0)}`,
              align: "center",
              type: "numeric",
            },
            {
              value: `${formatNumber(ranking.score_counts.three ?? 0)}`,
              align: "center",
              type: "numeric",
            },
            {
              value: `${formatNumber(ranking.score_counts.two ?? 0)}`,
              align: "center",
              type: "numeric",
            },
            {
              value: `${formatNumber(ranking.score_counts.one ?? 0)}`,
              align: "center",
              type: "numeric",
            },
            {
              value:
                type === "season"
                  ? `${formatNumber(ranking.current_score.points ?? 0)}`
                  : `+ ${formatNumber(ranking.current_score.points ?? 0)}`,
              align: "center",
              type: "numeric",
              label: type === "round" ? "correct-tendency" : "no-tip",
            },
          ],
          isSelected: currentUserId == ranking.user.uuid,
          linkUrl: linkUrlProfile(ranking.user.uuid),
        };
      }
    } else {
      if (type === "group") {
        return {
          columns: [
            {
              value: `${formatNumber(ranking.current_score.rank)}`,
              align: "center",
              width: "4ch",
              type: "numeric",
            },
            {
              value: userImage(ranking.user),
              username: ranking.user.username,
              type: "image",
            },
            {
              value: ranking.user.username,
              truncate: true,
            },
            {
              value: `${formatNumber(ranking.score_counts.total ?? 0)}`,
              align: "center",
              type: "numeric",
            },
            {
              value: `${formatNumber(ranking.current_score.points ?? 0)} Punkt${
                ranking.current_score.points === 1 ? "" : "e"
              }`,
              label: "no-tip",
              align: "center",
              type: "numeric",
            },
          ],
          isSelected: currentUserId == ranking.user.uuid,
          linkUrl: linkUrlProfile(ranking.user.uuid),
        };
      } else
        return {
          columns: [
            {
              value: _getTrend(ranking),
              align: "center",
              width: "4ch",
              type: "trend",
            },
            {
              value: `${formatNumber(ranking.current_score.rank)}`,
              align: "center",
              width: "4ch",
              type: "numeric",
            },
            {
              value: userImage(ranking.user),
              username: ranking.user.username,
              type: "image",
            },
            {
              value: ranking.user.username,
              truncate: true,
            },
            {
              value: `${formatNumber(ranking.score_counts.total ?? 0)}`,
              align: "center",
              type: "numeric",
            },
            {
              value:
                type === "round"
                  ? `+${formatNumber(ranking.current_score.points ?? 0)} Punkt${
                      ranking.current_score.points === 1 ? "" : "e"
                    }`
                  : `${formatNumber(ranking.current_score.points ?? 0)} Punkt${
                      ranking.current_score.points === 1 ? "" : "e"
                    }`,
              label: type === "round" ? "correct-tendency" : "no-tip",
              align: "center",
              type: "numeric",
            },
          ],
          isSelected: currentUserId == ranking.user.uuid,
          linkUrl: linkUrlProfile(ranking.user.uuid),
        };
    }
  });
}

function _getTableHeaders(tippMode: TippMode, type: RankingType): Column[] {
  if (tippMode === "full") {
    if (type === "roundresult") {
      return [
        {
          value: "PLATZ",
          columnSpan: 2,
        },
        {
          value: "TIPPS",
          align: "center",
        },
        {
          value: "+3",
          align: "center",
          label: "correct-tendency",
          type: "numeric",
        },
        {
          value: "+2",
          align: "center",
          label: "correct-tendency",
          type: "numeric",
        },
        {
          value: "+1",
          align: "center",
          label: "correct-tendency",
          type: "numeric",
        },
      ];
    } else {
      if (type === "postmatch") {
        return [
          {
            value: "Name",
            columnSpan: 2,
          },
          {
            value: "Spieltag",
          },
        ];
      }
      return [
        {
          value: "Platz",
          columnSpan: type === "group" ? 3 : 4,
        },
        {
          value: "T.",
          align: "center",
          minWidth: "38px",
        },
        {
          value: "+3",
          align: "center",
          label: type === "round" ? "correct-tendency" : "no-tip",
          type: "numeric",
        },
        {
          value: "+2",
          align: "center",
          label: type === "round" ? "correct-tendency" : "no-tip",
          type: "numeric",
        },
        {
          value: "+1",
          align: "center",
          label: type === "round" ? "correct-tendency" : "no-tip",
          type: "numeric",
        },
        {
          value: "Pkt.",
          align: "center",
        },
      ];
    }
  } else
    return [
      {
        value: "PLATZ",
        columnSpan: type === "group" ? 3 : 4,
      },
      {
        value: "TIPPS",
        align: "center",
      },
      {
        value: "PUNKTE",
        align: "center",
      },
    ];
}

interface GlobalRankingPage {
  pageNumber: number;
  pageData: UserRanking[];
  isUserPage: boolean;
}

interface RankingState {
  data: Array<UserRanking | undefined>;
  pages: GlobalRankingPage[];
  myUserIndex: number;
}

function _calculateInitialRankingState(
  rankingData: UserRanking[],
  size: RankingSize,
  currentUserId?: string,
  // overwrite length, needed for global
  overwriteRankingLength?: number
) {
  const myUserIndex = rankingData.findIndex((ranking) => {
    return currentUserId == ranking.user.uuid;
  });
  // filter out all users who have not tipped
  const rankingDataWithTipsOnly = rankingData.filter(
    (ranking) => ranking.score_counts.total && ranking.score_counts.total > 0
  );
  const pages = [
    {
      pageNumber: 1,
      pageData: rankingData,
      isUserPage: true,
    },
  ];
  const dataArray = Array<UserRanking | undefined>(
    overwriteRankingLength ?? rankingData.length
  );
  if (size === "complete") {
    if (rankingData.length <= 10) {
      // less than 10 players, show all of them at once without a gap
      return {
        data: rankingData,
        myUserIndex: myUserIndex,
        pages: pages,
      };
    } else if (myUserIndex > -1 && myUserIndex < 9) {
      // player is in top 9. only show top 10 and then a gap
      for (let index = 0; index < 10; index++) {
        dataArray[index] = rankingData[index];
      }
      return {
        data: dataArray,
        myUserIndex: myUserIndex,
        pages: pages,
      };
    } else if (myUserIndex > -1 && myUserIndex < 12) {
      // player is 10th, 11th or 12th. show everyone until the player after my player and then a gap
      for (let index = 0; index < myUserIndex + 2; index++) {
        dataArray[index] = rankingData[index];
      }
      return {
        data: dataArray,
        myUserIndex: myUserIndex,
        pages: pages,
      };
    } else {
      // show top 10, then a gap and then the player and the players before and after him
      for (let index = 0; index < 10; index++) {
        dataArray[index] = rankingData[index];
      }
      dataArray[myUserIndex - 1] = rankingData[myUserIndex - 1];
      dataArray[myUserIndex] = rankingData[myUserIndex];
      dataArray[myUserIndex + 1] = rankingData[myUserIndex + 1];
      return {
        data: dataArray,
        myUserIndex: myUserIndex,
        pages: pages,
      };
    }
  } else if (size === "minimal") {
    // only show the row of the user
    return {
      data: rankingData.slice(myUserIndex, myUserIndex + 1),
      myUserIndex: 0,
      pages: pages,
    };
  } else if (rankingDataWithTipsOnly.length < 3) {
    // (very) short ranking without top3 only table rows
    return {
      data: rankingData.slice(0, 3),
      myUserIndex: myUserIndex,
      pages: pages,
    };
  } else if (rankingData.length === 3) {
    // (very) short ranking with only top3, no table rows
    return {
      data: [],
      myUserIndex: -1,
      pages: pages,
    };
  } else if (myUserIndex > 3) {
    // short ranking with a top 3, 3 table rows containing the user (this) and a button
    return {
      data: rankingData.slice(myUserIndex - 1, myUserIndex + 2),
      myUserIndex: 1,
      pages: pages,
    };
  } else {
    // short ranking with a top 3 containing the user, 3 table rows (this) and a button
    return {
      data: rankingData.slice(3, 6),
      myUserIndex: -1,
      pages: pages,
    };
  }
}

function _calculateInitialGlobalRankingState(
  firstPageData: UserRanking[],
  size: RankingSize,
  pageCount: number,
  totalElements: number,
  myPageNum?: number,
  myPageData?: UserRanking[],
  currentUserId?: string
) {
  const dataArray = Array<UserRanking | undefined>(totalElements);
  // filter out all users who have not tipped
  const firstPageDataWithTipsOnly = firstPageData.filter(
    (ranking) => ranking.score_counts.total && ranking.score_counts.total > 0
  );
  if (myPageData && myPageNum) {
    // this is not the actual user index but the user index on the user's page
    const myUserIndex = myPageData.findIndex((ranking) => {
      return currentUserId == ranking.user.uuid;
    });
    const realUserIndex = (myPageNum - 1) * 100 + myUserIndex;
    if (size === "complete") {
      for (
        let index = 0;
        index < (firstPageData.length > 10 ? 10 : firstPageData.length);
        index++
      ) {
        dataArray[index] = firstPageData[index];
      }
      dataArray[realUserIndex - 1] = myPageData[myUserIndex - 1];
      dataArray[realUserIndex] = myPageData[myUserIndex];
      dataArray[realUserIndex + 1] = myPageData[myUserIndex + 1];
      const pages = Array.from({ length: pageCount }, (_, i) => {
        return {
          pageNumber: i + 1,
          pageData: Array<UserRanking>(),
          isUserPage: false,
        };
      });
      pages[0] = {
        pageNumber: 1,
        pageData: firstPageData,
        isUserPage: false,
      };
      pages[myPageNum - 1] = {
        pageNumber: myPageNum,
        pageData: myPageData,
        isUserPage: true,
      };
      // player is on another page than the top 10 so we show the top 10 and then a huge gap and then the player and the two players around him
      return {
        data: dataArray,
        myUserIndex: realUserIndex,
        pages: pages,
      };
    } else if (firstPageDataWithTipsOnly.length < 3) {
      // short ranking with no top 3, 3 table rows containing the user and a button. the top 3 will be taken from the first page, the table rows from the user page
      return {
        data: (firstPageDataWithTipsOnly.length > 0
          ? firstPageDataWithTipsOnly
          : firstPageData.slice(0, 1)
        ).concat(myPageData.slice(myUserIndex - 1, myUserIndex + 2)),
        myUserIndex: realUserIndex,
        pages: [],
      };
    } else {
      // short ranking with a top 3, 3 table rows containing the user and a button. the top 3 will be taken from the first page, the table rows from the user page
      return {
        data: myPageData.slice(myUserIndex - 1, myUserIndex + 2),
        myUserIndex: realUserIndex,
        pages: [],
      };
    }
  } else {
    const state: RankingState = _calculateInitialRankingState(
      firstPageData,
      size,
      currentUserId,
      dataArray.length
    );
    state.pages = Array.from({ length: pageCount }, (_, i) => {
      return {
        pageNumber: i + 1,
        pageData: Array<UserRanking>(),
        isUserPage: false,
      };
    });
    state.pages[0] = {
      pageNumber: 1,
      pageData: firstPageData,
      isUserPage: false,
    };
    return state;
  }
}

function _reduceRows(
  data: Array<UserRanking | undefined>,
  tippMode: TippMode,
  type: RankingType,
  userTipps?: UserTipp[],
  currentUserId?: string
) {
  // we have to get rid of the undefined here
  const reducedData = data.reduce((result, ranking) => {
    if (ranking) {
      result.push(ranking);
    }
    return result;
  }, Array<UserRanking>());
  return reducedData.length > 0
    ? _getTableRows(reducedData, tippMode, type, userTipps, currentUserId)
    : null;
}

function Ranking({
  rankingData,
  tippMode,
  buttonPath,
  size,
  type,
  userTipps,
}: RankingProps) {
  const currentUserId = useEnvironment().authState.user?.uuid;

  const updatedState = useMemo(() => {
    return _calculateInitialRankingState(rankingData, size, currentUserId);
  }, [currentUserId, rankingData, size]);

  const [state, setState] = useState<RankingState>(updatedState);
  useEffect(() => {
    setState(updatedState);
  }, [updatedState]);

  // filter out all users who have not tipped
  const rankingDataWithTipsOnly = rankingData.filter(
    (ranking) => ranking.score_counts.total && ranking.score_counts.total > 0
  );

  const top3 =
    size === "short" && rankingDataWithTipsOnly.length > 2 ? (
      <RankingTop3
        elements={rankingData.slice(0, 3).map((ranking) => {
          return {
            userId: ranking.user.uuid,
            iconUrl: userImage(ranking.user),
            username: ranking.user.username,
            number: ranking.current_score.rank,
            points: ranking.current_score.points,
            trend: _getTrend(ranking),
            type: type,
          };
        })}
      />
    ) : null;

  const headersArray: Column[] = _getTableHeaders(tippMode, type);

  const firstLabelIndex = state.data.findIndex(
    (ranking) => ranking === undefined
  );
  const upperRows = _reduceRows(
    firstLabelIndex > 0 ? state.data.slice(0, firstLabelIndex) : state.data,
    tippMode,
    type,
    userTipps,
    currentUserId
  );

  const upperLabel =
    firstLabelIndex > 0 && firstLabelIndex < state.myUserIndex ? (
      <RankingLabel state={state} setState={setState} type={"upper"} />
    ) : null;

  // get everything not undefined after the first label
  const lowerRows =
    firstLabelIndex > 0
      ? _reduceRows(
          state.data.slice(firstLabelIndex),
          tippMode,
          type,
          userTipps,
          currentUserId
        )
      : null;

  const lowerLabel =
    state.data.length > 0 && state.data[state.data.length - 1] === undefined ? (
      <RankingLabel state={state} setState={setState} type={"lower"} />
    ) : null;

  const button =
    size === "short" ? (
      <LinkButton to={buttonPath}>Gesamtes Ranking</LinkButton>
    ) : null;

  return (
    <>
      {top3}
      {upperRows && (
        <Table size={size} headerItems={headersArray} rows={upperRows} />
      )}
      {upperLabel}
      {lowerRows && (
        <Table size={size} headerItems={headersArray} rows={lowerRows} />
      )}
      {lowerLabel}
      {button && <ButtonWrapper>{button}</ButtonWrapper>}
    </>
  );
}

function GlobalRanking({
  firstPageData,
  myPageData,
  pagination,
  myPageNumber,
  buttonPath,
  size,
  tippMode,
  type,
  roundNum,
  leagueId,
}: GlobalRankingProps) {
  const currentUserId = useEnvironment().authState.user?.uuid;

  // filter out all users who have not tipped
  const firstPageDataWithTipsOnly = firstPageData.filter(
    (ranking) => ranking.score_counts.total && ranking.score_counts.total > 0
  );

  const updatedState = useMemo(() => {
    return _calculateInitialGlobalRankingState(
      firstPageData,
      size,
      pagination.total_pages,
      pagination.total_elements,
      myPageNumber,
      myPageData,
      currentUserId
    );
  }, [
    firstPageData,
    myPageData,
    myPageNumber,
    pagination.total_elements,
    pagination.total_pages,
    size,
    currentUserId,
  ]);

  const [state, setState] = useState<RankingState>(updatedState);
  useEffect(() => {
    setState(updatedState);
  }, [updatedState]);

  const top3 =
    size === "short" && firstPageDataWithTipsOnly.length > 2 ? (
      <RankingTop3
        elements={firstPageData.slice(0, 3).map((ranking) => {
          return {
            userId: ranking.user.uuid,
            iconUrl: userImage(ranking.user),
            username: ranking.user.username,
            number: ranking.current_score.rank,
            points: ranking.current_score.points,
            trend: _getTrend(ranking),
            type: type,
          };
        })}
      />
    ) : null;

  const headersArray: Column[] = _getTableHeaders(tippMode, type);

  const firstLabelIndex = state.data.findIndex(
    (ranking) => ranking === undefined
  );
  const upperRows = _reduceRows(
    firstLabelIndex > 0 ? state.data.slice(0, firstLabelIndex) : state.data,
    tippMode,
    type,
    undefined,
    currentUserId
  );

  const upperLabel =
    firstLabelIndex > -1 && firstLabelIndex < state.myUserIndex ? (
      <GlobalRankingLabel
        state={state}
        setState={setState}
        type={"upper"}
        rankingType={type}
        elementsPerPage={pagination.per_page}
        leagueId={leagueId}
        roundNum={roundNum}
      />
    ) : null;

  const lowerRows =
    firstLabelIndex > 0
      ? _reduceRows(
          state.data.slice(firstLabelIndex),
          tippMode,
          type,
          undefined,
          currentUserId
        )
      : null;

  const lowerLabel =
    state.data.length > 0 && state.data[state.data.length - 1] === undefined ? (
      <GlobalRankingLabel
        state={state}
        setState={setState}
        type={"lower"}
        rankingType={type}
        elementsPerPage={pagination.per_page}
        leagueId={leagueId}
        roundNum={roundNum}
      />
    ) : null;

  const button =
    size === "short" ? (
      <LinkButton to={buttonPath}>Gesamtes Ranking</LinkButton>
    ) : null;

  return (
    <>
      {top3}
      {upperRows && <Table headerItems={headersArray} rows={upperRows} />}
      {upperLabel}
      {lowerRows && <Table headerItems={headersArray} rows={lowerRows} />}
      {lowerLabel}
      {button && <ButtonWrapper>{button}</ButtonWrapper>}
    </>
  );
}

interface GlobalRankingLabelProps {
  type: "lower" | "upper";
  rankingType: RankingType;
  state: RankingState;
  setState: Dispatch<RankingState>;
  elementsPerPage: number;
  roundNum?: number;
  leagueId: string;
}

function GlobalRankingLabel({
  type,
  rankingType,
  state,
  setState,
  elementsPerPage,
  roundNum,
  leagueId,
}: GlobalRankingLabelProps) {
  const labelStart =
    type === "upper"
      ? state.data.findIndex((ranking) => ranking === undefined)
      : (state.myUserIndex > -1 ? state.myUserIndex : 0) +
        state.data
          .slice(state.myUserIndex > 0 ? state.myUserIndex : 0)
          .findIndex((ranking) => ranking === undefined);
  const nextPageNumber = Math.floor(labelStart / 100) + 1;
  const dataFromLabel = state.data.slice(labelStart);
  const labelEnd = dataFromLabel.findIndex((ranking) => ranking !== undefined);
  const remainingCount = dataFromLabel.slice(
    0,
    labelEnd > 0 ? labelEnd : dataFromLabel.length
  ).length;

  const [loading, setLoading] = useState(false);
  if (loading && nextPageNumber) {
    return rankingType === "round" && roundNum ? (
      <GlobalRoundRankingLabelLoader
        type={type}
        state={state}
        setLoading={setLoading}
        setState={setState}
        roundNum={roundNum}
        leagueId={leagueId}
        pageNumber={nextPageNumber}
        elementsPerPage={elementsPerPage}
      />
    ) : (
      <GlobalSeasonRankingLabelLoader
        type={type}
        state={state}
        setLoading={setLoading}
        setState={setState}
        leagueId={leagueId}
        pageNumber={nextPageNumber}
        roundNum={roundNum}
        elementsPerPage={elementsPerPage}
      />
    );
  } else
    return (
      <InnerRankingLabel
        remainingCount={remainingCount}
        onClick={() => setLoading(true)}
      />
    );
}

interface GlobalRoundRankingLabelLoaderProps {
  type: "lower" | "upper";
  state: RankingState;
  setLoading: Dispatch<boolean>;
  setState: Dispatch<RankingState>;
  roundNum: number;
  leagueId: string;
  pageNumber: number;
  elementsPerPage: number;
}

function GlobalRoundRankingLabelLoader({
  type,
  state,
  setLoading,
  setState,
  leagueId,
  roundNum,
  pageNumber,
  elementsPerPage,
}: GlobalRoundRankingLabelLoaderProps) {
  const { data: newPageData, ...newPageQuery } = useGetGlobalRankingRoundPage({
    leagueId: leagueId,
    round_number: roundNum,
    page: pageNumber,
  });

  return (
    <QueryWrapper useQueryResult={[newPageQuery]}>
      {newPageData != null ? (
        <GlobalRankingLabelLoader
          type={type}
          state={state}
          newData={newPageData.data.base}
          setLoading={setLoading}
          setState={setState}
          pageNumber={pageNumber}
          elementsPerPage={elementsPerPage}
        />
      ) : null}
    </QueryWrapper>
  );
}

interface GlobalSeasonRankingLabelLoaderProps {
  type: "lower" | "upper";
  state: RankingState;
  setLoading: Dispatch<boolean>;
  setState: Dispatch<RankingState>;
  leagueId: string;
  pageNumber: number;
  roundNum?: number;
  elementsPerPage: number;
}

function GlobalSeasonRankingLabelLoader({
  type,
  state,
  setLoading,
  setState,
  leagueId,
  pageNumber,
  roundNum,
  elementsPerPage,
}: GlobalSeasonRankingLabelLoaderProps) {
  const { data: newPageData, ...newPageQuery } = useGetGlobalRankingSeason({
    leagueId: leagueId,
    page: pageNumber,
    upto_round_number: roundNum,
  });

  return (
    <QueryWrapper useQueryResult={[newPageQuery]}>
      {newPageData != null ? (
        <GlobalRankingLabelLoader
          type={type}
          state={state}
          newData={newPageData.data.base}
          setLoading={setLoading}
          setState={setState}
          pageNumber={pageNumber}
          elementsPerPage={elementsPerPage}
        />
      ) : null}
    </QueryWrapper>
  );
}

interface GlobalRankingLabelLoaderProps {
  type: "lower" | "upper";
  state: RankingState;
  setLoading: Dispatch<boolean>;
  setState: Dispatch<RankingState>;
  pageNumber: number;
  newData: UserRanking[];
  elementsPerPage: number;
}

function _updatePages(
  state: RankingState,
  pageNumber: number,
  newData: UserRanking[]
) {
  return state.pages?.map((p) => {
    return p.pageNumber === pageNumber
      ? {
          ...p,
          pageData: newData,
        }
      : p;
  });
}

function GlobalRankingLabelLoader({
  type,
  state,
  setLoading,
  setState,
  pageNumber,
  newData,
  elementsPerPage,
}: GlobalRankingLabelLoaderProps) {
  useEffect(() => {
    if (newData) {
      setLoading(false);
      const newDataStartIndex = (pageNumber - 1) * elementsPerPage;
      const dataCopy = state.data.slice();
      if (type === "lower") {
        if (state.myUserIndex < newDataStartIndex) {
          // new data is after page of the user
          for (let index = 0; index < newData.length; index++) {
            dataCopy[newDataStartIndex + index] = newData[index];
          }
        } else {
          // new data is on the page of the user. only set the data after him
          for (
            let index = (state.myUserIndex % elementsPerPage) + 2;
            index < newData.length;
            index++
          ) {
            dataCopy[newDataStartIndex + index] = newData[index];
          }
        }
      } else {
        if (state.myUserIndex > newDataStartIndex + elementsPerPage) {
          // new data is before the page of the user
          for (let index = 0; index < newData.length; index++) {
            dataCopy[newDataStartIndex + index] = newData[index];
          }
        } else {
          // new data is on the page of the user. only set the data before him
          for (
            let index = 0;
            index < (state.myUserIndex % elementsPerPage) - 1;
            index++
          ) {
            dataCopy[newDataStartIndex + index] = newData[index];
          }
        }
      }
      setState({
        ...state,
        pages: _updatePages(state, pageNumber, newData),
        data: dataCopy,
      });
    }
  });
  return null;
}

interface RankingLabelProps {
  type: "lower" | "upper";
  state: RankingState;
  setState: Dispatch<RankingState>;
}

function RankingLabel({ type, state, setState }: RankingLabelProps) {
  const labelStart =
    type === "upper" || state.myUserIndex === -1
      ? state.data.findIndex((ranking) => ranking == undefined)
      : state.myUserIndex +
        state.data
          .slice(state.myUserIndex)
          .findIndex((ranking) => ranking == undefined);
  const dataFromLabel = state.data.slice(labelStart);
  const nextIndexWithData = dataFromLabel.findIndex(
    (ranking) => ranking != undefined
  );
  const remainingArray = dataFromLabel.slice(
    0,
    nextIndexWithData > -1 ? nextIndexWithData : dataFromLabel.length
  );
  const remainingCount = remainingArray.length;
  const onClick = () => {
    const dataCopy = state.data.slice();
    if (type === "lower") {
      for (
        let index = state.myUserIndex + 2;
        index < state.pages[0].pageData.length;
        index++
      ) {
        dataCopy[index] = state.pages[0].pageData[index];
      }
    } else {
      for (let index = 0; index < state.myUserIndex - 1; index++) {
        dataCopy[index] = state.pages[0].pageData[index];
      }
    }
    setState({
      ...state,
      data: dataCopy,
    });
  };
  return (
    <InnerRankingLabel remainingCount={remainingCount} onClick={onClick} />
  );
}

interface InnerRankingLabelProps {
  remainingCount: number;
  onClick: () => void;
}

function InnerRankingLabel({
  remainingCount,
  onClick,
}: InnerRankingLabelProps) {
  return (
    <div
      className="p-4 text-center flex items-center justify-center"
      onClick={onClick}
    >
      <span className="flex items-center space-x-1">
        <span className="w-1 h-1 bg-default-color" />
        <span className="w-1 h-1 bg-default-color" />
        <span className="w-1 h-1 bg-default-color" />
      </span>
      <span className="mx-4 uppercase">{`${remainingCount} weitere${
        remainingCount === 1 ? "r" : ""
      } Spieler`}</span>
      <span className="flex items-center space-x-1">
        <span className="w-1 h-1 bg-default-color" />
        <span className="w-1 h-1 bg-default-color" />
        <span className="w-1 h-1 bg-default-color" />
      </span>
    </div>
  );
}

interface PostMatchRankingNotTipped {
  users: Array<TipUser>;
}
function PostMatchRankingNotTipped({ users }: PostMatchRankingNotTipped) {
  const currentUserId = useEnvironment().authState.user?.uuid;
  const rows: Row[] = users.map((user) => ({
    columns: [
      {
        value: userImage(user),
        username: user.username,
        type: "image",
      },
      {
        value: user.username,
        truncate: true,
      },
    ],
    isSelected: user.uuid === currentUserId,
  }));
  return <Table headerItems={[{ value: "Name", columnSpan: 2 }]} rows={rows} />;
}
