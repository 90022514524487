import React from "react";
import { Profile } from "../../models/Profile";
import { userImage } from "../../shared/urlHelper";
import { Header } from "../common/Header";
import UserImage from "../common/UserImage";

interface ProfileProps {
  profile?: Profile;
  sublabel?: string;
  sublabelIcon?: string;
}

export function ProfileHeader({
  profile,
  sublabel,
  sublabelIcon,
}: ProfileProps) {
  return (
    <>
      {profile ? (
        <Header
          labelIcon={
            <UserImage
              size="md"
              image={userImage(profile.user)}
              name={profile.user.username}
            />
          }
          label={profile.user?.username}
          sublabel={sublabel ?? "Profil"}
          sublabelIcon={sublabelIcon}
        />
      ) : (
        <Header />
      )}
    </>
  );
}
