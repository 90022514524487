import { UrlQuery, useAuthUrl, useBackendUrl } from "./useBackendUrl";
import { QueryFunction } from "react-query";
import { useEnvironment } from "../../Environment";
import { fetchWithJwtToken } from "./fetchWithJwtToken";
import { ErrorResponse } from "../../models/ErrorResponse";
import { ApiError } from "./ApiError";

export function useBackendQueryFunction<T>(
  path: string,
  params: UrlQuery = {},
  useAuth = false
) {
  const url = useBackendUrl(path, params);
  const env = useEnvironment();
  const headers = {};
  const authUrls = useAuthUrl();

  const queryFn: QueryFunction<T> = async ({ signal }) => {
    const init = {
      signal,
      method: "GET",
      headers: headers,
    };
    if (useAuth) {
      return fetchWithJwtToken<T>(url, authUrls, env, init);
    } else {
      const response = await fetch(url, init);
      if (!response.ok) {
        const error: ErrorResponse = (await response.json()) as ErrorResponse;
        throw new ApiError(
          response.status,
          error.short_code,
          error.description
        );
      }
      return (await response.json()) as T;
    }
  };

  return queryFn;
}
