import { useState } from "react";
import { useNavigate } from "react-router";
import { E2BarSticky } from "../../components/e2/E2Bar";
import { AddLeague, AddLeagueState } from "../../components/tipgroup/AddLeague";
import { CreateTipGroup } from "../../components/tipgroup/CreateTipGroup";
import {
  NewTipGroupAddLeagueHeader,
  NewTipGroupHeader,
} from "../../components/tipgroup/TipGroupHeader";

export const CreateTipGroupStorageKey = "CreateGroup";

export function CreateTipGroupPage() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [base64Image, setBase64Image] = useState("");
  const [state, setState] = useState<AddLeagueState>({
    uiState: "start",
    leagues: [],
  });
  const backFunc = () => {
    if (state.uiState === "start") {
      navigate(-1);
    } else {
      setState(
        state.uiState === "amateur-association"
          ? { leagues: state.leagues, uiState: "start" }
          : state.uiState === "amateur-teamType"
          ? { ...state, uiState: "amateur-association", association: undefined }
          : state.uiState === "amateur-leagueType"
          ? { ...state, uiState: "amateur-teamType", teamType: undefined }
          : state.uiState === "amateur-league"
          ? { ...state, uiState: "amateur-leagueType", leagueType: undefined }
          : { leagues: state.leagues, uiState: "start" }
      );
    }
  };

  return state.uiState === "start" ? (
    <>
      <NewTipGroupHeader name={name} image={base64Image} />
      <CreateTipGroup
        name={name}
        setName={setName}
        description={description}
        setDescription={setDescription}
        setBase64Image={setBase64Image}
        state={state}
        setState={setState}
      />
      <E2BarSticky reset roundNumber={0}/>
    </>
  ) : (
    <>
      <NewTipGroupAddLeagueHeader
        image={base64Image}
        name={name}
        backFunc={backFunc}
      />
      <AddLeague state={state} setState={setState} uiMode="create" />
      <E2BarSticky reset roundNumber={0}/>
    </>
  );
}
