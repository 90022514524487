import { Link } from "react-router-dom";
import { League } from "../../models/League";
import { LeagueWithMatchlist } from "../../models/LeagueWithMatchList";
import { getSportTypePriority } from "../../models/SportType";
import { Tipgroup } from "../../models/Tipgroup";
import { linkUrlTipgroupLeague } from "../../shared/linkUrlHelper";
import { leagueLogoPath } from "../../shared/urlHelper";
import { useSportsTypeHeaderAndSortInTipgroup } from "../../shared/useSportsTypeHeaderAndSort";
import Card from "../common/Card";
import LeagueImage from "../common/LeagueImage";
import { SportTypeHeader } from "../home/SportTypeHeader";
import List, { ListItem, ListRow } from "../list/List";

interface LeaguesInTippgroupProps {
  group: Tipgroup;
  openFixtures: LeagueWithMatchlist[];
}

export function LeaguesInTippgroup({
  group,
  openFixtures,
}: LeaguesInTippgroupProps) {
  const activeContests = group.contests?.filter((contest) =>
    openFixtures.find((c) => c.contest.id === contest.contest.id)
  );
  const inactiveContests = group.contests?.filter(
    (contest) => !openFixtures.find((c) => c.contest.id === contest.contest.id)
  );
  const addSportsTypeHeadersForActive =
    useSportsTypeHeaderAndSortInTipgroup(activeContests);
  const addSportsTypeHeadersForInactive =
    useSportsTypeHeaderAndSortInTipgroup(inactiveContests);
  return (
    <>
      {activeContests && activeContests?.length > 0 ? (
        <Card labelText="Wettbewerbe">
          <List>
            {activeContests.map((contest, index) => (
              <>
                {addSportsTypeHeadersForActive &&
                  getSportTypePriority(contest.contest.sportType) !==
                    getSportTypePriority(
                      activeContests.at(index - 1)?.contest.sportType
                    ) && <SportTypeHeader type={contest.contest.sportType} />}
                <LeaguesInTippgroupElement
                  group={group}
                  league={contest.contest}
                  rank={contest.score?.rank}
                  key={contest.contest.id}
                />
              </>
            ))}
          </List>
        </Card>
      ) : null}
      {inactiveContests && inactiveContests?.length > 0 ? (
        <Card labelText="Inaktive Wettbewerbe">
          <List>
            {inactiveContests.map((contest, index) => (
              <>
                {addSportsTypeHeadersForInactive &&
                  getSportTypePriority(contest.contest.sportType) !==
                    getSportTypePriority(
                      inactiveContests.at(index - 1)?.contest.sportType
                    ) && <SportTypeHeader type={contest.contest.sportType} />}
                <LeaguesInTippgroupElement
                  group={group}
                  league={contest.contest}
                  rank={contest.score?.rank}
                  key={contest.contest.id}
                />
              </>
            ))}
          </List>
        </Card>
      ) : null}
    </>
  );
}

interface LeaguesInTippgroupElement {
  group: Tipgroup;
  league: League;
  rank?: number;
}
function LeaguesInTippgroupElement({
  group,
  league,
  rank,
}: LeaguesInTippgroupElement) {
  return (
    <Link
      to={linkUrlTipgroupLeague(
        group.uuid,
        group.name,
        league.id,
        league.name,
        league.currentRound.number
      )}
    >
      <ListRow>
        <ListItem>
          <LeagueImage image={leagueLogoPath(league.id)} name={league.name} />
        </ListItem>
        <ListItem>{league.name}</ListItem>
        {rank ? <ListItem>{`${rank}. Platz`}</ListItem> : null}
      </ListRow>
    </Link>
  );
}
