import { ReactNode } from "react";
import { leagueLogoPath } from "../../shared/urlHelper";
import { Header } from "../common/Header";
import LeagueImage from "../common/LeagueImage";

interface LeagueHeaderProps {
  leagueName: string;
  leagueId: string;
  children?: ReactNode;
}

export function LeagueHeader({
  leagueName,
  leagueId,
  children,
}: LeagueHeaderProps) {
  return (
    <Header
      label={leagueName}
      labelIcon={
        <LeagueImage image={leagueLogoPath(leagueId)} name={leagueName} />
      }
    >
      {children}
    </Header>
  );
}
