import React from "react";
import { Gameday } from "../../models/Gameday";
import { Match, matchHasSpecialState } from "../../models/Match";
import { Tipgroup } from "../../models/Tipgroup";
import { TippResult } from "../../models/TippResult";
import { linkUrlMatch } from "../../shared/linkUrlHelper";
import { formattedDateFull, isSameDay } from "../../shared/timeHelper";
// import BetPresenter from "../common/BetPresenter";
import Card from "../common/Card";
import { ErrorCard } from "../common/ErrorCard";
import SectionHeader from "../common/SectionHeader";
import { MatchRow } from "../match/MatchRow";
import MatchTime from "../match/MatchTime";

interface GamedayProps {
  leagueId: string;
  leagueName: string;
  roundNumber: number;
  isAmateurLeague: boolean;
  group?: Tipgroup;
  gamedays: Match[];
  tipps: Record<string, TippResult>;
  otherUserId?: string;
}

export function Gameday({
  group,
  gamedays,
  tipps,
  // leagueId,
  leagueName,
  roundNumber,
  // isAmateurLeague,
  otherUserId,
}: GamedayProps) {
  let latestDateMs = "";
  const untippedMatches: Match[] = [];
  const matches = gamedays.map((match) => {
    if (
      !match.started &&
      !match.finished &&
      match.predictable &&
      tipps[match.id] == null &&
      !matchHasSpecialState(match) &&
      !otherUserId
    ) {
      untippedMatches.push(match);
    }
    const kickoffDate = new Date(match.kickoff);
    const latestDate = new Date(latestDateMs);
    if (!isSameDay(kickoffDate, latestDate)) {
      latestDateMs = match.kickoff;
      return (
        <React.Fragment key={match.id}>
          <SectionHeader>{formattedDateFull(match.kickoff)}</SectionHeader>
          {!otherUserId && <MatchTime>{match.kickoff}</MatchTime>}
          <MatchRow
            match={match}
            group={group}
            matchType={"league"}
            otherUserId={otherUserId}
          />
        </React.Fragment>
      );
    } else if (kickoffDate.getTime() !== latestDate.getTime()) {
      latestDateMs = match.kickoff;
      return (
        <React.Fragment key={match.id}>
          {!otherUserId && <MatchTime>{match.kickoff}</MatchTime>}
          <MatchRow
            match={match}
            group={group}
            matchType={"league"}
            otherUserId={otherUserId}
          />
        </React.Fragment>
      );
    }
    return (
      <MatchRow
        key={match.id}
        match={match}
        group={group}
        matchType={"league"}
        otherUserId={otherUserId}
      />
    );
  });
  const [label, attention, labelLink] =
    untippedMatches.length > 0
      ? [
          `Noch ${untippedMatches.length} ungetippte${
            untippedMatches.length === 1 ? "s" : ""
          } Spiel${untippedMatches.length > 1 ? "e" : ""}`,
          true,
          linkUrlMatch(
            untippedMatches[0].contest_id,
            leagueName,
            untippedMatches[0].id,
            roundNumber,
            "league",
            group?.uuid,
            group?.name
          ),
        ]
      : ["Tipp-Auswertung", false, undefined];

  return (
    <>
      {matches && matches.length > 0 ? (
        <>
          {/* {leagueId && roundNumber && !isAmateurLeague && (
              <Card transparent noPadding>
                <BetPresenter leagueId={leagueId} gameday={roundNumber} />
              </Card>
            )} */}
          <Card
            labelText={label}
            labelType={attention ? "card-attention" : "card"}
            labelLinkTo={labelLink}
            contentPadding="match-row"
          >
            {matches}
          </Card>
        </>
      ) : (
        <ErrorCard type="empty" />
      )}
    </>
  );
}
