import { useParams } from "react-router";
import { VereinsheimHome } from "../components/vereinsheim/VereinsheimHome";
import { VereinsheimParamKeys } from "../models/ParamKeys";
import { Error404Page } from "./Error404Page";

export function VereinsheimPage() {
  const params = useParams<VereinsheimParamKeys>();
  // TODO; use params.leagueId
  if (params.vereinsheimId) {
    return (
      <VereinsheimHome
        vereinsheimId={params.vereinsheimId}
        leagueId={params.leagueId}
      />
    );
  } else {
    return <Error404Page />;
  }
}
