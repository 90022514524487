import { useNavigate, useParams } from "react-router";
import {
  GlobalRoundRanking,
  GlobalSeasonRanking,
} from "../components/ranking/Ranking";
import { Error404Page } from "./Error404Page";
import {
  GroupDetailRanking,
  RankingDetail,
} from "../components/ranking/RankingDetail";
import { LeagueHeader } from "../components/league/LeagueHeader";
import { useState, Dispatch, SetStateAction } from "react";
import { useGetGlobalRankingRound } from "../api/useGetGlobalRankingRound";
import { useGetLeague } from "../api/useGetLeague";
import { QueryWrapper } from "../components/common/QueryWrapper";
import { useGetGlobalRankingSeason } from "../api/useGetGlobalRankingSeason";
import { RankingDetailParamKeys } from "../models/ParamKeys";
import { TipGroupLeagueHeader } from "../components/tipgroup/TipGroupHeader";
import { HeaderButtonSwitch } from "../components/common/Header";
import { GamedayChooserHeader } from "../components/gameday/GamedayChooserHeader";
import { useGetGamedays } from "../api/useGetGamedays";
import { linkUrlLeagueRanking } from "../shared/linkUrlHelper";
import { ErrorCard } from "../components/common/ErrorCard";
import { Gameday } from "../models/Gameday";
import { League } from "../models/League";
import { useGetGameday } from "../api/useGetGameday";
import { useGetTipgroup } from "../api/useGetTipgroup";
import { E2BarSticky } from "../components/e2/E2Bar";
interface GlobalRankingDetailProps {
  leagueId: string;
  roundNumber?: string;
  headerButtonState: RankingHeaderButtonState;
}
interface GlobalRoundRankingDetailProps {
  league: League;
  roundNumber: number;
  gameday: Gameday;
  rounds: Gameday[];
}
interface GlobalSeasonRankingDetailProps {
  league: League;
  roundNumber: number;
  gameday: Gameday;
  rounds: Gameday[];
}

interface GroupLeagueRankingDetailProps {
  leagueId: string;
  leagueName: string;
  tipGroupId: string;
  groupName: string;
  roundNumber?: string;
  headerButtonState: RankingHeaderButtonState;
  setHeaderButtonState: Dispatch<SetStateAction<RankingHeaderButtonState>>;
}

interface GlobalRankingDetailLeagueProps {
  leagueId: string;
  leagueName: string;
  roundNumber?: string;
  headerButtonState: RankingHeaderButtonState;
  setHeaderButtonState: Dispatch<SetStateAction<RankingHeaderButtonState>>;
}

interface GlobalRankingDetailPreloaderProps {
  leagueId: string;
  leagueName: string;
  headerButtonState: RankingHeaderButtonState;
  setHeaderButtonState: Dispatch<SetStateAction<RankingHeaderButtonState>>;
}

interface RankingDetailCurrentGamedayPreloaderProps {
  leagueId: string;
  leagueName: string;
  tipGroupId: string;
  groupName: string;
  groupImage?: string;
  groupFullImage?: string;
  headerButtonState: RankingHeaderButtonState;
  setHeaderButtonState: Dispatch<SetStateAction<RankingHeaderButtonState>>;
}

export type RankingHeaderButtonState = "spieltag" | "saison";
export type RankingDetailPageType = "gameday" | "season";

export function RankingDetailPage() {
  const params = useParams<RankingDetailParamKeys>();
  const [headerButtonState, setHeaderButtonState] =
    useState<RankingHeaderButtonState>(
      params.type === "gameday" ? "spieltag" : "saison"
    );
  if (params.tipGroupId && params.tipGroupName) {
    if (params.leagueId && params.leagueName) {
      return (
        <GroupLeagueRankingDetail
          tipGroupId={params.tipGroupId}
          groupName={params.tipGroupName}
          leagueName={params.leagueName}
          leagueId={params.leagueId}
          headerButtonState={headerButtonState}
          setHeaderButtonState={setHeaderButtonState}
          roundNumber={params.roundNumber}
        />
      );
    }
    return (
      <GroupDetailRanking
        tipGroupName={params.tipGroupName}
        tipGroupId={params.tipGroupId}
      />
    );
  }
  if (params.leagueId && params.leagueName) {
    if (params.roundNumber === undefined || params.roundNumber == "0") {
      return (
        <GlobalRankingDetailPreloader
          leagueId={params.leagueId}
          leagueName={params.leagueName}
          headerButtonState={headerButtonState}
          setHeaderButtonState={setHeaderButtonState}
        />
      );
    }

    return (
      <GlobalRankingDetailLeague
        leagueId={params.leagueId}
        leagueName={params.leagueName}
        roundNumber={params.roundNumber}
        headerButtonState={headerButtonState}
        setHeaderButtonState={setHeaderButtonState}
      />
    );
  }
  return <Error404Page />;
}

function GlobalRankingDetailLeague({
  leagueId,
  leagueName,
  roundNumber,
  headerButtonState,
  setHeaderButtonState,
}: GlobalRankingDetailLeagueProps) {
  const { data: leagueData, ...leagueQuery } = useGetLeague({
    leagueId: leagueId,
  });
  const league = leagueData?.data;

  const { data: roundsData, ...roundsQuery } = useGetGamedays({
    leagueId: leagueId,
  });

  const rounds = roundsData?.data;
  const gameday = roundsData?.data.find((gameday) =>
    roundNumber
      ? gameday.number === parseInt(roundNumber)
      : gameday.number === leagueData?.data.currentRound.number
  );
  return (
    <>
      <LeagueHeader leagueName={leagueName} leagueId={leagueId}>
        <HeaderButtonSwitch
          options={["spieltag", "saison"]}
          state={headerButtonState}
          setState={setHeaderButtonState}
        />
      </LeagueHeader>

      <QueryWrapper useQueryResult={[leagueQuery, roundsQuery]}>
        {league && gameday && rounds && (
          <GlobalRankingDetail
            leagueId={leagueId}
            roundNumber={roundNumber}
            headerButtonState={headerButtonState}
          />
        )}
      </QueryWrapper>
      <E2BarSticky
        reset
        roundNumber={parseInt(roundNumber ? roundNumber : '0')}
      />
    </>
  );
}

function GlobalRankingDetail({
  leagueId,
  roundNumber,
  headerButtonState,
}: GlobalRankingDetailProps) {
  const { data: leagueData, ...leagueQuery } = useGetLeague({
    leagueId: leagueId,
  });
  const league = leagueData?.data;
  const { data: roundsData, ...roundsQuery } = useGetGamedays({
    leagueId: leagueId,
  });
  const rounds = roundsData?.data;
  const gameday = roundsData?.data.find((gameday) =>
    roundNumber
      ? gameday.number === parseInt(roundNumber)
      : gameday.number === leagueData?.data.currentRound.number
  );
  return (
    <QueryWrapper useQueryResult={[leagueQuery, roundsQuery]}>
      {league && gameday && rounds ? (
        headerButtonState === "spieltag" ? (
          <GlobalRoundRankingDetail
            league={league}
            roundNumber={
              roundNumber ? parseInt(roundNumber) : league.currentRound.number
            }
            gameday={gameday}
            rounds={rounds}
          />
        ) : (
          <GlobalSeasonRankingDetail
            league={league}
            roundNumber={
              roundNumber ? parseInt(roundNumber) : league.currentRound.number
            }
            gameday={gameday}
            rounds={rounds}
          />
        )
      ) : (
        <ErrorCard type="empty" />
      )}
    </QueryWrapper>
  );
}

function GlobalRoundRankingDetail({
  league,
  roundNumber,
  gameday,
  rounds,
}: GlobalRoundRankingDetailProps) {
  const navigate = useNavigate();
  const { data: gamedayData, ...gamedayQuery } = useGetGameday({
    leagueId: league.id,
    round_number: roundNumber,
  });
  const showRanking = gamedayData?.data.some(
    (match) => match.started || match.finished
  );
  const { data: rankingData, ...rankingQuery } = useGetGlobalRankingRound({
    props: { leagueId: league.id, round_number: roundNumber },
    enabled: showRanking,
  });
  return (
    <>
      <GamedayChooserHeader
        gameday={gameday}
        rounds={rounds}
        navigate={(number) =>
          navigate(
            linkUrlLeagueRanking(league.id, league.name, number, "round"),
            {
              replace: true,
            }
          )
        }
      />

      <QueryWrapper useQueryResult={[rankingQuery, gamedayQuery]}>
        {rankingData ? (
          <GlobalRoundRanking
            rankingData={rankingData}
            league={league}
            roundNumber={roundNumber}
            size="complete"
          />
        ) : (
          <ErrorCard type="empty" />
        )}
      </QueryWrapper>
    </>
  );
}

function GlobalSeasonRankingDetail({
  league,
  roundNumber,
  gameday,
  rounds,
}: GlobalSeasonRankingDetailProps) {
  const navigate = useNavigate();
  const { data: leagueData, ...leagueQuery } = useGetLeague({
    leagueId: league.id,
  });

  const { data: rankingData, ...rankingQuery } = useGetGlobalRankingSeason({
    leagueId: league.id,
    upto_round_number: roundNumber,
  });

  return (
    <>
      <GamedayChooserHeader
        gameday={gameday}
        rounds={rounds}
        navigate={(number) =>
          navigate(
            linkUrlLeagueRanking(league.id, league.name, number, "season"),
            {
              replace: true,
            }
          )
        }
      />
      <QueryWrapper useQueryResult={[leagueQuery, rankingQuery]}>
        {leagueData && rankingData && (
          <GlobalSeasonRanking
            league={leagueData.data}
            size="complete"
            rankingData={rankingData}
            roundNumber={roundNumber}
          />
        )}
      </QueryWrapper>
    </>
  );
}

function GlobalRankingDetailPreloader({
  headerButtonState,
  setHeaderButtonState,
  leagueName,
  leagueId,
}: GlobalRankingDetailPreloaderProps) {
  const { data: leagueData, ...leagueQuery } = useGetLeague({
    leagueId: leagueId,
  });

  return (
    <>
      <LeagueHeader leagueName={leagueName} leagueId={leagueId}>
        <HeaderButtonSwitch
          options={["spieltag", "saison"]}
          state={headerButtonState}
          setState={setHeaderButtonState}
        />
      </LeagueHeader>
      <QueryWrapper useQueryResult={[leagueQuery]}>
        {leagueData && leagueData?.data?.currentRound.number ? (
          <GlobalRankingDetail
            leagueId={leagueId}
            roundNumber={`${leagueData?.data?.currentRound.number}`}
            headerButtonState={headerButtonState}
          />
        ) : (
          <ErrorCard type="404" />
        )}
      </QueryWrapper>
      <E2BarSticky reset roundNumber={leagueData?.data?.currentRound.number} />
    </>
  );
}

function GroupLeagueRankingDetail({
  tipGroupId,
  groupName,
  leagueId,
  leagueName,
  roundNumber,
  headerButtonState,
  setHeaderButtonState,
}: GroupLeagueRankingDetailProps) {
  const { data: groupData } = useGetTipgroup({
    groupId: tipGroupId,
  });
  if (roundNumber == undefined || roundNumber == "0") {
    return (
      <RankingDetailCurrentGamedayPreloader
        tipGroupId={tipGroupId}
        groupName={groupName}
        groupImage={groupData?.data.image?.thumbnail_file_url}
        groupFullImage={groupData?.data.image?.thumbnail_file_url}
        leagueName={leagueName}
        leagueId={leagueId}
        headerButtonState={headerButtonState}
        setHeaderButtonState={setHeaderButtonState}
      />
    );
  }
  return (
    <>
      <TipGroupLeagueHeader
        groupName={groupName}
        leagueId={leagueId}
        leagueName={leagueName}
        image={groupData?.data.image?.thumbnail_file_url}
        fullImage={groupData?.data.image?.full_file_url}
      >
        <HeaderButtonSwitch
          options={["spieltag", "saison"]}
          state={headerButtonState}
          setState={setHeaderButtonState}
        />
      </TipGroupLeagueHeader>
      <RankingDetail
        tipGroupId={tipGroupId}
        leagueId={leagueId}
        roundNumber={roundNumber}
        headerButtonState={headerButtonState}
      />
      <E2BarSticky reset roundNumber={parseInt(roundNumber)} />
    </>
  );
}

function RankingDetailCurrentGamedayPreloader({
  groupName,
  tipGroupId,
  groupImage,
  groupFullImage,
  leagueName,
  headerButtonState,
  setHeaderButtonState,
  leagueId,
}: RankingDetailCurrentGamedayPreloaderProps) {
  const { data: leagueData, ...leagueQuery } = useGetLeague({
    leagueId: leagueId,
  });

  return (
    <>
      <TipGroupLeagueHeader
        groupName={groupName}
        leagueId={leagueId}
        leagueName={leagueName}
        image={groupImage}
        fullImage={groupFullImage}
      >
        <HeaderButtonSwitch
          options={["spieltag", "saison"]}
          state={headerButtonState}
          setState={setHeaderButtonState}
        />
      </TipGroupLeagueHeader>
      <QueryWrapper useQueryResult={[leagueQuery]}>
        {leagueData && leagueData?.data?.currentRound.number ? (
          <RankingDetail
            tipGroupId={tipGroupId}
            leagueId={leagueId}
            roundNumber={`${leagueData?.data?.currentRound.number}`}
            headerButtonState={headerButtonState}
          />
        ) : (
          <ErrorCard type="404" />
        )}
      </QueryWrapper>
      <E2BarSticky reset roundNumber={leagueData?.data?.currentRound.number} />
    </>
  );
}
