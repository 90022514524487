import { useMutation } from "react-query";
import { useBackendMutationFunction } from "./common/useBackendMutationFunction";

export interface PostHandleApplicationData {
  success: boolean
}

export interface TippgroupApplyProps {
  invite_code: string;
}

export interface UseTippgroupApplyProps {
  onError:  ((err: unknown) => void) | undefined
}

export function usePostTippgroupApply(props: UseTippgroupApplyProps) {
  const mutationFn = useBackendMutationFunction<
  PostHandleApplicationData,
    TippgroupApplyProps
  >("/groups/apply");

  return useMutation(mutationFn, {onError: props.onError});
}
