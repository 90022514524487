import React, { ReactNode, useCallback } from "react";
import { useQueryErrorResetBoundary } from "react-query";
import { ErrorBoundary } from "./components/common/ErrorBoundary";
import { ErrorCard } from "./components/common/ErrorCard";
import { Header } from "./components/common/Header";

function GlobalErrorBoundaryView({
  error,
  clearError,
}: {
  error: Error;
  info: React.ErrorInfo;
  clearError: () => void;
}) {
  const { reset } = useQueryErrorResetBoundary();
  console.error(error);

  const tryAgainClick = useCallback(() => {
    reset();
    clearError();
  }, [reset, clearError]);

  return (
    <>
      <Header />
      <ErrorCard type="default" callback={tryAgainClick} error={error} />
    </>
  );
}

interface GlobalErrorBoundaryProps {
  children: ReactNode;
}

export function GlobalErrorBoundary({ children }: GlobalErrorBoundaryProps) {
  return (
    <ErrorBoundary FallbackComponent={GlobalErrorBoundaryView}>
      {children}
    </ErrorBoundary>
  );
}
