import { useEffect, useMemo, useState } from "react";
import { stringToColour } from "../../shared/colorHelper";
import { getLeagueInitial } from "../../shared/nameHelper";

type LeagueImageSize = "sm" | "md" | "lg";

interface UserImageProps {
  image: string;
  name: string;
  size?: LeagueImageSize;
}

export default function LeagueImage({
  image,
  name,
  size = "md",
}: UserImageProps) {
  const [imageWorks, setImageWorks] = useState(true);
  const [imageWidth, setImageWidth] = useState(0);
  const img = useMemo(() => new Image(), []);

  useEffect(() => {
    img.src = image;
    img.onload = function () {
      setImageWidth(img.naturalWidth);
    };
    return () => {
      img.onload = null;
    };
  }, [image, img]);

  return imageWorks && imageWidth > 1 ? (
    <img
      src={image}
      onError={() => setImageWorks(false)}
      className={`league-image league-image-${size}`}
      alt={`Logo ${name}`}
    />
  ) : (
    <div
      className={`league-image-initials league-image-initials-${size}`}
      style={{
        backgroundColor: stringToColour(name),
      }}
      title={`Logo ${name}`}
    >
      <span className="league-image-initial">{getLeagueInitial(name)}</span>
    </div>
  );
}
