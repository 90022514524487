import { useBackendQueryFunction } from "./common/useBackendQueryFunction";
import { useQuery } from "react-query";
import { League } from "../models/League";

export interface LeagueData {
  data: League[];
}

export function useGetTopLeagues() {
  const queryFn = useBackendQueryFunction<LeagueData>("/contests/top");

  return useQuery<LeagueData>(["GetTopLeagues"], queryFn);
}
