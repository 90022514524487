import { useMemo } from "react";
import { League } from "../models/League";
import { getSportTypePriority } from "../models/SportType";
import { TipgroupLeague } from "../models/TipgroupLeague";

export function useSportsTypeHeaderAndSort(contests?: League[]) {
  return useMemo(() => {
    const types = contests?.map((c) => c.sportType) ?? [];
    const distinctTypes = types.filter((t, i) => t && types.indexOf(t) === i);
    console.log(distinctTypes);
    // do not show any headers when we only have "Soccer"
    const useSportsTypeHeader =
      distinctTypes.length > 1 ||
      (distinctTypes.length === 1 && distinctTypes[0] !== "Soccer");
    if (useSportsTypeHeader) {
      contests?.sort(
        (a, b) =>
          getSportTypePriority(a.sportType) - getSportTypePriority(b.sportType)
      );
    }
    return useSportsTypeHeader;
  }, [contests]);
}

export function useSportsTypeHeaderAndSortInTipgroup(
  contests?: TipgroupLeague[]
) {
  return useMemo(() => {
    const types = contests?.map((c) => c.contest.sportType) ?? [];
    const distinctTypes = types.filter((t, i) => t && types.indexOf(t) === i);
    // do not show any headers when we only have "Soccer"
    const useSportsTypeHeader =
      distinctTypes.length > 1 ||
      (distinctTypes.length === 1 && distinctTypes[0] !== "Soccer");
    if (useSportsTypeHeader) {
      contests?.sort(
        (a, b) =>
          getSportTypePriority(a.contest.sportType) -
          getSportTypePriority(b.contest.sportType)
      );
    }
    return useSportsTypeHeader;
  }, [contests]);
}
