import { useEffect, useRef } from "react";
import { useEnvironment } from "../../Environment";
import { useAdClickEffect } from "../../shared/useAdClickEffect";

declare global {
  interface Window {
    oddsServe: (
      contentUnitId: number,
      competition: string,
      matchday: number | null,
      match: string | null,
      node: HTMLSpanElement | null
    ) => void;
  }
}

interface BetMatchProps {
  leagueId: string;
  gameday: number | null;
  matchId: string;
}

export default function BetMatch({
  leagueId,
  gameday,
  matchId,
}: BetMatchProps) {
  const env = useEnvironment();
  const betRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (!env.isVereinsheim && !env.disableAds) {
      window.oddsServe(4754069, leagueId, gameday, matchId, betRef?.current);
    }
  }, [leagueId, gameday, matchId, env.isVereinsheim, env.disableAds]);
  useAdClickEffect(betRef, true);

  return !env.isVereinsheim && !env.disableAds ? (
    <span
      id={`oddsServe-match-${matchId}`}
      className="oddsServe"
      ref={betRef}
      data-competition={leagueId}
      data-matchday={gameday}
      data-match={matchId}
      data-device="desktop"
    />
  ) : null;
}
