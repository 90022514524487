import React, { useState } from "react";
import Card from "../common/Card";
import { CropImageModal } from "../crop/CropImageModal";
import { UploadImage } from "../upload/UploadImage";

interface TipGroupAdminImageProps {
  setImageFormData: (image: FormData) => void;
  setBase64Image: (image: string) => void;
}

export function TipGroupAdminImage({
  setImageFormData,
  setBase64Image,
}: TipGroupAdminImageProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [imageToCrop, setImageToCrop] = useState<string>();

  function handleImageSaveEvent() {
    setIsOpen(false);
  }

  function handleCloseModal() {
    setIsOpen(false);
  }

  function handleNewImage(image: string) {
    setImageToCrop(image);
    setIsOpen(true);
  }

  return (
    <>
      {imageToCrop && (
        <CropImageModal
          isOpen={isOpen}
          canCrop
          handleCloseModal={handleCloseModal}
          handleImageSaveEvent={handleImageSaveEvent}
          setImageFormData={setImageFormData}
          setBase64Image={setBase64Image}
          image={imageToCrop}
        />
      )}
      <Card labelText="Gruppenbild hochladen">
        <UploadImage handleNewImage={handleNewImage} />
      </Card>
    </>
  );
}
