import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { useGetGameday } from "../../api/useGetGameday";
import { useGetGamedays } from "../../api/useGetGamedays";
import { useGetLeague } from "../../api/useGetLeague";
import { useGetOpenFixtures } from "../../api/useGetOpenFixtures";
import { useGetTipgroup } from "../../api/useGetTipgroup";
import { useGetTipgroupApplications } from "../../api/useGetTipgroupApplications";
import { useGetTipgroupMembers } from "../../api/useGetTipgroupMembers";
import { useGetTipgroupRanking } from "../../api/useGetTipgroupRanking";
import { useGetTipgroupRankingRound } from "../../api/useGetTipgroupRankingRound";
import { useGetTipgroupRankingSeason } from "../../api/useGetTipgroupRankingSeason";
import { useGetTipps } from "../../api/useGetTipps";
import { useGetToday } from "../../api/useGetToday";
import { usePostHandleApplication } from "../../api/usePostHandleApplication";
import { ButtonWrapper, LinkButton } from "../../components/common/Button";
import Card from "../../components/common/Card";
import { ColumnsWrapper } from "../../components/common/Columns";
import { ErrorCard } from "../../components/common/ErrorCard";
import { QueryWrapper } from "../../components/common/QueryWrapper";
import UserImage from "../../components/common/UserImage";
import { Gameday } from "../../components/gameday/Gameday";
import { GamedayChooserHeader } from "../../components/gameday/GamedayChooserHeader";
import { CurrentMatchesHome } from "../../components/home/CurrentMatchesHome";
import List, { ListItem, ListRow } from "../../components/list/List";
import {
  GroupRanking,
  RoundRanking,
  SeasonRanking,
} from "../../components/ranking/Ranking";
import { LeaguesInTippgroup } from "../../components/tipgroup/LeaguesInTippgroup";
import { TipGroupEdit } from "../../components/tipgroup/TipGroupEdit";
import {
  TipGroupHeader,
  TipGroupLeagueHeader,
} from "../../components/tipgroup/TipGroupHeader";
import { TipGroupInvite } from "../../components/tipgroup/TipGroupInvite";
import { TipGroupLeave } from "../../components/tipgroup/TipGroupLeave";
import { OpenTipps } from "../../components/tipgroup/OpenTipps";
import { TipGroupWaiting } from "../../components/tipgroup/TipGroupWaiting";
import { TipGroupHomePageParamKeys } from "../../models/ParamKeys";
import { TipUser } from "../../models/TipUser";
import {
  linkUrlProfile,
  linkUrlTipgroupLeague,
  linkUrlTipGroupMemberList,
} from "../../shared/linkUrlHelper";
import { userImage } from "../../shared/urlHelper";
import { Error404Page } from "../Error404Page";
import { TipGroupDescription } from "../../components/tipgroup/TipGroupDescription";
import { getGroupApplicationByUuid } from "../../shared/aplicationHelper";
import { useGetGroupApplications } from "../../api/useGetGroupApplications";
import { ApplicationCountLabel } from "../../components/applicationCount/ApplicationCountLabel";
import { E2BarSticky } from "../../components/e2/E2Bar";
import { useE2Tipps } from "../../components/e2/E2TippsContext";

interface TipGroupHomeProps {
  groupId: string;
  groupName: string;
}

interface TipGroupHomeDefaultProps {
  groupId: string;
}

interface TipGroupHomeSingleLeagueProps {
  groupId: string;
  leagueId: string;
  roundNumber: number;
}

export function TipGroupHomePage() {
  const params = useParams<TipGroupHomePageParamKeys>();

  if (params.tipGroupId && params.tipGroupName) {
    return (
      <TipGroupHome
        groupId={params.tipGroupId}
        groupName={params.tipGroupName}
      />
    );
  } else {
    return <Error404Page />;
  }
}

function TipGroupHome({ groupId, groupName }: TipGroupHomeProps) {
  const { data: tipgroupData, ...tipgroupQuery } = useGetTipgroup({
    groupId: groupId,
  });
  const { data: groupApplications, ...groupApplicationsQuery } =
    useGetGroupApplications();

  const type =
    tipgroupData?.data.contests?.length === 1 ? "singleLeague" : "default";
  const singleLeagueId =
    type === "singleLeague" && tipgroupData?.data.contests
      ? tipgroupData?.data.contests[0].contest.id
      : undefined;
  const singleLeagueRoundNumber =
    type === "singleLeague" && tipgroupData?.data.contests
      ? tipgroupData?.data.contests[0].contest.currentRound.number
      : undefined;

  const groupApplication = getGroupApplicationByUuid(
    groupId,
    groupApplications?.data
  );
  const groupLabel = groupApplication ? (
    <ApplicationCountLabel
      groupName={groupName}
      groupApplication={groupApplication}
    />
  ) : undefined;

  return (
    <>
      {type === "singleLeague" &&
      tipgroupData?.data.contests &&
      tipgroupData.data.contests[0] ? (
        <TipGroupLeagueHeader
          groupName={groupName}
          leagueName={tipgroupData.data.contests[0].contest.name}
          leagueId={tipgroupData.data.contests[0].contest.id}
          image={tipgroupData.data.image?.thumbnail_file_url}
          fullImage={tipgroupData.data.image?.full_file_url}
        />
      ) : (
        <TipGroupHeader
          name={groupName}
          groupLabel={groupLabel}
          image={tipgroupData?.data.image?.thumbnail_file_url}
          fullImage={tipgroupData?.data.image?.full_file_url}
        />
      )}
      <QueryWrapper useQueryResult={[tipgroupQuery, groupApplicationsQuery]}>
        {type === "singleLeague" &&
        singleLeagueId &&
        singleLeagueRoundNumber ? (
          <TipGroupHomeSingleLeague
            groupId={groupId}
            leagueId={singleLeagueId}
            roundNumber={singleLeagueRoundNumber}
          />
        ) : (
          <TipGroupHomeDefault groupId={groupId} />
        )}
      </QueryWrapper>
    </>
  );
}

function TipGroupHomeDefault({ groupId }: TipGroupHomeDefaultProps) {
  const { data: tipgroupData, ...tipgroupQuery } = useGetTipgroup({
    groupId: groupId,
  });
  const { data: openFixturesData, ...openFixturesQuery } = useGetOpenFixtures({
    props: { group_id: groupId },
  });
  const { data: groupRankingData, ...groupRankingQuery } =
    useGetTipgroupRanking({
      groupId: groupId,
    });
  const { data: todayData, ...todayQuery } = useGetToday({
    props: { group_id: groupId },
  });
  const { mutate: handleApplication } = usePostHandleApplication();
  const { data: applications } = useGetTipgroupApplications({
    props: { groupId: groupId },
    enabled: tipgroupData?.data.user_is_admin === true,
  });
  const { data: membersData, ...membersQuery } = useGetTipgroupMembers({
    groupId: groupId,
  });

  return (
    <>
      <QueryWrapper
        useQueryResult={[
          tipgroupQuery,
          openFixturesQuery,
          groupRankingQuery,
          todayQuery,
          membersQuery,
        ]}
      >
        {tipgroupData &&
        openFixturesData &&
        groupRankingData &&
        todayData &&
        membersData ? (
          <ColumnsWrapper>
            {applications ? (
              <TipGroupWaiting
                applications={applications.data}
                handleApplication={handleApplication}
                tipGroupId={groupId}
              />
            ) : null}
            <OpenTipps
              group={tipgroupData.data}
              openFixtures={openFixturesData.data}
            />
            <LeaguesInTippgroup
              group={tipgroupData.data}
              openFixtures={openFixturesData.data}
              {...{ isRight: true }}
            />
            <CurrentMatchesHome
              todayData={todayData.data}
              groups={[tipgroupData.data]}
            />
            <GroupRanking
              group={tipgroupData.data}
              size={"short"}
              rankingData={groupRankingData}
              {...{
                isRight:
                  groupRankingData.data.length > 0 &&
                  !groupRankingData.data.every(
                    (ranking) => !ranking.score_counts.total
                  ),
              }}
            />
            {groupRankingData.data.length === 0 ||
            groupRankingData.data.every(
              (ranking) => !ranking.score_counts.total
            ) ? (
              <MemberList
                members={membersData.data}
                groupId={groupId}
                groupName={tipgroupData.data.name}
                {...{ isRight: true }}
              />
            ) : null}
            {tipgroupData.data.description ? (
              <TipGroupDescription
                description={tipgroupData.data.description}
                {...{ isRight: true }}
              />
            ) : null}

            {tipgroupData.data.invite_code ? (
              <TipGroupInvite inviteCode={tipgroupData.data.invite_code} />
            ) : null}
            {tipgroupData.data.user_is_admin ? (
              <TipGroupEdit
                groupId={tipgroupData.data.uuid}
                groupName={tipgroupData.data.name}
              />
            ) : null}
            <TipGroupLeave
              groupName={tipgroupData.data.name}
              groupId={tipgroupData.data.uuid}
            />
          </ColumnsWrapper>
        ) : null}
      </QueryWrapper>
      <E2BarSticky reset roundNumber={0} />
    </>
  );
}

function TipGroupHomeSingleLeague({
  groupId,
  leagueId,
  roundNumber,
}: TipGroupHomeSingleLeagueProps) {
  const { setE2Data } = useE2Tipps();
  const { data: tipgroupData, ...tipgroupQuery } = useGetTipgroup({
    groupId: groupId,
  });
  const { data: leagueData, ...leagueQuery } = useGetLeague({
    leagueId: leagueId,
  });
  const navigate = useNavigate();
  const league = leagueData?.data;
  const { data: seasonRankingData, ...seasonRankingQuery } =
    useGetTipgroupRankingSeason({
      groupId,
      leagueId,
      upto_round_number: roundNumber,
    });

  const { data: roundsData, ...roundsQuery } = useGetGamedays({
    leagueId,
  });
  const gameday = roundsData?.data.find(
    (gameday) => gameday.number === roundNumber
  );
  const { data: roundRankingData, ...roundRankingQuery } =
    useGetTipgroupRankingRound({
      props: {
        groupId: groupId,
        leagueId: leagueId,
        round_number: roundNumber,
      },
    });
  const { data: gamedayData, ...gamedayQuery } = useGetGameday({
    leagueId: leagueId,
    round_number: roundNumber,
  });
  const { data: tippData, ...tippQuery } = useGetTipps({
    leagueId: leagueId,
    round_number: roundNumber,
  });
  const { mutate: handleApplication } = usePostHandleApplication();
  const { data: applications } = useGetTipgroupApplications({
    props: { groupId: groupId },
    enabled: tipgroupData?.data.user_is_admin === true,
  });
  const { data: membersData, ...membersQuery } = useGetTipgroupMembers({
    groupId: groupId,
  });

  useEffect(() => {
    if (gamedayData && tippData) {
      setE2Data({
        gamedays: gamedayData.data,
        tipps: tippData.data,
        tippMode: tippData?.meta?.prediction_mode ?? "full",
      });
    }
  }, [gamedayData, tippData, setE2Data]);

  return (
    <>
      {roundsData && league && gameday && tipgroupData && (
        <GamedayChooserHeader
          gameday={gameday}
          rounds={roundsData.data}
          navigate={(number) =>
            navigate(
              linkUrlTipgroupLeague(
                tipgroupData.data.uuid,
                tipgroupData.data.name,
                league.id,
                league.name,
                number
              ),
              { replace: true }
            )
          }
        />
      )}
      <QueryWrapper
        useQueryResult={[
          tipgroupQuery,
          leagueQuery,
          seasonRankingQuery,
          roundsQuery,
          roundRankingQuery,
          gamedayQuery,
          tippQuery,
          membersQuery,
        ]}
      >
        {league &&
        roundsData &&
        tipgroupData &&
        roundRankingData &&
        seasonRankingData &&
        gamedayData &&
        tippData &&
        membersData ? (
          <ColumnsWrapper>
            {applications ? (
              <TipGroupWaiting
                applications={applications.data}
                handleApplication={handleApplication}
                tipGroupId={groupId}
              />
            ) : null}
            <RoundRanking
              roundNumber={roundNumber}
              group={tipgroupData.data}
              league={league}
              size="short"
              rankingData={roundRankingData}
              {...{ isRight: true }}
            />
            <Gameday
              leagueId={league?.id}
              leagueName={league.name}
              roundNumber={roundNumber}
              group={tipgroupData.data}
              gamedays={gamedayData.data}
              tipps={tippData.data}
              isAmateurLeague={league.state === "GermanAmateurContest"}
            />
            <SeasonRanking
              league={league}
              group={tipgroupData.data}
              size="short"
              rankingData={seasonRankingData}
              roundNumber={roundNumber}
              {...{ isRight: true }}
            />
            {seasonRankingData.data.length === 0 ||
            seasonRankingData.data.every(
              (ranking) => !ranking.score_counts.total
            ) ? (
              <MemberList
                members={membersData.data}
                groupId={groupId}
                groupName={tipgroupData.data.name}
                {...{ isRight: true }}
              />
            ) : null}
            {tipgroupData.data.description ? (
              <TipGroupDescription
                description={tipgroupData.data.description}
                {...{ isRight: true }}
              />
            ) : null}
            {tipgroupData.data.invite_code ? (
              <TipGroupInvite inviteCode={tipgroupData.data.invite_code} />
            ) : null}
            {tipgroupData.data.user_is_admin ? (
              <TipGroupEdit
                groupId={tipgroupData.data.uuid}
                groupName={tipgroupData.data.name}
              />
            ) : null}
            <TipGroupLeave
              groupName={tipgroupData.data.name}
              groupId={tipgroupData.data.uuid}
            />
          </ColumnsWrapper>
        ) : (
          <ErrorCard type="empty" />
        )}
      </QueryWrapper>
      <E2BarSticky reset roundNumber={roundNumber} />
    </>
  );
}

interface MemberListProps {
  members: TipUser[];
  isPage?: boolean;
  groupId: string;
  groupName: string;
}
export function MemberList({
  members,
  groupId,
  groupName,
  isPage = false,
}: MemberListProps) {
  return members.length > 0 ? (
    <Card
      labelText="Mitglieder"
      labelLinkTo={
        !isPage && members.length > 5
          ? linkUrlTipGroupMemberList(groupName, groupId)
          : undefined
      }
    >
      <List>
        {members.slice(0, isPage ? members.length : 5).map((member) => (
          <Link key={member.uuid} to={linkUrlProfile(member.uuid)}>
            <ListRow>
              <ListItem>
                <UserImage
                  image={userImage(member)}
                  name={member.username}
                  size="md"
                />
              </ListItem>
              <ListItem className="truncate">{member.username}</ListItem>
            </ListRow>
          </Link>
        ))}
      </List>
      {!isPage && members.length > 5 ? (
        <ButtonWrapper>
          <LinkButton to={linkUrlTipGroupMemberList(groupName, groupId)}>
            Alle Mitglieder
          </LinkButton>
        </ButtonWrapper>
      ) : null}
    </Card>
  ) : null;
}

export function GroupApplications() {
  return <Card labelText="Mitglieder"></Card>;
}
