import Card from "../common/Card";

export function LeagueMuted() {
  return (
    <Card labelType="card-attention" labelText="Wettbewerb tippen">
      <p className="pb-4 text-center font-normal">
        Du hast diesen Wettbewerb nicht getippt oder ihn aus der Liste deiner
        Wettbewerbe entfernt. Sobald du ein weiteres Spiel tippst, erscheint der
        Wettbewerb wieder in deiner Liste.
      </p>
    </Card>
  );
}
