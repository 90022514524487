import { Fragment, ReactNode, useCallback, useMemo } from "react";
import { UseQueryResult } from "react-query";
import { ErrorCard, ErrorType } from "./ErrorCard";
import { LoadingIndicator } from "./LoadingIndicator";

type PartialUseQueryResult = Pick<
  UseQueryResult,
  "status" | "refetch" | "error"
>;

interface QueryWrapperProps {
  children: ReactNode;
  useQueryResult: PartialUseQueryResult | PartialUseQueryResult[];
  errorPlaceholder?: ReactNode;
}

type QueryWrapperStatus = "idle" | "loading" | "error" | "success";

const queryWrapperStatusPriorityMap: Record<QueryWrapperStatus, number> = {
  error: 1,
  loading: 2,
  success: 3,

  idle: 100,
};

export function QueryWrapper({
  children,
  useQueryResult,
  errorPlaceholder,
}: QueryWrapperProps) {
  const useQueryResultArray = useMemo(() => {
    return Array.isArray(useQueryResult) ? useQueryResult : [useQueryResult];
  }, [useQueryResult]);

  const [status, error]: [QueryWrapperStatus, Error?] = useMemo(() => {
    let resultStatus: QueryWrapperStatus = "idle";
    let error: Error | undefined = undefined;
    for (let i = 0; i < useQueryResultArray.length; i++) {
      const value = useQueryResultArray[i];
      if (
        !resultStatus ||
        queryWrapperStatusPriorityMap[value.status] <
          queryWrapperStatusPriorityMap[resultStatus]
      ) {
        resultStatus = value.status;
      }
      if (!error && resultStatus === "error" && value.error instanceof Error) {
        // only set the first error of the array
        error = value.error;
      }
    }

    return [resultStatus, error];
  }, [useQueryResultArray]);

  const tryAgainClick = useCallback(() => {
    useQueryResultArray
      .filter((r) => r.status === "error")
      .forEach((r) => void r.refetch());
  }, [useQueryResultArray]);

  if (status === "loading" || status === "idle") {
    return <LoadingIndicator />;
  } else if (status === "error") {
    if (errorPlaceholder) {
      return <Fragment>{errorPlaceholder}</Fragment>;
    } else {
      let errorType: ErrorType = "data";
      if (error?.name.includes("contest_not_found")) {
        errorType = "contest_not_found";
      }
      return (
        <ErrorCard
          type={errorType}
          error={error}
          callback={errorType === "data" ? tryAgainClick : undefined}
        />
      );
    }
  } else {
    return <Fragment>{children}</Fragment>;
  }
}
