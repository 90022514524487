import { useParams } from "react-router";
import { EditTipGroup } from "../../components/tipgroup/EditTipGroup";
import { TipGroupIdParamKeys } from "../../models/ParamKeys";
import { Error404Page } from "../Error404Page";

export function EditTipGroupPage() {
  const params = useParams<TipGroupIdParamKeys>();
  return params.groupId ? (
    <EditTipGroup groupId={params.groupId} />
  ) : (
    <Error404Page />
  );
}
