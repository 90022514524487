import { useGetLeague } from "../../api/useGetLeague";
import { Tipgroup } from "../../models/Tipgroup";
import { QueryWrapper } from "../common/QueryWrapper";
import { E2BarSticky } from "../e2/E2Bar";
import {
  TipGroupHeader,
  TipGroupLeagueHeader,
} from "../tipgroup/TipGroupHeader";
import { TipGroupLeague } from "../tipgroup/TipGroupLeague";

interface VereinsheimLeagueHomeProps {
  group: Tipgroup;
  leagueId: string;
}

export function VereinsheimLeagueHome({
  group,
  leagueId,
}: VereinsheimLeagueHomeProps) {
  const { data: leagueData, ...leagueQuery } = useGetLeague({
    leagueId: leagueId,
  });
  return (
    <>
      {leagueData ? (
        <TipGroupLeagueHeader
          groupName={group.name}
          leagueId={leagueData.data.id}
          leagueName={leagueData.data.name}
          image={group.image?.thumbnail_file_url}
          fullImage={group.image?.full_file_url}
        />
      ) : (
        <TipGroupHeader
          name={group.name}
          image={group.image?.thumbnail_file_url}
          fullImage={group.image?.full_file_url}
        />
      )}
      <QueryWrapper useQueryResult={[leagueQuery]}>
        {leagueData ? (
          <TipGroupLeague
            groupId={group.uuid}
            leagueId={leagueId}
            roundNumber={leagueData?.data.currentRound.number}
          />
        ) : null}
        <E2BarSticky roundNumber={leagueData?.data.currentRound.number} />
      </QueryWrapper>
    </>
  );
}
