import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useGetGameday } from "../../api/useGetGameday";
import { useGetGamedays } from "../../api/useGetGamedays";
import { useGetGlobalRankingRound } from "../../api/useGetGlobalRankingRound";
import { useGetGlobalRankingSeason } from "../../api/useGetGlobalRankingSeason";
import { useGetLeague } from "../../api/useGetLeague";
import { useGetOpenFixtures } from "../../api/useGetOpenFixtures";
import { useGetTipgroups } from "../../api/useGetTipgroups";
import { useGetTipps } from "../../api/useGetTipps";
import { linkUrlLeague } from "../../shared/linkUrlHelper";
import { ColumnsWrapper } from "../common/Columns";
import { ErrorCard } from "../common/ErrorCard";
import { QueryWrapper } from "../common/QueryWrapper";
import { useE2Tipps } from "../e2/E2TippsContext";
import { Gameday } from "../gameday/Gameday";
import { GamedayChooserHeader } from "../gameday/GamedayChooserHeader";
import { GlobalRoundRanking, GlobalSeasonRanking } from "../ranking/Ranking";
import { TipGroupRecommendation } from "../tipgroup/TipGroupRecommendation";
import { LeagueLeave } from "./LeagueLeave";
import { LeagueMuted } from "./LeagueMuted";

interface LeagueProps {
  leagueId: string;
  roundNumber: number;
}

export function League({ leagueId, roundNumber }: LeagueProps) {
  const navigate = useNavigate();
  const { setE2Data } = useE2Tipps();
  const { data: leagueData, ...leagueQuery } = useGetLeague({
    leagueId: leagueId,
  });
  const league = leagueData?.data;

  const { data: roundsData, ...roundsQuery } = useGetGamedays({
    leagueId,
  });
  const gameday = roundsData?.data.find(
    (gameday) => gameday.number === roundNumber
  );
  const { data: openFixturesData, ...openFixturesQuery } = useGetOpenFixtures(
    {}
  );
  const { data: seasonRankingData, ...seasonRankingQuery } =
    useGetGlobalRankingSeason({
      leagueId: leagueId,
      upto_round_number: roundNumber,
    });
  const { data: gamedayData, ...gamedayQuery } = useGetGameday({
    leagueId: leagueId,
    round_number: roundNumber,
  });
  const showRanking = gamedayData?.data.some(
    (match) => match.started || match.finished
  );
  const { data: globalRankingData, ...globalRankingQuery } =
    useGetGlobalRankingRound({
      props: { leagueId: leagueId, round_number: roundNumber },
      enabled: showRanking,
    });

  const { data: tipgroupsData, ...tipgroupsQuery } = useGetTipgroups();
  const { data: tippData, ...tippQuery } = useGetTipps({
    leagueId: leagueId,
    round_number: roundNumber,
  });
  const leagueIsActive = openFixturesData?.data.some(
    (fixture) => fixture.contest.id === leagueId
  );

  useEffect(() => {
    if (gamedayData && tippData) {
      setE2Data({
        gamedays: gamedayData.data,
        tipps: tippData.data,
        tippMode: tippData?.meta?.prediction_mode ?? "full",
      });
    }
  }, [gamedayData, tippData, setE2Data, roundNumber]);

  // const atLeastOneMatchInFuture =
  //   gamedayData?.data.some((m) => {
  //     return !m.started && !m.finished;
  //   }) === true;
  return (
    <>
      {roundsData && league && gameday && (
        <GamedayChooserHeader
          gameday={gameday}
          rounds={roundsData.data}
          navigate={(round: number) =>
            navigate(linkUrlLeague(league.id, league.name, round), {
              replace: true,
            })
          }
        />
      )}
      <QueryWrapper
        useQueryResult={[
          leagueQuery,
          roundsQuery,
          seasonRankingQuery,
          globalRankingQuery,
          tipgroupsQuery,
          gamedayQuery,
          tippQuery,
          openFixturesQuery,
        ]}
      >
        {league &&
        gameday &&
        roundsData &&
        seasonRankingData &&
        globalRankingData &&
        tipgroupsData &&
        gamedayData &&
        tippData &&
        openFixturesData ? (
          <ColumnsWrapper>
            {!leagueIsActive ? <LeagueMuted /> : null}
            {showRanking ? (
              <GlobalRoundRanking
                rankingData={globalRankingData}
                roundNumber={roundNumber}
                league={leagueData.data}
                size={"short"}
                {...{ isRight: true }}
              />
            ) : null}
            <Gameday
              leagueId={leagueId}
              leagueName={league.name}
              roundNumber={roundNumber}
              gamedays={gamedayData.data}
              tipps={tippData.data}
              isAmateurLeague={league.state === "GermanAmateurContest"}
            />
            <GlobalSeasonRanking
              league={leagueData.data}
              size="short"
              rankingData={seasonRankingData}
              roundNumber={roundNumber}
              {...{ isRight: true }}
            />
            <TipGroupRecommendation
              league={league}
              size="short"
              tipGroups={tipgroupsData.data}
              roundnumber={roundNumber}
              {...{ isRight: true }}
            />
            {leagueIsActive ? (
              <LeagueLeave leagueName={league.name} leagueId={league.id} />
            ) : null}
          </ColumnsWrapper>
        ) : (
          <ErrorCard type="empty" />
        )}
      </QueryWrapper>
    </>
  );
}
