import { QueryClient, QueryClientProvider } from "react-query";
import "./styles/main.css";
import { Route, Routes, useLocation } from "react-router";
import { HomePage } from "./pages/HomePage";
import { Environment } from "./Environment";
import { Error404Page } from "./pages/Error404Page";
import { GlobalErrorBoundary } from "./GlobalErrorBoundary";
import { TipGroupLeaguePage } from "./pages/tipgroup/TipGroupLeaguePage";
import { RankingDetailPage } from "./pages/RankingDetailPage";
import { TipGroupHomePage } from "./pages/tipgroup/TipGroupHomePage";
import { LeaguePage } from "./pages/LeaguePage";
import { BreakpointProvider } from "./shared/useBreakpoints";
import { MatchPage } from "./pages/MatchPage";
import { CreateTipGroupPage } from "./pages/tipgroup/CreateTipGroupPage";
import { AppConfig } from "./AppConfig";
import { TrackedPage } from "./pages/TrackedPage";
import { NewsPage } from "./pages/NewsPage";
import { TrackingPage } from "./models/TrackedPage";
import { EditTipGroupPage } from "./pages/tipgroup/EditTipGroupPage";
import { TipGroupMemberListPage } from "./pages/tipgroup/TipGroupMemberListPage";
import { LeagueListPage } from "./pages/LeagueListPage";
import { TipGroupApplyPage } from "./pages/tipgroup/TipGroupApplyPage";
import { useEffect, useLayoutEffect, useMemo } from "react";
import { VereinsheimPage } from "./pages/VereinsheimPage";
import { TipGroupListPage } from "./pages/TipGroupListPage";
import { loadOddsServeScript } from "./oddsServe";
import { VereinsheimGroupListPage } from "./pages/VereinsheimGroupListPage";
import { TipGroupMemberPage } from "./pages/tipgroup/TipGroupMemberPage";
import { ProfilePage } from "./pages/ProfilePage";
import { ProfileLeaguePage } from "./pages/ProfileLeaguePage";
import { E2Provider } from "./components/e2/E2TippsContext";

const queryClient = new QueryClient();

interface AppProps {
  ssoId?: string;
  config?: AppConfig;
  trackingFunc?: (action: string, leagueId?: string) => void;
  replacerFunc?: (replacer: string) => void;
  reloadFunc?: () => void;
  closeFunc?: () => void;
  pushesFunc?: (uuid: string | null) => void;
  externalJwtToken?: string;
  isWebApp?: boolean;
  isVereinsheim?: boolean;
}

const fallbackConsent = // only for testing, remove later!
  "CPblccAPbovYAAFADBDECWCsAP_AAAAAAAYgIpBV5D5NDWFBcXx_QNtkWYQX1sAVImQCCgCAA6AFADGA8KQA10ASMAyABCACAQgAoQIBAAAADAFEAECQQIAEARHkAgQEgAAIICAEABEQQkJAAAoKAAAAAAAIAAARKwSAkACQA8bmRFAA1IAQSAIAgAABAIADAhMACEAYAAACAAIAAABAAgIAEAAAAAEDtoA4AOADAAHkAYIA00BuYDtgCQkAGAAgIpBoAMABARSEQAYACAikKgAwAEBFIZABgAICKQ6ADAAQEUiEAGAAgIpEoAMABARSKQAYACAikAAA.YAAAAAAAAAAA";

export function App({
  ssoId,
  config,
  trackingFunc,
  replacerFunc,
  reloadFunc,
  closeFunc,
  pushesFunc,
  externalJwtToken,
  isWebApp,
  isVereinsheim,
}: AppProps) {
  const darkModeMemoized = useMemo(() => {
    if (config?.colorScheme) {
      if (config?.colorScheme === "dark") {
        return true;
      }
    } else if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      return true;
    }
    return false;
  }, [config]);
  const disableAdsMemoized = useMemo(
    () => config?.disableAds === "true",
    [config]
  );
  useEffect(() => {
    if (!isVereinsheim && !disableAdsMemoized) {
      loadOddsServeScript(
        config?.consensTracking ?? fallbackConsent,
        darkModeMemoized
      );
    }
  }, [
    config?.consensTracking,
    darkModeMemoized,
    disableAdsMemoized,
    isVereinsheim,
  ]);
  useLayoutEffect(() => {
    const addDarkmode = () =>
      document.getElementById("root")?.classList.add("dark");

    if (darkModeMemoized) {
      addDarkmode();
    }
  }, [darkModeMemoized]);
  const deepLinkUrl = useLocation().pathname;
  const deepLinkUrlMemoized: string = useMemo(
    () => deepLinkUrl,
    // this is on purpose, I only want to do this once on appstart otherwise it would be changed on every render
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div
      className={`kicker-tippspiel-container${isWebApp === true ? "-web" : ""}`}
    >
      <QueryClientProvider client={queryClient}>
        <Environment
          ssoId={ssoId}
          deeplinkUrl={deepLinkUrlMemoized}
          useDarkMode={darkModeMemoized}
          disableAds={disableAdsMemoized}
          trackingFunc={trackingFunc}
          replacerFunc={replacerFunc}
          reloadFunc={reloadFunc}
          closeFunc={closeFunc}
          pushesFunc={pushesFunc}
          externalJwtToken={externalJwtToken}
          isVereinsheim={isVereinsheim === true}
          isWebApp={isWebApp === true}
          consent={config?.consensTracking ?? fallbackConsent}
          pushes={config?.pushes}
          registerPushes={config?.registerPushes}
        >
          <BreakpointProvider>
            <E2Provider>
              <GlobalErrorBoundary>
                <Routes>
                  <Route
                    index
                    element={
                      <TrackedPage page={TrackingPage.Home}>
                        <HomePage />
                      </TrackedPage>
                    }
                  />
                  <Route
                    path="/tipgroup/:tipGroupName/:tipGroupId"
                    element={
                      <TrackedPage
                        page={TrackingPage.TipGroup}
                        trackTipGroupId={true}
                      >
                        <TipGroupHomePage />
                      </TrackedPage>
                    }
                  />
                  <Route
                    path="/tipgroup/new"
                    element={<CreateTipGroupPage />}
                  />
                  <Route
                    path="/tipgroup/:tipGroupName/:tipGroupId/ranking"
                    element={
                      <TrackedPage
                        page={TrackingPage.RankingDetail}
                        trackTipGroupId={true}
                      >
                        <RankingDetailPage />
                      </TrackedPage>
                    }
                  />
                  <Route
                    path="/league/:leagueId/gameday/:roundNumber"
                    element={
                      <TrackedPage
                        page={TrackingPage.League}
                        trackLeagueId={true}
                        trackRoundNumber={true}
                      >
                        <LeaguePage />
                      </TrackedPage>
                    }
                  />
                  <Route
                    path="/league/:leagueName/:leagueId/gameday/:roundNumber"
                    element={
                      <TrackedPage
                        page={TrackingPage.League}
                        trackLeagueId={true}
                        trackRoundNumber={true}
                      >
                        <LeaguePage />
                      </TrackedPage>
                    }
                  />
                  <Route
                    path="/league/:leagueName/:leagueId/ranking/:type/:roundNumber/"
                    element={
                      <TrackedPage
                        page={TrackingPage.RankingDetail}
                        trackLeagueId={true}
                        trackRoundNumber={true}
                      >
                        <RankingDetailPage />
                      </TrackedPage>
                    }
                  />
                  <Route
                    path="/league/:leagueName/:leagueId/ranking/:type/:roundNumber/"
                    element={
                      <TrackedPage
                        page={TrackingPage.RankingDetail}
                        trackLeagueId={true}
                        trackRoundNumber={true}
                      >
                        <RankingDetailPage />
                      </TrackedPage>
                    }
                  />
                  <Route
                    path="/tipgroup/:tipGroupName/:tipGroupId/league/:leagueName/:leagueId/gameday/:roundNumber"
                    element={
                      <TrackedPage
                        page={TrackingPage.TipGroupLeague}
                        trackLeagueId={true}
                        trackTipGroupId={true}
                        trackRoundNumber={true}
                      >
                        <TipGroupLeaguePage />
                      </TrackedPage>
                    }
                  />
                  <Route
                    path="/tipgroup/:tipGroupName/:tipGroupId/ranking/league/:leagueName/:leagueId/:type/:roundNumber/"
                    element={
                      <TrackedPage
                        page={TrackingPage.RankingDetail}
                        trackLeagueId={true}
                        trackTipGroupId={true}
                        trackRoundNumber={true}
                      >
                        <RankingDetailPage />
                      </TrackedPage>
                    }
                  />
                  <Route
                    path="/tipgroup/:tipGroupName/:tipGroupId/ranking/league/:leagueName/:leagueId/:type/:roundNumber/"
                    element={
                      <TrackedPage
                        page={TrackingPage.RankingDetail}
                        trackLeagueId={true}
                        trackTipGroupId={true}
                        trackRoundNumber={true}
                      >
                        <RankingDetailPage />
                      </TrackedPage>
                    }
                  />
                  <Route
                    path="/tipgroup/:tipGroupName/:tipGroupId/match/:matchId/league/:leagueName/:leagueId/round/:roundNumber/type/:type"
                    element={
                      <TrackedPage
                        page={TrackingPage.Match}
                        trackLeagueId={true}
                        trackTipGroupId={true}
                        trackRoundNumber={true}
                        trackMatchId={true}
                      >
                        <MatchPage />
                      </TrackedPage>
                    }
                  />
                  <Route
                    path="/match/:matchId/league/:leagueId/round/:roundNumber"
                    element={
                      <TrackedPage
                        page={TrackingPage.Match}
                        trackLeagueId={true}
                        trackRoundNumber={true}
                        trackMatchId={true}
                      >
                        <MatchPage />
                      </TrackedPage>
                    }
                  />
                  <Route
                    path="/match/:matchId/league/:leagueId/round/:roundNumber/type/:type"
                    element={
                      <TrackedPage
                        page={TrackingPage.Match}
                        trackLeagueId={true}
                        trackRoundNumber={true}
                        trackMatchId={true}
                      >
                        <MatchPage />
                      </TrackedPage>
                    }
                  />
                  <Route
                    path="/match/:matchId/league/:leagueName/:leagueId/round/:roundNumber"
                    element={
                      <TrackedPage
                        page={TrackingPage.Match}
                        trackLeagueId={true}
                        trackRoundNumber={true}
                        trackMatchId={true}
                      >
                        <MatchPage />
                      </TrackedPage>
                    }
                  />
                  <Route
                    path="/match/:matchId/league/:leagueName/:leagueId/round/:roundNumber/type/:type"
                    element={
                      <TrackedPage
                        page={TrackingPage.Match}
                        trackLeagueId={true}
                        trackRoundNumber={true}
                        trackMatchId={true}
                      >
                        <MatchPage />
                      </TrackedPage>
                    }
                  />
                  <Route
                    path="/tipgroup/:groupId/edit"
                    element={
                      <TrackedPage
                        page={TrackingPage.EditTipGroup}
                        trackTipGroupId={true}
                      >
                        <EditTipGroupPage />
                      </TrackedPage>
                    }
                  />
                  <Route
                    path="/tipgroup/:groupId/members"
                    element={
                      <TrackedPage
                        page={TrackingPage.TipGroupMembers}
                        trackTipGroupId={true}
                      >
                        <TipGroupMemberListPage />
                      </TrackedPage>
                    }
                  />
                  <Route
                    path="/tipgroup/:tipGroupName/:tipGroupId/memberlist"
                    element={
                      <TrackedPage
                        page={TrackingPage.TipGroupMemberList}
                        trackTipGroupId={true}
                      >
                        <TipGroupMemberPage />
                      </TrackedPage>
                    }
                  />
                  <Route
                    path="/tipgroup/apply/:code"
                    element={
                      <TrackedPage page={TrackingPage.TipGroupMembers}>
                        <TipGroupApplyPage />
                      </TrackedPage>
                    }
                  />
                  <Route
                    path="/tipgroup/:groupId/invite/:code/apply"
                    element={
                      <TrackedPage
                        page={TrackingPage.TipGroupMembers}
                        trackTipGroupId={true}
                      >
                        <TipGroupApplyPage />
                      </TrackedPage>
                    }
                  />
                  <Route
                    path="/news/:newsUrl"
                    element={
                      <TrackedPage page={TrackingPage.News}>
                        <NewsPage />
                      </TrackedPage>
                    }
                  />
                  <Route
                    path="/leaguelist"
                    element={
                      <TrackedPage page={TrackingPage.LeagueList}>
                        <LeagueListPage />
                      </TrackedPage>
                    }
                  />
                  <Route
                    path="/vereinsheim/:vereinsheimId"
                    element={
                      <TrackedPage page={TrackingPage.Vereinsheim}>
                        <VereinsheimPage />
                      </TrackedPage>
                    }
                  />
                  <Route
                    path="/vereinsheim/:vereinsheimId/:leagueId"
                    element={
                      <TrackedPage page={TrackingPage.Vereinsheim}>
                        <VereinsheimPage />
                      </TrackedPage>
                    }
                  />
                  <Route
                    path="/groups"
                    element={
                      <TrackedPage page={TrackingPage.TipGroupList}>
                        <TipGroupListPage />
                      </TrackedPage>
                    }
                  />
                  <Route
                    path="/groups/league/:leagueId/:leagueName"
                    element={
                      <TrackedPage
                        page={TrackingPage.TipGroupList}
                        trackLeagueId={true}
                      >
                        <TipGroupListPage />
                      </TrackedPage>
                    }
                  />
                  <Route
                    path="/groups/league/:leagueId/:leagueName/round/:roundNumber/match/:matchId"
                    element={
                      <TrackedPage
                        page={TrackingPage.TipGroupList}
                        trackLeagueId={true}
                        trackRoundNumber={true}
                        trackMatchId={true}
                      >
                        <TipGroupListPage />
                      </TrackedPage>
                    }
                  />
                  <Route
                    path="/vereinsheimgroups"
                    element={
                      <TrackedPage page={TrackingPage.VereinsheimGroupList}>
                        <VereinsheimGroupListPage />
                      </TrackedPage>
                    }
                  />
                  <Route
                    path="/vereinsheimgroups/league/:leagueId/:leagueName"
                    element={
                      <TrackedPage
                        page={TrackingPage.VereinsheimGroupList}
                        trackLeagueId={true}
                      >
                        <VereinsheimGroupListPage />
                      </TrackedPage>
                    }
                  />
                  <Route
                    path="/vereinsheimgroups/league/:leagueId/:leagueName/round/:roundNumber/match/:matchId"
                    element={
                      <TrackedPage
                        page={TrackingPage.VereinsheimGroupList}
                        trackLeagueId={true}
                        trackRoundNumber={true}
                        trackMatchId={true}
                      >
                        <VereinsheimGroupListPage />
                      </TrackedPage>
                    }
                  />
                  <Route
                    path="/profile/:uuid"
                    element={
                      <TrackedPage page={TrackingPage.Profile}>
                        <ProfilePage />
                      </TrackedPage>
                    }
                  />
                  <Route
                    path="/profile/:uuid/league/:leagueName/:leagueId/gameday/:roundNumber"
                    element={
                      <TrackedPage page={TrackingPage.ProfileLeague}>
                        <ProfileLeaguePage />
                      </TrackedPage>
                    }
                  />
                  <Route
                    path="/profile/:uuid/league/:leagueName/:leagueId/gameday/:roundNumber/group/:tipGroupId"
                    element={
                      <TrackedPage page={TrackingPage.ProfileLeague}>
                        <ProfileLeaguePage />
                      </TrackedPage>
                    }
                  />
                  <Route path="*" element={<Error404Page />} />
                </Routes>
              </GlobalErrorBoundary>
            </E2Provider>
          </BreakpointProvider>
        </Environment>
      </QueryClientProvider>
    </div>
  );
}
