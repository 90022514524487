import React from "react";

interface UploadImageProps {
  handleNewImage: (image: string) => void;
}

export function UploadImage({ handleNewImage }: UploadImageProps) {
  function handleFileToCrop(files: FileList | null) {
    if (files) {
      const fileRef = files[0] || "";
      if (fileRef) {
        const reader = new FileReader();
        reader.readAsDataURL(fileRef);

        reader.onload = (ev) => {
          if (ev?.target?.result) {
            handleNewImage(ev.target.result as string);
          }
        };
      }
    }
  }

  return (
    <div>
      <input
        type="file"
        onChange={(e) => handleFileToCrop(e.target.files)}
        accept="image/png, image/gif, image/jpeg, image/jpg"
      />
    </div>
  );
}
