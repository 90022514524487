import { useQuery } from "react-query";
import { LeagueWithMatchlist } from "../models/LeagueWithMatchList";
import { MetaData } from "../models/MetaData";
import { useBackendQueryFunction } from "./common/useBackendQueryFunction";
import { UrlQuery } from "./common/useBackendUrl";

export const GetTodayKey = "GetToday";
interface TodayData {
  data: LeagueWithMatchlist[];
  meta?: MetaData;
}

interface UseGetTodayQueryProps extends UrlQuery {
  group_id?: string;
}

interface UseGetTodayProps {
  props?: UseGetTodayQueryProps;
  enabled?: boolean;
}

export function useGetToday({ props, enabled = true }: UseGetTodayProps) {
  const queryFn = useBackendQueryFunction<TodayData>("/me/today", props, true);
  return useQuery<TodayData>([GetTodayKey, props], queryFn, {
    enabled: enabled,
  });
}
