import React, { ReactNode, useEffect } from "react";
import IconClose from "../../assets/icons/close.svg";

export interface ModalProps {
  children?: ReactNode;
  handleCloseModal?: () => void;
  isOpen: boolean;
}

// type OnRequestCloseFunction = (
//   event: React.MouseEvent | React.KeyboardEvent
// ) => void;

export default function Modal({
  isOpen,
  handleCloseModal,
  children,
}: ModalProps) {
  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "";
  }, [isOpen]);

  return isOpen ? (
    <div className="z-[200] fixed top-0 left-0 w-full h-full flex items-center justify-center">
      <div className="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-90" />
      <div className="absolute w-full max-w-[500px] p-4 flex justify-center">
        <div className="rounded-md overflow-hidden">{children}</div>
      </div>
      {handleCloseModal && (
        <button
          onClick={handleCloseModal}
          className={"absolute top-0 right-0 p-4"}
        >
          <IconClose />
        </button>
      )}
    </div>
  ) : (
    <></>
  );
}
