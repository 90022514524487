import { _getTrendElement, RankingTrend, RankingType } from "./Ranking";
import { Label, LabelText } from "../common/Label";
import UserImage from "../common/UserImage";
import { Link } from 'react-router-dom'
import { linkUrlProfile } from "../../shared/linkUrlHelper";

export interface RankingTop3Props {
  elements: RankingTop3ItemProps[];
}

export interface RankingTop3ItemProps {
  userId: string;
  username: string;
  iconUrl: string;
  number: number;
  points: number;
  trend: RankingTrend;
  type: RankingType;
}

export function RankingTop3({ elements }: RankingTop3Props) {
  return (
    <div className={`top-three`}>
      {elements.map((element, index) => {
        return <RankingTop3Item key={index} {...element} />;
      })}
    </div>
  );
}

type UserColor =
  | "default"
  | "gold"
  | "silver"
  | "bronze"
  | "head-to-head"
  | "self";

export function RankingTop3Item({
  number,
  userId,
  username,
  iconUrl,
  points,
  trend,
  type,
}: RankingTop3ItemProps) {
  const getColor = (n: number): UserColor => {
    switch (n) {
      case 1:
        return "gold";
      case 2:
        return "silver";
      case 3:
        return "bronze";
      default:
        return "default";
    }
  };
  return (
    <div className={`t3-item t3-item-${getColor(number)}`}>
        <div className="t3-item-trend">
          {type !== "group" ? _getTrendElement(trend) : undefined}
          <span className="t3-item-trend-number">{number}.</span>
        </div>
        <div className="t3-item-user">
            <div className="t3-item-user-image">
              <Link to={linkUrlProfile(userId)}>
                <UserImage
                  image={iconUrl}
                  name={username}
                  size={number === 1 ? "lg" : "sm"}
                />
              </Link>
            </div>
            <div className="t3-item-user-points">
              <Label
                type={
                  type === "season" || type === "group"
                    ? "no-tip"
                    : "correct-tendency"
                }
                className="t3-item-user-points-label"
                size={number === 1 ? "normal" : "medium"}
              >
                <LabelText>
                  {type === "season" || type === "group"
                    ? `${points} Punkt${points === 1 ? "" : "e"}`
                    : `+ ${points} Pkt`}
                </LabelText>
              </Label>
            </div>
        </div>
        <div className="t3-item-user-name">
          <Label type="name" size="medium" className="t3-item-user-name-label">
            <Link className="truncate" to={linkUrlProfile(userId)}>
              <LabelText>{username}</LabelText>
            </Link>
          </Label>
        </div>
    </div>
  );
}
