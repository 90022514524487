import { GroupApplication } from "../models/GroupApplication";

export function getGroupApplicationByUuid(groupUuid: string, groupApplications?: GroupApplication[]): GroupApplication | undefined{
    if(!groupApplications){
        return undefined
    }

    return groupApplications.find(group => group.group.uuid === groupUuid)
}

export function getApplicationsByUuid(groupApplications?: GroupApplication[]) {
    if(!groupApplications){
        return {}
    }

    const applicationsByUuid: Record<string, GroupApplication> = {}

    groupApplications.map(group => {
        applicationsByUuid[group.group.uuid] = group
    })

    return applicationsByUuid
}

export function getApplicationCountIfUserIsAdmin(group?: GroupApplication){
    if(!group){
        return ''
    }

    return `${group && group?.group.user_is_admin ? ` ( ${group.applications} )` : ''}`
}