import { ReactNode } from "react";
import { leagueLogoPath } from "../../shared/urlHelper";
import { Header } from "../common/Header";
import { TipGroupImage } from "./TipGroupImage";

interface TipGroupHeaderProps {
  name?: string;
  image?: string;
  fullImage?: string;
  groupLabel?: ReactNode;
}
interface NewTipGroupHeaderProps {
  name?: string;
  image?: string;
  fullImage?: string;
  backFunc?: () => void;
}
interface EditTipGroupHeaderProps {
  name?: string;
  image?: string;
  fullImage?: string;
}

interface TipGroupLeagueHeaderProps {
  groupLabel?: ReactNode;
  groupName: string;
  leagueName: string;
  leagueId: string;
  image?: string;
  fullImage?: string;
  children?: ReactNode;
}

export function NewTipGroupHeader({
  name,
  image,
  fullImage,
}: NewTipGroupHeaderProps) {
  name = name ? name : "Neue Tippgruppe";

  return (
    <Header
      labelIcon={
        <TipGroupImage name={name} image={image} fullImage={fullImage} />
      }
      label={name}
      sublabel="Übersicht"
    />
  );
}

export function EditTipGroupHeader({
  name,
  image,
  fullImage,
}: EditTipGroupHeaderProps) {
  return (
    <Header
      labelIcon={
        name || image ? (
          <TipGroupImage
            name={name ?? ""}
            image={image}
            fullImage={fullImage}
          />
        ) : undefined
      }
      label={name}
      sublabel="Bearbeiten"
    />
  );
}

export function TipGroupMemberHeader({
  name,
  image,
  fullImage,
}: EditTipGroupHeaderProps) {
  return (
    <Header
      label={name}
      sublabel="Gruppenmitglieder"
      labelIcon={
        <TipGroupImage name={name ?? ""} image={image} fullImage={fullImage} />
      }
    />
  );
}
export function NewTipGroupAddLeagueHeader({
  name,
  image,
  fullImage,
  backFunc,
}: NewTipGroupHeaderProps) {
  name = name ? name : "Neue Tippgruppe";

  return (
    <Header
      labelIcon={
        <TipGroupImage name={name} image={image} fullImage={fullImage} />
      }
      label={name}
      sublabel="Wettbewerb hinzufügen"
      backFunc={backFunc}
    />
  );
}

export function TipGroupHeader({
  name,
  image,
  fullImage,
  groupLabel,
}: TipGroupHeaderProps) {
  return name ?? groupLabel ? (
    <Header
      label={groupLabel ?? name}
      sublabel="Übersicht"
      labelIcon={
        <TipGroupImage name={name ?? ""} image={image} fullImage={fullImage} />
      }
    />
  ) : (
    <Header />
  );
}

export function TipGroupLeagueHeader({
  groupLabel,
  groupName,
  leagueId,
  leagueName,
  image,
  fullImage,
  children,
}: TipGroupLeagueHeaderProps) {
  return (
    <Header
      label={groupLabel ?? groupName}
      sublabel={leagueName}
      labelIcon={
        <TipGroupImage name={groupName} image={image} fullImage={fullImage} />
      }
      sublabelIcon={leagueLogoPath(leagueId)}
    >
      {children}
    </Header>
  );
}
