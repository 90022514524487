import { UrlQuery } from "./common/useBackendUrl";
import { useBackendQueryFunction } from "./common/useBackendQueryFunction";
import { useQuery } from "react-query";
import { Tipgroup } from "../models/Tipgroup";

export const GetTipgroupKey = "GetTipgroups";

interface GetTipgroupData {
  data: Tipgroup;
}

interface UseGetTipgroupProps extends UrlQuery {
  groupId: string;
}

export function useGetTipgroup(props: UseGetTipgroupProps) {
  const queryFn = useBackendQueryFunction<GetTipgroupData>(
    "/groups/:groupId",
    props,
    true
  );

  return useQuery<GetTipgroupData>([GetTipgroupKey, props], queryFn);
}
