import { useBackendQueryFunction } from "./common/useBackendQueryFunction";
import { useQuery } from "react-query";
import { UrlQuery } from "./common/useBackendUrl";

interface AmateurTeamTypesData {
  data: string[];
}

interface UseGetAmateurTeamTypesProps extends UrlQuery {
  association: string;
}

export function useGetAmateurTeamTypes(props: UseGetAmateurTeamTypesProps) {
  const queryFn = useBackendQueryFunction<AmateurTeamTypesData>(
    "/contests/amateur/team_types",
    props
  );

  return useQuery<AmateurTeamTypesData>(["GetAmateurTeamTypes"], queryFn);
}
