import { UrlQuery } from "./common/useBackendUrl";
import { useBackendQueryFunction } from "./common/useBackendQueryFunction";
import { useQuery } from "react-query";
import { MetaData } from "../models/MetaData";
import { GlobalUserRanking } from "../models/GlobalUserRanking";

interface GetGlobalRankingSeasonData {
  data: GlobalUserRanking;
  meta: MetaData;
}

interface useGetGlobalRankingSeasonProps extends UrlQuery {
  leagueId: string;
  page?: number;
  upto_round_number?: number;
}

export function useGetGlobalRankingSeason(
  props: useGetGlobalRankingSeasonProps
) {
  const queryFn = useBackendQueryFunction<GetGlobalRankingSeasonData>(
    "/rankings/season/:leagueId/global",
    props,
    true
  );

  return useQuery<GetGlobalRankingSeasonData>(
    ["GetGlobalRankingSeasonPage", props],
    queryFn
  );
}
