import { useBackendQueryFunction } from "./common/useBackendQueryFunction";
import { useQuery } from "react-query";
import { League } from "../models/League";
import { UrlQuery } from "./common/useBackendUrl";

interface ProLeaguesData {
  data: League[];
}

interface ProLeaguesProps extends UrlQuery {
  sportType?: string;
}

export function useGetProLeagues(props: ProLeaguesProps) {
  const queryFn = useBackendQueryFunction<ProLeaguesData>(
    "/contests/pro",
    props
  );

  return useQuery<ProLeaguesData>(["GetProLeagues", props], queryFn);
}
