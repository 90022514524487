import { useQuery } from "react-query";
import { Profile } from "../models/Profile";
import { useBackendQueryFunction } from "./common/useBackendQueryFunction";
import { UrlQuery } from "./common/useBackendUrl";

interface GetProfileData {
  data: Profile;
}

interface useGetProfileProps extends UrlQuery {
  uuid?: string;
}

export function useGetProfile(props: useGetProfileProps) {
  const queryFn = useBackendQueryFunction<GetProfileData>(
    "/user/:uuid",
    props,
    true
  );

  return useQuery<GetProfileData>(["GetProfile", props], queryFn, {
    enabled: props.uuid != null,
  });
}
