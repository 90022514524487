import { ReactNode } from "react";
import { Link, To } from "react-router-dom";

interface ListProps {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
}

interface ListRow {
  children: React.ReactNode;
  isHeadline?: boolean;
  isLightHeadline?: boolean;
  isSmallHeadline?: boolean;
  hasBorder?: boolean;
  to?: To;
}

interface ListItemProps {
  children: React.ReactNode;
  className?: string;
  noPadding?: boolean;
}

export function ListItem({
  children,
  noPadding,
  className = "",
}: ListItemProps) {
  return (
    <div
      className={`${
        noPadding ? "custom-list-item-no-padding" : "custom-list-item"
      } ${className}`}
    >
      {children}
    </div>
  );
}

const ConditionalWrapper = ({
  to,
  children,
  className,
}: {
  to?: To;
  children: ReactNode;
  className: string;
}) =>
  to ? (
    <Link to={to} className={className}>
      {children}
    </Link>
  ) : (
    <div className={className}>{children}</div>
  );

export function ListRow({
  children,
  isHeadline,
  isLightHeadline,
  isSmallHeadline,
  hasBorder = true,
  to,
}: ListRow) {
  return (
    <ConditionalWrapper
      to={to}
      className={`
        custom-list-row 
        ${isHeadline ? "custom-list-row-headline" : ""}
        ${isLightHeadline ? "custom-list-row-headline-light" : ""}
        ${isSmallHeadline ? "custom-list-row-headline-small" : ""}
        ${hasBorder ? "custom-list-row-headline-border" : ""}
      `}
    >
      {children}
    </ConditionalWrapper>
  );
}

export default function List({ children, className = "", onClick }: ListProps) {
  return (
    <div
      onClick={onClick}
      className={`custom-list ${onClick ? "cursor-pointer" : ""} ${className}`}
    >
      {children}
    </div>
  );
}
