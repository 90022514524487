import { Profile } from "../../models/Profile";
import { ProfileRankings } from "../../models/ProfileRankings";
import { ProfileCarrier } from "./ProfileCarrier";
import { ProfileLeagues } from "./ProfileLeagues";
import { ProfileTipGroups } from "./ProfileTipGroups";

interface ProfileProps {
  profile: Profile;
  profileRankings?: ProfileRankings;
}

export function Profile({ profile, profileRankings }: ProfileProps) {
  return (
    <>
      <ProfileCarrier profile={profile} />

      <ProfileTipGroups
        commonGroups={profileRankings?.common_groups}
        userId={profile.user.uuid}
      />

      <ProfileLeagues
        otherLeagues={profileRankings?.other_contests}
        userId={profile.user.uuid}
        sharesTipgroups={
          profileRankings?.common_groups != null &&
          profileRankings.common_groups.length > 0
        }
      />
    </>
  );
}
