// import { useMemo } from "react";
// import { Match } from "../models/Match";
// import { TippMode } from "../models/TippMode";
import { TippResult } from "../models/TippResult";

// type useE2BarProps = {
//   gamedays: Match[];
//   tipps: Record<string, TippResult | null>;
//   tippMode: TippMode;
// };

export type E2CorrectScore = {
  home: number;
  away: number;
};
export type E2Result = {
  competition: string;
  match: string;
  "3W"?: string;
  correctScore?: E2CorrectScore;
};

export function getW3Status(tipp: TippResult): string | undefined {
  if (tipp.home_score === undefined || tipp.away_score === undefined) {
    return undefined;
  }

  if (tipp.home_score == tipp.away_score) {
    return "X";
  } else if (tipp.home_score > tipp.away_score) {
    return "1";
  }

  return "2";
}

export function getCorrectScore(tipp: TippResult): E2CorrectScore | undefined {
  if (tipp.home_score === undefined || tipp.away_score === undefined) {
    return undefined;
  }
  return {
    home: tipp.home_score,
    away: tipp.away_score,
  };
}

// export function useE2Bar({ gamedays, tipps, tippMode }: useE2BarProps) {
//   const result = useMemo(() => {
//     const tippedMatches: Record<string, E2Result> = {};
//     gamedays.map((match: Match) => {
//       const tipp = tipps[match.id];
//       if (tipp == null) {
//         return;
//       }

//       const e2Match = {
//         competition: match.contest_id,
//         match: match.id,
//       };

//       if (tippMode === "full") {
//         tippedMatches[match.id] = {
//           ...e2Match,
//           correctScore: getCorrectScore(tipp),
//         };
//       } else {
//         tippedMatches[match.id] = { ...e2Match, "3W": getW3Status(tipp) };
//       }
//     });
//     return tippedMatches;
//   }, [gamedays, tipps, tippMode]);
//   return result;
// }
