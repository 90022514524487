import { useBackendQueryFunction } from "./common/useBackendQueryFunction";
import { useQuery } from "react-query";
import { News } from "../models/News";

interface NewsData {
  data: News[];
}

export function useGetNews() {
  const queryFn = useBackendQueryFunction<NewsData>("/news");

  return useQuery<NewsData>(["GetNews"], queryFn);
}
