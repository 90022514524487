import React from "react";
import Bugsnag, { OnErrorCallback } from "@bugsnag/js";
import BugsnagPluginReact, {
  BugsnagPluginReactResult,
} from "@bugsnag/plugin-react";

Bugsnag.start({
  apiKey: "d6b6ffedf2e7b1ffcf174af09dc58309",
  plugins: [new BugsnagPluginReact()],
  autoTrackSessions: false,
  collectUserIp: false,
  generateAnonymousId: false,
  enabledReleaseStages: ["production", "staging"],
});

// Remove when https://github.com/bugsnag/bugsnag-js/issues/1717 is resolved
export type BugsnagErrorBoundaryWithChildren = React.ComponentType<
  React.PropsWithChildren<{
    onError?: OnErrorCallback;
    FallbackComponent?: React.ComponentType<{
      error: Error;
      info: React.ErrorInfo;
      clearError: () => void;
    }>;
  }>
>;

export const ErrorBoundary = (
  Bugsnag.getPlugin("react") as BugsnagPluginReactResult
).createErrorBoundary(React) as BugsnagErrorBoundaryWithChildren;
