import { UrlQuery } from "./common/useBackendUrl";
import { useBackendQueryFunction } from "./common/useBackendQueryFunction";
import { useQuery } from "react-query";
import { UserRanking } from "../models/UserRanking";
import { MetaData } from "../models/MetaData";

interface GetTipgroupRankingRoundData {
  data: UserRanking[];
  meta: MetaData;
}

interface UseGetTipgroupRankingRoundProps {
  props: UseGetTipgroupRankingRoundQueryProps;
  enabled?: boolean;
}

interface UseGetTipgroupRankingRoundQueryProps extends UrlQuery {
  groupId: string;
  leagueId: string;
  round_number: number;
}

export function useGetTipgroupRankingRound({
  props,
  enabled = true,
}: UseGetTipgroupRankingRoundProps) {
  const queryFn = useBackendQueryFunction<GetTipgroupRankingRoundData>(
    "/rankings/round/:leagueId/group/:groupId",
    props,
    true
  );

  return useQuery<GetTipgroupRankingRoundData>(
    ["GetTipgroupRankingRound", props],
    queryFn,
    { enabled: enabled }
  );
}
