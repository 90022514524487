import { HighlightState, TipChange } from "./MatchRow";
import Plus from "../../assets/team-name/result-controls.svg";
import { TippMode } from "../../models/TippMode";
import { useNavigate } from "react-router";
import TeamLogo from "../common/TeamLogo";

interface MatchTeamNameProps {
  teamName: string;
  teamIconUrl?: string;
  highlightState: HighlightState;
  isTippable: boolean;
  position: "left" | "right";
  tippmode: TippMode;
  changeTip: (tipChange: TipChange) => void;
  linkUrl?: string;
  isOtherUser?: boolean;
}

export function MatchTeamName({
  teamName,
  teamIconUrl,
  position,
  isTippable,
  highlightState,
  tippmode,
  changeTip,
  linkUrl,
  isOtherUser = false,
}: MatchTeamNameProps) {
  // TODO: use states to style the teamname (see comments in interface HighlightState)
  const navigate = useNavigate();
  const hideBackground = highlightState === "no-background";
  return (
    <div
      className={`
      match-team-name 
      match-team-name-${position}
      match-team-name-${highlightState}
      ${isTippable ? "match-team-name-tippable" : ""}
      `}
      onClick={() => {
        if (highlightState === "selected" && tippmode === "simple" && linkUrl) {
          navigate(linkUrl);
        } else if (isTippable) {
          changeTip(position === "left" ? "home" : "away");
        }
      }}
    >
      <div className="match-team-name-content">
        {!hideBackground && <div className={`match-team-name-bg ${isOtherUser ? 'match-team-name-bg-isOtherUser' : ''}`}/>}
        <div
          className={`match-team-name-text${
            hideBackground ? `-${highlightState}` : ""
          }`}
        >
          {teamName}
        </div>
        {teamIconUrl ? (
          <div className="match-team-name-icon">
            <TeamLogo image={teamIconUrl} name={teamName} />
          </div>
        ) : null}
      </div>
      {isTippable && tippmode === "full" && (
        <div className="match-team-name-plus">
          <Plus />
        </div>
      )}
    </div>
  );
}
