import { ReactNode } from "react";

export interface SectionHeaderProps {
  children: string | ReactNode;
  logo?: ReactNode;
}

export default function SectionHeader({ children, logo }: SectionHeaderProps) {
  return (
    <div className="section-header">
      {logo}
      <div className="section-header-title">{children}</div>
    </div>
  );
}
