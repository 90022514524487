import { useParams } from "react-router";
import { TipGroupMemberList } from "../../components/tipgroup/TipGroupMemberList";
import { TipGroupIdParamKeys } from "../../models/ParamKeys";
import { Error404Page } from "../Error404Page";

export function TipGroupMemberListPage() {
  const params = useParams<TipGroupIdParamKeys>();
  return params.groupId ? (
    <TipGroupMemberList groupId={params.groupId} />
  ) : (
    <Error404Page />
  );
}
