import React, { useEffect } from "react";
import { useTrackPage } from "../../api/common/tracking";
import { useGetAmateurAssociations } from "../../api/useGetAmateurAssociations";
import { useGetAmateurLeagues } from "../../api/useGetAmateurLeagues";
import { useGetAmateurLeagueTypes } from "../../api/useGetAmateurLeagueTypes";
import { useGetAmateurTeamTypes } from "../../api/useGetAmateurTeamTypes";
import { useGetProLeagues } from "../../api/useGetProLeagues";
import { League } from "../../models/League";
import { SportTypeType } from "../../models/SportType";
import { NextButton } from "../common/Button";
import { ErrorCard } from "../common/ErrorCard";
import { QueryWrapper } from "../common/QueryWrapper";
import {
  LeagueList,
  LeagueListLeague,
  LeagueListLeagueUiMode,
  LeagueListSection,
} from "../leaguelist/LeagueList";

export interface AddLeagueState {
  uiState: AddLeagueUiState;
  leagues: League[];
  sportType?: SportTypeType;
  association?: string;
  teamType?: string;
  leagueType?: string;
}

export type AddLeagueUiState =
  | "international"
  | "amateur-association"
  | "amateur-leagueType"
  | "amateur-teamType"
  | "amateur-league"
  | "start";

interface AddLeagueProps {
  state: AddLeagueState;
  setState: React.Dispatch<AddLeagueState>;
  uiMode: LeagueListLeagueUiMode;
}
interface AddLeagueAmateurLeagueProps {
  association?: string;
  teamType?: string;
  leagueType?: string;
  state: AddLeagueState;
  setState: React.Dispatch<AddLeagueState>;
  uiMode: LeagueListLeagueUiMode;
}
interface AddLeagueAmateurTeamTypesProps {
  association: string;
  state: AddLeagueState;
  setState: React.Dispatch<AddLeagueState>;
}
interface AddLeagueAmateurLeagueTypesProps {
  association: string;
  teamType: string;
  state: AddLeagueState;
  setState: React.Dispatch<AddLeagueState>;
}
interface AddLeagueAmateurTeamsProps {
  association: string;
  teamType: string;
  leagueType: string;
  state: AddLeagueState;
  setState: React.Dispatch<AddLeagueState>;
  uiMode: LeagueListLeagueUiMode;
}
interface AddLeagueInternationalLeagueProps {
  state: AddLeagueState;
  setState: React.Dispatch<AddLeagueState>;
  uiMode: LeagueListLeagueUiMode;
}
interface AddLeagueAmateurAssociationsProps {
  state: AddLeagueState;
  setState: React.Dispatch<AddLeagueState>;
}

export function AddLeague({ state, setState, uiMode }: AddLeagueProps) {
  const { uiState: type, association, leagueType, teamType } = state;
  const trackingFunc = useTrackPage();
  useEffect(() => {
    trackingFunc({});
    window.scrollTo(0, 0);
  }, [state.uiState, trackingFunc]);
  if (type.includes("amateur")) {
    return (
      <AddLeagueAmateurLeague
        association={association}
        leagueType={leagueType}
        teamType={teamType}
        state={state}
        setState={setState}
        uiMode={uiMode}
      />
    );
  }
  if (type === "international") {
    return (
      <AddLeagueInternationalLeague
        state={state}
        setState={setState}
        uiMode={uiMode}
      />
    );
  }
  return null;
}

function _isAlreadyAdded(state: AddLeagueState, leagueId: string) {
  return state.leagues.some((league) => league.id === leagueId);
}

function AddLeagueInternationalLeague({
  state,
  setState,
  uiMode,
}: AddLeagueInternationalLeagueProps) {
  const { data: proLeaguesData, ...proLeaguesQuery } = useGetProLeagues({
    sport: state.sportType,
  });

  const addLeague = (league: League) => {
    setState({ uiState: "start", leagues: [...state.leagues, league] });
  };
  const proLeagues = proLeaguesData?.data.reduce<Array<LeagueListSection>>(
    (leagues, league) => {
      if (
        leagues[leagues.length - 1] &&
        leagues[leagues.length - 1].header === league.countryName
      ) {
        leagues[leagues.length - 1].elements.push(
          <LeagueListLeague
            addLeague={addLeague}
            removeLeague={() => null}
            league={league}
            type={_isAlreadyAdded(state, league.id) ? "added" : "add"}
            key={league.id}
            uiMode={uiMode}
          />
        );
      } else {
        leagues.push({
          header: league.countryName ?? "Error",
          elements: [
            <LeagueListLeague
              addLeague={addLeague}
              removeLeague={() => null}
              league={league}
              type={_isAlreadyAdded(state, league.id) ? "added" : "add"}
              key={league.id}
              uiMode={uiMode}
            />,
          ],
        });
      }
      return leagues;
    },
    []
  );

  return (
    <QueryWrapper useQueryResult={[proLeaguesQuery]}>
      {proLeagues ? (
        proLeagues.length !== 0 ? (
          <LeagueList type="international" sections={[...proLeagues]} />
        ) : (
          <ErrorCard type="empty" />
        )
      ) : null}
    </QueryWrapper>
  );
}

function AddLeagueAmateurLeague({
  association,
  leagueType,
  teamType,
  state,
  setState,
  uiMode,
}: AddLeagueAmateurLeagueProps) {
  if (association) {
    if (teamType) {
      if (leagueType) {
        return state.uiState === "amateur-league" ? (
          <AddLeagueAmateurTeams
            association={association}
            leagueType={leagueType}
            teamType={teamType}
            state={state}
            setState={setState}
            uiMode={uiMode}
          />
        ) : (
          <ErrorCard />
        );
      }
      return state.uiState === "amateur-leagueType" ? (
        <AddLeagueAmateurLeagueTypes
          association={association}
          teamType={teamType}
          state={state}
          setState={setState}
        />
      ) : (
        <ErrorCard />
      );
    }
    return state.uiState === "amateur-teamType" ? (
      <AddLeagueAmateurTeamTypes
        association={association}
        state={state}
        setState={setState}
      />
    ) : (
      <ErrorCard />
    );
  }
  return state.uiState === "amateur-association" ? (
    <AddLeagueAmateurAssociations state={state} setState={setState} />
  ) : (
    <ErrorCard />
  );
}

function AddLeagueAmateurAssociations({
  state,
  setState,
}: AddLeagueAmateurAssociationsProps) {
  const { data: associationsData, ...associationsQuery } =
    useGetAmateurAssociations();
  const associations = {
    header: "Verbände",
    elements:
      associationsData?.data.map((association, index) => (
        <NextButton
          key={index}
          onClick={() =>
            setState({ ...state, uiState: "amateur-teamType", association })
          }
        >
          {association}
        </NextButton>
      )) ?? [],
  };

  return (
    <QueryWrapper useQueryResult={[associationsQuery]}>
      {associationsData ? (
        associationsData.data.length !== -1 ? (
          <LeagueList type="amateur-associations" sections={[associations]} />
        ) : (
          <ErrorCard type="empty" />
        )
      ) : null}
    </QueryWrapper>
  );
}

function AddLeagueAmateurTeamTypes({
  association,
  state,
  setState,
}: AddLeagueAmateurTeamTypesProps) {
  const { data: teamTypesData, ...teamTypesQuery } = useGetAmateurTeamTypes({
    association,
  });
  const teamTypes = {
    header: "Teamtyp",
    elements:
      teamTypesData?.data.map((teamType, index) => (
        <NextButton
          key={index}
          onClick={() =>
            setState({
              ...state,
              uiState: "amateur-leagueType",
              association,
              teamType,
            })
          }
        >
          {teamType}
        </NextButton>
      )) ?? [],
  };

  return (
    <QueryWrapper useQueryResult={[teamTypesQuery]}>
      {teamTypesData ? (
        teamTypesData.data.length !== -1 ? (
          <LeagueList
            type="amateur-teamtypes"
            sections={[teamTypes]}
            label={association}
          />
        ) : (
          <ErrorCard type="empty" />
        )
      ) : null}
    </QueryWrapper>
  );
}
function AddLeagueAmateurLeagueTypes({
  association,
  teamType,
  state,
  setState,
}: AddLeagueAmateurLeagueTypesProps) {
  const { data: leagueTypesData, ...leagueTypesQuery } =
    useGetAmateurLeagueTypes({
      association,
      team_type: teamType,
    });
  const leagueTypes = {
    header: "Spielklasse",
    elements:
      leagueTypesData?.data.map((leagueType, index) => (
        <NextButton
          key={index}
          onClick={() =>
            setState({
              ...state,
              uiState: "amateur-league",
              association,
              teamType,
              leagueType,
            })
          }
        >
          {leagueType}
        </NextButton>
      )) ?? [],
  };

  return (
    <QueryWrapper useQueryResult={[leagueTypesQuery]}>
      {leagueTypesData ? (
        leagueTypesData.data.length !== 0 ? (
          <LeagueList
            type="amateur-leaguetypes"
            sections={[leagueTypes]}
            label={teamType}
          />
        ) : (
          <ErrorCard type="empty" />
        )
      ) : null}
    </QueryWrapper>
  );
}
function AddLeagueAmateurTeams({
  association,
  teamType,
  leagueType,
  state,
  setState,
  uiMode,
}: AddLeagueAmateurTeamsProps) {
  const { data: leagueData, ...teamQuery } = useGetAmateurLeagues({
    association,
    team_type: teamType,
    league_type: leagueType,
  });

  const addLeague = (league: League) => {
    setState({
      uiState: "start",
      leagues: [...state.leagues, league],
    });
  };
  const teamTypes = {
    header: "Wettbewerb",
    elements:
      leagueData?.data.map((league) => (
        <LeagueListLeague
          addLeague={addLeague}
          removeLeague={() => null}
          league={league}
          type={_isAlreadyAdded(state, league.id) ? "added" : "add"}
          key={league.id}
          uiMode={uiMode}
        />
      )) ?? [],
  };

  return (
    <QueryWrapper useQueryResult={[teamQuery]}>
      {leagueData ? (
        leagueData.data.length !== 0 ? (
          <LeagueList
            type="amateur-teams"
            sections={[teamTypes]}
            label={leagueType}
          />
        ) : (
          <ErrorCard type="empty" />
        )
      ) : null}
    </QueryWrapper>
  );
}
