import { ApplicationResponse } from "../../models/ApplicationResponse";
import { TipUser } from "../../models/TipUser";
import Card from "../common/Card";
import List from "../list/List";
import { TipGroupMember } from "./TipGroupMember";

interface TipGroupWaitingProps {
  applications: TipUser[];
  tipGroupId: string;
  handleApplication: (response: ApplicationResponse) => void;
}
export function TipGroupWaiting({
  applications,
  tipGroupId,
  handleApplication,
}: TipGroupWaitingProps) {
  return applications && applications.length > 0 ? (
    <Card
      labelType="card-attention"
      labelText={`${applications.length} ${
        applications.length > 1 ? "Personen warten" : "Person wartet"
      }`}
    >
      <p className="mb-4 text-center text-lg font-normal">
        … darauf dieser Gruppe beizutreten
      </p>
      <List>
        {applications.map((member, index) => {
          return (
            <TipGroupMember
              key={index}
              user={member}
              type={"waiting"}
              groupId={tipGroupId}
              handleApplication={handleApplication}
            />
          );
        })}
      </List>
    </Card>
  ) : null;
}
