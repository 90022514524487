import { useEffect } from "react";
import { useParams } from "react-router";
import { useGetGameday } from "../api/useGetGameday";
import { useGetGroupTipps } from "../api/useGetGroupTipps";
import { useGetLeague } from "../api/useGetLeague";
import { useGetOpenFixtures } from "../api/useGetOpenFixtures";
import { useGetTipgroup } from "../api/useGetTipgroup";
import { useGetTipgroups } from "../api/useGetTipgroups";
import { useGetTipps } from "../api/useGetTipps";
import { useGetToday } from "../api/useGetToday";
import { ErrorCard } from "../components/common/ErrorCard";
import { Header } from "../components/common/Header";
import { QueryWrapper } from "../components/common/QueryWrapper";
import { E2BarSticky } from "../components/e2/E2Bar";
import { useE2Tipps } from "../components/e2/E2TippsContext";
import { LeagueHeader } from "../components/league/LeagueHeader";
import { MatchDetail } from "../components/match/MatchDetail";
import { TipGroupLeagueHeader } from "../components/tipgroup/TipGroupHeader";
import { MatchPageParamKeys } from "../models/ParamKeys";
import { TippResult } from "../models/TippResult";
import { Error404Page } from "./Error404Page";

interface MatchProps {
  leagueId: string;
  matchId: string;
  roundNumber: number;
  tipGroupId?: string;
  leagueName: string;
  tipGroupName?: string;
  type?: MatchPageType;
}
interface PreloadMatchProps {
  leagueId: string;
  matchId: string;
  roundNumber: number;
  type?: MatchPageType;
}
interface MatchInTipgroupProps {
  leagueId: string;
  leagueName: string;
  matchId: string;
  roundNumber: number;
  tipGroupId: string;
  tipGroupName: string;
  type?: MatchPageType;
}
interface GlobalMatchProps {
  leagueId: string;
  matchId: string;
  roundNumber: number;
  type?: MatchPageType;
}
export type MatchPageType = "openTipps" | "todayHome" | "todayGroup" | "league";

export function MatchPage() {
  const params = useParams<MatchPageParamKeys>();

  if (params.leagueId && params.matchId && params.roundNumber) {
    if (params.leagueName) {
      return (
        <Match
          leagueId={params.leagueId}
          matchId={params.matchId}
          roundNumber={parseInt(params.roundNumber)}
          tipGroupId={params.tipGroupId}
          leagueName={params.leagueName}
          tipGroupName={params.tipGroupName}
          type={params.type}
        />
      );
    } else {
      return (
        <PreloadMatch
          leagueId={params.leagueId}
          matchId={params.matchId}
          roundNumber={parseInt(params.roundNumber)}
          type={params.type}
        />
      );
    }
  } else {
    return <Error404Page />;
  }
}

function PreloadMatch({
  leagueId,
  matchId,
  roundNumber,
  type,
}: PreloadMatchProps) {
  const { data: leagueData, ...leagueQuery } = useGetLeague({
    leagueId: leagueId,
  });
  const { data: tipgroupsData, ...tipgroupsQuery } = useGetTipgroups();
  const matchGroups = tipgroupsData?.data.filter(
    (g) =>
      g.contest_ids && g.contest_ids?.findIndex((id) => id === leagueId) > -1
  );
  const onlyMatchGroup = matchGroups?.length === 1 ? matchGroups[0] : null;
  const header = leagueData ? null : ( // header set in Match component once data is loaded.
    <Header />
  );
  return (
    <>
      {header}
      <QueryWrapper useQueryResult={[tipgroupsQuery, leagueQuery]}>
        {leagueData ? (
          <Match
            leagueId={leagueId}
            leagueName={leagueData.data.name}
            tipGroupId={onlyMatchGroup?.uuid}
            tipGroupName={onlyMatchGroup?.name}
            matchId={matchId}
            roundNumber={roundNumber}
            type={type}
          />
        ) : null}
      </QueryWrapper>
      <E2BarSticky roundNumber={roundNumber} matchId={matchId} />
    </>
  );
}

function Match({
  leagueId,
  matchId,
  roundNumber,
  tipGroupId,
  leagueName,
  tipGroupName,
  type,
}: MatchProps) {
  return tipGroupId && tipGroupName ? (
    <MatchInTipgroup
      leagueId={leagueId}
      leagueName={leagueName}
      matchId={matchId}
      roundNumber={roundNumber}
      tipGroupId={tipGroupId}
      tipGroupName={tipGroupName}
      type={type}
    />
  ) : (
    <>
      <LeagueHeader leagueId={leagueId} leagueName={leagueName} />
      <GlobalMatch
        leagueId={leagueId}
        matchId={matchId}
        roundNumber={roundNumber}
        type={type}
      />
    </>
  );
}

function MatchInTipgroup({
  leagueId,
  leagueName,
  matchId,
  roundNumber,
  tipGroupId,
  tipGroupName,
  type,
}: MatchInTipgroupProps) {
  const { setE2Data } = useE2Tipps();
  const { data: leagueData, ...leagueQuery } = useGetLeague({
    leagueId: leagueId,
  });
  const { data: roundData, ...roundQuery } = useGetGameday({
    leagueId: leagueId,
    round_number: roundNumber,
  });
  const { data: roundTippData, ...tippQuery } = useGetTipps({
    leagueId: leagueId,
    round_number: roundNumber,
  });
  const { data: tipgroupData, ...tipgroupQuery } = useGetTipgroup({
    groupId: tipGroupId,
  });
  const { data: groupTipps, ...groupTippQuery } = useGetGroupTipps({
    fixtureId: matchId,
    groupId: tipGroupId,
  });
  const { data: groupOpenTippsData, ...groupOpenTippsQuery } =
    useGetOpenFixtures({
      props: { group_id: tipGroupId },
      enabled: type === "openTipps",
    });
  const { data: groupTodayData, ...groupTodayQuery } = useGetToday({
    props: { group_id: tipGroupId },
    enabled: type === "todayGroup",
  });
  const { data: todayHomeData, ...todayHomeQuery } = useGetToday({
    enabled: type === "todayHome",
  });
  const { data: tipgroupsData, ...tipgroupsQuery } = useGetTipgroups();
  const match = roundData?.data.find((match) => match.id === matchId);

  useEffect(() => {
    if (roundData && roundTippData) {
      const tipp: TippResult | undefined = roundTippData?.data[matchId];

      const tipps: Record<string, TippResult> = {};
      if (tipp) {
        tipps[tipp.fixture_id] = tipp;
      }

      setE2Data({
        gamedays: match ? [match] : [],
        tipps: tipp ? tipps : {},
        tippMode: roundTippData?.meta?.prediction_mode ?? "full",
      });
    }
  }, [roundData, roundTippData, setE2Data, match, matchId]);

  return (
    <>
      <TipGroupLeagueHeader
        groupName={tipGroupName}
        leagueName={leagueName}
        leagueId={leagueId}
        image={tipgroupData?.data?.image?.thumbnail_file_url}
        fullImage={tipgroupData?.data?.image?.full_file_url}
      />
      <QueryWrapper
        useQueryResult={[
          leagueQuery,
          roundQuery,
          tippQuery,
          tipgroupQuery,
          groupTippQuery,
          groupOpenTippsQuery,
          groupTodayQuery,
          todayHomeQuery,
          tipgroupsQuery,
        ]}
      >
        {leagueData && match && tipgroupData && groupTipps && tipgroupsData ? (
          <MatchDetail
            league={leagueData.data}
            match={match}
            roundNumber={roundNumber}
            group={tipgroupData.data}
            openMatches={groupOpenTippsData?.data}
            todaysMatches={
              type === "todayGroup" ? groupTodayData?.data : todayHomeData?.data
            }
            roundMatches={roundData?.data}
            roundTipps={roundTippData?.data}
            groupTipps={groupTipps.data}
            matchPageType={type}
            userTipGroups={tipgroupsData.data}
          />
        ) : null}
      </QueryWrapper>
      <E2BarSticky roundNumber={roundNumber} matchId={matchId} />
    </>
  );
}
function GlobalMatch({
  leagueId,
  matchId,
  roundNumber,
  type,
}: GlobalMatchProps) {
  const { setE2Data } = useE2Tipps();
  const { data: leagueData, ...leagueQuery } = useGetLeague({
    leagueId: leagueId,
  });
  const { data: roundData, ...roundQuery } = useGetGameday({
    leagueId: leagueId,
    round_number: roundNumber,
  });
  const { data: roundTippData, ...tippQuery } = useGetTipps({
    leagueId: leagueId,
    round_number: roundNumber,
  });
  const { data: todayData, ...todayQuery } = useGetToday({
    enabled: type === "todayHome",
  });
  const { data: openTippsData, ...openTippsQuery } = useGetOpenFixtures({
    props: {},
    enabled: type === "openTipps",
  });
  const { data: tipgroupsData, ...tipgroupsQuery } = useGetTipgroups();
  const match = roundData?.data.find((match) => match.id === matchId);

  useEffect(() => {
    if (roundData && roundTippData) {
      const tipp: TippResult | undefined = roundTippData?.data[matchId];

      const tipps: Record<string, TippResult> = {};
      if (tipp) {
        tipps[tipp.fixture_id] = tipp;
      }

      setE2Data({
        gamedays: match ? [match] : [],
        tipps: tipp ? tipps : {},
        tippMode: roundTippData?.meta?.prediction_mode ?? "full",
      });
    }
  }, [roundData, roundTippData, setE2Data, match, matchId]);

  return (
    <>
      <QueryWrapper
        useQueryResult={[
          leagueQuery,
          roundQuery,
          tippQuery,
          todayQuery,
          tipgroupsQuery,
          openTippsQuery,
        ]}
      >
        {leagueData && tipgroupsData ? (
          <MatchDetail
            league={leagueData.data}
            match={match}
            roundNumber={roundNumber}
            todaysMatches={todayData?.data}
            roundMatches={roundData?.data}
            roundTipps={roundTippData?.data}
            openMatches={openTippsData?.data}
            matchPageType={type}
            userTipGroups={tipgroupsData.data}
            isGlobalMatch={true}
          />
        ) : (
          <ErrorCard type="empty" />
        )}
      </QueryWrapper>
      <E2BarSticky roundNumber={roundNumber} matchId={matchId} />
    </>
  );
}
