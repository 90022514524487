import { useBackendQueryFunction } from "./common/useBackendQueryFunction";
import { useQuery } from "react-query";
import { UrlQuery } from "./common/useBackendUrl";

interface AmateurLeagueTypesData {
  data: string[];
}

interface UseGetAmateurLeagueTypesProps extends UrlQuery {
  association: string;
  team_type: string;
}

export function useGetAmateurLeagueTypes(props: UseGetAmateurLeagueTypesProps) {
  const queryFn = useBackendQueryFunction<AmateurLeagueTypesData>(
    "/contests/amateur/league_types",
    props
  );

  return useQuery<AmateurLeagueTypesData>(["GetAmateurLeagueTypes"], queryFn);
}
