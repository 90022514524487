import { useEffect } from "react";
import { useAdClickCallback } from "./useAdClickCallback";

export function useAdClickEffect(
  ref: React.RefObject<HTMLElement>,
  isLoaded: boolean
) {
  const handleOnLinkClick = useAdClickCallback();
  useEffect(() => {
    const contentWindow =
      ref && ref.current
        ? ref.current
            ?.querySelector("iframe")
            ?.contentDocument?.querySelector("iframe")?.contentWindow
        : undefined;

    if (contentWindow) {
      contentWindow.document.addEventListener("click", handleOnLinkClick);
    }

    return () => {
      if (contentWindow) {
        contentWindow.document.removeEventListener("click", handleOnLinkClick);
      }
    };
  }, [isLoaded, handleOnLinkClick, ref]);
}
