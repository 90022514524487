import { ProfileRankingsGroup } from "../../models/ProfileRankings";
import { linkUrlProfileLeague } from "../../shared/linkUrlHelper";
import { leagueLogoPath } from "../../shared/urlHelper";
import Card from "../common/Card";
import LeagueImage from "../common/LeagueImage";
import List from "../list/List";
import { RankedListElement } from "../list/ListElement";
import { TipGroupListElement } from "../tipgroup/TipGroupListElement";

interface ProfileTipGroupsProps {
  userId: string;
  commonGroups?: ProfileRankingsGroup[];
}

export function ProfileTipGroups({
  userId,
  commonGroups,
}: ProfileTipGroupsProps) {
  return commonGroups && commonGroups.length > 0 ? (
    <Card labelText={`Tippgruppen`}>
      <List>
        {commonGroups.map((group) => {
          return (
            <ProfileTipGroup
              key={group.group.uuid}
              group={group}
              userId={userId}
            />
          );
        })}
      </List>
    </Card>
  ) : null;
}

interface ProfileTipGroupProps {
  userId: string;
  group: ProfileRankingsGroup;
}

function ProfileTipGroup({ userId, group }: ProfileTipGroupProps) {
  const hasContests = group.contests && group.contests.length > 0;
  return (
    <div className="mt-4 first:mt-0">
      <TipGroupListElement
        tipgroup={group.group}
        isHeadline
        hasBorder={hasContests}
        score={group.score}
      />
      {group.contests &&
        group.contests.length > 0 &&
        group.contests.map((contest) => {
          const league = contest.contest;
          const icon = (
            <LeagueImage image={leagueLogoPath(league.id)} name={league.name} />
          );
          return (
            <RankedListElement
              key={league.id}
              icon={icon}
              linkUrl={linkUrlProfileLeague(
                league.id,
                league.name,
                league.currentRound.number,
                userId,
                group.group.uuid
              )}
              name={league.name}
              rank={contest.score?.rank}
              count={group.group?.member_count}
            />
          );
        })}
    </div>
  );
}
