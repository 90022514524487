import { ComponentType, Fragment, useEffect } from "react";
import { useParams } from "react-router";
import { useTrackPage } from "../api/common/tracking";
import { AllParamKeys } from "../models/ParamKeys";
import { TrackedPageProps, TrackingContextProps } from "../models/TrackedPage";

export const TrackedPage: ComponentType<TrackedPageProps> = (
  props: TrackedPageProps
) => {
  const params = useParams<AllParamKeys>();
  const trackingFunc = useTrackPage();

  useEffect(() => {
    const contextProps: TrackingContextProps = {
      page: props.page,
      kickerLeagueId: props.trackLeagueId ? params.leagueId : undefined,
      matchId: props.trackMatchId ? params.matchId : undefined,
      roundNumber: props.trackRoundNumber ? params.roundNumber : undefined,
      tipGroupId: props.trackTipGroupId ? params.tipGroupId : undefined,
    };
    trackingFunc(contextProps);
  }, [
    params.leagueId,
    params.matchId,
    params.roundNumber,
    params.tipGroupId,
    props.page,
    props.trackLeagueId,
    props.trackMatchId,
    props.trackRoundNumber,
    props.trackTipGroupId,
    trackingFunc,
  ]);

  return <Fragment>{props.children}</Fragment>;
};
