export interface TrackedPageProps {
  children?: React.ReactNode;
  page: string;
  trackLeagueId?: boolean;
  trackRoundNumber?: boolean;
  trackMatchId?: boolean;
  trackTipGroupId?: boolean;
}

export interface TrackingContextProps {
  page?: string;
  kickerLeagueId?: string;
  roundNumber?: string;
  matchId?: string;
  tipGroupId?: string;
}

export enum TrackingPage {
  Home = "home",
  Match = "Match",
  TipGroup = "TipGroup",
  RankingDetail = "RankingDetail",
  Gameday = "Gameday",
  TipGroupLeague = "TipGroupLeague",
  League = "League",
  EditTipGroup = "EditTipGroup",
  TipGroupMembers = "TipGroupMembers",
  TipGroupMemberList = "TipGroupMemberList",
  News = "News",
  LeagueList = "LeagueList",
  Vereinsheim = "Vereinsheim",
  TipGroupList = "TipGroupList",
  VereinsheimGroupList = "VereinsheimGroupList",
  Profile = "Profile",
  ProfileLeague = "Profile",
}
export enum TrackingAction {
  RouteChange = "route_change",
  RouteChangeNoSticky = "route_change_no_sticky",
  TippChange = "tipp_change",
  HeaderToggleChange = "header_toggle_change",
}
