import { useParams } from "react-router";
import { ProfileLeague } from "../components/profile/ProfileLeague";
import { ProfileLeagueParamKeys } from "../models/ParamKeys";
import { Error404Page } from "./Error404Page";

export function ProfileLeaguePage() {
  const params = useParams<ProfileLeagueParamKeys>();
  if (
    params.leagueId &&
    params.leagueName &&
    params.roundNumber &&
    params.uuid
  ) {
    return (
      <ProfileLeague
        leagueId={params.leagueId}
        leagueName={params.leagueName}
        roundNumber={parseInt(params.roundNumber)}
        userId={params.uuid}
        tipgroupId={params.tipGroupId}
      />
    );
  } else {
    return <Error404Page />;
  }
}
