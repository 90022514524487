import React, { useEffect, useState } from "react";
import { useGetRecentTipgroupMembers } from "../../api/useGetRecentTipgroupMembers";
import { useGetTipgroup } from "../../api/useGetTipgroup";
import { useGetTipgroupMembers } from "../../api/useGetTipgroupMembers";
import Card from "../common/Card";
import { QueryWrapper } from "../common/QueryWrapper";
import SectionHeader from "../common/SectionHeader";
import { E2BarSticky } from "../e2/E2Bar";
import List from "../list/List";
import { TipGroupMemberHeader } from "./TipGroupHeader";
import { TipGroupMember } from "./TipGroupMember";

interface TipGroupMemberListProps {
  groupId: string;
}

export function TipGroupMemberList({ groupId }: TipGroupMemberListProps) {
  const { data: tipGroupData } = useGetTipgroup({
    groupId: groupId,
  });
  const { data: recentMembers, ...recentMembersQuery } =
    useGetRecentTipgroupMembers({ groupId: groupId });

  const { data: members, ...membersQuery } = useGetTipgroupMembers({
    groupId: groupId,
  });

  const [name, setName] = useState(tipGroupData?.data.name ?? "");
  useEffect(() => {
    // sets name to state once after feed is loaded
    if (name === "" && tipGroupData) {
      setName(tipGroupData?.data.name);
    }
  }, [name, tipGroupData]);

  return (
    <>
      <TipGroupMemberHeader
        name={name}
        image={tipGroupData?.data.image?.thumbnail_file_url}
        fullImage={tipGroupData?.data.image?.full_file_url}
      />
      <QueryWrapper useQueryResult={[recentMembersQuery, membersQuery]}>
        {members && members.data.length > 0 ? (
          <Card
            labelText={`${members.data.length} ${
              members.data.length > 1 ? "Mitglieder" : "Mitglied"
            } `}
          >
            {recentMembers && recentMembers.data.length > 0 ? (
              <>
                <SectionHeader>ZULETZT HINZUGEFÜGT</SectionHeader>
                <List>
                  {recentMembers?.data.map((member, index) => {
                    return (
                      <TipGroupMember
                        key={index}
                        user={member}
                        type={"detail"}
                        groupId={groupId}
                      />
                    );
                  })}
                </List>
              </>
            ) : undefined}
            {members.data.length >
              (recentMembers != null ? recentMembers.data.length : 0) && (
              <React.Fragment>
                <SectionHeader>WEITERE MITGLIEDER</SectionHeader>
                <List>
                  {members?.data.map((member, index) => {
                    if (
                      !recentMembers?.data.some(
                        (recentMember) => recentMember.uuid === member.uuid
                      )
                    ) {
                      return (
                        <TipGroupMember
                          key={index}
                          user={member}
                          type={"detail"}
                          groupId={groupId}
                        />
                      );
                    }
                  })}
                </List>
              </React.Fragment>
            )}
          </Card>
        ) : null}
        <E2BarSticky reset roundNumber={0}/>
      </QueryWrapper>
    </>
  );
}
