import { Profile, ProfileScoreCounts } from "../../models/Profile";
import Card from "../common/Card";
import { Label } from "../common/Label";
import BadgeUser from "../common/BadgeUser";

interface ProfileCarrierProps {
  profile: Profile;
}

export function ProfileCarrier({ profile }: ProfileCarrierProps) {
  return (
    <Card labelText="Karriere" contentClassName={`flex flex-col items-center`}>
      <h2 className="text-l mb-4">
        Statistik aller Tipps in allen Tippgruppen
      </h2>

      <BadgeUser user={profile.user} />
      <div className="flex flex-col items-center justify-center -mt-8 space-y-4">
        <Label type="no-tip">
          {profile.predictions} {profile.predictions === 1 ? "Tipp" : "Tipps"}
        </Label>
        <Label type="no-tip">
          {profile.points} {profile.points === 1 ? "Punkt" : "Gesamtpunkte"}
        </Label>
      </div>
      <div className="w-full">
        <div className="profile-tendency">
          <div className="profile-tendency-label profile-tendency-label-start" />
          <div className="w-8" />
          <div className="profile-tendency-label profile-tendency-label-end" />
        </div>
        <ProfilePercentageOfRatedTips score_counts={profile.score_counts} />
      </div>
    </Card>
  );
}

interface ProfilePercentageOfRatedTipsProps {
  score_counts: ProfileScoreCounts;
}

function ProfilePercentageOfRatedTips({
  score_counts,
}: ProfilePercentageOfRatedTipsProps) {
  const total = score_counts.total + score_counts.zero;

  return (
    <div className="flex space-x-4 items-center justify-between">
      <ProfilePercentageOfRatedTipsItem
        percentage={getPercentage(score_counts.three, total)}
        text="+ 3 Pkt"
      />
      <ProfilePercentageOfRatedTipsItem
        percentage={getPercentage(score_counts.two, total)}
        text="+ 2 Pkt"
      />
      <ProfilePercentageOfRatedTipsItem
        percentage={getPercentage(score_counts.one, total)}
        text="+ 1 Pkt"
      />
      <ProfilePercentageOfRatedTipsItem
        percentage={getPercentage(score_counts.zero, total)}
        text="+ 0 Pkt"
        type="incorrect"
      />
    </div>
  );
}

const ProfilePercentageOfRatedTipsItem = ({
  percentage,
  text,
  type = "correct-tendency",
}: {
  percentage: string;
  text: string;
  type?: "correct-tendency" | "incorrect";
}) => {
  return (
    <div className="flex-1 text-center ">
      <div className="text-lg mb-1">{percentage}%</div>
      <Label type={type} size="medium">
        {text}
      </Label>
    </div>
  );
};

function getPercentage(value: number, total: number) {
  const percentage = (value / total) * 100;
  return !isNaN(percentage) ? percentage.toFixed(0) : "0";
}
