import { useMemo } from "react";
import { Tipgroup } from "../../models/Tipgroup";
import {
  linkUrlTipGroupHome,
  linkUrlTipGroupList,
} from "../../shared/linkUrlHelper";
import { ButtonWrapper, LinkButton } from "../common/Button";
import Card from "../common/Card";
import List, { ListItem, ListRow } from "../list/List";
import { TipGroupImage } from "../tipgroup/TipGroupImage";
import { useGetGroupApplications } from "../../api/useGetGroupApplications";
import { getApplicationsByUuid } from "../../shared/aplicationHelper";
import { GroupApplication } from "../../models/GroupApplication";
import { ApplicationCountLabel } from "../applicationCount/ApplicationCountLabel";
import { Link } from "react-router-dom";

interface MyTipgroupsProps {
  tipgroups: Tipgroup[];
  size: "short" | "long";
}

export function MyTipgroups({ tipgroups, size }: MyTipgroupsProps) {
  const notVereinsheimGroups = tipgroups.filter((g) => !g.is_vereinsheim);
  const vereinsheimGroups = tipgroups.filter((g) => g.is_vereinsheim);
  const notVereinsheimElements =
    size === "short" && notVereinsheimGroups.length > 5
      ? notVereinsheimGroups.slice(0, 5)
      : notVereinsheimGroups;
  const vereinsheimElements =
    size === "short" && vereinsheimGroups.length > 5
      ? vereinsheimGroups.slice(0, 5)
      : vereinsheimGroups;
  return (
    <>
      {notVereinsheimElements.length !== 0 ? (
        <MyTipgroupsWrapper
          type="notVereinsheim"
          linkUrl={
            size === "short" && notVereinsheimGroups.length > 5
              ? linkUrlTipGroupList()
              : undefined
          }
          elements={notVereinsheimElements}
        />
      ) : null}
      {vereinsheimElements.length !== 0 ? (
        <MyTipgroupsWrapper
          type="vereinsheim"
          linkUrl={
            size === "short" && vereinsheimGroups.length > 5
              ? linkUrlTipGroupList(
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  true
                )
              : undefined
          }
          elements={vereinsheimElements}
        />
      ) : null}
    </>
  );
}

interface MyTipgroupsWrapperProps {
  type: "vereinsheim" | "notVereinsheim";
  linkUrl?: string;
  elements: Tipgroup[];
}

export function MyTipgroupsWrapper({
  type,
  linkUrl,
  elements,
}: MyTipgroupsWrapperProps) {
  const { data: applicationsData } = useGetGroupApplications();

  const applicationsByUuid = useMemo(
    () => getApplicationsByUuid(applicationsData?.data),
    [applicationsData]
  );

  return (
    <Card
      labelText={
        type === "vereinsheim" ? "Meine Vereinsheim Apps" : "Meine Tippgruppen"
      }
      labelLinkTo={linkUrl}
    >
      <List>
        {elements.map((tipgroup) => (
          <TipgroupElement
            groupApplication={applicationsByUuid[tipgroup.uuid]}
            tipgroup={tipgroup}
            key={tipgroup.uuid}
          />
        ))}
      </List>
      {linkUrl ? (
        <ButtonWrapper>
          <LinkButton to={linkUrlTipGroupList()}>
            {type === "vereinsheim"
              ? "Alle Vereinsheim Apps"
              : "Alle Tippgruppen"}
          </LinkButton>
        </ButtonWrapper>
      ) : null}
    </Card>
  );
}

interface TipgroupElementProps {
  tipgroup: Tipgroup;
  groupApplication?: GroupApplication;
}
function TipgroupElement({ tipgroup, groupApplication }: TipgroupElementProps) {
  return (
    <>
      <Link to={linkUrlTipGroupHome(tipgroup.uuid, tipgroup.name)}>
        <ListRow>
          <ListItem>
            <TipGroupImage
              name={tipgroup.name}
              image={tipgroup.image?.thumbnail_file_url}
              fullImage={tipgroup.image?.full_file_url}
            />
          </ListItem>
          <ListItem>
            <ApplicationCountLabel
              groupName={tipgroup.name}
              groupApplication={groupApplication}
            />
          </ListItem>
          {tipgroup.score?.rank ? (
            <ListItem>{tipgroup.score.rank}. Platz</ListItem>
          ) : null}
        </ListRow>
      </Link>
    </>
  );
}
