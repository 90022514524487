import { useEffect } from 'react'
import { useNavigate } from "react-router";
import { useGetGameday } from "../../api/useGetGameday";
import { useGetGamedays } from "../../api/useGetGamedays";
import { useGetLeague } from "../../api/useGetLeague";
import { useGetOpenFixtures } from "../../api/useGetOpenFixtures";
import { useGetTipgroup } from "../../api/useGetTipgroup";
import { useGetTipgroupRankingRound } from "../../api/useGetTipgroupRankingRound";
import { useGetTipgroupRankingSeason } from "../../api/useGetTipgroupRankingSeason";
import { useGetTipps } from "../../api/useGetTipps";
import { League } from "../../models/League";
import {
  linkUrlLeagueRanking,
  linkUrlTipgroupLeague,
} from "../../shared/linkUrlHelper";
import { leagueLogoPath } from "../../shared/urlHelper";
import { ButtonWrapper, LinkButton } from "../common/Button";
import Card from "../common/Card";
import { ColumnsWrapper } from "../common/Columns";
import { ErrorCard } from "../common/ErrorCard";
import LeagueImage from "../common/LeagueImage";
import { QueryWrapper } from "../common/QueryWrapper";
import { useE2Tipps } from '../e2/E2TippsContext';
import { Gameday } from "../gameday/Gameday";
import { GamedayChooserHeader } from "../gameday/GamedayChooserHeader";
import { LeagueLeave } from "../league/LeagueLeave";
import { LeagueMuted } from "../league/LeagueMuted";
import { RoundRanking, SeasonRanking } from "../ranking/Ranking";

interface TipGroupLeagueProps {
  leagueId: string;
  groupId: string;
  roundNumber: number;
}

export function TipGroupLeague({
  groupId,
  leagueId,
  roundNumber,
}: TipGroupLeagueProps) {
  const { setE2Data } = useE2Tipps()
  const { data: leagueData, ...leagueQuery } = useGetLeague({
    leagueId: leagueId,
  });
  const navigate = useNavigate();
  const league = leagueData?.data;
  const { data: seasonRankingData, ...seasonRankingQuery } =
    useGetTipgroupRankingSeason({
      groupId,
      leagueId,
      upto_round_number: roundNumber,
    });
  const { data: tipgroupData, ...tipgroupQuery } = useGetTipgroup({
    groupId: groupId,
  });

  const { data: roundsData, ...roundsQuery } = useGetGamedays({
    leagueId,
  });
  const gameday = roundsData?.data.find(
    (gameday) => gameday.number === roundNumber
  );
  const { data: gamedayData, ...gamedayQuery } = useGetGameday({
    leagueId: leagueId,
    round_number: roundNumber,
  });
  const { data: tippData, ...tippQuery } = useGetTipps({
    leagueId: leagueId,
    round_number: roundNumber,
  });
  const showRanking = gamedayData?.data.some(
    (match) => match.started || match.finished
  );
  const { data: roundRankingData, ...roundRankingQuery } =
    useGetTipgroupRankingRound({
      props: {
        groupId: groupId,
        leagueId: leagueId,
        round_number: roundNumber,
      },
      enabled: showRanking,
    });
  const { data: openFixturesData, ...openFixturesQuery } = useGetOpenFixtures({
    props: { group_id: groupId },
  });
  const leagueIsActive = openFixturesData?.data.some(
    (fixture) => fixture.contest.id === leagueId
  );

  useEffect(() => {
    if (gamedayData && tippData) {
      setE2Data({
        gamedays: gamedayData.data,
        tipps: tippData.data,
        tippMode: tippData?.meta?.prediction_mode ?? "full",
      });
    }
  }, [gamedayData, tippData, setE2Data]);

  return (
    <>
      {roundsData && league && gameday && tipgroupData && (
        <GamedayChooserHeader
          gameday={gameday}
          rounds={roundsData.data}
          navigate={(number) =>
            navigate(
              linkUrlTipgroupLeague(
                tipgroupData.data.uuid,
                tipgroupData.data.name,
                league.id,
                league.name,
                number
              ),
              { replace: true }
            )
          }
        />
      )}
      <QueryWrapper
        useQueryResult={[
          leagueQuery,
          roundRankingQuery,
          roundsQuery,
          tipgroupQuery,
          seasonRankingQuery,
          gamedayQuery,
          tippQuery,
          openFixturesQuery,
        ]}
      >
        {league &&
        roundsData &&
        tipgroupData &&
        seasonRankingData &&
        gamedayData &&
        tippData ? (
          <ColumnsWrapper>
            {!leagueIsActive ? <LeagueMuted /> : null}
            {showRanking && roundRankingData ? (
              <RoundRanking
                roundNumber={roundNumber}
                group={tipgroupData.data}
                league={league}
                size="short"
                rankingData={roundRankingData}
                {...{ isRight: true }}
              />
            ) : null}
            <Gameday
              leagueId={league?.id}
              leagueName={league.name}
              roundNumber={roundNumber}
              group={tipgroupData.data}
              gamedays={gamedayData.data}
              tipps={tippData.data}
              isAmateurLeague={league.state === "GermanAmateurContest"}
            />
            <SeasonRanking
              league={league}
              group={tipgroupData.data}
              size="short"
              rankingData={seasonRankingData}
              roundNumber={roundNumber}
              {...{ isRight: true }}
            />
            {leagueIsActive ? (
              <LeagueLeave leagueName={league.name} leagueId={league.id} />
            ) : null}
            <GlobalSeasonRankingLink
              league={league}
              roundNumber={roundNumber}
              {...{ isRight: true }}
            />
          </ColumnsWrapper>
        ) : (
          <ErrorCard type="empty" />
        )}
      </QueryWrapper>
    </>
  );
}
interface GlobalSeasonRankingLinkProps {
  league: League;
  roundNumber: number;
}
function GlobalSeasonRankingLink({
  league,
  roundNumber,
}: GlobalSeasonRankingLinkProps) {
  return (
    <Card transparent>
      <div className="btn-container">
        <LeagueImage
          image={leagueLogoPath(league.id)}
          name={league.name}
          size="lg"
        />
        <ButtonWrapper>
          <LinkButton
            to={linkUrlLeagueRanking(
              league.id,
              league.name,
              roundNumber,
              "season"
            )}
          >
            Ranking aller kicker-Nutzer
          </LinkButton>
        </ButtonWrapper>
      </div>
    </Card>
  );
}
