import { ReactNode } from "react";

interface StickyFooterProps {
  children: ReactNode;
  background?: boolean;
}

export default function StickyFooter({
  children,
  background = false,
}: StickyFooterProps) {
  return (
    <div
      className={`sticky-footer ${
        background ? "sticky-footer-background" : ""
      }`}
    >
      {children}
    </div>
  );
}
