import { useEffect, useMemo, useState } from "react";
import { useGetSportTypes } from "../../api/useGetSportTypes";
import { getImageUrlForSportType, SportTypeType } from "../../models/SportType";

interface SportTypeHeaderProps {
  type: SportTypeType;
}

export function SportTypeHeader({ type }: SportTypeHeaderProps) {
  return (
    <div className="sports-type-header">
      <SportTypeHeaderIcon type={type} />
      <SportTypeHeaderText type={type} />
    </div>
  );
}

export function SportTypeHeaderIcon({ type }: SportTypeHeaderProps) {
  const imageUrl = getImageUrlForSportType(type);
  const [imageWorks, setImageWorks] = useState(true);
  const [imageWidth, setImageWidth] = useState(0);
  const img = useMemo(() => new Image(), []);

  useEffect(() => {
    img.src = imageUrl;
    img.onload = function () {
      setImageWidth(img.naturalWidth);
    };
    return () => {
      img.onload = null;
    };
  }, [imageUrl, img]);
  return imageWorks && imageWidth > 1 ? (
    <img
      src={imageUrl}
      onError={() => setImageWorks(false)}
      className={"sports-type-image"}
      alt={`Logo ${type}`}
    />
  ) : (
    <span className="sports-type-image-placeholder" />
  );
}

export function SportTypeHeaderText({ type }: SportTypeHeaderProps) {
  const { data: sportTypesData } = useGetSportTypes();
  const label =
    sportTypesData && sportTypesData.length > 0
      ? sportTypesData.find((st) => st.sport_type === type)?.label
      : null;
  return label ? (
    <span className="sports-type-text">{label.toUpperCase()}</span>
  ) : null;
}
