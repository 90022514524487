import { useGetOpenFixtures } from "../../api/useGetOpenFixtures";
import { useGetTipgroup } from "../../api/useGetTipgroup";
import { useGetToday } from "../../api/useGetToday";
import { useEnvironment } from "../../Environment";
import { News } from "../../models/News";
import {
  conditions,
  faq,
  feedback,
  impressum,
  privacy,
  prizes,
  rules,
} from "../../shared/articleHelper";
import Card from "../common/Card";
import { QueryWrapper } from "../common/QueryWrapper";
import { CurrentMatchesHome } from "../home/CurrentMatchesHome";
import { LeaguesInTippgroup } from "../tipgroup/LeaguesInTippgroup";
import { TipGroupHeader } from "../tipgroup/TipGroupHeader";
import { OpenTipps } from "../tipgroup/OpenTipps";
import { E2BarSticky } from "../e2/E2Bar";

interface VereinsheimGroupHomeProps {
  groupId: string;
  groupName: string;
}

export function VereinsheimGroupHome({
  groupId,
  groupName,
}: VereinsheimGroupHomeProps) {
  const { data: groupData, ...groupQuery } = useGetTipgroup({
    groupId: groupId,
  });
  const { data: todayData, ...todayQuery } = useGetToday({
    props: { group_id: groupId },
  });
  const { data: openFixturesData, ...openFixturesQuery } = useGetOpenFixtures({
    props: { group_id: groupId },
  });
  return (
    <>
      <TipGroupHeader
        name={groupName}
        image={groupData?.data.image?.thumbnail_file_url}
        fullImage={groupData?.data.image?.full_file_url}
      />
      <QueryWrapper
        useQueryResult={[groupQuery, todayQuery, openFixturesQuery]}
      >
        {todayData && groupData ? (
          <LeaguesInTippgroup
            group={groupData.data}
            openFixtures={todayData.data}
          />
        ) : null}
        {openFixturesData && groupData ? (
          <OpenTipps
            group={groupData?.data}
            openFixtures={openFixturesData.data}
          />
        ) : null}
        {/* {groupData ? <TipGroupRoundResult group={groupData?.data} /> : null} */}
        {todayData && groupData ? (
          <CurrentMatchesHome
            todayData={todayData.data}
            groups={[groupData.data]}
          />
        ) : null}
        <Links />
        <E2BarSticky reset/>
      </QueryWrapper>
    </>
  );
}

function Links() {
  const newsData = [
    {
      title: "Spielregeln",
      url: rules().url,
    },
    {
      title: "Preise",
      url: prizes().url,
    },
    {
      title: "FAQ",
      url: faq().url,
    },
    {
      title: "Nutzungsbedingungen",
      url: conditions().url,
    },
    {
      title: "Impressum",
      url: impressum().url,
    },
    {
      title: "Datenschutz",
      url: privacy().url,
    },
    {
      title: "Feedback",
      url: feedback().url,
    },
  ];
  return <News newsList={newsData} />;
}

interface NewsProps {
  newsList: NewsElementProps[];
}

function News({ newsList }: NewsProps) {
  return (
    <Card labelText="Sonstiges">
      {newsList.map((news) => (
        <NewsElement title={news.title} url={news.url} key={news.url} />
      ))}
    </Card>
  );
}

interface NewsElementProps {
  url: string;
  title: string;
}

function NewsElement({ url, title }: NewsElementProps) {
  const env = useEnvironment();
  return (
    <div className="news-entry">
      <div
        className="nav-group-item"
        onClick={() => {
          env.replacerFunc && env.replacerFunc(url);
        }}
      >
        <div className="news-entry-title">{title}</div>
      </div>
    </div>
  );
}
