import Card from "../common/Card";

interface TipGroupDescriptionProps {
  description: string;
}

export function TipGroupDescription({ description }: TipGroupDescriptionProps) {
  return (
    <Card
      labelText="Beschreibung"
      contentClassName="flex flex-col space-y-4 text-center"
    >
      <p className="font-normal">{description}</p>
    </Card>
  );
}
