import { UrlQuery } from "./common/useBackendUrl";
import { useBackendQueryFunction } from "./common/useBackendQueryFunction";
import { useQuery } from "react-query";
import { TipUser } from "../models/TipUser";

export const GetTipgroupMembersKey = "GetTipgroupMembers";

interface GetTipgroupMembersData {
  data: TipUser[];
}

interface UseGetTipgroupMembersProps extends UrlQuery {
  groupId: string;
}

export function useGetTipgroupMembers(props: UseGetTipgroupMembersProps) {
  const queryFn = useBackendQueryFunction<GetTipgroupMembersData>(
    "/groups/:groupId/members",
    props,
    true
  );

  return useQuery<GetTipgroupMembersData>(
    [GetTipgroupMembersKey, props],
    queryFn
  );
}
