import { formattedDateTimeFull, formattedTime } from "../../shared/timeHelper";

interface MatchTimeProps {
  children: string;
  type?: "time" | "date-time";
}

export default function MatchTime({ children, type = "time" }: MatchTimeProps) {
  return (
    <div className="match-time">
      {`${
        type === "time"
          ? formattedTime(children)
          : formattedDateTimeFull(children)
      } Uhr`}
    </div>
  );
}
