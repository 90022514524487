import { useCallback } from "react";
import { useEnvironment } from "../Environment";
import { webviewReplacer } from "./urlHelper";
import { useReplacerCallback } from "./useReplacerCallback";

export function useAdClickCallback() {
  const env = useEnvironment();
  const replacerCallback = useReplacerCallback();
  return useCallback(
    (event: Event) => {
      event.preventDefault();
      const closestAnchorElement = (event?.target as HTMLElement).closest("a");
      const href = (closestAnchorElement as HTMLAnchorElement)?.href;
      if (href) {
        if (!env.isWebApp) {
          replacerCallback(webviewReplacer(href));
        } else {
          window.open(href, "_blank");
        }
      }
    },
    [env.isWebApp, replacerCallback]
  );
}
