import { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { useGetTipps } from "../../api/useGetTipps";
import {
  LocalTippValues,
  usePostTippWithDebounce,
} from "../../api/usePostTippWithDebounce";
import { useEnvironment } from "../../Environment";
import { League } from "../../models/League";
import { Match } from "../../models/Match";
import { UserTipp } from "../../models/UserTipp";
import { linkUrlProfile } from "../../shared/linkUrlHelper";
import { teamLogoPath, userImage } from "../../shared/urlHelper";
import BetMatch from "../common/BetMatch";
// import BetPresenter from "../common/BetPresenter";
import { ButtonWrapper, LinkButton } from "../common/Button";
import Card from "../common/Card";
import TeamLogo from "../common/TeamLogo";
import UserImage from "../common/UserImage";
import { DateMatchResult } from "./DateMatchResult";
import { DeleteTippButton } from "./DeleteTippButton";
import { MatchDetailLastThree } from "./MatchDetailLastThree";
import { MatchDetailPrediction as MatchDetailTendency } from "./MatchDetailTendency";
import { MatchDetailTippOverlay } from "./MatchDetailTippOverlay";
import { MatchInfoButton } from "./MatchInfoButton";
import { MatchRow } from "./MatchRow";

interface PreMatchDetailProps {
  match: Match;
  nextMatchUrl?: string;
  league: League;
  groupTipps?: UserTipp[];
}

export function PreMatchDetail({
  match,
  nextMatchUrl,
  league,
  groupTipps,
}: PreMatchDetailProps) {
  const [hasError, setHasError] = useState(false);
  const {
    responseData: {
      homeValue: responseValueHome,
      awayValue: responseValueAway,
      id: responseId,
    },
    localValues: { homeValue, awayValue, id: localValueId },
    setTipValues: setTipValues,
  } = usePostTippWithDebounce({
    matchId: match.id,
    leagueId: match.contest_id,
    roundNumber: match.round.number,
    onError: (error) => {
      if (error) {
        setHasError(true);
      }
    },
  });
  const setTipValuesWrapper = useCallback(
    (newValue: LocalTippValues) => {
      setTipValues(newValue);
      setHasError(false);
    },
    [setTipValues]
  );

  const { data: roundTippData } = useGetTipps({
    leagueId: match.contest_id,
    round_number: match.round.number,
  });
  const matchTippData = roundTippData?.data[match.id];
  const tippMode = roundTippData?.meta?.prediction_mode ?? "full";
  const remoteValueHome =
    responseId === match.id
      ? responseValueHome ?? matchTippData?.home_score
      : matchTippData?.home_score;
  const remoteValueAway =
    responseId === match.id
      ? responseValueAway ?? matchTippData?.away_score
      : matchTippData?.away_score;
  const [tippDeleted, setTippDeleted] = useState(false);
  return (
    <>
      {/* {league.id &&
        match.round.number &&
        league.state !== "GermanAmateurContest" && (
          <Card transparent>
            <BetPresenter leagueId={league.id} gameday={match.round.number} />
          </Card>
        )} */}
      <Card labelText="Jetzt tippen!" contentClassName="match-detail">
        <div>{`${league.name}, ${match.round.name}`}</div>

        <div className="match-row match-row-full match-row-detail">
          <div className="match-row-items">
            <div className="match-team-name match-team-name-left">
              <div className="match-team-name-content">
                <span className="match-team-name-text">
                  {match.homeTeam?.shortName ??
                    match.homeTeam?.name ??
                    match.homeTeamPlaceholder}
                </span>
                <div className="match-team-name-icon">
                  <TeamLogo
                    image={
                      match.homeTeam ? teamLogoPath(match.homeTeam?.id) : ""
                    }
                    name={match.homeTeam?.name || ""}
                  />
                </div>
              </div>
            </div>
            <DateMatchResult match={match} />
            <div className="match-team-name match-team-name-right">
              <div className="match-team-name-content">
                <span className="match-team-name-text">
                  {match.awayTeam?.shortName ??
                    match.awayTeam?.name ??
                    match.awayTeamPlaceholder}
                </span>
                <div className="match-team-name-icon">
                  <TeamLogo
                    image={
                      match.awayTeam ? teamLogoPath(match.awayTeam?.id) : ""
                    }
                    name={match.awayTeam?.name || ""}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <MatchDetailLastThree match={match} />
        {tippMode === "full" ? (
          <>
            {match.prediction_tendency ? (
              <MatchDetailTendency
                matchId={match.id}
                tendency={match.prediction_tendency}
                setTipValues={setTipValuesWrapper}
              />
            ) : null}
            <MatchDetailTippOverlay
              match={match}
              homeResult={
                !hasError && localValueId === match.id
                  ? homeValue ?? remoteValueHome
                  : remoteValueHome
              }
              awayResult={
                !hasError && localValueId === match.id
                  ? awayValue ?? remoteValueAway
                  : remoteValueAway
              }
              setTipValues={setTipValuesWrapper}
            />
          </>
        ) : (
          <MatchRow
            match={match}
            isMatchDetail={true}
            tippDeleted={tippDeleted}
            setTippDeleted={setTippDeleted}
          />
        )}
        {hasError && <p>Ein Fehler ist aufgetreten</p>}
        <div className="my-4">
          <BetMatch
            leagueId={league.id}
            gameday={match.round.number}
            matchId={match.id}
          />
        </div>
        {groupTipps && <AlsoTipped groupTipps={groupTipps} />}
        <ButtonWrapper>
          {tippMode === "simple" && matchTippData ? (
            <DeleteTippButton setTippDeleted={setTippDeleted} className={'order-last md:order-first'}/>
          ) : null}
          <MatchInfoButton matchId={match.id} />
          {nextMatchUrl && (
            <LinkButton to={nextMatchUrl} replace={true} color="primary">
              Nächstes Spiel tippen
            </LinkButton>
          )}
        </ButtonWrapper>
      </Card>
    </>
  );
}

interface AlsoTippedProps {
  groupTipps: UserTipp[];
}

function AlsoTipped({ groupTipps }: AlsoTippedProps) {
  const myUserId = useEnvironment().authState.user?.uuid;
  if (groupTipps.length === 0) {
    return null;
  }
  const groupTippsWithoutMe = groupTipps.filter(
    (user) => user.user.uuid !== myUserId
  );
  if (groupTippsWithoutMe.length === 0) {
    return null;
  }
  return (
    <div className="user-tips">
      <div className="user-tips-images">
        {groupTippsWithoutMe.slice(0, 3).map((tipp) => (
          <Link key={tipp.user.uuid} to={linkUrlProfile(tipp.user.uuid)}>
            <UserImage image={userImage(tipp.user)} name={tipp.user.username} />
          </Link>
        ))}
      </div>
      <div className="user-tips-text">
        {groupTippsWithoutMe.length <= 3 ? (
          <>
            <span className="font-bold">
              {groupTippsWithoutMe
                .map(
                  (tip, idx) =>
                    `${tip.user.username}${
                      idx !== groupTippsWithoutMe.length - 1
                        ? idx === groupTippsWithoutMe.length - 2
                          ? " und "
                          : ", "
                        : " "
                    }`
                )
                .join("")}
            </span>{" "}
            {groupTippsWithoutMe.length > 1 ? "haben" : "hat"} bereits getippt
          </>
        ) : (
          <>
            <span className="font-bold">
              {groupTippsWithoutMe
                .slice(0, 3)
                .map(
                  (tip, idx) => `${tip.user.username}${idx !== 2 ? ", " : " "}`
                )
                .join("")}
            </span>
            und {Math.max(groupTippsWithoutMe.length - 3, 0)} weitere haben
            bereits getippt
          </>
        )}
      </div>
    </div>
  );
}
