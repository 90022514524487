import { useBackendQueryFunction } from "./common/useBackendQueryFunction";
import { useQuery } from "react-query";
import { League } from "../models/League";

export const GetLeaguesKey = "GetLeagues";
interface LeagueData {
  data: League[];
}

export function useGetLeagues() {
  const queryFn = useBackendQueryFunction<LeagueData>("/contests");

  return useQuery<LeagueData>([GetLeaguesKey], queryFn);
}
