import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useGetGameday } from "../../api/useGetGameday";
import { useGetGamedays } from "../../api/useGetGamedays";
import { useGetLeague } from "../../api/useGetLeague";
import { useGetProfile } from "../../api/useGetProfile";
import { useGetTipgroup } from "../../api/useGetTipgroup";
import { useGetTipps } from "../../api/useGetTipps";
import {
  linkUrlProfileLeague,
  linkUrlTipGroupLeagueRanking,
} from "../../shared/linkUrlHelper";
import { leagueLogoPath } from "../../shared/urlHelper";
import { ButtonWrapper, LinkButton } from "../common/Button";
import Card from "../common/Card";
import { ErrorCard } from "../common/ErrorCard";
import { QueryWrapper } from "../common/QueryWrapper";
import { E2BarSticky } from "../e2/E2Bar";
import { useE2Tipps } from "../e2/E2TippsContext";
import { Gameday } from "../gameday/Gameday";
import { GamedayChooserHeader } from "../gameday/GamedayChooserHeader";
import { ProfileHeader } from "./ProfileHeader";

interface ProfileLeagueProps {
  userId: string;
  roundNumber: number;
  leagueId: string;
  leagueName: string;
  tipgroupId?: string;
}

export function ProfileLeague({
  userId,
  roundNumber,
  leagueId,
  leagueName,
  tipgroupId,
}: ProfileLeagueProps) {
  const { setE2Data } = useE2Tipps();
  const navigate = useNavigate();
  const { data: profileData, ...profileQuery } = useGetProfile({
    uuid: userId,
  });
  const { data: leagueData, ...leagueQuery } = useGetLeague({
    leagueId: leagueId,
  });
  const league = leagueData?.data;
  const { data: roundsData, ...roundsQuery } = useGetGamedays({
    leagueId,
  });
  const gameday = roundsData?.data.find(
    (gameday) => gameday.number === roundNumber
  );
  const { data: tippData, ...tippQuery } = useGetTipps({
    leagueId: leagueId,
    round_number: roundNumber,
    user_id: userId,
  });
  const { data: gamedayData, ...gamedayQuery } = useGetGameday({
    leagueId: leagueId,
    round_number: roundNumber,
  });

  useEffect(() => {
    if (gamedayData && tippData) {
      setE2Data({
        gamedays: gamedayData.data,
        tipps: tippData.data,
        tippMode: tippData?.meta?.prediction_mode ?? "full",
      });
    }
  }, [gamedayData, tippData, setE2Data]);

  return (
    <>
      <ProfileHeader
        profile={profileData?.data}
        sublabel={leagueName}
        sublabelIcon={leagueLogoPath(leagueId)}
      />

      <QueryWrapper
        useQueryResult={[
          profileQuery,
          leagueQuery,
          roundsQuery,
          tippQuery,
          gamedayQuery,
        ]}
      >
        {roundsData && league && gameday && (
          <GamedayChooserHeader
            gameday={gameday}
            rounds={roundsData.data}
            navigate={(round: number) =>
              navigate(
                linkUrlProfileLeague(
                  league.id,
                  league.name,
                  round,
                  userId,
                  tipgroupId
                ),
                {
                  replace: true,
                }
              )
            }
          />
        )}
        {league && gamedayData && tippData ? (
          <>
            <Gameday
              leagueId={leagueId}
              leagueName={league.name}
              roundNumber={roundNumber}
              gamedays={gamedayData.data}
              tipps={tippData.data}
              isAmateurLeague={league.state === "GermanAmateurContest"}
              otherUserId={userId}
            />
            {tipgroupId && (
              <ProfileLeagueGroupButton
                leagueId={leagueId}
                roundNumber={roundNumber}
                tipgroupId={tipgroupId}
                leagueName={leagueName}
              />
            )}
          </>
        ) : (
          <ErrorCard type="empty" />
        )}
        <E2BarSticky reset roundNumber={roundNumber} />
      </QueryWrapper>
    </>
  );
}

interface ProfileLeagueGroupButtonProps {
  tipgroupId: string;
  leagueId: string;
  leagueName: string;
  roundNumber: number;
}

function ProfileLeagueGroupButton({
  tipgroupId,
  leagueId,
  leagueName,
  roundNumber,
}: ProfileLeagueGroupButtonProps) {
  const { data: tipgroupData } = useGetTipgroup({ groupId: tipgroupId });
  return tipgroupData ? (
    <Card transparent>
      <div className="btn-container">
        <ButtonWrapper>
          <LinkButton
            color="primary"
            to={linkUrlTipGroupLeagueRanking(
              tipgroupId,
              tipgroupData.data.name,
              leagueId,
              leagueName,
              roundNumber,
              "round"
            )}
          >
            Zum {tipgroupData.data.name} Ranking
          </LinkButton>
        </ButtonWrapper>
      </div>
    </Card>
  ) : null;
}
