import { useCallback, useEffect, useMemo, useState } from "react";
import Klodeckel from "../../assets/icons/klodeckel.svg";

type TeamLogoSize = "sm" | "md" | "lg";

interface TeamLogoProps {
  image: string;
  name: string;
  size?: TeamLogoSize;
}

export default function TeamLogo({ image, name, size = "md" }: TeamLogoProps) {
  const [imageWorks, setImageWorks] = useState(true);
  const [imageWidth, setImageWidth] = useState(0);
  const img = useMemo(() => new Image(), []);

  const onLoad = useCallback(() => {
    setImageWidth(img.naturalWidth);
  }, [img.naturalWidth]);

  useEffect(() => {
    img.src = image;
    img.onload = onLoad;
    return () => {
      img.onload = null;
      img.src = "";
    };
  }, [image, img, onLoad]);

  return imageWorks && imageWidth > 1 ? (
    <img
      src={image}
      onError={() => setImageWorks(false)}
      className={`team-logo team-logo-${size}`}
      alt={`Logo ${name}`}
    />
  ) : (
    <Klodeckel className={`team-logo team-logo-${size}`} />
    // <div
    //   className={`team-logo-initials team-logo-initials-${size}`}
    //   title={`Logo ${name}`}
    //   data-color={stringToColour(name)}
    // >
    //   <span className="team-logo-initial">{getInitial(name)}</span>
    // </div>
  );
}
