import { useParams } from "react-router";
import { TipGroupList } from "../components/tipgroup/TipGroupList";
import { TipGroupListParamKeys } from "../models/ParamKeys";

export function VereinsheimGroupListPage() {
  const params = useParams<TipGroupListParamKeys>();
  return (
    <TipGroupList
      leagueId={params.leagueId}
      leagueName={params.leagueName}
      matchId={params.matchId}
      roundNumber={
        params.roundNumber ? Number.parseInt(params.roundNumber) : undefined
      }
      type="vereinsheim"
    />
  );
}
