interface OddsServe {
  (
    contentUnitId: number,
    competition: string,
    matchday: number | null,
    match: string | null,
    node: HTMLSpanElement | null
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queue?: any[];
  options?: Record<string, string | number>;
}

export const oddsServe: OddsServe = function (
  contentUnitId: number,
  competition: string,
  matchday: number | null,
  match: string | null,
  node: HTMLSpanElement | null
) {
  if (!node) {
    const s =
      document.readyState === "loading"
        ? document.getElementsByTagName("script")
        : [];
    oddsServe.queue?.push({
      contentUnitId: contentUnitId,
      competition: competition,
      matchday: matchday,
      match: match,
      node: s.length ? s[s.length - 1].parentNode : null,
    });
  } else {
    oddsServe.queue?.push({
      contentUnitId: contentUnitId,
      competition: competition,
      matchday: matchday,
      match: match,
      node: node,
    });
  }
};

oddsServe.queue = [];

export function loadOddsServeScript(consent: string, darkmode: boolean) {
  oddsServe.options = {
    "cp-theme": darkmode ? "dark" : "light",
    gdpr: 1,
    gdpr_pd: 1,
    gdpr_consent: consent,
  };
  loadScript(
    "creative-cdn.oddsserve.com/unit/loader.js?publisher=kicker",
    true
  );
}

export function loadE2Script(
  consent: string,
  isLarge: boolean,
  setLoaded: (v: boolean) => void
) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
  const adition = (window as any).adition;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  adition.srq = adition.srq ?? [];
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-explicit-any
  adition.srq.push(function (api: any) {
    // console.log("loading e2");
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    api.registerAdfarm("ad13.adfarm1.adition.com");
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    api.consentService.setGdpr(1).setGdprPd(1).setGdprConsent(consent);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    api
      .configureRenderSlot("renderSlot")
      .setContentunitId(isLarge ? 4813960 : 4787452);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    api.renderSlot("renderSlot");
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    api.load(["renderSlot"]);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    api.completeRendering();
  });
  loadScript("imagesrv.adition.com/js/aut.js", true);
  loadScript("imagesrv.adition.com/js/srp.js", true);
  // TODO: find a better way?
  setTimeout(() => setLoaded(true), 2000);
}

function loadScript(src: string, async: boolean) {
  const url =
    (document.location.protocol === "https:" ? "https" : "http") + "://" + src;
  let script: HTMLScriptElement | null = document.querySelector(
    `script[src="${url}"]`
  );

  if (!script) {
    script = document.createElement("script");
    script.src = url;
    script.async = async;
    const head = document.getElementsByTagName("head")[0];
    head.appendChild(script);
  }
}
