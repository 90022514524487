import { useQuery } from "react-query";
import { MetaData } from "../models/MetaData";
import { UserTipp } from "../models/UserTipp";
import { useBackendQueryFunction } from "./common/useBackendQueryFunction";
import { UrlQuery } from "./common/useBackendUrl";

interface GetGroupTippsData {
  meta: MetaData;
  data: UserTipp[];
}

interface UseGetGroupTippsProps extends UrlQuery {
  fixtureId: string;
  groupId: string;
}

export function useGetGroupTipps(props: UseGetGroupTippsProps) {
  const queryFn = useBackendQueryFunction<GetGroupTippsData>(
    "/predictions/fixture/:fixtureId/group/:groupId",
    props,
    true
  );

  return useQuery<GetGroupTippsData>(["GetGroupTipps"], queryFn);
}
