interface Article {
  url: string;
  id: string;
}

export const rules = () =>
  ({
    url: "https://www.kicker.de/die-offiziellen-spielregeln-im-kicker-tippspiel-912038/artikel",
    id: "912038",
  } as Article);
export const prizes = () =>
  ({ url: "https://www.kicker.de/1030976/artikel", id: "1030976" } as Article);
export const faq = () =>
  ({ url: "https://www.kicker.de/912040/artikel", id: "912040" } as Article);
export const conditions = () =>
  ({ url: "https://www.kicker.de/644235/artikel", id: "644235" } as Article);
export const impressum = () =>
  ({ url: "https://www.kicker.de/316443/artikel", id: "316443" } as Article);
export const privacy = () =>
  ({ url: "https://www.kicker.de/513381/artikel", id: "513381" } as Article);
export const feedback = () =>
  ({ url: "https://www.kicker.de/774569/artikel", id: "774569" } as Article);
