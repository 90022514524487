import { ProfileUser } from "../models/Profile";
import { TipUser } from "../models/TipUser";

export const teamLogoPath = (teamId: string): string =>
  `https://ovsyndication.kicker.de/IMAGE/whiteteamlogo/xl/0/${teamId}`;

export const leagueLogoPath = (leagueId: string): string =>
  `https://ovsyndication.kicker.de/IMAGE/darkleaguelogo/xl/0/${leagueId}`;

export const randomImage = (): string =>
  `https://source.unsplash.com/random/300x200?sig=${Math.floor(Math.random())}`;

export const groupImage = (): string =>
  `https://kicker.de/getuserimage?personid=928897`;

export const userImage = (user: TipUser | ProfileUser): string =>
  `https://kicker.de/getuserimage?personid=${user.person_id}`;

export const matchReplacer = (matchId: string): string => `@@sppa:${matchId}@@`;

export const webviewReplacer = (url: string): string => `@@webview:${url}@@`;
