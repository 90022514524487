import { Match } from "../../models/Match";
import { formattedDateTimeFull } from "../../shared/timeHelper";
import Card from "../common/Card";
import List, { ListItem, ListRow } from "../list/List";
import { MatchRow } from "./MatchRow";
import { Label } from "../common/Label";
import { PostMatchRanking } from "../ranking/Ranking";
import SectionHeader from "../common/SectionHeader";
import { ButtonWrapper } from "../common/Button";
import { MatchInfoButton } from "./MatchInfoButton";
interface PostMatchDetailProps {
  match: Match;
  roundNumber: number;
  leagueId: string;
  groupId?: string;
}

interface PostMatchTendencyProps {
  match: Match;
}

export function PostMatchDetail({
  match,
  roundNumber,
  leagueId,
  groupId,
}: PostMatchDetailProps) {
  const button = <MatchInfoButton matchId={match.id} />;
  return (
    <>
      <Card labelText="Tipp-Auswertung" contentPadding="match-row">
        <SectionHeader>{formattedDateTimeFull(match.kickoff)}</SectionHeader>
        <MatchRow match={match} isMatchDetail={true} />
      </Card>
      {groupId ? (
        <PostMatchRanking
          fixtureId={match.id}
          groupId={groupId}
          leagueId={leagueId}
          roundNumber={roundNumber}
        />
      ) : (
        <PostMatchTendency match={match} />
      )}
      {button ? (
        <Card transparent>
          <ButtonWrapper>{button}</ButtonWrapper>
        </Card>
      ) : null}
    </>
  );
}

function PostMatchTendency({ match }: PostMatchTendencyProps) {
  if (match.prediction_tendency?.full) {
    return (
      <>
        <Card labelText="Tipp-Tendenzen">
          <List>
            {Object.entries(match.prediction_tendency?.full).map(
              (entry, index) => {
                return (
                  <ListRow key={index}>
                    <ListItem className="">
                      {(entry[1].rate * 100).toFixed()}%
                    </ListItem>
                    <ListItem>{entry[0]}</ListItem>
                    {entry[1].score && entry[1].score !== 0 ? (
                      <ListItem>
                        <Label type="correct-tendency">
                          +{entry[1].score} Pkt
                        </Label>
                      </ListItem>
                    ) : null}
                  </ListRow>
                );
              }
            )}
          </List>
        </Card>
      </>
    );
  }
  return null;
}
