import React from "react";
import { useGetSportTypes } from "../../api/useGetSportTypes";
import { LeagueData } from "../../api/useGetTopLeagues";
import { League } from "../../models/League";
import { getSportTypePriority } from "../../models/SportType";
import { useLeagueListOtherLeagues } from "../../shared/useLeagueListOtherLeagues";
import { useSportsTypeHeaderAndSort } from "../../shared/useSportsTypeHeaderAndSort";
import { SportTypeHeader } from "../home/SportTypeHeader";
import { LeagueList, LeagueListLeague } from "../leaguelist/LeagueList";
import { AddLeagueUiState } from "./AddLeague";

interface TipGroupAdminLeagueListProps {
  leagues: League[];
  topLeaguesData: LeagueData | undefined;
  addLeague: (league: League) => void;
  removeLeague: (id: string) => void;
  setUiState: (uiState: AddLeagueUiState) => void;
}

export function TipGroupAdminLeagueList({
  leagues,
  topLeaguesData,
  addLeague,
  removeLeague,
  setUiState,
}: TipGroupAdminLeagueListProps) {
  const { data: sportTypesData } = useGetSportTypes();
  const addSportsTypeHeaders = useSportsTypeHeaderAndSort(leagues);
  const addedLeagues =
    leagues.length > 0 ? (
      <LeagueList
        type="added"
        sections={[
          {
            elements: leagues.map((l, index) => {
              return (
                <>
                  {addSportsTypeHeaders &&
                    getSportTypePriority(l.sportType) !==
                      getSportTypePriority(
                        leagues.at(index - 1)?.sportType
                      ) && <SportTypeHeader type={l.sportType} />}
                  <LeagueListLeague
                    key={l.id}
                    league={l}
                    type={leagues.length > 1 ? "remove" : ""}
                    addLeague={addLeague}
                    removeLeague={removeLeague}
                    uiMode="create"
                  />
                </>
              );
            }),
          },
        ]}
      />
    ) : null;

  const topLeagues =
    topLeaguesData && topLeaguesData.data.length > 0
      ? {
          header: "Beliebte Wettbewerbe",
          elements: topLeaguesData.data
            // filter out the leagues the user already added
            .filter(
              (topLeague) =>
                !leagues.some((addedLeague) => topLeague.id === addedLeague.id)
            )
            .map((league) => (
              <LeagueListLeague
                key={league.id}
                league={league}
                type={"add"}
                addLeague={addLeague}
                removeLeague={removeLeague}
                uiMode="create"
              />
            )),
        }
      : null;

  const otherLeagues = useLeagueListOtherLeagues({
    setUiState,
    sportTypesData,
  });

  return (
    <React.Fragment>
      {addedLeagues}
      {topLeagues ? (
        <LeagueList type="mixed" sections={[topLeagues, otherLeagues]} />
      ) : (
        <LeagueList type="mixed" sections={[otherLeagues]} />
      )}
    </React.Fragment>
  );
}
