import React, { ReactNode } from "react";
import { useNavigate } from "react-router";
import { League } from "../../models/League";
import { SportTypeType } from "../../models/SportType";
import { linkUrlLeague } from "../../shared/linkUrlHelper";
import { leagueLogoPath } from "../../shared/urlHelper";
import { NextButton } from "../common/Button";
import Card from "../common/Card";
import { ErrorCard } from "../common/ErrorCard";
import LeagueImage from "../common/LeagueImage";
import SectionHeader from "../common/SectionHeader";
import List, { ListItem, ListRow } from "../list/List";
import { AddLeagueUiState } from "../tipgroup/AddLeague";

export interface LeagueListSection {
  header?: string;
  elements: ReactNode[];
}

export type LeagueListType =
  | "leaguelist"
  | "mixed"
  | "added"
  | "top"
  | "international"
  | "amateur"
  | "amateur-associations"
  | "amateur-teamtypes"
  | "amateur-leaguetypes"
  | "amateur-teams";

interface LeagueListAmateurParams {
  association?: string;
  teamtype?: string;
  leaguetype?: string;
}

interface LeagueListProps {
  type: LeagueListType;
  sections: LeagueListSection[];
  label?: string;
}

function _getLabelForType(type: LeagueListType) {
  if (type === "mixed") {
    return "Wettbewerbe der Gruppe festlegen";
  } else if (type === "added") {
    return "Hinzugefügte Wettbewerbe";
  } else if (type === "international") {
    return "Profis";
  } else if (type === "amateur-associations") {
    return "Amateure";
  }
}

function getActionButton(type: LeagueListLeagueType) {
  switch (type) {
    case "added":
      return (
        <ListItem>
          <span className="fake-link">Bereits hinzugefügt</span>
        </ListItem>
      );
    case "add":
      return (
        <ListItem>
          <span className="fake-link">Hinzufügen</span>
        </ListItem>
      );
    case "remove":
      return (
        <ListItem>
          <span className="fake-link">Entfernen</span>
        </ListItem>
      );
    default:
      return <React.Fragment></React.Fragment>;
  }
}

export function LeagueList({ type, sections, label }: LeagueListProps) {
  if (sections.length === 0) {
    return <ErrorCard type="empty" />;
  }
  return (
    <Card labelText={label ?? _getLabelForType(type)}>
      {sections.map((section, idx) => {
        return (
          <React.Fragment key={section.header ?? idx}>
            {section.header ? (
              <SectionHeader>{section.header}</SectionHeader>
            ) : null}
            <List>{section.elements}</List>
          </React.Fragment>
        );
      })}
    </Card>
  );
}

interface LeagueListLeagueProps {
  league: League;
  type: LeagueListLeagueType;
  uiMode: LeagueListLeagueUiMode;
  addLeague?: (league: League) => void;
  removeLeague?: (leagueId: string) => void;
  onClick?: (leagueId: string) => void;
}
export type LeagueListLeagueUiMode = "create" | "leaguelist" | "tipnow";
export type LeagueListLeagueType = "add" | "remove" | "added" | "";

export function LeagueListLeague({
  league,
  type,
  uiMode,
  addLeague = () => null,
  removeLeague = () => null,
}: LeagueListLeagueProps) {
  const navigate = useNavigate();

  return (
    <List
      onClick={() => {
        if (uiMode === "create") {
          if (type === "add") {
            return addLeague(league);
          } else if (type === "remove") {
            return removeLeague(league.id);
          } else return undefined;
        } else {
          return navigate(
            linkUrlLeague(league.id, league.name, league.currentRound.number),
            { replace: uiMode === "leaguelist" }
          );
        }
      }}
    >
      <ListRow key={league.id}>
        <ListItem>
          <LeagueImage image={leagueLogoPath(league.id)} name={league.name} />
        </ListItem>
        <ListItem>{league.name}</ListItem>
        {uiMode === "create" ? (
          getActionButton(type)
        ) : uiMode === "tipnow" ? (
          <ListItem>
            <span className="fake-link">WETTBEWERB TIPPEN</span>
          </ListItem>
        ) : undefined}
      </ListRow>
    </List>
  );
}

interface LeagueListLinkProps {
  title: string;
  uiState: AddLeagueUiState;
  setUiState: (uiState: AddLeagueUiState, sportType?: SportTypeType) => void;
  sportType?: SportTypeType;
  params?: LeagueListAmateurParams;
}

export function LeagueListLink({
  title,
  setUiState,
  uiState,
  sportType,
}: LeagueListLinkProps) {
  return (
    <NextButton onClick={() => setUiState(uiState, sportType)}>
      {title}
    </NextButton>
  );
}
