import { UrlQuery } from "./common/useBackendUrl";
import { useBackendQueryFunction } from "./common/useBackendQueryFunction";
import { useQuery } from "react-query";
import { UserRanking } from "../models/UserRanking";
import { MetaData } from "../models/MetaData";

interface GetTipgroupRankingSeasonData {
  data: UserRanking[];
  meta: MetaData;
}

interface UseGetTipgroupRankingSeasonProps extends UrlQuery {
  groupId: string;
  leagueId: string;
  upto_round_number?: number;
}

export function useGetTipgroupRankingSeason(
  props: UseGetTipgroupRankingSeasonProps
) {
  const queryFn = useBackendQueryFunction<GetTipgroupRankingSeasonData>(
    "/rankings/season/:leagueId/group/:groupId",
    props,
    true
  );

  return useQuery<GetTipgroupRankingSeasonData>(
    ["useGetTipgroupRankingSeason", props],
    queryFn
  );
}
