import React, { useRef } from "react";
import { createPortal } from "react-dom";
import { useOnClickOutside } from "../../shared/onClickOutside";
import Modal from "../modal/Modal";
import { CropImage } from "./CropImage";

interface CropImageModalProps {
  isOpen: boolean;
  canCrop?: boolean;
  handleImageSaveEvent?: () => void;
  handleCloseModal?: () => void;
  image: string;
  setImageFormData?: (image: FormData) => void;
  setBase64Image?: (image: string) => void;
}

export function CropImageModal({
  isOpen,
  canCrop,
  handleImageSaveEvent,
  handleCloseModal,
  setImageFormData,
  setBase64Image,
  image,
}: CropImageModalProps) {
  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, handleCloseModal ? handleCloseModal : () => undefined);

  return createPortal(
    <Modal isOpen={isOpen} handleCloseModal={handleCloseModal}>
      <div ref={ref} className="kicker-tippspiel bg-transparent py-0">
        {canCrop ? (
          <CropImage
            handleCloseModal={handleCloseModal}
            setBase64Image={setBase64Image}
            setImageFormData={setImageFormData}
            handleImageSaveEvent={handleImageSaveEvent}
            image={image}
          />
        ) : (
          <img className={"max-w-full"} src={image} alt={image} />
        )}
      </div>
    </Modal>,
    //TODO: why not only #root?
    document.querySelector("#root") ??
      document.querySelector(".kicker-tippspiel-container-web") ??
      document.querySelector(".kicker-tippspiel-container") ??
      document.body
  );
}
