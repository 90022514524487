import { ReactNode } from "react";
import { Link } from "react-router-dom";

export type LabelType =
  | "correct-difference"
  | "correct-score"
  | "correct-tendency"
  | "incorrect"
  | "input"
  | "no-tip"
  | "name"
  | "card"
  | "card-attention";

export interface LabelProps {
  children: ReactNode;
  type: LabelType;
  side?: boolean;
  linkTo?: string;
  className?: string;
  size?: "normal" | "medium" | "small";
  checkmark?: boolean;
  isOtherUser?: boolean;
}

interface LabelImageProps {
  url?: string;
}

export function LabelImage({ url }: LabelImageProps) {
  if (url) return <img className="label-image" src={url} />;
  else return null;
}

interface LabelTextProps {
  children: string;
}

export function LabelText({ children }: LabelTextProps) {
  return <span className="label-text">{children}</span>;
}

export function Label({
  children,
  type,
  side,
  linkTo,
  size = "normal",
  className = "",
  checkmark,
  isOtherUser = false,
}: LabelProps) {
  const label = (
    <div
      className={`
      label 
      label-${type} 
      ${side ? "label-side" : ""} 
      ${checkmark ? "label-checkmark" : ""} 
      label-${size} 
      ${className}
      ${isOtherUser ? "label-isOtherUser" : ""}
      `}
    >
      <div className="label-content">{children}</div>
    </div>
  );
  return linkTo ? <Link to={linkTo}>{label}</Link> : label;
}
