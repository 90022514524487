import { ProfileRankingsLeague } from "../../models/ProfileRankings";
import { linkUrlProfileLeague } from "../../shared/linkUrlHelper";
import { leagueLogoPath } from "../../shared/urlHelper";
import Card from "../common/Card";
import LeagueImage from "../common/LeagueImage";
import List from "../list/List";
import { ListElement, RankedListElement } from "../list/ListElement";

interface ProfileLeaguesProps {
  userId: string;
  otherLeagues?: ProfileRankingsLeague[];
  sharesTipgroups: boolean;
}

export function ProfileLeagues({
  userId,
  otherLeagues,
  sharesTipgroups,
}: ProfileLeaguesProps) {
  return otherLeagues && otherLeagues.length > 0 ? (
    <Card labelText={sharesTipgroups ? "Weitere Ligen" : "Ligen"}>
      <List>
        <ListElement name={<small>WETTBEWERB</small>} endComponent={<small>PLATZ</small>} linkUrl={'#'} isLightHeadline noPaddingName></ListElement>
        {otherLeagues.map((contest) => {
          const league = contest.contest;
          const icon = (
            <LeagueImage image={leagueLogoPath(league.id)} name={league.name} />
          );
          return (
            <RankedListElement
              key={league.id}
              icon={icon}
              linkUrl={linkUrlProfileLeague(
                league.id,
                league.name,
                league.currentRound.number,
                userId
              )}
              name={league.name}
              rank={contest.score?.rank}
              count={contest.score?.participant_count}
            />
          );
        })}
      </List>
    </Card>
  ) : null;
}
