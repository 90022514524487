import { ApiError } from "./ApiError";
import { ExtendedEnvironmentProps } from "../../Environment";
import { getJwtToken } from "./useGetJwtToken";
import { getAuthUser } from "./useGetAuthUser";
import { ErrorResponse } from "../../models/ErrorResponse";
import { jwtExpiresSoon } from "./useCheckJwtExpiresSoon";

// let tokenFetchPromise: Promise<string | undefined> | undefined = undefined;

export async function fetchWithJwtToken<T>(
  url: string,
  authUrl: string,
  env: ExtendedEnvironmentProps,
  init?: RequestInit
) {
  if (env.externalJwtToken) {
    if (!env.authState.user) {
      const user = await getAuthUser(authUrl, env.externalJwtToken);
      env.setAuthState((props) => {
        return { ...props, user: user };
      });
    }
    return fetchWithJwtTokenInternal<T>(
      env.externalJwtToken,
      url,
      authUrl,
      env,
      init
    );
  }
  // try {
  let token: string | undefined = env.authState.jwt;
  const needJwtRefetch = jwtExpiresSoon({ token: token });
  if ((!token || needJwtRefetch) && env.ssoId) {
    // we should always have a token in the environment but check anyway
    token = await getJwtToken(env.ssoId).then(function (token) {
      env.setAuthState((props) => {
        return { ...props, jwt: token };
      });
      return token;
    });
  }

  if (!env.authState.user && token) {
    const user = await getAuthUser(authUrl, token);
    env.setAuthState((props) => {
      return { ...props, user: user };
    });
  }
  return fetchWithJwtTokenInternal<T>(token ?? "", url, authUrl, env, init);
}

async function fetchWithJwtTokenInternal<T>(
  token: string,
  url: string,
  authUrl: string,
  env: ExtendedEnvironmentProps,
  init?: RequestInit
) {
  let response = await fetch(url, {
    ...init,
    headers: {
      ...init?.headers,
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok && response.status === 403 && env.ssoId) {
    console.error("Request not authorized. Getting new Jwt and retrying...");
    token = await getJwtToken(env.ssoId);
    const user = await getAuthUser(authUrl, token);
    env.setAuthState((props) => {
      return { ...props, user: user, jwt: token };
    });
    response = await fetch(url, {
      ...init,
      headers: {
        ...init?.headers,
        Authorization: `Bearer ${token}`,
      },
    });
  }

  if (!response.ok) {
    const error: ErrorResponse = (await response.json()) as ErrorResponse;
    throw new ApiError(response.status, error.short_code, error.description);
  }

  //empty response
  if (response.status == 204) {
    return {} as T;
  }

  return (await response.json()) as T;
}
