import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { LoadingIndicator } from "./components/common/LoadingIndicator";
import { AuthState, useAuthState } from "./api/common/useAuthState";
import { useSkipFirstEffect } from "./shared/useSkipFirstEffect";

interface EnvironmentProps {
  ssoId?: string;
  deeplinkUrl: string;
  useDarkMode: boolean;
  disableAds: boolean;
  trackingFunc?: (action: string, leagueId?: string) => void;
  replacerFunc?: (replacer: string) => void;
  reloadFunc?: () => void; // TODO: remove?
  closeFunc?: () => void;
  pushesFunc?: (uuid: string | null) => void;
  externalJwtToken?: string;
  isVereinsheim: boolean;
  isWebApp: boolean;
  consent: string;
  pushes?: boolean;
  registerPushes?: boolean;
}

export interface ExtendedEnvironmentProps extends EnvironmentProps {
  authState: AuthState;
  setAuthState: Dispatch<SetStateAction<AuthState>>;
  setPushesToggle: Dispatch<SetStateAction<boolean>>;
  pushesToggle: boolean;
}

const EnvironmentContext = React.createContext<
  ExtendedEnvironmentProps | undefined
>(undefined);

export function Environment({
  children,
  ...envProps
}: PropsWithChildren<EnvironmentProps>) {
  const [authState, setAuthState] = useAuthState(
    envProps.ssoId,
    envProps.externalJwtToken
  );
  const [pushesToggle, setPushesToggle] = useState<boolean>(
    envProps.pushes ?? false
  );

  useEffect(() => {
    if (
      envProps.registerPushes &&
      envProps.pushesFunc &&
      authState.user?.uuid
    ) {
      envProps.pushesFunc(authState.user.uuid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [envProps.registerPushes, envProps.pushesFunc, authState]);

  useSkipFirstEffect(() => {
    if (envProps.pushesFunc) {
      envProps.pushesFunc(pushesToggle ? authState.user?.uuid ?? null : null);
    }
  }, [envProps.pushesFunc, pushesToggle]);

  const [extendedEnvProps, setExtendedEnvProps] =
    useState<ExtendedEnvironmentProps>({
      ...envProps,
      authState,
      setAuthState,
      pushesToggle,
      setPushesToggle,
    });

  useEffect(() => {
    setExtendedEnvProps(
      (props) =>
        (props = {
          ...props,
          authState: authState,
          setAuthState: setAuthState,
          pushesToggle: pushesToggle,
          setPushesToggle: setPushesToggle,
        })
    );
  }, [authState, setAuthState, pushesToggle, setPushesToggle]);

  if (authState.state !== "authorized") {
    return <LoadingIndicator />;
  } else {
    return (
      <EnvironmentContext.Provider value={extendedEnvProps}>
        {children}
      </EnvironmentContext.Provider>
    );
  }
}

export function useEnvironment() {
  const env = useContext(EnvironmentContext);
  if (env) {
    return env;
  } else throw new Error("Environment not initialized");
}
