import BasketballIcon from "../assets/sportTypes/Basketball.png";
import FootballIcon from "../assets/sportTypes/Football.png";
import EishockeyIcon from "../assets/sportTypes/Eishockey.png";
import FussballIcon from "../assets/sportTypes/Fussball.png";
import HandballIcon from "../assets/sportTypes/Handball.png";
export interface SportType {
  sport_type: SportTypeType;
  label: string;
}

export type SportTypeType =
  | "Basketball"
  | "Football"
  | "Handball"
  | "IceHockey"
  | "Soccer";

export function getSportTypePriority(type?: SportTypeType) {
  switch (type) {
    case "Soccer":
      return 1;
    case "IceHockey":
      return 2;
    case "Basketball":
      return 3;
    case "Handball":
      return 4;
    case "Football":
      return 5;
    default:
      // this should never happen normally, but right now a lot of soccer leagues are sportType: undefined!!
      return 1;
  }
}

export function getImageUrlForSportType(type: SportTypeType) {
  switch (type) {
    case "Soccer":
      return FussballIcon;
    case "IceHockey":
      return EishockeyIcon;
    case "Basketball":
      return BasketballIcon;
    case "Handball":
      return HandballIcon;
    case "Football":
      return FootballIcon;
    default:
      // this should never happen normally, but right now a lot of soccer leagues are sportType: undefined!!
      return FussballIcon;
  }
}
