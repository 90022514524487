import { useParams } from "react-router";
import { useGetTipgroup } from "../../api/useGetTipgroup";
import { useGetTipgroupMembers } from "../../api/useGetTipgroupMembers";
import { ErrorCard } from "../../components/common/ErrorCard";
import { QueryWrapper } from "../../components/common/QueryWrapper";
import { E2BarSticky } from "../../components/e2/E2Bar";
import { TipGroupHeader } from "../../components/tipgroup/TipGroupHeader";
import { TipGroupHomePageParamKeys } from "../../models/ParamKeys";
import { Error404Page } from "../Error404Page";
import { MemberList } from "./TipGroupHomePage";

export function TipGroupMemberPage() {
  const params = useParams<TipGroupHomePageParamKeys>();
  return params.tipGroupId && params.tipGroupName ? (
    <TipGroupMembers
      tipGroupId={params.tipGroupId}
      tipGroupName={params.tipGroupName}
    />
  ) : (
    <Error404Page />
  );
}

interface TipGroupMembersProps {
  tipGroupId: string;
  tipGroupName: string;
}
function TipGroupMembers({ tipGroupId, tipGroupName }: TipGroupMembersProps) {
  const { data: membersData, ...membersQuery } = useGetTipgroupMembers({
    groupId: tipGroupId,
  });
  const { data: tipgroupData } = useGetTipgroup({
    groupId: tipGroupId,
  });

  return (
    <>
      <TipGroupHeader
        name={tipGroupName}
        image={tipgroupData?.data.image?.thumbnail_file_url}
        fullImage={tipgroupData?.data.image?.full_file_url}
      />
      <QueryWrapper useQueryResult={[membersQuery]}>
        {membersData ? (
          <MemberList
            members={membersData.data}
            groupId={tipGroupId}
            groupName={tipGroupName}
            isPage={true}
          />
        ) : (
          <ErrorCard type="empty" />
        )}
      </QueryWrapper>
      <E2BarSticky reset roundNumber={0} />
    </>
  );
}
