import { useCallback, useEffect, useRef, useState } from "react";
import { useEnvironment } from "../Environment";
import { trackTipp } from "./common/tracking";
import { useDeleteTipp } from "./useDeleteTipp";
import { PostTippProps, usePostTipp } from "./usePostTipp";

interface UsePostTippWithDebounceProps {
  matchId: string;
  leagueId: string;
  roundNumber: number;
  onError?: (error: unknown) => void;
}

export interface LocalTippValues {
  homeValue: number | undefined;
  awayValue: number | undefined;
  id: string;
}

export function usePostTippWithDebounce({
  matchId,
  leagueId,
  roundNumber,
  onError,
}: UsePostTippWithDebounceProps) {
  const trackingFunc = useEnvironment().trackingFunc;
  const { data: postTippData, mutate: postTipps } = usePostTipp({
    onError,
  } as PostTippProps);
  const { mutate: deleteTipp } = useDeleteTipp({
    props: { matchId: matchId },
    leagueId: leagueId,
    roundNumber: roundNumber,
  });
  const postTippsCallback = useCallback(
    ({ homeValue, awayValue, id }: LocalTippValues) => {
      if (homeValue != null && awayValue != null) {
        if (homeValue === -1 || awayValue === -1) {
          deleteTipp();
        } else {
          postTipps({
            fixture_id: id,
            home_score: homeValue,
            away_score: awayValue,
          });
        }
      }
    },
    [postTipps, deleteTipp]
  );

  const [{ homeValue, awayValue, id }, setTipValues] =
    useStateWithThrottledSideEffect<LocalTippValues>(
      { homeValue: undefined, awayValue: undefined, id: matchId },
      1000,
      postTippsCallback
    );

  return {
    responseData: {
      homeValue: postTippData?.data.home_score,
      awayValue: postTippData?.data.away_score,
      id,
    },
    localValues:
      homeValue === -1 || awayValue === -1
        ? { homeValue: -1, awayValue: -1, id }
        : { homeValue, awayValue, id },
    setTipValues: (values: LocalTippValues) => {
      setTipValues(values);
      trackTipp(trackingFunc);
    },
  };
}

function useStateWithThrottledSideEffect<T>(
  defaultValue: T,
  timeout: number,
  callback: (value: T) => void
): [T, (newValue: T) => void] {
  const timeoutRef = useRef<number>();
  const callbackRef = useRef<() => void>();
  const [value, setValue] = useState(defaultValue);

  const callCallback = useCallback(() => {
    if (callbackRef.current) {
      callbackRef.current();
      callbackRef.current = undefined;
      timeoutRef.current = undefined;
    }
  }, []);

  const setValueImp = useCallback(
    (newValue: T) => {
      setValue(newValue);
      callbackRef.current = () => callback(newValue);

      if (!timeoutRef.current) {
        timeoutRef.current = setTimeout(callCallback, timeout);
      }
    },
    [setValue, callCallback, callback, timeout]
  );

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
      callCallback();
    };
  }, [callCallback]);

  return [value, setValueImp];
}
