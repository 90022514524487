import { Dispatch } from "react";
import { useQuery } from "react-query";
import { ErrorResponse } from "../../models/ErrorResponse";
import { TipUser } from "../../models/TipUser";
import { ApiError } from "./ApiError";
import { AuthState } from "./useAuthState";
import { useAuthUrl } from "./useBackendUrl";

export async function getAuthUser(
  authUrl: string,
  jwtToken: string
): Promise<TipUser> {
  const headers = {
    Authorization: `Bearer ${jwtToken}`,
  };
  const response = await fetch(authUrl, {
    method: "GET",
    headers: headers,
  });

  if (!response.ok) {
    const error: ErrorResponse = (await response.json()) as ErrorResponse;
    throw new ApiError(response.status, error.short_code, error.description);
  }
  const json = (await response.json()) as TipUser;
  return json;
}

interface GetAuthUserProps {
  jwtToken: string;
  enabled: boolean;
  setAuthState: Dispatch<React.SetStateAction<AuthState>>;
}

export function useGetAuthUser({
  jwtToken,
  enabled,
  setAuthState,
}: GetAuthUserProps) {
  const authUrl = useAuthUrl();
  return useQuery(["GetAuthUser"], () => getAuthUser(authUrl, jwtToken), {
    enabled: enabled,
    onSuccess: (data) => {
      setAuthState((props) => {
        return {
          ...props,
          user: data,
        };
      });
    },
  });
}
