import { useParams } from "react-router";
import { useGetLeague } from "../../api/useGetLeague";
import { useGetTipgroup } from "../../api/useGetTipgroup";
import { QueryWrapper } from "../../components/common/QueryWrapper";
import { E2BarSticky } from "../../components/e2/E2Bar";
import { TipGroupLeagueHeader } from "../../components/tipgroup/TipGroupHeader";
import { TipGroupLeague } from "../../components/tipgroup/TipGroupLeague";
import { Error404Page } from "../Error404Page";

interface TipGroupLeaguePageParamKeys extends Record<string, string> {
  tipGroupId: string;
  leagueId: string;
  roundNumber: string;
  tipGroupName: string;
  leagueName: string;
}

export function TipGroupLeaguePage() {
  const params = useParams<TipGroupLeaguePageParamKeys>();

  if (
    params.tipGroupId &&
    params.leagueId &&
    params.roundNumber &&
    params.tipGroupName &&
    params.leagueName
  ) {
    if (params.roundNumber === undefined || params.roundNumber == "0") {
      return (
        <TipGroupLeagueCurrentGamedayPreloader
          tipGroupId={params.tipGroupId}
          leagueId={params.leagueId}
          roundNumber={params.roundNumber}
          tipGroupName={params.tipGroupName}
          leagueName={params.leagueName}
        />
      );
    }

    return (
      <TipGroupLeagueHome
        tipGroupId={params.tipGroupId}
        leagueId={params.leagueId}
        roundNumber={params.roundNumber}
        tipGroupName={params.tipGroupName}
        leagueName={params.leagueName}
      />
    );
  } else {
    return <Error404Page />;
  }
}

interface TipGroupLeagueHomeProps {
  leagueId: string;
  tipGroupName: string;
  leagueName: string;
  tipGroupId: string;
  roundNumber: string;
}

function TipGroupLeagueHome({
  tipGroupName,
  leagueName,
  tipGroupId,
  roundNumber,
  leagueId,
}: TipGroupLeagueHomeProps) {
  const { data: groupData } = useGetTipgroup({
    groupId: tipGroupId,
  });

  return (
    <>
      <TipGroupLeagueHeader
        groupName={tipGroupName}
        leagueName={leagueName}
        leagueId={leagueId}
        image={groupData?.data.image?.thumbnail_file_url}
        fullImage={groupData?.data.image?.full_file_url}
      />
      <TipGroupLeague
        groupId={tipGroupId}
        leagueId={leagueId}
        roundNumber={parseInt(roundNumber)}
      />
      <E2BarSticky roundNumber={parseInt(roundNumber)} />
    </>
  );
}

interface TipGroupLeagueCurrentGamedayPreloaderProps {
  leagueId: string;
  tipGroupName: string;
  leagueName: string;
  tipGroupId: string;
  roundNumber: string;
}

function TipGroupLeagueCurrentGamedayPreloader({
  tipGroupName,
  leagueName,
  tipGroupId,
  roundNumber,
  leagueId,
}: TipGroupLeagueCurrentGamedayPreloaderProps) {
  const { data: leagueData, ...leagueQuery } = useGetLeague({
    leagueId: leagueId,
  });
  const { data: groupData } = useGetTipgroup({
    groupId: tipGroupId,
  });

  return (
    <>
      <TipGroupLeagueHeader
        groupName={tipGroupName}
        leagueName={leagueName}
        leagueId={leagueId}
        image={groupData?.data.image?.thumbnail_file_url}
        fullImage={groupData?.data.image?.full_file_url}
      />
      <QueryWrapper useQueryResult={[leagueQuery]}>
        <TipGroupLeague
          groupId={tipGroupId}
          leagueId={leagueId}
          roundNumber={
            leagueData?.data?.currentRound.number ?? parseInt(roundNumber)
          }
        />
      </QueryWrapper>
      <E2BarSticky roundNumber={parseInt(roundNumber)} />
    </>
  );
}
